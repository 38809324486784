import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { verifyPhone, requestSendOtp, toggleLoader, showAlert } from 'actions/actions'
import { withStyles, Card, Button } from '@material-ui/core';
import loginBackground from 'images/login-background.png'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';

let myInterval

class VerifyPhone extends Component {

	state = {
		code: "",
		timer: 30
	}

	componentDidMount() {
		myInterval = setInterval(() => {
			if (this.state.timer !== 0)
				this.setState({
					timer: this.state.timer - 1
				})
			else {
				clearInterval(myInterval)
			}
		}, 1000)
	}

	componentWillUnmount() {
		clearInterval(myInterval)
	}

	handleSubmitOtp = () => {
		let type = this.props.changePhoneNumber ? 'change' : 'signup'

		if (this.state.code.length === 6)
			this.props.verifyPhone(this.state.code, this.props.history, type, this.props.changeStep)
	}

	goBack = () => {
		if (!this.props.changePhoneNumber) {
			this.props.history.push("/user/my-profile")
		} else {
			this.props.history.push("/user/my-account")
		}
	}

	resendOtp = () => {
		if (this.state.timer === 0) {
			this.props.toggleLoader()
			requestSendOtp().then(
				res => {
					if (res.status !== 200) {
						console.log("Something went wrong")
						this.props.toggleLoader()
						return
					}
					this.props.toggleLoader()
					this.props.showAlert('Success', 'Code has been sent again.');

					this.setState({ timer: 30 }, () => {
						myInterval = setInterval(() => {
							if (this.state.timer !== 0)
								this.setState({
									timer: this.state.timer - 1
								})
							else {
								clearInterval(myInterval)
							}
						}, 1000)
					})
				},
				err => {
					this.props.toggleLoader()
					console.log('error', JSON.stringify(err))
				}
			)
		}
	}

	render() {
		const { classes, userInfo, changePhoneNumber } = this.props
		const { code, timer } = this.state

		if (_.isObject(userInfo) && _.isEmpty(userInfo)) {
			return <div></div>
		}

		if ((userInfo == null || userInfo.sign_up_status_2 != "secondStageVerifyPhone") && !changePhoneNumber) {
			this.props.history.push('/user/my-profile')//@notfound
			return null
		}

		return (
			<div className={classes.container} style={{ ...changePhoneNumber && { background: 'none', minHeight: 'auto', marginTop: 50 } }}>
				<Card className={classes.card}>
					<h1>Verify phone number</h1>
					<ValidatorForm onSubmit={this.handleSubmitOtp}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40, }}>
							<img src={require('images/Auth/verifyPhone.svg')} style={{ width: 126, height: 112 }} />
							<Text style={{ fontSize: 16, marginTop: 20 }}>Verification Code</Text>
							<Text style={{ color: Theme.Colors.brownishGrey, marginTop: 10 }}>Enter the 6 digit verification code we texted you</Text>
							<TextValidator
								value={code}
								onChange={(e) => this.setState({ code: e.target.value })}
								name="code"
								placeholder="Enter the code"
								variant="outlined"
								style={{ width: '30%', marginTop: 30 }} />
							<div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '30%' }}>
								<Text>00:{_.padStart(timer, 2, '0')}</Text>
								<a className="pointer" onClick={this.resendOtp} style={{ color: timer === 0 ? Theme.Colors.primaryPurple : Theme.Colors.warmGreyTwo }}>Resend Code</a>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 70, ...changePhoneNumber && { justifyContent: 'center' } }}>
							{
								!changePhoneNumber &&
								<Button
									color={'primary'}
									variant="outlined"
									onClick={this.goBack}
									style={{ fontWeight: 700, width: 170 }}>
									Back
                        		</Button>
							}
							<Button
								color={this.state.code.length !== 6 ? "default" : "primary"}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps = dispatch => ({
	verifyPhone: (code, navigation, type, changeStep) => dispatch(verifyPhone(code, navigation, type, changeStep)),
	toggleLoader: () => dispatch(toggleLoader()),
	showAlert: (title, msg) => dispatch(showAlert(title, msg))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: '60%',
		padding: 60,
		borderRadius: 10
	}
}

export default withRouter(withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(VerifyPhone)))