import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import ContainedButton from 'components/ContainedButton';
import { Checkbox } from '@material-ui/core';
import OutlinedButton from 'components/OutlinedButton';

class Step0 extends React.Component {

	state = {
		question1: [{
			answer: "General Investing", risk: 0
		}, {
			answer: "Long Term Investing", risk: 0
		}, {
			answer: "Retirement Investing", risk: 0
		}],
		question2: [{
			answer: "Minimizing losses", risk: 1
		}, {
			answer: "Maximizing gains", risk: 3
		}, {
			answer: "Equal", risk: 0
		}],
		question3: [{
			answer: "None", value: "NONE", risk: 0
		}, {
			answer: "1 - 2 years", value: "YRS_1_2", risk: 0
		}, {
			answer: "3 - 5 years", value: "YRS_3_5", risk: 0
		}, {
			answer: "5 - 10 years", value: "YRS_5_10", risk: 0
		}, {
			answer: "Above 10 years", value: "YRS_10_", risk: 0
		}],
		question4: [{
			answer: "Sell all", value: "LOW", risk: 1
		}, {
			answer: "Keep but sell a bit", value: "MODERATE", risk: 2
		}, {
			answer: "Keep all", value: "SPECULATION", risk: 3
		}, {
			answer: "Buy more", value: "HIGH", risk: 4
		}]
	}

	handleSelect = (v) => {
		const { formData_risk_profile, changeState, state } = this.props.parentProps

		formData_risk_profile.answers[state.risk_profile_step - 1].option.text = v.value || v.answer
		formData_risk_profile.answers[state.risk_profile_step - 1].option.value = v.risk

		changeState(state)
	}

	handleSubmit = () => {
		const { changeStep } = this.props.parentProps

		changeStep(1)
	}

	changeStep = (step) => {
		const { changeState, state } = this.props.parentProps
		state.risk_profile_step = step

		changeState(state)
	}

	handleBackPress = () => {
		const { navigation, state } = this.props.parentProps
		if (state.risk_profile_step === 1) {
			navigation.push("/user/invest")
		} else {
			this.changeStep(state.risk_profile_step - 1)
		}
	}

	render() {
		const { formData_risk_profile, state } = this.props.parentProps

		let actionCondition = !_.isEmpty(formData_risk_profile.answers[state.risk_profile_step - 1].option.text)

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>{formData_risk_profile.answers[state.risk_profile_step - 1].question}</Text>
				{
					_.map(this.state[`question${state.risk_profile_step}`], (value, index) => {
						let selected = (value.value || value.answer) === formData_risk_profile.answers[state.risk_profile_step - 1].option.text
						return (
							<div onClick={() => this.handleSelect(value)} key={`item_${index}`} style={{ display: "flex", marginTop: 30 }}>
								<Checkbox
									color="primary"
									checked={selected}
								/>
								<Text style={{ fontSize: 16, marginTop: 9, ...selected && { color: Theme.Colors.primaryPurple } }}>
									{value.answer}
								</Text>
							</div>
						)
					})
				}

				<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={this.handleBackPress}>
						Previous
            		</OutlinedButton>
					<ContainedButton
						color={!actionCondition && "default"}
						style={{ width: 200 }}
						onClick={!actionCondition ? () => { } : () => {
							if (state.risk_profile_step === 4) {
								this.handleSubmit()
								return
							}
							this.changeStep(state.risk_profile_step + 1)
						}}>
						Continue
            		</ContainedButton>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Step0)

const STYLES = {
	headline: {
		fontSize: 24, width: "80%"
	}
}