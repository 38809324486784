import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { Button } from '@material-ui/core';

export default class US_LostCardStep3 extends Component {

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
				<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
				<Text style={{ fontSize: 20, marginTop: 20 }}>Confirmed!</Text>
				<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, textAlign: 'center', marginTop: 20 }}>Your card is on it’s way</Text>
				<Button
					variant="contained"
					color="primary"
					style={{ marginTop: 40, width: 200 }}
					onClick={() => this.props.history.push("/user/my-cards/north-loop-us-debit/details")}>
					DONE
				</Button>
			</div>
		)
	}
}
