import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { create_investor } from '../../actions'
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Header from 'components/Header';

class USI_Auth extends Component {

	state = {
		formData: {
			idNo: "123456",//garbage value
			idType: "PAN",
			gender: _.capitalize(this.props.userInfo.basic_information.gender || ""),
			citizenship: "IN",
			politicallyExposedNames: false,
			address1: "",
			address2: "",
			city: "",
			state: "",
			postalCode: "",
			employmentStatus: "EMPLOYED",
			employmentPosition: "ACCOUNTANT",
			employmentCompany: "",
			employmentType: "PROFESSIONAL",
			isBroker: false,
			directorOf: false,
			investmentObjectives: "",
			investmentExperience: "",
			annualIncome: "",
			illiquidAssets: "",
			liabilities: "",
			networthLiquid: "",
			networthTotal: 0,
			riskTolerance: "",
			termsOfUse: true,
			rule14b: true,
			customerAgreement: true,
			marketDataAgreement: true,
			privacyPolicy: true,
			dataSharing: true,
			signedBy: this.props.userInfo.basic_information.name
		},
		formData_risk_profile: {
			answers: [
				{
					question: "What are you looking for?",
					option: {
						text: "",
						value: 0
					}
				},
				{
					question: "When investing what do you care about more?",
					option: {
						text: "",
						value: 0
					}
				},
				{
					question: "How new are you to investing?",
					option: {
						text: "",
						value: 0
					}
				},
				{
					question: "If the stock market fell 10% over a month during a downturn, what would you do?",
					option: {
						text: "",
						value: 0
					}
				},
			],
			country: "US"
		},
		check: true,
		modal: false,
		step: this.props.userInfo.risk_score === null ? 0 : 1,
		risk_profile_step: 1
	}

	handleChange = (name, value) => {
		const { formData } = this.state

		switch (name) {
			default:
				_.set(formData, name, value)
		}

		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData, formData_risk_profile } = this.state

		let data = JSON.parse(JSON.stringify(formData))

		data.annualIncome = _.isEmpty(data.annualIncome) ? 0 : data.annualIncome
		data.illiquidAssets = _.isEmpty(data.illiquidAssets) ? 0 : data.illiquidAssets
		data.liabilities = _.isEmpty(data.liabilities) ? 0 : data.liabilities
		data.networthLiquid = _.isEmpty(data.networthLiquid) ? 0 : data.networthLiquid

		data.networthTotal = data.networthLiquid + data.illiquidAssets - data.liabilities
		_.unset(data, "illiquidAssets")
		_.unset(data, "liabilities")

		if (data.employmentStatus !== "EMPLOYED" && data.employmentStatus !== "SELF_EMPLOYED") {
			_.unset(data, "employmentCompany")
			_.unset(data, "employmentPosition")
			_.unset(data, "employmentType")
		}

		this.props.create_investor(data, formData_risk_profile, this.props.history)
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	changeState = (state) => {
		this.setState(state)
	}

	handleBackPress = () => {
		const { step } = this.state
		const { userInfo } = this.props

		if (step !== 0) {
			if (step === 1 && userInfo.risk_score !== null) {
				this.props.history.push("/user/invest")
			} else {
				this.changeStep(step - 1)
			}
			return true;
		}

		return false;
	}

	render() {
		const { formData, formData_risk_profile, step } = this.state

		let parentProps = {
			formData: formData,
			formData_risk_profile: formData_risk_profile,
			state: this.state,
			changeStep: this.changeStep,
			changeState: this.changeState,
			handleChange: this.handleChange,
			handleSubmit: this.handleSubmit,
			handleBack: this.handleBackPress,
			navigation: this.props.history
		}

		return (
			<div style={{ padding: 50, background: Theme.Colors.white, marginTop: 40, marginBottom: 50, borderRadius: 5 }}>

				{
					step === 0 &&
					<Step0 parentProps={parentProps} />
				}

				{
					step === 1 &&
					<Step1 parentProps={parentProps} />
				}

				{
					step === 2 &&
					<Step2 parentProps={parentProps} />
				}

				{
					step === 3 &&
					<Step3 parentProps={parentProps} />
				}

				{
					step === 4 &&
					<Step4 parentProps={parentProps} />
				}

				{
					step === 5 &&
					<Step5 parentProps={parentProps} />
				}

				{
					step === 6 &&
					<Step6 parentProps={parentProps} />
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps = dispatch => ({
	create_investor: (data, formData_risk_profile, navigation) => dispatch(create_investor(data, formData_risk_profile, navigation))
})

export default connect(mapStateToProps, mapDispatchToProps)(USI_Auth)

const STYLES = {
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	arrowBack: {
		marginLeft: -5
	}
}