import React, { Component } from 'react'
import _ from 'lodash'
import Step3Address from 'screens/Auth/BankAccount/Step3Address';
import Step3AddressAutoComplete from 'screens/Auth/BankAccount/Step3AddressAutoComplete';
import Step4ConfirmAddress from 'screens/Auth/BankAccount/Step4ConfirmAddress';
import { Button } from '@material-ui/core';
import Text from 'components/Text';
import Theme from 'resources/Theme';
import Header from 'components/Header';

export default class ChangeAddress extends Component {

	state = {
		step: 1
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	render() {
		const { step } = this.state
		return (
			<div>
				<Header name="Change Address" onBack={true} />
				{
					step == 1 &&
					<Step3AddressAutoComplete changeStep={this.changeStep} changeAddress={true} />
				}

				{
					step == 2 &&
					<Step3Address changeStep={this.changeStep} changeAddress={true} />
				}

				{
					step == 3 &&
					<Step4ConfirmAddress changeStep={this.changeStep} changeAddress={true} />
				}

				{
					step == 4 &&
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
						<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
						<Text style={{ fontSize: 20, marginTop: 20 }}>Done</Text>
						<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, textAlign: 'center', marginTop: 20 }}>Successfully changed your address.</Text>
						<Button
							variant="contained"
							color="primary"
							style={STYLES.actionButton}
							onClick={() => this.props.history.push("/user/my-account")}>
							DONE
                		</Button>
					</div>
				}
			</div>
		)
	}
}

const STYLES = {
	actionButton: {
		marginTop: 40
	}
}