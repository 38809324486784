import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";

import Theme from "resources/Theme";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 26,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
  },
  tableContainer: {
    maxWidth: 847,
    "& .MuiTable-root": {
      marginTop: 10,
      "& .MuiTableBody-root tr:hover": {
        background: Theme.Colors.lightPurple,
      },
    },
  },
  tableHeaderCell: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    color: "#263238",
    textTransform: "uppercase",
  },
  tableBodyCell: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 12,
    color: "#131212"
  },
  iconButton: {
    color: Theme.Colors.primaryPurple,
  },
  divider: {
    height: 2,
    flexGrow: 0,
    backgroundColor: "rgba(219, 219, 219, 0.46)",
  },
}));

function createData(assetName, date, amount) {
  return { assetName, date, amount };
}

const rows = [
  createData("High Growth Startups A", "25/04/2021 | 6:56 AM", "$35,000"),
  createData("High Growth Startups A", "25/04/2021 | 6:56 AM", "$35,000"),
  createData("High Growth Startups A", "25/04/2021 | 6:56 AM", "$35,000"),
  createData("High Growth Startups A", "25/04/2021 | 6:56 AM", "$35,000"),
  createData("High Growth Startups A", "25/04/2021 | 6:56 AM", "$35,000"),
];

const options = ["Edit", "Remove"];

const InvestmentHistory = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenMenu = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const renderBodyRow = useCallback(
    () =>
      rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell className={classes.tableBodyCell}>{row.assetName}</TableCell>
          <TableCell className={classes.tableBodyCell}>{row.date}</TableCell>
          <TableCell className={classes.tableBodyCell}>{row.amount}</TableCell>
          <TableCell className={classes.tableBodyCell}>
            <IconButton
              classes={{ root: classes.iconButton }}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleOpenMenu(event, row)}
            >
              <MoreHorizIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [rows]
  );

  return (
    <>
      <Box mb={3}>
        <Typography classes={{ h5: classes.title }} variant="h5">
          Your Investment History
        </Typography>
        <Divider
          classes={{ root: classes.divider }}
          style={{ width: 146, marginTop: 14 }}
        />
      </Box>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                asset name
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>date</TableCell>
              <TableCell className={classes.tableHeaderCell}>amount</TableCell>
              <TableCell className={classes.tableHeaderCell}>
                view more
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderBodyRow()}</TableBody>
        </Table>
      </TableContainer>
      {selectedRow && (
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={true}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem key={index} onClick={handleClose}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default InvestmentHistory;
