import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Text from 'components/Text';
import moment from 'moment'
import CreateAccountCard from 'components/CreateAccountCard';
// import NewsSection from './NewsSection';
import { getMyProfileData, getUpdateLinkToken } from './actions';
import { withStyles } from '@material-ui/core';
// import CreditCardSection from './CreditCardSection';
import BankAccountSection from './BankAccount/BankAccountSection';
import CreditCardSection from './CreditCard/CreditCardSection';
import Header from 'components/Header';
import NRI_Banking_Section from "screens/NRI_Banking/NRI_Banking_Section"
class MyProfile extends React.Component {

	state = {
		modal: false,
		item_id: ""
	}

	componentWillMount() {
		if (_.isEmpty(this.props.accountInfo))
			this.props.getMyProfileData()
	}

	toggleModal = (item_id = "") => {
		this.setState({
			modal: !this.state.modal,
			item_id
		})
	}

	render() {
		const { userInfo } = this.props
		let title = ''
		let format = 'hh:mm:ss'

		let time = moment(Date.now())
		let firstTime = moment('05:00:00', format)
		let secondTime = moment('12:00:00', format)
		let thirdTime = moment('17:00:00', format)
		let fourthTime = moment('23:59:59', format)
		let fifthTime = moment('00:00:00', format)

		if (time.isBetween(firstTime, secondTime) || time.isSame(firstTime)) {
			title = "Morning"
		}

		if (time.isBetween(secondTime, thirdTime) || time.isSame(secondTime)) {
			title = "Afternoon"
		}

		if (time.isBetween(thirdTime, fourthTime) || time.isSame(thirdTime) || time.isSame(fourthTime) || time.isBetween(fifthTime, firstTime) || time.isSame(fifthTime)) {
			title = "Evening"
		}

		let name = _.capitalize(_.split(_.get(userInfo, 'basic_information.name') || _.get(userInfo, 'name') || "", " ")[0])

		return (
			<div>
				<Header name="Home" />
				<div style={{ paddingBottom: 40 }}>
					<Text style={{ fontSize: 20 }}>Good {title}, {name}</Text>

					<BankAccountSection toggleModal={this.toggleModal} />
					<NRI_Banking_Section history={this.props.history} />
					<CreateAccountCard />
					<CreditCardSection />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),
	..._.pick(state.AddFunds, ['linkedAccountsDebit']),
})

const mapDispatchToProps = dispatch => ({
	getMyProfileData: () => dispatch(getMyProfileData()),
	getUpdateLinkToken: (item_id, navigation) => dispatch(getUpdateLinkToken(item_id, navigation))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)

const STYLES = {

}