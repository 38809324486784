import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import { Checkbox } from '@material-ui/core';
import OutlinedButton from 'components/OutlinedButton';

export default class Step1 extends Component {

	render() {
		const { formData, changeStep, handleBack, handleChange } = this.props.parentProps

		const actionCondition = !_.isEmpty(formData.investmentObjectives)

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>How often do you plan on trading?</Text>

				{
					_.map([{
						name: "Long Term", value: "LONG_TERM"
					}, {
						name: "Infrequent", value: "INFREQUENT"
					}, {
						name: "Frequent", value: "FREQUENT"
					}, {
						name: "Day Trading", value: "ACTIVE_DAILY"
					}, {
						name: "New to investing", value: "NEW"
					}], (value, index) => {
						let selected = value.value === formData.investmentObjectives
						return (
							<div onClick={() => handleChange("investmentObjectives", value.value)} key={`item_${index}`} style={{ display: "flex", marginTop: 30 }}>
								<Checkbox
									color="primary"
									checked={selected}
								/>
								<Text style={{ fontSize: 16, marginTop: 9, ...selected && { color: Theme.Colors.primaryPurple } }}>
									{value.name}
								</Text>
							</div>
						)
					})
				}

				<div style={{ width: 450, paddingTop: 120, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={handleBack}>
						Previous
            		</OutlinedButton>
					<ContainedButton
						color={!actionCondition && "default"}
						style={{ width: 200 }}
						onClick={actionCondition ? () => changeStep(2) : () => { }}>
						Continue
            		</ContainedButton>
				</div>
			</div>
		)
	}
}

const STYLES = {
	container: {
		flex: 1,
	},
	contentContainer: {
		width: "90%",
		marginLeft: '5%'
	},
	headline: {
		fontSize: 24
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	box: {
		alignItems: "center", justifyContent: "center", backgroundColor: Theme.Colors.white, height: 80, marginTop: 20, borderRadius: 10, borderWidth: 1, borderColor: Theme.Colors.white
	},
	arrowBack: {
		marginLeft: -5
	},
	actionButton: {
		marginTop: 30, marginBottom: 30
	}
}