import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import { TextField } from '@material-ui/core';
import OutlinedButton from 'components/OutlinedButton';

export default class Step6 extends Component {

	render() {
		const { formData, handleBack, handleChange, handleSubmit } = this.props.parentProps

		const actionCondition = !_.isEmpty(formData.signedBy)

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>Please enter your name below to sign electronically</Text>

				<TextField
					onChange={e => handleChange("signedBy", e.target.value)}
					value={formData["signedBy"]}
					style={STYLES.textInput}
					label="Name"
					fullWidth
					placeholder="Enter Name" />

				<Text style={{ fontSize: 12, color: Theme.Colors.purplishGrey, marginTop: 20 }}>I authorize my broker and/ or Clearing Firm to obtain a consumer report at the time of application to verify my creditworthiness and to obtain a consumer report from time to time for updates, renewals, extensions, and collection activity on any approved account. Upon my written request, my broker and/ or Clearing Firm will disclose to me whether it obtained a report, and if so, the name and address of the consumer-reporting agency that provided it. In the event that my account is denied by Clearing Firm, as a result of the consumer report verification, I authorize Clearing Firm to provide to my broker the reason(s) for such denial.</Text>
				<Text style={{ fontSize: 12, color: Theme.Colors.purplishGrey, marginTop: 10 }}>W-8BEN (Non U.S. Citizens)</Text>

				<Text style={{ fontSize: 12, color: Theme.Colors.purplishGrey, marginTop: 10 }}>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:</Text>

				<div style={{ marginLeft: 20 }}>
					<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
						<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
						<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
							I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the beneficial owner) of all the income to which this form relates or am using this form to document myself as an individual that is an owner or account holder of a foreign financial institution,
							</Text>
					</div>
					<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
						<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
						<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
							The person named on line 1 of this form is not a U.S. person,
							</Text>
					</div>
					<div style={{ display: "flex", alignItems: 'flex-start' }}>
						<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
						<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
							The income to which this form relates is:
							</Text>
					</div>
					<div style={{ marginLeft: 40 }}>
						<div style={{ display: "flex", alignItems: 'flex-start' }}>
							<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
							<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
								not effectively connected with the conduct of a trade or business in the United States,
								</Text>
						</div>
						<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
							<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
							<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
								effectively connected but is not subject to tax under an applicable income tax treaty, or
								</Text>
						</div>
						<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
							<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
							<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
								the partner’s share of a partnership's effectively connected income,
								</Text>
						</div>
					</div>
					<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
						<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
						<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
							The person named on this form is a resident of the treaty country listed on the form (if any) within the meaning of the income tax treaty between the United States and that country, and
							</Text>
					</div>
					<div style={{ display: "flex", alignItems: 'flex-start', marginTop: 10 }}>
						<Text style={{ fontSize: 50, color: Theme.Colors.purplishGrey, marginTop: -40 }}>.</Text>
						<Text style={{ marginLeft: 5, fontSize: 12, color: Theme.Colors.purplishGrey }}>
							For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.
							</Text>
					</div>
				</div>
				<Text style={{ fontSize: 12, color: Theme.Colors.purplishGrey, marginTop: 10 }}>Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income of which I am the beneficial owner or any withholding agent that can disburse or make payments of the income of which I am the beneficial owner. I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect. </Text>

				<div style={{ width: 450, paddingTop: 50, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={handleBack}>
						Previous
            			</OutlinedButton>
					<ContainedButton
						color={!actionCondition && "default"}
						style={{ width: 200 }}
						onClick={actionCondition ? handleSubmit : () => { }}>
						Continue
            		</ContainedButton>
				</div>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	arrowBack: {
		marginLeft: -5
	},
	textInput: {
		marginTop: 20,
	},
	actionButton: {
		marginTop: 30, marginBottom: 30
	}
}