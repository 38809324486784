import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { Button, ListItem } from '@material-ui/core';
import Header from 'components/Header';

export default class US_LostCardStep1 extends React.Component {

	state = {
		reason: ""
	}

	handleSubmit = () => {
		if (this.state.reason !== "") {
			this.props.getStep1Details(this.state.reason === 'Damaged' ? false : true)
		}
	}

	render() {
		const { reason } = this.state
		return (
			<div>
				<Text style={STYLES.subHeadline}>Select why you would like to replace your card</Text>
				<Text style={STYLES.title}>Choose One</Text>
				<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
					{
						_.map([{
							name: "Damaged", image: require("images/LostCard/reason1.png")
						}, {
							name: "Lost", image: require("images/LostCard/reason2.png")
						}, {
							name: "Stolen", image: require("images/LostCard/reason3.png")
						}, {
							name: "Fraud", image: require("images/LostCard/reason4.png")
						}], (value, index) => {
							return (
								<ListItem
									key={`btn${index}`}
									button
									onClick={() => { this.setState({ reason: value.name }) }}
									style={{
										...STYLES.row,
										...reason === value.name && STYLES.selectedRow
									}}>
									<div>
										<img style={{ marginTop: 20 }} src={value.image} />
										<Text bold="true" style={{
											...STYLES.rowText,
											...reason === value.name && STYLES.selectedRowText
										}}>{value.name}</Text>
									</div>
								</ListItem>
							)
						})
					}
				</div>
				<Button
					variant="contained"
					color={reason === "" ? "default" : 'primary'}
					onClick={this.handleSubmit}
					style={{ marginTop: 40, width: '100%' }}>
					CONFIRM
				</Button>
			</div>
		);
	}
}


const STYLES = {
	subHeadline: {
		fontSize: 16, color: Theme.Colors.black, marginTop: 30
	},
	title: {
		fontSize: 14, color: Theme.Colors.purplishGrey, marginTop: 30
	},
	row: {
		width: '45%', borderRadius: 10, border: `1px solid ${Theme.Colors.lightGrey}`, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 200, marginTop: 40
	},
	selectedRow: {
		border: `1px solid ${Theme.Colors.primaryPurple}`,
	},
	rowText: {
		color: Theme.Colors.black, marginTop: 10, fontSize: 18, textAlign: 'center', letterSpacing: 0.53
	},
	selectedRowText: {
		color: Theme.Colors.primaryPurple,
	}
}