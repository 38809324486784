import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import ContainedButton from 'components/ContainedButton';
import { create_order } from './actions';
import { showAlert, toggleError } from 'actions/actions';
import { Button, IconButton, MenuItem, Select, TextField } from '@material-ui/core';

class USI_Buy_Sell extends React.Component {

	state = {
		formData: {
			symbol: this.props.symbol,
			type: "MARKET",
			side: this.props.side,
			quantity: 0,
			amount: 0,
			category: "EQUITY",
			// price: 0
		},
		step: 1,
		price: this.props.price,
		type: "share", //or amount
		priceModal: false,
		typeModal: false
	}

	toggleModal = (name) => {
		this.setState({
			[`${name}Modal`]: !this.state[`${name}Modal`]
		})
	}

	handleChange = (name, value) => {
		const { formData, type } = this.state

		if (type === 'amount') {
			let split = _.split(value, "$")
			value = Number(split.length > 1 ? split[1] : value)
		}

		formData[name] = value
		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData, type } = this.state
		const { us_investor_details, holdings } = this.props

		let data = JSON.parse(JSON.stringify(formData))

		let available_shares = _.get(_.find(holdings, { symbol: formData.symbol }), "shares") || 0

		let estimated_cost
		let estimated_shares

		if (type === 'share') {
			_.unset(data, "amount")
			estimated_cost = data.quantity * this.state.price + 1
			estimated_shares = data.quantity
		} else {
			_.unset(data, "quantity")
			estimated_cost = data.amount + 1
			estimated_shares = (data.amount - 1) / this.state.price
		}

		switch (formData.side) {
			case "BUY":
				if (estimated_cost > _.get(us_investor_details, "cash_available")) {
					this.props.toggleError("Estimated cost or dollar amount should be less than available balance.")
					return
				}
				break;
			case "SELL":
				if (estimated_shares > available_shares) {
					this.props.toggleError("Estimated shares cannot be more than available balance.")
					return
				}
				break;
		}

		this.props.create_order(formData, this.changeStep)
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	handleBackPress = () => {
		if (this.state.typeModal) {
			this.setState({ typeModal: false })
			return
		}

		this.props.navigation.goBack()
	}

	render() {
		const { priceModal, formData, type, typeModal, price, step } = this.state
		const { us_investor_details, holdings } = this.props

		const actionCondition = (type === 'share' && formData.quantity > 0) || (type === 'amount' && formData.amount > 0)

		let symbol = formData.symbol
		let available_shares = _.get(_.find(holdings, { symbol }), "shares") || 0

		let estimated
		let share_price = price.replace(/,/g, "");

		switch (type) {
			case "share":
				if (formData.quantity > 0) {
					estimated = "$" + formData.side === "BUY" ? (formData.quantity * share_price + 1).toFixed(2) : (formData.quantity * share_price - 1).toFixed(2)
				} else {
					estimated = "$0"
				}
				break;

			case "amount":
				estimated = formData.amount > 1 ? ((formData.amount - 1) / share_price).toFixed(2) : 0
				break;
		}

		return (
			<div style={{ background: Theme.Colors.white, borderRadius: 5, padding: `40px 0px`, marginBottom: 40 }}>
				{
					step === 1 &&
					<>
						<div style={STYLES.headerContainer}>
							<IconButton onClick={() => this.props.changeStep(1)} style={{ marginLeft: -15 }}>
								<i className="material-icons" color={Theme.Colors.purplishGrey}>
									arrow_back
								</i>
							</IconButton>
							<Button onClick={() => { }}>
								<Text>Market Order</Text>
								<i className="material-icons" color={Theme.Colors.purplishGrey}>
									expand_more
								</i>
							</Button>
						</div>

						<div style={{ margin: `0 40px` }}>

							<div style={{ display: "flex", marginTop: 10 }}>
								<div>
									<Text bold="true" style={{ letterSpacing: 0.6, fontSize: 24, lineHeight: 2.2 }}>
										{_.capitalize(formData.side)} {symbol}
									</Text>
									{
										formData.side === "BUY" ?
											<Text style={{ letterSpacing: 0.6, lineHeight: 2.2, marginTop: 5, color: Theme.Colors.greyishBrown, fontSize: 12 }}>
												Available balance is <span style={{ color: Theme.Colors.darkishGreen }}>${_.get(us_investor_details, "cash_available")}</span>
											</Text>
											:
											<Text style={{ letterSpacing: 0.6, lineHeight: 2.2, marginTop: 5, color: Theme.Colors.greyishBrown, fontSize: 12 }}>
												Available shares is <span style={{ color: Theme.Colors.darkishGreen }}>{available_shares}</span>
											</Text>
									}
								</div>
							</div>
							<div style={{ ...STYLES.menu, paddingVertical: 20 }}>
								<Select
									style={{ width: 150 }}
									value={type}
									onChange={(e) => this.setState({ type: e.target.value })}>
									<MenuItem value="share">{`${_.capitalize(formData.side)} in shares`}</MenuItem>
									<MenuItem value="amount">{`${_.capitalize(formData.side)} in dollars`}</MenuItem>
								</Select>
								<div>
									<TextField
										variant="outlined"
										fullWidth
										placeholder={type === 'share' ? "Enter no.of shares" : "Enter amount"}
										value={
											formData[type === 'share' ? "quantity" : "amount"] === 0 ?
												"" :
												type === 'share' ? String(formData.quantity) : "$" + String(formData.amount)
										}
										onChange={e => this.handleChange(type === 'share' ? "quantity" : "amount", e.target.value)}
									/>
								</div>
							</div>

							<div style={STYLES.menu}>
								<div style={{ display: "flex" }}>
									<Text>Price</Text>
									<div
										onClick={() => this.props.showAlert("Price", "This is the latest price for the stock. You may find the final settlement price differs slightly")}
										style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: Theme.Colors.primaryPurple, display: "flex", alignItems: "center", justifyContent: 'center', marginLeft: 10, marginTop: -5, cursor: "pointer" }}>
										<Text style={{ color: Theme.Colors.white }}>?</Text>
									</div>
								</div>

								<Text bold="true" style={{ fontSize: 16 }}>
									${price}
								</Text>
							</div>

							<div style={STYLES.menu}>
								<Text>Estimated {type === 'share' ? (formData.side === "BUY" ? "cost" : "price") : "shares"}</Text>
								<Text>{estimated}</Text>
							</div>

							<div style={STYLES.actionButton}>
								<ContainedButton
									color={!actionCondition && "default"}
									onClick={actionCondition ? this.handleSubmit : () => { }}>
									NEXT
								</ContainedButton>
							</div>
							{/* 
						{
							typeModal &&
							<Modal styles={{ width: '100%', marginLeft: 0, height: '100%', padding: 0 }}>
								<div style={STYLES.contentContainer2}>
									<div style={STYLES.headerContainer2}>
										<IconButton icon="arrow-back" onPress={this.handleBackPress} style={STYLES.arrowBack2} />
									</div>
									<Text bold="true"style={STYLES.headline2}>Market orders</Text>
									{
										_.map([{
											name: ,
											image: require("src/images/Investing/US_Investing/dollar.png"),
											type: "share",
											subtext: "shares"
										}, {
											name: `${_.capitalize(formData.side)} in dollars`,
											image: require("src/images/Investing/US_Investing/share.png"),
											type: "amount",
											subtext: "dollar amounts"
										}], (value, index) => {
											return (
												<TouchableOpacity key={`item${index}`} onPress={() =>  style={{ paddingVertical: 25, borderColor: Theme.Colors.whiteTwo, borderBottomWidth: 1, display: "flex" }}>
													<Image source={value.image} style={{ width: 45, height: 45 }} />
													<div style={{ marginLeft: 20, }}>
														<Text bold="true"style={{ fontSize: 16, letterSpacing: 0.6, marginBottom: 5 }}>{value.name}</Text>
														<Text style={{ color: Theme.Colors.warmGrey, fontSize: 12 }}>{_.capitalize(formData.side)} {formData.symbol} in {value.subtext}</Text>
													</div>
												</TouchableOpacity>
											)
										})
									}

								</div>
							</Modal>
						} */}
						</div>
					</>
				}

				{
					step === 2 &&
					<div style={STYLES.contentContainer3}>
						<img src={require('images/tick_purple.png')} style={{ width: 100, height: 100 }} />
						<Text style={{ fontSize: 16, color: Theme.Colors.black, width: 300, textAlign: 'center', marginTop: 30 }}>Your order has been successfully placed!</Text>
						<Text style={{ fontSize: 16, color: Theme.Colors.black, width: 300, textAlign: 'center', marginTop: 30, marginBottom: 20 }}>We'll notify you when it's fulfilled.</Text>
						<ContainedButton
							style={STYLES.actionButton}
							onClick={() => this.props.changeStep(1)}>
							DONE
						</ContainedButton>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Investing, ["us_investor_details", "holdings"])
})

const mapDispatchToProps = dispatch => ({
	create_order: (data, changeStep) => dispatch(create_order(data, changeStep)),
	showAlert: (title, msg) => dispatch(showAlert(title, msg)),
	toggleError: (err) => dispatch(toggleError(err))
})

export default connect(mapStateToProps, mapDispatchToProps)(USI_Buy_Sell)

const STYLES = {
	headerContainer: {
		display: "flex", justifyContent: 'space-between', border: `0px solid ${Theme.Colors.whiteTwo}`, borderBottomWidth: 1, padding: `0px 40px`, paddingBottom: 20
	},
	contentContainer: {
		width: '90%', marginLeft: '5%', marginTop: 20
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	rowFlex: {
		display: "flex", justifyContent: 'space-between'
	},
	menu: {
		border: `0px solid ${Theme.Colors.whiteTwo}`, borderBottomWidth: 1, padding: '30px 0', display: "flex", justifyContent: "space-between", alignItems: 'center'
	},
	actionButton: {
		width: 200, margin: "0 auto", marginTop: 100
	},
	contentContainer2: {
		width: '90%', marginLeft: '5%'
	},
	headerContainer2: {
		marginTop: 20, display: 'flex', justifyContent: 'space-between'
	},
	headline2: {
		fontSize: 24, marginTop: 5, marginBottom: 10
	},
	arrowBack2: {
		marginLeft: -5
	},
	contentContainer3: {
		display: "flex", flexDirection: "column", alignItems: 'center', paddingTop: 100
	},
}