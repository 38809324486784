import React, { Component } from 'react'
import utils from 'resources/utils'
import _ from 'lodash'
import Text from 'components/Text'
import Theme from 'resources/Theme'
import { Button } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'

export default class Investing_First_Time extends Component {
	render() {
		return (
			<div style={STYLES.container} contentContainerStyle={{ minHeight: "100%" }}>
				<div style={STYLES.contentContainer}>
					<Text style={STYLES.headline}>Invest</Text>
					{
						_.map([{
							headline: "Buy and sell for just $1", description: "US Stock and ETF trades with near zero fees and no account minimums", image: require("images/Investing/First_Time/buy-sell.png")
						}, {
							headline: "Free Mutual Funds & SIPs", description: "Invest in over 3,000 Mutual Funds and set up SIPs in seconds.", image: require("images/Investing/First_Time/built.png")
						}, {
							headline: "Stay up to date", description: "Get convenient, curated content, data, and real-time news.", image: require("images/Investing/First_Time/up-to-date.png")
						}, {
							headline: "Goal Based Investing", description: "From buying a home to retirement, choose a goal and invest towards it.", image: require("images/Investing/First_Time/goal.png")
						}, {
							headline: "Safety First", description: "Serious technology to keep your money and personal info secure.", image: require("images/Investing/First_Time/safety-first.png")
						}, {
							headline: "Award Winning Advisors", description: "Speak to your dedicated advisor whenever you want", image: require("images/Investing/First_Time/advisors.png")
						}, {
							headline: "Built for Indians", description: "Trade from India or as NRI.", image: require("images/Investing/First_Time/built.png")
						}], (value, index) => {
							return (
								<div key={`item_${index}`} style={{ marginTop: 30, flexDirection: 'row', width: "80%" }}>
									<img src={value.image} style={{ height: 56, width: 56, marginTop: 5 }} />
									<div style={{ marginLeft: 20 }}>
										<Text bold="true" style={{ fontSize: 18, letterSpacing: 0.5 }}>{value.headline}</Text>
										<Text style={{ letterSpacing: 0.5, marginTop: 5, lineHeight: 20 }}>{value.description}</Text>
									</div>
								</div>
							)
						})
					}
					<ContainedButton
						style={{ marginTop: 40, marginBottom: 40 }}
						onClick={async () => {
							utils._storeData("investing_first_time_seen", "true")
							this.props.changeState({
								investing_first_time_seen: "true"
							})
						}}>
						Start Investing
					</ContainedButton>
				</div>
			</div>
		)
	}
}


const STYLES = {
	container: {
		flex: 1,
		backgroundColor: Theme.Colors.white
	},
	contentContainer: {
		width: "90%",
		marginLeft: "5%"
	},
	headline: {
		fontSize: 24, marginTop: 20
	}
}