import { toggleLoader, toggleError, userInfoUpdated } from 'actions/actions'
import _ from 'lodash'
import utils from 'resources/utils'
import { order_created } from "../actions"
import constants from 'resources/constants'
import ClevertapReact from 'clevertap-react'

// create investor flow
const requestCreateInvestor = (data) => (
	utils.us_investing_request({
		url: `/investors`,
		method: "POST",
		data
	})
)

const requestSubmitRiskProfile = (data) => (
	utils.request({
		url: "/risk-profiles",
		method: "POST",
		data
	})
)

const requestUploadKyc = (data) => (
	utils.us_investing_request({
		url: `/investors/upload-kyc-document`,
		method: "POST",
		data
	})
)

const request_create_order = (data) => (
	utils.us_investing_request({
		url: `/orders`,
		method: "POST",
		data
	})
)

const request_get_us_stock_company_info = (symbol) => (
	utils.us_investing_request({
		url: `/yahoo-finance/stock/about`,
		params: {
			symbol
		}
	})
)

const request_get_us_stock_details = (symbol) => (
	utils.us_investing_request({
		url: `/yahoo-finance/stock/profile`,
		params: {
			symbol
		}
	})
)

const request_get_us_stock_chart = (symbol) => (
	utils.us_investing_request({
		url: `/yahoo-finance/stock/chart`,
		params: {
			symbol,
			region: "US"
		}
	})
)

const request_pay_fees_init = () => (
	utils.us_investing_request({
		url: `/razorpay/orders`,
		method: "POST",
	})
)

const request_pay_fees_success = (data) => (
	utils.us_investing_request({
		url: `/razorpay/verify-payments`,
		method: "POST",
		data
	})
)


export const request_usi_investor_details = () => (
	utils.us_investing_request({
		url: `/investors/get-details`
	})
)

export const request_get_usi_investment_history = () => (
	utils.us_investing_request({
		url: `/orders/investment-value-history`
	})
)

export const request_get_usi_holdings = () => (
	utils.us_investing_request({
		url: `/investors/holdings`
	})
)

export const request_get_usi_order_history = () => (
	utils.us_investing_request({
		url: `/orders`
	})
)

export const create_investor = (data, formData_risk_profile, navigation) => (dispatch, getState) => {
	dispatch(toggleLoader())

	ClevertapReact.event("Create US Investing Account", data)

	let state = getState()

	return requestCreateInvestor(data).then(
		res => {
			if (res.status === 202) {
				dispatch(userInfoUpdated({ us_investing_status: constants.us_investing_status.CUSTOMER_CARE_ONBOARDING }))
				dispatch(toggleError("Our Customer Support Team will reach out to you.", "Alert"))
				dispatch(toggleLoader())
				navigation.push("/user/invest")
				return
			}

			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				dispatch(toggleError("Something went wrong. Please contact customer care"))
				return
			}

			dispatch(userInfoUpdated({ us_investing_status: constants.us_investing_status.UPLOAD_KYC, investment_experience: data.investmentExperience }))

			navigation.push("/user/invest/us/kyc")
			dispatch(toggleLoader())

			if (state.App.userInfo.risk_score === null) {
				requestSubmitRiskProfile(formData_risk_profile).then(
					res2 => {
						if (res2.status !== 200) {
							console.log("Something went wrong")
							return
						}
						dispatch(userInfoUpdated({ risk_score: _.get(res2, "data.data.risk_score") }))
					},
					err => {
						console.log(JSON.stringify(err), 'error')
					}
				)
			}
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const upload_kyc = (data, changeState) => async dispatch => {
	dispatch(toggleLoader())

	return requestUploadKyc(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(userInfoUpdated({ us_investing_status: constants.us_investing_status.PROCESSING }))
			changeState({ step: 3 })
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const pay_fees = () => async (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	return request_pay_fees_init().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			let data = _.get(res, "data.data")

			let options = {
				key: utils.get_razorpay_key(),
				name: "North Loop",
				order_id: data.razorpay_order_id,
				handler: function (razorpay_data) {
					dispatch(toggleLoader())
					request_pay_fees_success(razorpay_data).then(
						res2 => {
							if (res2.status !== 200) {
								console.log("Something went wrong")
								dispatch(toggleLoader())
								return
							}
							dispatch(userInfoUpdated({ us_investing_status: constants.us_investing_status.COMPLETED }))
							dispatch(toggleLoader())
						},
						err => {
							console.log(JSON.stringify(err), 'error')
							dispatch(toggleError(err))
							dispatch(toggleLoader())
						}
					)
				},
				prefill: {
					email: state.App.userInfo.basic_information.email,
					contact: state.App.userInfo.basic_information.phone_no_code + state.App.userInfo.basic_information.phone_no,
					name: state.App.userInfo.basic_information.name
				}
			};

			let rzp1 = new window.Razorpay(options);
			rzp1.open()

			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const get_us_stock_details = (symbol, update_initial_data) => dispatch => {
	dispatch(toggleLoader())

	Promise.all([
		request_get_us_stock_details(symbol),
		request_get_us_stock_company_info(symbol),
		request_get_us_stock_chart(symbol),
	]).then(
		res => {
			if (res[0].status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			if (res[1].status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			if (res[2].status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			update_initial_data({
				stock_details: res[0].data.data,
				company_details: res[1].data.data,
				chart_data_intra_day: res[2].data.data.intra_day,
				chart_data_past: res[2].data.data.past
			})
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleLoader())
		}
	)
}

export const create_order = (data, changeStep) => async dispatch => {
	dispatch(toggleLoader())

	ClevertapReact.event("Confirm US Stocks Buy/Sell", data)

	return request_create_order(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(order_created({ ...res.data.data, country: "US" }))
			changeStep(2)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}