import { toggleLoader, toggleError, userInfoUpdated, getIP } from "actions/actions"
import _ from 'lodash'
import utils from "resources/utils"
import constants from "resources/constants";
import { order_created } from "../actions";
import ClevertapReact from 'clevertap-react';

// create investor flow
const requestCheckKyc = () => (
	utils.mutual_fund_request({
		url: `/investors/kyc-status`
	})
)

const request_upload_documents = (data) => (
	utils.mutual_fund_request({
		url: `/investors/upload-kyc-images`,
		method: "POST",
		data
	})
)

const requestCreateInvestor = (data) => (
	utils.mutual_fund_request({
		url: `/investors`,
		method: "POST",
		data
	})
)

const requestSubmitRiskProfile = (data) => (
	utils.request({
		url: "/risk-profiles",
		method: "POST",
		data
	})
)

//Goals

const request_get_goal_calculation = (data) => (
	utils.mutual_fund_request({
		url: `/goal-calculation`,
		params: data
	})
)

// MF Details 

const request_mf_details = (isin) => (
	utils.mutual_fund_request({
		url: `/mutual-funds/${isin}`
	})
)

// create orders
const request_create_lumpsum_netbanking_order = (data) => (
	utils.mutual_fund_request({
		url: `/orders/lumpsum/netbanking`,
		method: "POST",
		data: data
	})
)

const request_create_mandate = (data) => (
	utils.mutual_fund_request({
		url: `/mandates/nach`,
		method: "POST",
		data: data
	})
)

const request_create_sip = (data) => (
	utils.mutual_fund_request({
		url: `/orders/sips`,
		method: "POST",
		data: data
	})
)

const request_create_sell_order = (data) => (
	utils.mutual_fund_request({
		url: `/orders/sell`,
		method: "POST",
		data: data
	})
)

export const request_get_mf_order_history = () => (
	utils.mutual_fund_request({
		url: `/orders/all`
	})
)

//Investor Details

const request_investor_details = () => (
	utils.mutual_fund_request({
		url: `/investors`,
	})
)

export const request_get_mf_holdings = () => (
	utils.mutual_fund_request({
		url: `/reports/holdings`,
	})
)


export const checkKyc = (navigation) => dispatch => {
	dispatch(toggleLoader())
	dispatch(toggleError("Please wait, we are checking your KYC status.", "Alert"))

	return requestCheckKyc().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			if (_.get(res, "data.data.kycStatus")) {
				navigation.push("/user/invest/in/signup")
				dispatch(userInfoUpdated({ mutual_fund_status: constants.mutual_fund_status.CREATE_INVESTOR }))
			} else {
				toggleError("Our Customer Support Team will be in touch shortly to complete your account", "Alert")
			}
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const upload_documents = (state, changeState) => dispatch => {
	dispatch(toggleLoader())

	let data = {
		signature: state.images.signature,
		cheque: state.images.cancelled_cheque,
	}

	return request_upload_documents(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			state.images.uploaded = true
			state.step = 7
			changeState(state)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const createInvestor = (data, formData_risk_profile, changeState) => async dispatch => {
	dispatch(toggleLoader())

	ClevertapReact.event("Create MF Account", data)

	data.ip_address = await getIP()

	return requestCreateInvestor(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			requestSubmitRiskProfile(formData_risk_profile).then(
				res2 => {
					if (res2.status !== 200) {
						console.log("Something went wrong")
						dispatch(toggleLoader())
						return
					}

					dispatch(userInfoUpdated({ risk_score: res2.data.data.risk_score }))
					changeState({
						step: 8,
						suggested_mutual_funds: res2.data.data.stocks
					})
					dispatch(toggleLoader())
				},
				err => {
					console.log(JSON.stringify(err), 'error')
					toggleError(err)
					dispatch(toggleLoader())
				}
			)
		},
		err => {
			let message = ''

			let data = _.get(err, 'response.data.data') || []
			for (let i = 0; i < data.length; i++) {
				message = message + `${i + 1}. ` + _.get(data[i], 'field') + " " + _.get(data[i], 'message')
				if (i !== data.length - 1) {
					message = message + '\n' + '\n'
				}
			}

			if (_.isEmpty(message)) {
				message = err
			}
			console.log(JSON.stringify(err), 'error')
			toggleError(message)
			dispatch(toggleLoader())
		}
	)
}

export const get_mf_details = (id, update_initial_data) => dispatch => {
	dispatch(toggleLoader())

	return request_mf_details(id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			update_initial_data(_.get(res, 'data.data') || {})
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const create_lumpsum_netbanking_order = (data, changeStep) => dispatch => {
	dispatch(toggleLoader())

	return request_create_lumpsum_netbanking_order(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(order_created({ ...(_.get(res, "data.data") || {}), country: "IN" }))
			changeStep(2)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const create_sell_order = (data, changeStep) => dispatch => {
	dispatch(toggleLoader())

	return request_create_sell_order(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(order_created({ ..._.get(res, "data.data") || {}, country: "IN" }))
			changeStep(2)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const create_sip = (data, changeStep) => dispatch => {
	dispatch(toggleLoader())

	data = JSON.parse(JSON.stringify(data))

	return request_create_mandate({
		mandate_type: "E_MANDATE",
		mandate_limit: data.orders[0].amount,
		provider_name: "BSE"
	}).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			data.orders[0].mandate_id = _.get(res, "data.data.id")

			request_create_sip(data).then(
				res => {
					if (res.status !== 200) {
						console.log("Something went wrong")
						dispatch(toggleLoader())
						return
					}

					dispatch(order_created({ ..._.get(res, "data.data") || {}, country: "IN" }))
					changeStep(2)
					dispatch(toggleLoader())
				},
				err => {
					console.log(JSON.stringify(err), 'error')
					toggleError(err)
					dispatch(toggleLoader())
				}
			)
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const get_goal_calculation = (data, changeState) => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	switch (data.type) {
		case "retirement":
			data = {
				...data,
				b_date: state.App.userInfo.basic_information.date_of_birth
			}
			break;

		case "purchase":
			data = _.pick(data, ["purchase_amt", "purchase_date", "type", "r_score"])
			break

		case "edu":
			data = _.pick(data, ["school_cost", "child_age", "type", "r_score"])
			break
	}

	request_get_goal_calculation(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			switch (data.type) {
				case "retirement":
					changeState({
						goal_calculation: _.get(res, "data.data")
					})
					break;

				case "edu":
					changeState({
						goal_calculation: _.get(res, "data.data"),
						step: 4
					})
					break
				case "purchase":
					changeState({
						goal_calculation: _.get(res, "data.data"),
						step: 3
					})
					break
			}

			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}