import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { } from './actions';
import utils from 'resources/utils';
import { toggleLoader, toggleError } from 'actions/actions';
import { Button, Card } from '@material-ui/core';
import Header from 'components/Header';

class ChangePassword extends React.Component {

	state = {
		emailSent: false
	}

	handleSubmit = async () => {
		let email = await utils._retrieveData("email")

		this.props.toggleLoader()
		utils.request({
			url: `/auth/reset-password`,
			method: "POST",
			data: {
				email: email
			}
		}).then(
			res => {
				if (res.status !== 200) {
					this.props.toggleLoader()
					return
				}
				this.props.toggleLoader()
				this.setState({
					emailSent: true
				})
			},
			err => {
				console.log(JSON.stringify(err), 'error')
				this.props.toggleError(err)
				this.props.toggleLoader()
			}
		)
	}

	render() {
		return (
			<div>
				<Header name="Change Password" onBack={true} />
				{
					this.state.emailSent ?
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
							<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
							<Text style={{ fontSize: 20, marginTop: 20 }}>Done</Text>
							<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, textAlign: 'center', marginTop: 20 }}>Password reset link has been sent to your email</Text>
							<Button
								variant="contained"
								color="primary"
								style={STYLES.actionButton}
								onClick={() => this.props.history.push("/user/my-account")}>
								DONE
                			</Button>
						</div>
						:
						<>
							<div style={STYLES.box}>
								<Text style={{ fontSize: 16, lineHeight: 2 }}>Password reset link will be sent to your registered email</Text>
							</div>
							<Button variant="contained" color="primary" style={{ marginTop: 40 }} onClick={this.handleSubmit}>
								SEND EMAIL
							</Button>
						</>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: () => dispatch(toggleLoader()),
	toggleError: (err) => dispatch(toggleError(err))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)


const STYLES = {
	box: {
		marginTop: 50, backgroundColor: Theme.Colors.whiteTwo, padding: 30, flexDirection: 'row'
	},
	openEmail: {
		marginTop: 40, marginBottom: 40
	},
	actionButton: {
		marginTop: 40
	}
}