import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { orderCard } from './actions';
import { getAccountInfo } from 'actions/actions';
import { Button, ListItem } from '@material-ui/core';
import Header from 'components/Header';

class US_OrderCard extends React.Component {

	state = {
		expedite: false,
		step: 1
	}

	componentWillMount() {
		if (_.isEmpty(this.props.accountInfo) && this.props.userInfo.sign_up_status_2 == 'completed') {
			this.props.getAccountInfo()
		}
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	handleSubmit = () => {
		if (
			(this.state.expedite == true && _.get(this.props.accountInfo, 'info.balance.amount') >= 60) ||
			(this.state.expedite == false && _.get(this.props.accountInfo, 'info.balance.amount') >= 20)
		) {
			this.props.orderCard({ expedite: this.state.expedite, }, this.changeStep)
		} else {
			this.setState({ step: -1 })
		}
	}

	render() {
		const { accountInfo, userInfo } = this.props
		const { step } = this.state

		if (_.isEmpty(accountInfo)) {
			return <div></div>
		}

		if (!(!accountInfo.isCardIssued && userInfo.cipTag === 3)) {
			this.props.history.push("/user/my-cards")
			return
		}

		return (
			<div>
				<Header name="Order Card" />
				{
					step == 1 &&
					<>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginTop: 30 }}>
							<img style={{ width: 157, height: 65, marginTop: 10 }} src={require('images/MyCard/order-card.png')} />
							<Text bold="true" style={{ fontSize: 22, marginTop: 20 }}>
								Order a card
                    		</Text>
							<Text style={{ color: Theme.Colors.greyishBrown, fontSize: 16, width: 300, textAlign: 'center', lineHeight: 2.4, marginTop: 7 }}>
								Please select an option to order your North Loop Mastercard
                    		</Text>
						</div>
						<Text style={{ fontSize: 14, marginTop: 30, color: Theme.Colors.purplishGrey }}>Select Delivery Type</Text>
						{
							_.map([{
								title: "Standard mail", subTitle: "8 business days", money: "0", expedite: false
							}, {
								title: "Rush", subTitle: "2 business days", money: "50", expedite: true
							}], (value, index) => {
								return (
									<ListItem
										button
										key={`box${index}`}
										onClick={() => this.setState({ expedite: value.expedite })}
										style={{
											paddingBottom: 15, paddingTop: 20, paddingHorizontal: 15, elevation: 3, backgroundColor: Theme.Colors.white, marginTop: 25, borderRadius: 5, display: 'flex', justifyContent: "space-between",
											...this.state.expedite === value.expedite &&
											{ borderWidth: 1, borderColor: Theme.Colors.primaryPurple },
										}}>
										<div style={{ display: 'flex' }}>
											{
												this.state.expedite === value.expedite ?
													<img src={require("images/Auth/group5.png")} style={{ height: 30, width: 30 }} />
													:
													<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 30, width: 30, borderRadius: 5 }}>
													</div>
											}
											<div style={{ marginLeft: 15, marginTop: -10 }}>
												<Text bold="true" style={{
													fontSize: 22,
													...this.state.expedite === value.expedite &&
													{ color: Theme.Colors.primaryPurple }
												}}>{value.title}</Text>
												<Text style={{ fontSize: 14, color: this.state.expedite === value.expedite ? Theme.Colors.primaryPurple : Theme.Colors.warmGrey }}>{value.subTitle}</Text>
											</div>
										</div>
										<Text bold="true" style={{ color: Theme.Colors.primaryPurple, fontSize: 34, marginTop: -10 }}>${value.money}</Text>
									</ListItem>
								)
							})
						}

						<Button variant="contained" color="primary" onClick={this.handleSubmit} style={{ width: '100%', marginTop: 50 }}>
							ORDER
                		</Button>
					</>
				}
				{
					step == -1 &&

					<div>
						<div style={{
							display: 'flex', flexDirection: 'column', alignItems: 'center', width: "90%", marginLeft: "5%"
						}}>
							<img src={require('images/insufficient-funds.png')} style={{ marginTop: 100, width: 160, height: 160 }} />
							<Text bold="true" style={{ fontSize: 22, width: 300, lineHeight: 2.2, textAlign: 'center', marginTop: 30 }}>Add {this.state.expedite ? ' $60' : ' $20'}
							</Text>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown, width: 300, textAlign: 'center', marginTop: 10, lineHeight: 2.4 }}>
								Please deposit {this.state.expedite ? ' $60' : ' $20'} to receive your North Loop Mastercard </Text>
						</div>
						<Button variant="contained" color="primary"
							style={{ width: '50%', marginTop: 50, marginLeft: '25%' }}
							onClick={() => { }}>
							{/* @todo navigate to add funds */}
							Add Funds
						</Button>
					</div>
				}

				{
					step == 2 &&
					<div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
						<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
						<Text bold="true" style={{ fontSize: 20, marginTop: 20 }}>Confirmed!</Text>
						<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, width: 300, lineHeight: 2.2, textAlign: 'center', marginTop: 10 }}>Your card is on it’s way</Text>
						<Button
							variant="contained"
							color="primary"
							style={{ width: '50%', marginTop: 50 }}
							onClick={() => this.props.history.push("/user/my-cards")}>
							OK
						</Button>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),

})

const mapDispatchToProps = dispatch => ({
	orderCard: (formData, changeStep) => dispatch(orderCard(formData, changeStep)),
	getAccountInfo: () => dispatch(getAccountInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(US_OrderCard)

const STYLES = {

}