import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { getAccountDetails } from './actions';
import { Button } from '@material-ui/core';
import Header from 'components/Header';
import constants from 'resources/constants';

class MyAccount extends React.Component {

	handleRoute = (route) => {
		switch (route) {
			case 'logout':
				this.props.logout("You have been logged out")
				break
			default:
				this.props.history.push(`/user/${route}`)
		}
	}

	render() {
		const { userInfo } = this.props
		return (
			<div>
				<Header name="My Account" />
				<div style={STYLES.contentContainer}>
					{
						_.map([{
							name: "Account Details", route: "account-details", image: require("images/MyAccount/icon1.png"), show: userInfo.sign_up_status_2 === constants.sign_up_status.COMPLETED
						}, {
							name: "My Linked Accounts", route: "linked-accounts", image: require("images/MyAccount/icon1.png")
						}, {
							name: "Order History", route: "orders", image: require("images/MyAccount/icon7.png")
						}, {
							name: "My Investments", route: "investments", image: require("images/MyAccount/investment.png")
						},
						//  {
						// 	name: "Cashback", route: "Cashback", image: require("src/images/MyAccount/cashback.png"), height: 27, marginTop: -1, show: userInfo.cipTag !== -1
						// }, 
						// {
						// 	name: "Premium", route: "Premium_Upgrade", image: require("src/images/MyAccount/premium.png"), height: 18, marginTop: 3, show: userInfo.cipTag !== -1
						// }, 
						{
							name: "Change Address", route: "change-address", image: require("images/MyAccount/icon2.png"), show: userInfo.sign_up_status_2 === constants.sign_up_status.COMPLETED
						}, {
							name: "Change Password", route: "change-phone-number", image: require("images/MyAccount/icon3.png")
						}, {
							name: "Change Phone Number", route: "ChangePhoneNumber", image: require("images/MyAccount/icon4.png")
						},
							// {
							// 	name: "Offers", route: "OffersHome", image: require("src/images/MyAccount/offers.png")
							// }, 
							// {
							// 	name: "Help", route: "Help", image: require("src/images/MyAccount/help.png")
							// }
						], (value, index) => {
							if (value.show === false) {
								return null
							}

							return (
								<div style={STYLES.menu} key={`menu${index}`} >
									<Button onClick={() => this.handleRoute(value.route)} style={{ width: '100%', borderRadius: 0, padding: 25 }}>
										<div>
											<img src={value.image} style={{ marginTop: 2, height: 82, width: 82 }} />
											<Text style={STYLES.rowText}>{value.name}</Text>
										</div>
									</Button>
								</div>
							)
						})
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),
})

const mapDispatchToProps = dispatch => ({
	getAccountDetails: () => dispatch(getAccountDetails())
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)


const STYLES = {
	contentContainer: {
		display: 'flex', gap: "5%", flexWrap: 'wrap', marginTop: -30, width: '100%'
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	rowText: {
		fontSize: 16, marginTop: 10, letterSpacing: 0.3
	},
	menu: {
		marginTop: 50, width: '30%', backgroundColor: Theme.Colors.white,
	},
	iconRight: {
		color: Theme.Colors.warmGrey
	}
}