import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Divider, Select, MenuItem, Grid, InputLabel, FormControl, Button } from '@material-ui/core'

import Header from 'components/Header'
import Text from "components/Text"
import Theme from "resources/Theme"

class Step2 extends Component {
	handleChange = (value, type) => {
		const { formData, changeState, state } = this.props.parentProps

		switch (type) {
			case "tenure":
				changeState({
					formData: {
						...formData,
						tenure_in_days: _.get(value, "definite_tenure.value") || _.get(value, "range_tenure.ranges[0].value") || ""
					},
					selectedTenure: value,
					selectedRate: value.rate
				})

				break

			case "range":
				changeState({
					formData: {
						...formData,
						tenure_in_days: value
					}
				})

				break

			case "rate":
				let tenure

				for (let i = 0; i < state.selectedTenureArray.length; i++) {
					const element = state.selectedTenureArray[i];

					if (element.rate == value) {
						tenure = element
						break
					}

				}

				changeState({
					formData: {
						...formData,
						tenure_in_days: _.get(tenure, "definite_tenure.value") || _.get(tenure, "range_tenure.ranges[0].value") || ""
					},
					selectedTenure: tenure,
					selectedRate: value
				})
				break
		}

	}

	render() {
		const { formData, changeStep, handleBack, state } = this.props.parentProps

		let rates = _.uniq(
			_.map(state.selectedTenureArray || [], (value) => {
				return value.rate
			})
		)

		return (
			<div>
				<Header name={`${formData.type} Fixed Deposit`} />
				<div>
					<div style={STYLES.box}>
						<div style={STYLES.imgBox}>
							<img src={require("images/NRI_Banking/wide-nre.png")} alt="wide-nre.png" style={STYLES.wideImg} />
							<div style={STYLES.imgText}>
								<Text bold
									style={{
										color: Theme.Colors.primaryPurple,
										fontSize: 22,
									}}>
									Open {formData.type.toUpperCase()} fixed deposit
								</Text>
								<Text style={{
									letterSpacing: 0.6,
									fontSize: 16,
									marginTop: "1vh"
								}}>
									{formData.type === 'NRE' ? "Ideal for foreign deposits. Income earned is tax-free" : "Ideal for Indian Deposits."}
								</Text>
							</div>
						</div>
						<div style={STYLES.restContentBox}>
							<Text style={{
								marginTop: 20,
								fontSize: 18,
								letterSpacing: 0.6,
								marginLeft: "3%",
							}}>
								Select any one
							</Text>
							<Divider style={STYLES.divider} />
							<Grid container spacing={2} style={{ marginTop: "3vh" }}>
								<Grid item xs={12} sm={6}>
									<div style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}} >
										<img src={require("images/NRI_Banking/clock.png")} style={{ width: 25, height: 25, marginLeft: 20 }} />
										<Text style={{ marginLeft: 20, fontSize: 16 }}>
											Set the Tenure
										</Text>
									</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl style={{ width: "50%" }}>
										<InputLabel id="select-tenure">Tenure</InputLabel>
										<Select
											labelId="select-tenure"
											value={state.selectedTenure}
											onChange={(e) =>
												this.handleChange(e.target.value, "tenure")
											}>
											{_.map(
												state.selectedTenureArray,
												(value, index) => {
													let label =
														_.get(value, "definite_tenure.text") ||
														_.get(value, "range_tenure.text")
													return (
														<MenuItem
															key={`item${index}`}
															label={label}
															value={value}
														>
															{label}
														</MenuItem>
													)
												}
											)}
										</Select>
									</FormControl>
									{_.get(state, "selectedTenure.range_tenure") && (
										<div style={{ marginTop: 20 }}>
											<Select
												value={formData.tenure_in_days}
												onChange={(e) =>
													this.handleChange(e.target.value, "range")
												}>
												{
													_.map(_.get(state, "selectedTenure.range_tenure.ranges") || [], (value, index) => {
														return (
															<MenuItem
																key={`item${index}`}
																label={value.text}
																value={value.value}>
																{value.text}
															</MenuItem>
														)
													})
												}
											</Select>
										</div>
									)}
								</Grid>
							</Grid>
							<Divider style={STYLES.divider} />
							<Text style={{ textAlign: "center", marginTop: 20, fontSize: 16 }}	>
								(OR)
							</Text>
							<Divider style={STYLES.divider} />
							<Grid container spacing={2} style={{ marginTop: "3vh" }}>
								<Grid item xs={12} sm={6}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<img
											src={require("images/NRI_Banking/interest_rate.png")}
											style={{ width: 25, height: 25, marginLeft: 20 }}
										/>
										<Text style={{ marginLeft: 20, fontSize: 16 }}>
											Choose the Interest Rate
										</Text>
									</div>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl style={{ width: "50%" }}>
										<InputLabel id="select-IR">Interest Rate</InputLabel>
										<Select
											labelId="select-IR"
											value={state.selectedRate}
											onChange={(e) =>
												this.handleChange(e.target.value, "rate")
											}
										>
											{_.map(rates, (value, index) => {
												return (
													<MenuItem key={`item${index}`} label={value} value={value}>
														{value}
													</MenuItem>
												)
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Divider style={STYLES.divider} />
							<div style={{ display: "flex", justifyContent: "center", alignItems: "space-around", margin: "5vh 0", paddingBottom: "5vh" }}>
								<Button style={STYLES.outlineBtn} onClick={handleBack}>
									back
								</Button>
								<Button style={STYLES.primaryBtn} onClick={() => changeStep(3)}>
									continue
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Step2)

const STYLES = {
	box: {
		marginTop: 20,
		backgroundColor: Theme.Colors.white,
		width: "100%",
	},
	imgBox: {
		position: 'relative',
		textAlign: 'left',
	},
	imgText: {
		marginTop: "5vh",
		position: 'absolute',
		padding: "15px",
		top: 8,
		left: 16,
	},
	wideImg: {
		width: "100%",
		height: "200px",
		borderRadius: 10,
	},
	setSelect: {
		display: "flex"
	},
	divider: {
		marginTop: "28px"
	},
	outlineBtn: {
		width: 170,
		height: 45,
		borderRadius: 5,
		border: `solid 1px ${Theme.Colors.primaryPurple}`,
		color: Theme.Colors.primaryPurple,
		backgroundColor: Theme.Colors.white,
		textTransform: "uppercase",
		marginRight: "10px",
		fontWeight: 700
	},
	primaryBtn: {
		width: 170,
		height: 45,
		borderRadius: 5,
		backgroundColor: Theme.Colors.primaryPurple,
		color: Theme.Colors.white,
		textTransform: "uppercase",
		marginLeft: "10px",
		fontWeight: 700
	}
}