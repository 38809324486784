import { NOTIFICATIONS_RECEIVED, NOTIFICATION_DISMISSED, PLAID_ACCOUNTS_RECEIVED, PLAID_ACCOUNT_REMOVED, LIABILITY_INFO_RECEIVED, CREDIT_CARD_TRANSACTIONS_RECEIVED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";
import utils from "resources/utils";

const initialState = {
	notifications: [],
	plaidAccounts: [],
	liabilityInfo: null,
	creditCardTransactions: []
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case NOTIFICATIONS_RECEIVED: {
			return { ...state, notifications: action.notifications }
		}
		case NOTIFICATION_DISMISSED: {
			action.notification.delete = true
			let notifications = utils.updateItemList(state.notifications, action.notification, 'UPDATE')
			utils._storeData('notifications', JSON.stringify(notifications))
			return { ...state, notifications: notifications }
		}
		case PLAID_ACCOUNTS_RECEIVED: {
			return { ...state, plaidAccounts: action.plaidAccounts || [] }
		}
		case PLAID_ACCOUNT_REMOVED: {
			let plaidAccounts = utils.updateItemList(state.plaidAccounts, { item_id: action.item_id }, 'DELETE')
			return { ...state, plaidAccounts }
		}
		case LIABILITY_INFO_RECEIVED: {
			return { ...state, liabilityInfo: action.liabilityInfo }
		}
		case CREDIT_CARD_TRANSACTIONS_RECEIVED: {
			return { ...state, creditCardTransactions: action.creditCardTransactions }
		}
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}