import _ from 'lodash'
import ClevertapReact from 'clevertap-react'
import constants from 'resources/constants'
import utils from 'resources/utils'
import { userCreated, setCipTag, signUpCompleted } from 'screens/Auth/actions'

export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const ACCOUNT_INFO_RECEIVED = 'ACCOUNT_INFO_RECEIVED'
export const USER_INFO_RECEIVED = 'USER_INFO_RECEIVED'
export const USER_INFO_UPDATED = 'USER_INFO_UPDATED'
export const LOGOUT_USER = 'LOGOUT_USER'
export const ALERT_OPEN = 'ALERT_OPEN'
export const ALERT_CLOSE = 'ALERT_CLOSE'
export const CONTINUE_SIGNUP = 'CONTINUE_SIGNUP'

const requestLogin = async (formData) => {
	// let fcmToken = await utils._retrieveData('fcmToken');
	return utils.request({
		url: `/auth/login`,
		method: "POST",
		data: {
			username: formData.username,
			password: formData.password,
			device_token: 'web'
			// device_token: fcmToken || 'abc'
		}
	})
}

export const requestSendOtp = () => (
	utils.request({
		url: `/banking/2fa`
	})
)

export const requestAccountInfo = () => (
	utils.request({
		url: `/banking/account-info`
	})
)

const requestVerifyPhone = (code) => (
	utils.request({
		url: `/banking/2fa`,
		params: { mfa: code }
	})
)

const requestGetUserDetails = () => (
	utils.request({
		url: `/auth/user`
	})
)

export const getIP = async () => {
	let ip = await utils.request({ url: "/admin/ip" })
	return ip.data
}

export const login = (formData, navigation, changeStep = undefined) => (dispatch, getState) => {
	dispatch(toggleLoader())
	requestLogin(formData).then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			utils.setRequestAuthHeader(res.data.data.access_token, res.data.data.oAuth_key, res.data.data.user_id, formData.username)

			if (res.data.data.sign_up_status_2 === constants.sign_up_status.FIRST_STAGE_INCOMPLETE) {
				dispatch(userCreated(res.data.data, formData, "continue"))
				navigation.push("/sign-up", { step: 2 })
				dispatch(toggleLoader())
				return
			}
			const userDetails = res.data.data;
			const payload = {
				Site: {
					Name: userDetails.name,
					Identity: userDetails.basic_information.auth0_id,
					Email: userDetails.basic_information.email_id,
					Phone: `${userDetails.basic_information.phone_no_code}${userDetails.basic_information.phone_no}`,
					Gender: userDetails.basic_information.gender === 'male' ? "M" : "F",
					DOB: userDetails.basic_information.date_of_birth,
					Country: userDetails.basic_information.country_of_residence,
					Plan: userDetails.premium_plan,
					// Address: "",
					"CIP-tag": userDetails.cipTag,
					"MSG-email": false,
					"MSG-push": true,
					"MSG-sms": true,
					"MSG-whatsapp": true,
				}
			}
			ClevertapReact.profile(payload);
			dispatch(userCreated(res.data.data, formData, "normal"))
			dispatch(userInfoReceived(res.data.data))
			dispatch(setCipTag(res.data.data.cipTag, null, 'login'))
			if (changeStep) {
				changeStep(1)
			} else {
				navigation.push("/user/my-profile")
			}
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'err')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getAccountInfo = () => dispatch => {
	dispatch(toggleLoader())
	Promise.all([
		dispatch(getAccountInfo_Function())
	]).then(res => {
		dispatch(toggleLoader())
	})
}

export const getAccountInfo_Function = () => dispatch => {
	return requestAccountInfo().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(accountInfoReceived(res.data.data))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			// dispatch(toggleError(err))
			utils.clearCookies()
			window.location.replace("/");
		}
	)
}

export const verifyPhone = (code, navigation, type, changeStep = undefined) => dispatch => {
	dispatch(toggleLoader())
	requestVerifyPhone(code).then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			if (type === 'signup') {
				await requestAccountInfo().then(
					res2 => {
						if (res2.status !== 200) {
							console.log("Something went wrong")
							return
						}
						dispatch(accountInfoReceived(res2.data.data))

						switch (res2.data.data.sign_up_status_2) {
							case "completed":
								dispatch(signUpCompleted("completed"))
								navigation.push("/user/my-profile")
								break;
							case "secondStageReviewDocs":
								dispatch(signUpCompleted("secondStageReviewDocs"))
								navigation.push("/review-docs")
							default:
								dispatch(signUpCompleted(res2.data.data.sign_up_status_2))
								navigation.push("/thank-you")
								break;
						}
					},
					err => {
						console.log(JSON.stringify(err), 'error')
						dispatch(toggleError(err))
					}
				)
			} else {
				changeStep(3)
			}
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getUserDetails = () => dispatch => {
	dispatch(toggleLoader())
	requestGetUserDetails().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(userInfoReceived(null))
				dispatch(toggleLoader())
				return
			}
			dispatch(userInfoReceived(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(userInfoReceived(null))
			utils.clearCookies()
			dispatch(toggleLoader())
		}
	)
}

export const logout = (message, history) => dispatch => {
	utils.logout()
	ClevertapReact.logout()
	dispatch(showAlert("Alert", message || 'You have been logged out due to inactivity'))
	history.push("/")
	dispatch(userLoggedOut())
}

export const showAlert = (title = '', message) => ({
	type: ALERT_OPEN,
	title,
	message
})

export const hideAlert = () => ({
	type: ALERT_CLOSE
})

export const toggleLoader = () => ({
	type: TOGGLE_LOADER
})

export const userLoggedOut = () => ({
	type: LOGOUT_USER,
})

export const accountInfoReceived = (accountInfo) => ({
	type: ACCOUNT_INFO_RECEIVED,
	accountInfo
})

export const userInfoReceived = (userInfo) => ({
	type: USER_INFO_RECEIVED,
	userInfo
})

export const userInfoUpdated = (userInfo) => ({
	type: USER_INFO_UPDATED,
	userInfo
})

const continueSignUp = (signUpState) => ({
	type: CONTINUE_SIGNUP,
	signUpState
})

export const toggleError = (err, title) => dispatch => {
	dispatch(showAlert(title || 'Error',
		_.isString(err) ? err :
			(!_.isEmpty(_.get(err, 'response.data.message')) && _.isString(_.get(err, 'response.data.message'))) ?
				_.get(err, 'response.data.message') : 'Uh oh! Something went wrong!'))
}