import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';

export default class Step4 extends Component {

	render() {
		const { formData, handleBack, changeStep, handleChange, changeState } = this.props.parentProps

		return (
			<div>
				<Text bold style={STYLES.headline}>We need to ask these</Text>

				{
					_.map([{
						title: "Are you a senior executive or 10% shareholder in publicly traded company, or employed in a high ranking government role?", name: "kyc_identity_detail.pep_exposed"
					}, {
						title: "Is any of your family a senior executive or 10% shareholder in publicly traded company, or employed in a high ranking government role?", name: "kyc_identity_detail.pep_related"
					}], (value, index) => {
						let data = _.get(formData, value.name)

						return (
							<div style={{ display: "flex", marginTop: 30, alignItems: 'center' }} key={`item${index}`}>
								<div onClick={() => handleChange(value.name, !data)} style={{ cursor: "pointer" }}>
									{
										data ?
											<img src={require("images/Auth/group5.png")} style={{ height: 26, width: 26, }} />
											:
											<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 26, width: 26, borderRadius: 5 }}>
											</div>
									}
								</div>
								<Text style={{ marginLeft: 20 }}>{value.title}</Text>
							</div>
						)
					})
				}

				<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={handleBack}>
						Previous
            			</OutlinedButton>
					<ContainedButton
						style={{ width: 200 }}
						onClick={() => changeStep(5)}>
						Continue
            			</ContainedButton>
				</div>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	}
}