import { toggleLoader, getAccountInfo_Function, toggleError } from "actions/actions"
import utils from "resources/utils";
import _ from 'lodash'
import { requestPlaidTransactionHistory } from "../Transactions/actions";

import { getAllFD_Fn } from "../NRI_Banking/actions"
export const NOTIFICATIONS_RECEIVED = 'NOTIFICATIONS_RECEIVED'
export const NOTIFICATION_DISMISSED = 'NOTIFICATION_DISMISSED'
export const PLAID_ACCOUNTS_RECEIVED = 'PLAID_ACCOUNTS_RECEIVED'
export const PLAID_ACCOUNT_REMOVED = 'PLAID_ACCOUNT_REMOVED'
export const LIABILITY_INFO_RECEIVED = 'LIABILITY_INFO_RECEIVED'
export const CREDIT_CARD_TRANSACTIONS_RECEIVED = 'CREDIT_CARD_TRANSACTIONS_RECEIVED'

const requestGetNotifications = (userId) => (
	utils.request({
		url: `${utils.get_beamer_url()}/posts`,
		params: {
			filter: userId
		},
		headers: {
			['Beamer-Api-Key']: utils.get_beamer_api_key(),
			Authorization: "",
			auth: "",
			user_id: ""
		}
	})
)

const requestLinkPlaidBankAccounts = (data) => (
	utils.request({
		url: `/plaid/account/link`,
		method: "POST",
		data: {
			institution: data.institution,
			accounts: data.accounts,
			public_token: data.public_token
		}
	})
)

const requestGetPlaidAccounts = () => (
	utils.request({
		url: `/plaid/account/get`,
		method: "GET"
	})
)

const requestRemovePlaidAccount = (item_id) => (
	utils.request({
		url: `/plaid/account/de-link`,
		method: "DELETE",
		data: {
			item_id: item_id
		}
	})
)

const requestCreditLiabilityInfo = (account_id) => (
	utils.request({
		url: `/plaid/liabilities/get`,
		method: 'POST',
		data: {
			account_id
		}
	})
)

const requestUpdateLinkToken = (item_id) => (
	utils.request({
		url: `/plaid/update-link-mode`,
		method: 'POST',
		data: {
			item_id
		}
	})
)

export const getMyProfileData = () => (dispatch, getState) => {
	let state = getState()
	dispatch(toggleLoader())
	dispatch(getNotifications())

	let app = state.App
	let myprofile = state.MyProfile

	Promise.all([
		_.isEmpty(myprofile.plaidAccounts) ? dispatch(getLinkedPlaidAccounts_Fn()) : Promise.resolve(),
		(
			(_.get(app, 'userInfo.sign_up_status_2') == 'completed' ||
				_.get(app, 'userInfo.sign_up_status_2') == 'secondStageReviewDocs') && _.isEmpty(app.accountInfo)
		) ?
			dispatch(getAccountInfo_Function()) : Promise.resolve(),
		dispatch(getAllFD_Fn())
	]).then(res => {
		dispatch(toggleLoader())
	})
}

const getNotifications = () => (dispatch, getState) => {
	let state = getState().App
	return requestGetNotifications(_.get(state, 'userInfo.user_id') || '').then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			let notificationsSaved = JSON.parse(await utils._retrieveData("notifications"))
			let allNotifications = _.map(_.get(res, 'data') || [], value => {
				let n = _.find(notificationsSaved, { id: value.id })
				return {
					...value,
					filter: null,
					read: n ? n.read : false,
					delete: n ? n.delete : false,
				}
			})
			await utils._storeData("notifications", JSON.stringify(allNotifications))
			dispatch(notificationsReceived(allNotifications))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
		}
	)
}

export const linkPlaidBankAccounts = (data) => dispatch => {
	dispatch(toggleLoader())
	data.accounts = _.map(data.accounts, value => {
		delete value.mask
		return value
	})
	return requestLinkPlaidBankAccounts(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			Promise.all([
				dispatch(getLinkedPlaidAccounts_Fn())
			]).then(res => {
				dispatch(toggleLoader())
			})
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getLinkedPlaidAccounts = () => dispatch => {
	dispatch(toggleLoader())
	Promise.all([
		dispatch(getLinkedPlaidAccounts_Fn())
	]).then(res => {
		dispatch(toggleLoader())
	})
}

export const getLinkedPlaidAccounts_Fn = () => dispatch => {
	return requestGetPlaidAccounts().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(linkedPlaidAccountsReceived(res.data))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
		}
	)

}

export const removePlaidAccount = (item_id) => dispatch => {
	dispatch(toggleLoader())
	return requestRemovePlaidAccount(item_id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(plaidAccountRemoved(item_id))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getCreditCardScreenInfo = (account_id) => dispatch => {
	dispatch(toggleLoader())
	Promise.all([
		dispatch(getCreditLiabilityInfo_Function(account_id)),
		dispatch(getCreditCardTransaction_Function(account_id))
	]).then(res => {
		dispatch(toggleLoader())
	})
}

const getCreditLiabilityInfo_Function = (account_id) => dispatch => {
	return requestCreditLiabilityInfo(account_id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(creditCardInfoReceived(res.data))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

const getCreditCardTransaction_Function = (account_id) => dispatch => {
	return requestPlaidTransactionHistory(account_id, 0, 3).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(creditCardTransactionsReceived(res.data))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const getUpdateLinkToken = (item_id, navigation) => dispatch => {
	dispatch(toggleLoader())
	requestUpdateLinkToken(item_id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			navigation.navigate("Plaid", { token: res.data.public_token })
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleLoader())
			dispatch(toggleError(err))
		}
	)
}

export const creditCardTransactionsReceived = (creditCardTransactions) => ({
	type: CREDIT_CARD_TRANSACTIONS_RECEIVED,
	creditCardTransactions
})

export const creditCardInfoReceived = (liabilityInfo) => ({
	type: LIABILITY_INFO_RECEIVED,
	liabilityInfo
})

const linkedPlaidAccountsReceived = (plaidAccounts) => ({
	type: PLAID_ACCOUNTS_RECEIVED,
	plaidAccounts
})


const notificationsReceived = (notifications) => ({
	type: NOTIFICATIONS_RECEIVED,
	notifications
})

export const notificationDismissed = (notification) => ({
	type: NOTIFICATION_DISMISSED,
	notification
})

const plaidAccountRemoved = (item_id) => ({
	type: PLAID_ACCOUNT_REMOVED,
	item_id
})