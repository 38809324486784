import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { pushSSN } from '../../actions';
import { Card, Button, withStyles } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import loginBackground from 'images/login-background.png'

class SubStep1SSN extends Component {

	state = {
		formData: {
			SSN: ""
		},
		actionCondition: false,
		modal: false,
		help: false,
		showSSN: false
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.state.formData[name] = value
		if (value.length == 9) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	handleSubmit = () => {
		if (this.state.actionCondition) {
			this.props.pushSSN(this.state.formData.SSN)
			this.props.changeStep(2.9)
		}
	}

	toggleModal = () => {
		this.setState({
			modal: !this.state.modal
		})
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { classes } = this.props
		const { actionCondition, formData, showSSN } = this.state

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Verify your identity</h1>
					<ValidatorForm onSubmit={actionCondition ? this.handleSubmit : () => { }}>
						<div style={{ marginTop: 30, width: '60%' }}>
							<TextValidator
								value={formData.SSN}
								onChange={this.handleChange}
								name="SSN"
								type="number"
								label="SSN"

								placeholder="Enter your 9 digit Social Security Number"
								variant="outlined"
								fullWidth />
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<p style={{ color: Theme.Colors.brownishGrey, fontSize: 14, marginTop: 30, lineHeight: 1.5, width: '85%' }}>We require this information to verify your identity. The information is securely stored and encrypted using industry-leading technology. <img src={require('images/Auth/smiling2.png')} style={{ width: 14, height: 14, position: 'absolute', marginTop: 5, marginLeft: 5 }} /></p>
							<img src={require("images/Auth/pciLogo.png")} style={{ width: 55, height: 35, marginTop: 30 }} />
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
							<Button
								color={'primary'}
								variant="outlined"
								onClick={() => this.props.changeStep(1)}
								style={{ fontWeight: 700, width: 170 }}>
								Back
                        	</Button>
							<Button
								color={!(actionCondition) ? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	pushSSN: (SSN) => dispatch(pushSSN(SSN)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 700,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SubStep1SSN))