import utils from 'resources/utils'
import _ from 'lodash'
import { toggleLoader, toggleError } from 'actions/actions'
import { BANK_ACCOUNT_REMOVED } from '../AddFunds/actions';

export const LINKED_ACCOUNTS_RECEIVED = 'LINKED_ACCOUNTS_RECEIVED'
export const BANK_ACCOUNT_ADDED = 'BANK_ACCOUNT_ADDED'

const requestAddAccount = (formData) => (
	utils.request({
		url: `/banking/link-ach`,
		method: "POST",
		data: formData
	})
)

const requestGetLinkedAccounts = () => (
	utils.request({
		url: `/banking/link-ach`
	})
)

const requestRemoveAccount = (node_id) => (
	utils.request({
		url: `/banking/link-ach`,
		method: "DELETE",
		data: {
			node_id_to_be_deleted: node_id
		}
	})
)

const requestTransferMoney = (data, ip) => (
	utils.request({
		url: `/banking/transactions`,
		method: "POST",
		data: {
			send_to_id: _.get(data, 'to.node_id'),
			value: Number(data.amount),
			currency: "USD",
			ip: ip
		}
	})
)

export const addAccount = (formData, navigation) => dispatch => {
	dispatch(toggleLoader())
	requestAddAccount(formData, navigation).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(accountAdded(_.get(res, 'data.data')))
			navigation.navigate("TransferHome")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getAllLinkedAccounts = () => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		_.isEmpty(state, "Transfer.linkedAccounts") ? dispatch(getLinkedAccounts_Fn()) : Promise.resolve(),
	]).then(res => {
		dispatch(toggleLoader())
	})
}

export const getLinkedAccounts_Fn = () => dispatch => {
	return requestGetLinkedAccounts().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(linkedAccountsReceived(_.get(res, 'data.data') || []))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const removeAccount = (node_id) => dispatch => {
	dispatch(toggleLoader())
	requestRemoveAccount(node_id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(accountRemoved(node_id))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const transferMoney = (data, navigation) => (dispatch, getState) => {
	let state = getState().App
	if ((_.get(state.accountInfo, 'info.balance.amount') || 0) < Number(data.amount)) {
		navigation.navigate("InsufficientFunds")
		return
	}

	dispatch(toggleLoader())

	//@todo add ip
	let ip = ''

	requestTransferMoney(data, ip).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			navigation.navigate("TransferDone")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
	// })
}

const accountAdded = (account) => ({
	type: BANK_ACCOUNT_ADDED,
	account
})

const accountRemoved = (node_id) => ({
	type: BANK_ACCOUNT_REMOVED,
	node_id
})

const linkedAccountsReceived = (linkedAccounts) => ({
	type: LINKED_ACCOUNTS_RECEIVED,
	linkedAccounts
})