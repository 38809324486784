import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { getSubscriptions } from './actions'
import Header from 'components/Header';

class Subscriptions extends Component {

	componentDidMount() {
		this.props.getSubscriptions()
	}

	render() {
		const { subscriptions, loader } = this.props

		return (
			<div>
				<Header name="Subscriptions" />
				{
					_.map(_.get(subscriptions, 'subscriptions') || [], (value, index) => {
						return (
							<div key={`item${index}`} style={STYLES.subscriptionContainer} className="box-shadow">
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={STYLES.imgContainer}>
										<img src={value.merchant_logo} style={{ width: 50, height: 50, borderRadius: 25, }} />
									</div>
									<div style={{ marginLeft: 20, }}>
										<Text style={{ fontSize: 16, letterSpacing: 0.53, }}>{_.get(value, 'merchant_name')}</Text>
									</div>
								</div>
								<Text bold="true" style={{ fontSize: 18 }}>${value.amount}</Text>
							</div>
						)
					})
				}
				{
					!loader && _.isEmpty(subscriptions || []) &&
					<Text style={{ marginTop: 20 }}>No subscriptions yet</Text>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['loader']),
	..._.pick(state.Expense, ['subscriptions']),
})

const mapDispatchToProps = dispatch => ({
	getSubscriptions: () => dispatch(getSubscriptions())
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)

const STYLES = {
	subscriptionContainer: {
		display: 'flex', alignItems: 'center', padding: 25, justifyContent: 'space-between', background: Theme.Colors.white, marginTop: 20, borderRadius: 5
	},
	imgContainer: {
		width: 50, height: 50, borderRadius: 25
	}
}