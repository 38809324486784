import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Theme from "resources/Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
  },
}));

const dialogHeaderStyles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    borderBottom: "1px solid #f0f2f4",
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#353535",
  },
});

const dialogContentStyles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  subTitle1: {
    fontSize: 14,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#353535",
    fontWeight: 600,
  },
  subTitle2: {
    fontSize: 14,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
  },
  phoneNumber: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#200e32",
  },
  textButton: {
    fontSize: 14,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 600,
    color: Theme.Colors.primaryPurple,
  },
});

const dialogActionStyles = (theme) => ({
  root: {
    justifyContent: "center",
    margin: 0,
    paddingBottom: theme.spacing(5),
  },
  actionButton: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    fontFamily: "'Source Sans Pro', sans-serif",
    padding: "10px 30px",
    borderColor: Theme.Colors.primaryPurple,
    color: Theme.Colors.primaryPurple,
  },
});

const DialogTitle = withStyles(dialogHeaderStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography classes={{ h6: classes.title }} variant="h6">
        FIX APPOINTMENT
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(dialogContentStyles)((props) => {
  const { classes } = props;

  return (
    <MuiDialogContent classes={{ root: classes.root }}>
      <Typography
        classes={{ subtitle1: classes.subTitle1 }}
        variant="subtitle1"
      >
        Choose a convinient time
      </Typography>
      <Box p={2}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography
              classes={{ subtitle2: classes.subTitle2 }}
              variant="subtitle2"
            >
              Phone number
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Box display="flex" alignItems="center">
              <Typography
                classes={{ subtitle2: classes.phoneNumber }}
                variant="subtitle2"
              >
                +91 995 599 6666
              </Typography>
              <Button classes={{ root: classes.textButton }}>Edit</Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography
              classes={{ subtitle2: classes.subTitle2 }}
              variant="subtitle2"
            >
              Appointment on
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Button classes={{ root: classes.textButton }}>choose</Button>
          </Grid>
        </Grid>
      </Box>
    </MuiDialogContent>
  );
});

const DialogActions = withStyles(dialogActionStyles)((props) => {
  const { classes, onClose } = props;

  return (
    <MuiDialogActions className={classes.root}>
      <Button
        classes={{ outlinedSecondary: classes.actionButton }}
        variant="outlined"
        onClick={onClose}
        color="secondary"
      >
        Confirm
      </Button>
    </MuiDialogActions>
  );
});

export default function AppointmentConfirmModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        classes={{ paper: classes.root }}
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.onClose}
        ></DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions onClose={props.onClose}></DialogActions>
      </Dialog>
    </div>
  );
}
