import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { search_stock } from './actions';
import { ListItem, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class Investing_Search extends React.Component {

	state = {
		search: "",
		results: []
	}

	changeState = (state) => {
		this.setState(state)
	}

	handleSearch = (text) => {
		this.setState({
			search: text
		})

		this.props.search_stock(text, this.changeState)
	}

	render() {
		const { search, results } = this.state

		return (
			<div style={{ width: 300, position: "relative" }}>
				<TextField
					variant="outlined"
					fullWidth
					placeholder="Search"
					value={search}
					InputProps={{ style: { height: 45 } }}
					style={{ height: 45, background: Theme.Colors.white }}
					onChange={e => this.handleSearch(e.target.value)}
				/>
				{
					!_.isEmpty(results) &&
					<div style={{ position: "absolute", width: "100%", background: Theme.Colors.white, marginTop: 10, borderRadius: 5, border: `1px solid ${Theme.Colors.lightGrey}`, maxHeight: 300, overflowY: "scroll" }}>
						{
							_.map(results, (value, index) => {
								return (
									<ListItem
										button
										style={{
											padding: `20px 0`,
											border: `0px solid ${Theme.Colors.whiteTwo}`,
											borderBottomWidth: 1
										}}
										onClick={async () => {
											await this.setState({ search: "", results: [] })
											switch (value.country) {
												case "IN":
													this.props.history.push("MF_Detailed_Page", {
														stock: value
													})
													break;

												case "US":
													this.props.history.push(`/user/invest/us/stock/details/${value.code}`)
													break;
											}
										}}
										key={`item${index}`}>
										<div style={{ display: "flex", alignItems: 'center', margin: `0 10px` }}>
											<Text style={{ letterSpacing: 0.6, lineHeight: 2.2 }}>
												{value.name}
											</Text>
										</div>
									</ListItem>
								)
							})
						}

					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	search_stock: (search, changeState) => dispatch(search_stock(search, changeState))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Investing_Search))

const STYLES = {}