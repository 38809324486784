import utils from 'resources/utils'
import _ from 'lodash'
import { toggleLoader, requestSendOtp, toggleError } from 'actions/actions'
import { getLinkedPlaidAccounts_Fn } from 'screens/MyProfile/actions'
import { getLinkedAccounts_Fn } from 'screens/Transfer/actions'

export const ACCOUNT_DETAILS_RECEIVED = 'ACCOUNT_DETAILS_RECEIVED'
export const ACCOUNT_SETTINGS_RECEIVED = 'ACCOUNT_SETTINGS_RECEIVED'
export const ADDRESS_CHANGE_ENTERED = 'ADDRESS_CHANGE_ENTERED'

const requestAccountDetails = () => (
	utils.request({
		url: `/banking/transfer-details`
	})
)

const requestAccountSettings = () => (
	utils.request({
		url: `/banking/preferences`
	})
)

const requestUpdateSettings = (data) => (
	utils.request({
		url: `/banking/preferences`,
		method: "POST",
		data: data
	})
)

const requestUpdateSynapseUser = (data) => (
	utils.request({
		url: `/banking/synapse-user`,
		method: "PUT",
		data: data
	})
)

export const getAccountDetails = () => dispatch => {
	dispatch(toggleLoader())
	requestAccountDetails().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(accountDetailsReceived(_.get(res, 'data.data.transferDetails') || {}))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getAccountSettings = (update = false) => dispatch => {
	if (!update) {
		dispatch(toggleLoader())
	}
	requestAccountSettings().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(accountSettingsReceived(_.get(res, 'data.data') || {}))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const updateSettings = (key, value) => (dispatch, getState) => {
	let state = getState().MyAccount
	let accountSettings = {
		transaction: state.accountSettings.transaction || false,
		low_balance: state.accountSettings.low_balance || false,
		deals_offers: state.accountSettings.deals_offers || false,
		tip_suggestion: state.accountSettings.tip_suggestion || false,
		tip_percentage: state.accountSettings.tip_percentage || 0
	}

	accountSettings[key] = value

	if (key === 'tip_suggestion') {
		if (value === true) {
			accountSettings["tip_percentage"] = 18
		} else {
			accountSettings["tip_percentage"] = 0
		}
	}

	dispatch(toggleLoader())
	requestUpdateSettings(accountSettings).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(getAccountSettings(true))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const changePhoneNumber = (phone, changeStep) => dispatch => {
	dispatch(toggleLoader())
	requestUpdateSynapseUser({
		phone_number: phone
	}).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			requestSendOtp().then(
				res2 => {
					if (res.status !== 200) {
						console.log("Something went wrong")
						dispatch(toggleLoader())
						return
					}
					dispatch(toggleLoader())
					changeStep(2)
				},
				err => {
					console.log(JSON.stringify(err), 'error')
					dispatch(toggleError(err))
					dispatch(toggleLoader())
				}
			)
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const changeAddress = (changeStep) => (dispatch, getState) => {
	let state = getState().MyAccount
	dispatch(toggleLoader())
	requestUpdateSynapseUser({
		address: state.addressChange
	}).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(toggleLoader())
			changeStep(4)
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getAllLinkedAccounts = () => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		(_.isEmpty(_.get(state, "Transfer.linkedAccounts")) && _.get(state, 'App.userInfo.sign_up_status_2') === 'completed') ? dispatch(getLinkedAccounts_Fn()) : Promise.resolve(),
		_.isEmpty(_.get(state, "MyProfile.plaidAccounts")) ? dispatch(getLinkedPlaidAccounts_Fn()) : Promise.resolve(),
	]).then(res => {
		dispatch(toggleLoader())
	})
}

const accountSettingsReceived = (accountSettings) => ({
	type: ACCOUNT_SETTINGS_RECEIVED,
	accountSettings
})

const accountDetailsReceived = (accountDetails) => ({
	type: ACCOUNT_DETAILS_RECEIVED,
	accountDetails
})

export const pushChangeAddress = (address) => ({
	type: ADDRESS_CHANGE_ENTERED,
	address
})