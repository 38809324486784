import {
	USER_CREATED,
	SSN_RECEIVED,
	ADD_IMAGE,
	REMOVE_IMAGE,
	CONFIRM_DETAILS,
	ADDRESS_ENTERED,
	CONTINUE_SIGNUP,
	MOVE_TO_ADDRESS,
	MOVE_TO_CONFIRM_DETAILS,
	SIGNUP_COMPLETE,
	PUSH_DOB_PHONE_NUMBER,
	SET_CIP_TAG,
	TOGGLE_SIGNUP_LOADER,
	AUTOCOMEPLETE_APARTMENT,
	PUSH_NAME,
	PUSH_RESIDENCE
} from "./actions";
import moment from 'moment'
import _ from 'lodash'
import utils from "resources/utils";

const initialState = {
	personalDetails: {
		name: "",
		cip_tag: -1,
		country_of_residence: ""
	},
	signUpPayloadCommon: {
		email: "",
		phone_numbers: [""],
		user_ip: "{{ip}}",
		legal_names: [""],//new
		device_type: 'web',
		password: "",//new
		address: {
			country: "US",
			state: "",
			city: "",
			street: "",
			zip: ""
		},
		documents: {
			entity_scope: "Arts & Entertainment",
			email: "",
			phone_number: "",
			ip: "{{ip}}",
			name: "",
			entity_type: "M",
			day: "",
			month: "",
			year: "",
			desired_scope: "SEND|RECEIVE|TIER|1",
			docs_key: "GOVT_ID_ONLY",
			address: {
				country: "US",
				state: "",
				city: "",
				street: "",
				zip: ""
			}
		},
		supp_id: "asdaq213"
	},

	signUpPayloadCip3: {
		ssn: "",
		documents: {
			virtual_docs: [{
				document_value: "",
				document_type: "SSN"
			}],
			physical_docs: [{
				document_value: "",
				document_type: "GOVT_ID"
			}, {
				document_value: "",
				document_type: "GOVT_ID_BACK"
			}],
		},
		cip_tag: 3,
	},
	signUpPayloadCip2: {
		arrival_date: "",
		university: "",
		passport: "",
		documents: {
			virtual_docs: [{
				document_value: "",
				document_type: "PASSPORT"
			}],
			physical_docs: [{
				document_value: "",
				document_type: "GOVT_ID"
			}, {
				document_value: "",
				document_type: "OTHER"
			}, {
				document_value: "",
				document_type: "OTHER"
			}, {
				document_value: "",
				document_type: "OTHER"
			}, {
				document_value: "",
				document_type: "OTHER"
			}, {
				document_value: "",
				document_type: "PROOF_OF_ADDRESS"
			}]
		},
		cip_tag: 2
	},
	doc_cip_tag2: [false, false, false, false],
	doc_cip_tag3: false,
	cip_tag: -1,
	screen: 1,
	user: {},
	userData: {},
	signup_type: "normal",
	signUpLoader: false,
	autoCompleteApartment: false
}

export default (state = { ...initialState }, action) => {
	switch (action.type) {
		case USER_CREATED: {
			// utils._storeData('signUpState', JSON.stringify(state));
			return { ...state, user: action.user, userData: action.userData, signup_type: action.signup_type }
		}

		case SET_CIP_TAG: {
			return { ...state, cip_tag: Number(action.cip_tag) }
		}

		case PUSH_NAME: {
			return { ...state, personalDetails: { ...state.personalDetails, name: action.name } }
		}

		case PUSH_RESIDENCE: {
			return { ...state, personalDetails: { ...state.personalDetails, country_of_residence: action.residence } }
		}

		case PUSH_DOB_PHONE_NUMBER: {
			state.signUpPayloadCommon.documents.day = Number(moment(action.data.dob, 'MM/DD/YYYY').format("D"))
			state.signUpPayloadCommon.documents.month = Number(moment(action.data.dob, 'MM/DD/YYYY').format("M"))
			state.signUpPayloadCommon.documents.year = Number(moment(action.data.dob, 'MM/DD/YYYY').format("YYYY"))

			let phoneNumber = action.data.countryCode + action.data.phoneNumber
			state.signUpPayloadCommon.phone_numbers = [phoneNumber]
			state.signUpPayloadCommon.documents.phone_number = phoneNumber

			return { ...state, }
		}

		case SSN_RECEIVED: {
			state.signUpPayloadCip3.ssn = action.SSN
			state.signUpPayloadCip3.documents.virtual_docs[0].document_value = action.SSN

			// state.screen = 4
			// utils._storeData('signUpState', JSON.stringify(state));
			return { ...state }
		}

		case ADD_IMAGE: {
			if (!action.reupload) {
				state = JSON.parse(JSON.stringify(state))
				_.set(state, action.key, action.image)
				state = checkDocCompletion(state)
			}
			return { ...state }
		}

		case REMOVE_IMAGE: {
			if (!action.reupload) {
				state = JSON.parse(JSON.stringify(state))
				_.set(state, action.key, "")
				state = checkDocCompletion(state)
			}
			return { ...state }
		}

		case MOVE_TO_CONFIRM_DETAILS: {
			action.navigation.navigate("SubStep2Cip2ConfirmDetails")
			state.screen = 6
			// utils._storeData('signUpState', JSON.stringify(state));
			return { ...state }
		}

		case MOVE_TO_ADDRESS: {
			action.navigation.navigate("Step3AddressAutoComplete")
			state.screen = 7
			// utils._storeData('signUpState', JSON.stringify(state));
			return { ...state }
		}

		case CONTINUE_SIGNUP: {
			return { ...action.signUpState }
		}

		case CONFIRM_DETAILS: {
			state.signUpPayloadCip2.passport = action.confirmDetails.passport
			state.signUpPayloadCip2.documents.virtual_docs[0].document_value = action.confirmDetails.passport
			state.signUpPayloadCip2.university = action.confirmDetails.university
			if (!_.isEmpty(action.confirmDetails.arrival_date)) {
				state.signUpPayloadCip2.arrival_date = moment(action.confirmDetails.arrival_date, "MM/DD/YYYY").format("MM/DD/YYYY")
			}
			state.screen = 7
			// utils._storeData('signUpState', JSON.stringify(state));
			return { ...state }
		}

		case ADDRESS_ENTERED: {
			state = JSON.parse(JSON.stringify(state))

			state.signUpPayloadCommon.address = action.address
			state.signUpPayloadCommon.documents.address = action.address
			return state
		}

		case SIGNUP_COMPLETE:
			return { ...initialState, cip_tag: state.cip_tag }

		case TOGGLE_SIGNUP_LOADER: {
			return { ...state, signUpLoader: !state.signUpLoader }
		}

		case AUTOCOMEPLETE_APARTMENT: {
			return { ...state, autoCompleteApartment: action.autoCompleteApartment }
		}
		default:
			return state
	}
}

function checkDocCompletion(state) {
	switch (state.cip_tag) {
		case 2: {
			state.doc_cip_tag2[0] = !_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[0].document_value)
			state.doc_cip_tag2[1] =
				!_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[1].document_value) &&
				!_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[2].document_value) &&
				!_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[3].document_value)
			state.doc_cip_tag2[2] = !_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[4].document_value)
			state.doc_cip_tag2[3] = !_.isEmpty(state.signUpPayloadCip2.documents.physical_docs[5].document_value)
			return state
		}

		case 3: {
			state.doc_cip_tag3 = !_.isEmpty(state.signUpPayloadCip3.documents.physical_docs[0].document_value) && !_.isEmpty(state.signUpPayloadCip3.documents.physical_docs[1].document_value)
			return state
		}
		default: {
			return state
		}
	}
}