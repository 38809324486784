import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';

export default class Step5 extends Component {

	render() {
		const { state, formData, changeState, handleBack, handleChange, changeStep } = this.props.parentProps

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>We've got to ask these questions for compliance. Please select all that apply (if none apply, please click continue).</Text>

				{
					_.map([{
						title: "Are you or a family member, a senior executive, or 10% shareholder at a publicly traded company?", name: "directorOf"
					}, {
						title: "Are you or a family member a current or former politically exposed person or public official?", name: "politicallyExposedNames"
					}, {
						title: <>Are you, or a family member that lives with you, employed by a brokerage firm, your national securities exchange commission?<Text style={{ fontSize: 12, color: Theme.Colors.purplishGrey, fontStyle: "italic" }}>(e.g. SEC) or FINRA?</Text></>, name: "isBroker"
					}], (value, index) => {
						let data = _.get(formData, value.name)

						return (
							<div style={{ display: "flex", marginTop: 50, ...data && { borderColor: Theme.Colors.primaryPurple, borderWidth: 1 } }} key={`item${index}`} onClick={() => handleChange(value.name, !data)}>
								{
									data ?
										<img src={require("images/tick_purple.png")} style={{ height: 26, width: 26, }} />
										:
										<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 30, width: 30, borderRadius: 15 }}>
										</div>
								}

								<Text style={{ marginLeft: 20, ...data && { color: Theme.Colors.primaryPurple } }}>{value.title}</Text>
							</div>
						)
					})
				}


				<div style={STYLES.termsContainer}>
					<div onClick={() => changeState({ check: !state.check })}>
						{
							state.check ?
								<img src={require("images/Auth/group5.png")} style={{ height: 26, width: 26, }} />
								:
								<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 26, width: 26, borderRadius: 5 }}>
								</div>
						}
					</div>
					<div style={{ display: 'flex', marginLeft: 15 }}>
						<Text style={STYLES.termsText}>I have read and accept the terms of the</Text>
						<a href="https://apps.drivewealth.com/disclosures/?citizenship=undefined&country=undefined&accountType=Individual" target="_blank" style={STYLES.linkText}> Drivewealth disclosures</a>
					</div>
				</div>


				<div style={{ width: 450, paddingTop: 50, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={handleBack}>
						Previous
            			</OutlinedButton>
					<ContainedButton
						color={!state.check && "default"}
						style={{ width: 200 }}
						onClick={state.check ? () => changeStep(6) : () => { }}>
						Continue
            		</ContainedButton>
				</div>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 22,
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	box: {
		alignItems: "center", justifyContent: "center", backgroundColor: Theme.Colors.white, height: 80, marginTop: 20, borderRadius: 10, borderWidth: 1, borderColor: Theme.Colors.white
	},
	arrowBack: {
		marginLeft: -5
	},
	termsContainer: {
		display: "flex", marginTop: 50, alignItems: 'center'
	},
	termsText: {
		marginRight: 5
	},
	linkText: {
		fontSize: 14, color: Theme.Colors.primaryPurple
	},
	actionButton: {
		marginTop: 30, marginBottom: 30
	}
}