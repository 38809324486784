import React, { Component } from 'react'
import Theme from 'resources/Theme'
import Text from 'components/Text'
import Header from 'components/Header'

export default class ComingSoon extends Component {

	render() {
		return (
			<div>
				<Header name={this.props.title} />
				<Text style={{ color: Theme.Colors.primaryPurple, fontSize: 18 }}> Coming Soon. Currently available on the Mobile App</Text>
			</div>
		)
	}
}