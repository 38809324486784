import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import ContainedButton from 'components/ContainedButton';
import { create_sip } from '../actions';

class MF_Sip extends React.Component {

	state = {
		formData: {
			orders: [{
				isin: this.props.navigation.getParam("mf_details").isin,
				amount: 0,
				start_day: "2",
				frequency: "MONTHLY",
				installments: 1000,
				mandate_id: null
			}]
		},
		step: 1
	}

	changeStep = (step) => {
		this.setState(step)
	}

	handleChange = (name, value) => {
		const { formData } = this.state
		formData.orders[0][name] = value
		this.setState(this.state)
	}

	render() {
		const { formData, step } = this.state
		const data = [0, 300]

		return (
			<div>
				{
					step === 1 &&
					<div>
						<div style={STYLES.headerContainer}>
							{/* <IconButton onPress={() => this.props.navigation.goBack()} icon="arrow-back" color={Theme.Colors.purplishGrey} style={STYLES.backBtn} /> */}
							<Text bold style={STYLES.headline}>Auto-save</Text>
						</div>

						<Text bold style={{ fontSize: 40, textAlign: 'center' }}>₹ {formData.orders[0].amount}</Text>
						<Text style={{ textAlign: 'center', marginTop: 10 }}>Auto-save impact</Text>

						<div style={{ backgroundColor: Theme.Colors.white, marginTop: 20, paddingHorizontal: '5%', paddingVertical: 20 }}>
							<Text>Regular deposits add up!</Text>
							<Text bold style={{ position: 'absolute', color: Theme.Colors.primaryPurple, right: 20, marginTop: 60, }}>
								₹36k
    						</Text>
							{/* <LineChart
								style={{ height: 150 }}
								data={data}
								gridMin={0}
								gridMax={400}
								numberOfTicks={4}
								svg={{ stroke: Theme.Colors.primaryPurple }}
								contentInset={{ top: 20, bottom: 20 }}>
								<Grid />
							</LineChart> */}
						</div>

						<div style={{ backgroundColor: Theme.Colors.white, width: '90%', marginHorizontal: '5%', marginTop: 30, paddingVertical: 20, paddingHorizontal: '5%' }}>
							<Text bold style={{ fontSize: 20 }}>Monthly</Text>

							{/* <TextInput
								onChangeText={text => this.handleChange("amount", Number(text))}
								value={formData.orders[0].amount == 0 ? "" : String(formData.orders[0].amount)}
								style={{ marginTop: 20 }}
								label="Amount"
								placeholder="Enter amount"
								inputProps={{ keyboardType: 'numeric', returnKeyType: 'done' }}
							/> */}
						</div>

						<ContainedButton
							color={formData.orders[0].amount <= 0 && Theme.Colors.lightGrey}
							onPress={formData.orders[0].amount > 0 ? () => this.props.create_sip(formData, this.changeStep) : () => { }}
							style={{ marginTop: 30, marginBottom: 40, width: '90%', marginLeft: '5%' }}>
							Schedule ₹ {formData.orders[0].amount} monthly
    			</ContainedButton>
					</div>
				}

				{
					step === 2 &&
					<div>
						<img src={require('images/tick_purple.png')} style={{ width: 150, height: 150 }} />
						<Text bold style={{ fontSize: 20, color: Theme.Colors.black, width: 300, lineHeight: 22, textAlign: 'center', marginTop: 30 }}>Order Created</Text>
						{/* <Text bold style={{ fontSize: 20, color: Theme.Colors.black, width: 300, lineHeight: 22, textAlign: 'center', marginTop: 30, marginBottom: 20 }}>You will receive a confirmation email.</Text> */}
						<ContainedButton
							style={STYLES.actionButton}
							onPress={this.handleBackPress}>
							DONE
					</ContainedButton>
					</div>
				}

			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Investing, ["mf_details"])
})

const mapDispatchToProps = dispatch => ({
	create_sip: (data, changeStep) => dispatch(create_sip(data, changeStep))
})

export default connect(mapStateToProps, mapDispatchToProps)(MF_Sip)

const STYLES = {
	container: {
		flex: 1, backgroundColor: Theme.Colors.whiteTwo, height: '100%'
	},
	headerContainer: {
		flexDirection: 'row', justifyContent: 'center', paddingTop: 22, borderBottomWidth: 1, borderColor: Theme.Colors.whiteTwo, height: 65
	},
	contentContainer: {
		width: '90%', marginLeft: '5%',
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	}
}