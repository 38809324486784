import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/index';
import ClevertapReact from 'clevertap-react';

import 'react-app-polyfill/ie9'
import utils from 'resources/utils';

const env = utils.ENVIRONMENT;

if (env === utils.env.PRODUCTION) {
	console.log = () => { }
}

ClevertapReact.initialize(utils.get_clevertap_project_id());

const middleware = (env === utils.env.PRODUCTION) ?
	applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const store = createStore(
	rootReducer,
	middleware
)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
