import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
  Box,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Theme from "resources/Theme";
import financeamico from "images/InvestorClub/finance/amico.png";
import AppointmentConfirmModal from "./AppointmentConfirmModal";

const useStyles = makeStyles((theme) => ({
  headerSection: {
    position: "relative",
    height: 284,
    padding: "0px 30px",
    backgroundColor: "#fcfcfc",
  },
  financeAmicoImage: {
    position: "absolute",
    right: 0,
  },
  subTitle1: {
    fontSize: 28,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
  },
  subTitle2: {
    fontSize: 26,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
    maxWidth: 501,
  },
  body1: {
    fontSize: 20,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
  },
  body2: {
    fontSize: 20,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: "#263238",
    marginBottom: 16,
  },
  outlinedCustomButton: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "'Source Sans Pro', sans-serif",
    color: Theme.Colors.primaryPurple,
    borderColor: Theme.Colors.primaryPurple,
  },
  divider: {
    height: 2,
    flexGrow: 0,
    backgroundColor: "rgba(219, 219, 219, 0.46)",
  },
  cardContainer: {
    '&:hover': {
      boxShadow: "0 4px 20px 0 rgba(176, 73, 149, 0.25), 0 0 3px 0 #b04995",
      '& .MuiButton-outlinedPrimary': {
        backgroundColor: Theme.Colors.primaryPurple,
        color: "#FFFFFF"
      }
    }
  },
  cardContent: {
    padding: "32px 48px",
    '&:last-child': {
      paddingBottom: 32
    }
  },
}));

const InvestorClub = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const openHistoryPage = () => {
    props.history.push("/user/investor-club/my-history");
  }

  return (
    <>
      <Box
        classes={{ root: classes.headerSection }}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={3}
      >
        <Box>
          <Box mb={2}>
            <Typography classes={{ h5: classes.subTitle2 }} variant="h5">
              Get access to high yield products to diversify your portfolio, in
              both INR and USD
            </Typography>
          </Box>

          <Typography classes={{ body1: classes.body1 }} variant="body1">
            Investor’s Corner is invite-only. Interested ? Schedule a call to
            see if you qualify
          </Typography>
          <Box mt={3}>
            <Button
              classes={{ outlinedPrimary: classes.outlinedCustomButton }}
              variant="outlined"
              color="primary"
              onClick={handleOpenDialog}
            >
              SCHEDULE A CALL
            </Button>
          </Box>
        </Box>
        <img className={classes.financeAmicoImage} src={financeamico} />
      </Box>
      <Grid container>
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography classes={{ h5: classes.subTitle1 }} variant="h5">
                High Yield Investments
              </Typography>
              <Divider
                classes={{ root: classes.divider }}
                style={{ width: 233, marginTop: 4 }}
              />
            </Box>
            <Button
              classes={{ outlinedPrimary: classes.outlinedCustomButton }}
              variant="outlined"
              color="primary"
              onClick={openHistoryPage}
            >
              MY HISTORY
            </Button>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography classes={{ body1: classes.body1 }} variant="body1">
                Earn up to 18% p.a.
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card classes={{root: classes.cardContainer}}>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <Typography
                      classes={{ h5: classes.subTitle2 }}
                      variant="h5"
                    >
                      High Growth Startups A
                    </Typography>
                    <Divider
                      classes={{ root: classes.divider }}
                      style={{ width: 146, marginTop: 14 }}
                    />
                    <Box mt={2} mb={3}>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Type: Debt
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        ROI: 8% p.a.
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Lock In: 3 Months
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Minimum: $ 5,000
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Currency: USD
                      </Typography>
                    </Box>
                    <Button
                      classes={{
                        outlinedPrimary: classes.outlinedCustomButton,
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      DOWNLOAD PROSPECTUS
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card classes={{root: classes.cardContainer}}>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <Typography
                      classes={{ h5: classes.subTitle2 }}
                      variant="h5"
                    >
                      Backbone of India
                    </Typography>
                    <Divider
                      classes={{ root: classes.divider }}
                      style={{ width: 146, marginTop: 14 }}
                    />
                    <Box mt={2} mb={3}>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Type: Debt
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        ROI: 8% p.a.
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Lock In: 6 Months
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Minimum: ₹ 5,00,000
                      </Typography>
                      <Typography
                        classes={{ body2: classes.body2 }}
                        variant="body2"
                      >
                        Currency: INR
                      </Typography>
                    </Box>
                    <Button
                      classes={{
                        outlinedPrimary: classes.outlinedCustomButton,
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      DOWNLOAD PROSPECTUS
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <AppointmentConfirmModal open={open} onClose={handleCloseDialog} />
    </>
  );
};

export default InvestorClub;
