import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"

import Text from "components/Text"
import Theme from "resources/Theme"


class Step1 extends Component {
	handleSubmit = (type) => {
		const { changeState, state } = this.props.parentProps

		let tenureArray = _.filter(
			_.get(state, "terms.tenure") || [],
			(value) => {
				if (value.type.toLowerCase() === type) {
					return true
				}
			}
		)

		tenureArray = JSON.parse(JSON.stringify(tenureArray))

		changeState({
			formData: {
				...state.formData,
				tenure_in_days:
					_.get(tenureArray, "[0].definite_tenure.value") ||
					_.get(tenureArray, "[0].range_tenure.ranges[0].value") ||
					"",
				type: type.toUpperCase(),
			},
			selectedTenure: _.get(tenureArray, "[0]"),
			selectedRate: _.get(tenureArray, "[0].rate") || "",
			selectedTenureArray: tenureArray,
			step: 2,
		})
	}

	render() {
		const { handleBack } = this.props.parentProps
		return (
			<div>
				<div style={STYLES.headerContainer}>
					<span className="material-icons" style={{ fontSize: 34, color: Theme.Colors.warmGrey, cursor: "pointer" }} onClick={handleBack}>chevron_left</span>
					<Text style={STYLES.backText}>Fixed Desposit</Text>
				</div>
				<div style={STYLES.contentContainer}>
					<Text bold="true" style={STYLES.headline}>
						Types of Fixed Deposits
					</Text>
					<div style={STYLES.mainContainer}>
						{_.map(
							[
								{
									name: "NRE",
									description: "Ideal for foreign deposits. Income earned is tax-free.",
									image: require("images/NRI_Banking/nre-fd.png"),
								},
								{
									name: "NRO",
									description: "Ideal for Indian Deposits.",
									image: require("images/NRI_Banking/nro-fd.png"),
								},
							],
							(value, index) => {
								return (
									<button key={index} onClick={() => this.handleSubmit(value.name.toLowerCase())} style={STYLES.buttonFD}>
										<img src={value.image} alt={value.name} style={STYLES.imgFD} />
										<div style={{
											width: 344,
											height: 139,
											borderRadius: 5,
											boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.05)',
											backgroundImage: index === 0 ? "linear-gradient(to right, #a1d18a, #69be61 100%)" : "linear-gradient(114deg, #de82b5 18%, #b24f97 84%)"
										}}>
											<div style={{ padding: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
												<Text bold="true" style={STYLES.valueTitle}>
													Open {value.name} fixed deposit
												</Text>
												<Text style={STYLES.valueDescription}>
													{value.description}
												</Text>
											</div>
										</div>
									</button>
								)
							}
						)}
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Step1)

const STYLES = {
	headline: {
		marginLeft: "2%",
		fontSize: 24,
		marginTop: 30,
	},
	headerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "left",
		paddingTop: 22,
	},
	backText: {
		paddingLeft: "5px",
		fontFamily: 'Muli',
		fontSize: 26,
		fontWeight: 600,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 0.87,
		color: Theme.Colors.warmGrey
	},
	backBtn: {
		position: "absolute",
		left: 0,
		marginLeft: "3%",
		marginTop: 20,
	},
	buttonFD: {
		border: "none",
		padding: "10px",
		margin: "30px 20px",
		backgroundColor: "#fff",
		borderRadius: 5,
		cursor: "pointer",
		boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.05)',
	},
	imgFD: {
		maxWidth: "344px",
		height: "auto"
	},
	valueDescription: {
		fontSize: 16,
		textAlign: "center",
		color:
			Theme.Colors.white,
		letterSpacing: 0.6,
		marginTop: 10,
		paddingLeft: "20px",
	},
	valueTitle: {
		textAlign: "left",
		fontSize: 22,
		color:
			Theme.Colors.white,
	},
	mainContainer: { display: "flex", marginBottom: 30, paddingTop: "50px" },
}





