import { FD_RECEIVED, FD_CREATED, FD_UPDATED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";

const initialState = {
	fd: {}
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {

		case FD_CREATED: {
			return { ...state, fd: { ...state.fd, fds: [...(state.fd.fds || []), action.fd[0]] } }
		}

		case FD_UPDATED: {
			let fd = JSON.parse(JSON.stringify(state.fd))
			let index = _.findIndex(fd.fds, { manual_fd_id: action.fd.manual_fd_id })
			if (index !== -1) {
				fd.fds[index] = action.fd
			}
			return { ...state, fd }
		}

		case FD_RECEIVED: {
			return { ...state, fd: action.fd }
		}

		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}