import { toggleLoader, toggleError } from 'actions/actions'
import _ from 'lodash'
import utils from 'resources/utils'
import constants from 'resources/constants'
import { request_get_usi_order_history, request_usi_investor_details, request_get_usi_investment_history, request_get_usi_holdings } from "./US_Investing/actions"

export const INVESTING_MARKET_SUMMARY_RECEIVED = 'INVESTING_MARKET_SUMMARY_RECEIVED'
export const INVESTING_ORDER_HISTORY_RECEIVED = 'INVESTING_ORDER_HISTORY_RECEIVED'
export const INVESTING_ORDER_CREATED = 'INVESTING_ORDER_CREATED'
export const INVESTING_INVESTMENT_HISTORY_RECEIVED = 'INVESTING_INVESTMENT_HISTORY_RECEIVED'
export const INVESTING_HOLDINGS_RECEIVED = 'INVESTING_HOLDINGS_RECEIVED'
export const INVESTING_US_INVESTOR_DETAILS_RECEIVED = 'INVESTING_US_INVESTOR_DETAILS_RECEIVED'
export const INVESTING_MY_STOCKS_INFO_RECEIVED = 'INVESTING_MY_STOCKS_INFO_RECEIVED'

const request_search_stock = (search) => (
	utils.mutual_fund_request({
		url: `/investment-items`,
		params: {
			q: search
		}
	})
)

const request_get_market_summary = () => (
	utils.us_investing_request({
		url: `/yahoo-finance/market/summary`,
		params: {
			region: "US",
			lang: "en"
		}
	})
)

export const get_investor_route_details = (changeState) => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		state.App.userInfo.us_investing_status === constants.us_investing_status.COMPLETED && _.isEmpty(state.Investing.us_investor_details) && dispatch(get_us_investor_details_Fn()),
	]).then(
		res => {
			changeState({ data_received: true })
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const get_homepage_details = (changeGraphValues) => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		dispatch(get_market_summary_Fn()),
		_.isEmpty(state.Investing.investment_history) && dispatch(get_investment_history_Fn()),
		_.isEmpty(state.Investing.holdings) && dispatch(get_holdings_Fn())
	]).then(
		res => {
			dispatch(toggleLoader())
			changeGraphValues("1W")
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const get_market_summary_Fn = () => dispatch => {
	return new Promise((resolve, reject) => {
		request_get_market_summary().then(
			res => {
				if (res.status !== 200) {
					console.log("Something went wrong")
					reject("Something went wrong")
					return
				}

				dispatch(market_summary_received(res.data.data))
				resolve()
			},
			err => {
				reject(err)
			}
		)
	})
}

const get_us_investor_details_Fn = () => dispatch => {

	return new Promise((resolve, reject) => {
		request_usi_investor_details().then(
			res => {
				if (res.status !== 200) {
					console.log("Something went wrong")
					reject("Something went wrong")
					return
				}

				dispatch(us_investor_details_received(res.data.data))
				resolve()
			},
			err => {
				reject(err)
			}
		)
	})
}

const get_investment_history_Fn = () => (dispatch, getState) => {
	let state = getState()

	return new Promise((resolve, reject) => {
		Promise.all([
			state.App.userInfo.us_investing_status === constants.us_investing_status.COMPLETED && request_get_usi_investment_history(),
			// state.App.userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED && request_get_usi_holdings()
		]).then(
			res => {
				let investment_history = {}

				if (_.get(res[0], "status") === 200) {
					investment_history = res[0].data.data
				}

				dispatch(investment_history_received(investment_history))
				resolve()
			},
			err => {
				reject(err)
			}
		)
	})
}

export const get_holdings = () => dispatch => {
	dispatch(toggleLoader())

	Promise.all([
		dispatch(get_holdings_Fn())
	]).then(
		res => {
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const get_holdings_Fn = () => (dispatch, getState) => {
	let state = getState()

	return new Promise((resolve, reject) => {
		Promise.all([
			state.App.userInfo.us_investing_status === constants.us_investing_status.COMPLETED && request_get_usi_holdings(),
			// state.App.userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED && request_get_usi_holdings()
		]).then(
			res => {
				if (_.get(res[0], "status") !== 200) {
					console.log("Something went wrong")
					resolve()
					return
				}

				let holdings = _.map(res[0].data.data.holdings_arr, v => {
					return { ...v, country: "US" }
				})

				let my_stocks_info = res[0].data.data.my_stocks_info;

				dispatch(holdings_received(holdings));
				dispatch(my_stocks_info_received(my_stocks_info));
				resolve()
			},
			err => {
				reject(err)
			}
		)
	})
}

export const search_stock = (search, changeState) => dispatch => {
	return request_search_stock(search).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}

			changeState({ results: _.get(res, 'data.data') || [] })
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))

		}
	)
}

export const get_order_history = () => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		state.App.userInfo.us_investing_status === constants.us_investing_status.COMPLETED && request_get_usi_order_history(),
		state.App.userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED && request_get_usi_order_history()
	]).then(
		res => {

			let orders = []

			if (_.get(res[0], "status") === 200) {
				orders = _.map(res[0].data.data, v => {
					return { ...v, country: "US" }
				})
			}

			if (_.get(res[1], "status") === 200) {
				orders = [...orders, ..._.map(res[1].data.data, v => {
					return { ...v, country: "IN" }
				})]
			}

			dispatch(order_history_received(orders))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const us_investor_details_received = (us_investor_details) => ({
	type: INVESTING_US_INVESTOR_DETAILS_RECEIVED,
	us_investor_details
})

const order_history_received = (orders) => ({
	type: INVESTING_ORDER_HISTORY_RECEIVED,
	orders
})

const market_summary_received = (market_summary) => ({
	type: INVESTING_MARKET_SUMMARY_RECEIVED,
	market_summary
})

const investment_history_received = (investment_history) => ({
	type: INVESTING_INVESTMENT_HISTORY_RECEIVED,
	investment_history
})

const holdings_received = (holdings) => ({
	type: INVESTING_HOLDINGS_RECEIVED,
	holdings
})

const my_stocks_info_received = (my_stocks_info) => ({
	type: INVESTING_MY_STOCKS_INFO_RECEIVED,
	my_stocks_info
})

export const order_created = (order) => ({
	type: INVESTING_ORDER_CREATED,
	order
})