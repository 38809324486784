import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import Upload from 'components/Upload'
import ContainedButton from 'components/ContainedButton'
import { upload_kyc } from '../actions'
import { Card, MenuItem } from '@material-ui/core'
import OutlinedButton from 'components/OutlinedButton'
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator'
import constants from 'resources/constants'

class USI_Kyc extends Component {

	state = {
		formData_1: {
			type: "PICTURE_ID_PROOF_OF_ADDRESS",
			document_front: "",
			document_back: ""
		},
		formData_2: {
			type_1: "PICTURE_ID",
			document_1: "",
			type_2: "PROOF_OF_ADDRESS",
			document_2_front: "",
			document_2_back: ""
		},
		type: 1,
		step: 1
	}

	componentWillMount() {
		if (_.get(this.props.userInfo, 'us_investing_status') === constants.us_investing_status.PROCESSING) {
			this.props.history.push("/user/invest")
		}
	}


	changeState = (state) => {
		this.setState(state)
	}

	handleUpload = (key, image) => {
		_.set(this.state, key, image)
		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData_1, formData_2, step, type } = this.state

		if (step === 1) {
			this.setState({ step: 2 })
		} else {
			let data
			switch (type) {
				case 1:
					data = { ...formData_1 }
					if (_.isEmpty(data.document_back)) {
						_.unset(data, "document_back")
					}
					break;

				case 2:
					data = { ...formData_2 }
					if (_.isEmpty(data.document_2_back)) {
						_.unset(data, "document_2_back")
					}
					break;
			}
			this.props.upload_kyc(data, this.changeState)
		}
	}

	handleBackPress = () => {
		const { step } = this.state

		if (step === 2) {
			this.setState({ step: step - 1 })
		} else {
			this.props.history.push("/user/invest")
		}
	}

	render() {
		const { formData_1, formData_2, step, type } = this.state

		let actionCondition = step === 1 || (type === 1 && !_.isEmpty(formData_1.document_front)) ||
			(type === 2 && !_.isEmpty(formData_2.document_1) && !_.isEmpty(formData_2.document_2_front))

		return (
			<div>
				<ValidatorForm onSubmit={() => { }}>
					{
						step === 1 &&
						<div style={STYLES.content}>
							<Text bold="true" style={STYLES.headline}>Upload KYC Documents</Text>
							<SelectValidator
								value={type}
								variant="outlined"
								style={{ marginTop: 40 }}
								onChange={(e) =>
									this.setState({ type: e.target.value })
								}
								fullWidth
								label="Choose type of document">
								<MenuItem label="ID with address on it" value={1}>ID with address on it</MenuItem>
								<MenuItem label="ID and proof of address" value={2}>ID and proof of address</MenuItem>
							</SelectValidator>
							<div style={{ width: 450, paddingTop: 220, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
								<OutlinedButton
									style={{ width: 200 }}
									onClick={this.handleBackPress}>
									Previous
            					</OutlinedButton>
								<ContainedButton
									color={!actionCondition && "default"}
									style={{ width: 200 }}
									onClick={!actionCondition ? () => { } : this.handleSubmit}>
									Continue
            					</ContainedButton>
							</div>
						</div>
					}

					{
						step === 2 &&
						<div style={STYLES.content}>
							<Text bold="true" style={STYLES.headline}>Upload KYC Documents</Text>
							<Text style={STYLES.subHeadline}>Please make sure details are visible and no flash is used</Text>
							{
								type === 1 &&
								<div>
									<Upload
										navigation={this.props.navigation}
										label="Front"
										image={formData_1.document_front}
										addImage={(image) => this.handleUpload("formData_1.document_front", image)}
										removeImage={() => this.handleUpload("formData_1.document_front", "")} />

									<Upload
										navigation={this.props.navigation}
										label="Back (Optional)"
										style={{ marginTop: 20 }}
										image={formData_1.document_back}
										addImage={(image) => this.handleUpload("formData_1.document_back", image)}
										removeImage={() => this.handleUpload("formData_1.document_back", "")} />


									<Text style={{ marginTop: 40 }}>Valid, non-expired picture ID and proof of address.</Text>
									<Text style={{ marginTop: 5 }}>E.g. Valid Driver's License w/ Address, Government issued Photo ID w/ Address.</Text>
								</div>
							}

							{
								type === 2 &&
								<div>
									<Upload
										label=""
										image={formData_2.document_1}
										addImage={(image) => this.handleUpload("formData_2.document_1", image)}
										removeImage={() => this.handleUpload("formData_2.document_1", "")} />

									<Text style={{ marginTop: 40 }}>Valid, non-expired picture ID.</Text>
									<Text style={{ marginTop: 5 }}>E.g. Government issued Photo ID, Valid Driver’s License, Passport, Voters Registration Card with photo</Text>

									<Upload
										style={{ marginTop: 50 }}
										label="Front"
										image={formData_2.document_2_front}
										addImage={(image) => this.handleUpload("formData_2.document_2_front", image)}
										removeImage={() => this.handleUpload("formData_2.document_2_front", "")} />

									<Upload
										style={{ marginTop: 20 }}
										label="Back (Optional)"
										image={formData_2.document_2_back}
										addImage={(image) => this.handleUpload("formData_2.document_2_back", image)}
										removeImage={() => this.handleUpload("formData_2.document_2_back", "")} />

									<Text style={{ marginTop: 40 }}>Valid Proof of Address.</Text>
									<Text style={{ marginTop: 5 }}>E.g. Utility Bill, Mobile Phone Bill, Bank or Credit Card Statement.</Text>
								</div>
							}

							<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
								<OutlinedButton
									style={{ width: 200 }}
									onClick={this.handleBackPress}>
									Previous
            					</OutlinedButton>
								<ContainedButton
									color={!actionCondition && "default"}
									style={{ width: 200 }}
									onClick={!actionCondition ? () => { } : this.handleSubmit}>
									Continue
            					</ContainedButton>
							</div>
						</div>
					}

					{
						step === 3 &&
						<div style={STYLES.container}>
							<Card style={STYLES.card}>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
									<img src={require('images/Auth/thankyou.png')} style={{ height: 220, width: 280 }} />
									<h1 style={{ marginTop: 20 }}>Thank you for signing up</h1>
									<Text style={{ lineHeight: 1.8, width: 240, textAlign: 'center', marginTop: 10, color: Theme.Colors.purplishGrey }}>
										Your account will be created within 2-3 working days.
									</Text>

									<Text style={{ lineHeight: 1.8, width: 240, textAlign: 'center', marginTop: 10, color: Theme.Colors.purplishGrey }}>
										Here's what will happen next:
									</Text>

									<div style={{ width: "100%", padding: 20, display: "flex", flexDirection: "column", alignItems: "center", background: Theme.Colors.whiteTwo, marginTop: 20 }}>
										<div>
											{
												_.map([
													"Your account will be created",
													"You will transfer money to your new US",
													"You start trading!"
												], (value, index) => {
													return (
														<div key={`item${index}`} style={{ display: 'flex', ...index !== 0 && { marginTop: 20 } }}>
															<img src={require('images/Auth/hand.png')} style={{ width: 30, height: 18, marginRight: 10, marginTop: 4 }} />
															<Text key={`item${index}`} style={{ letterSpacing: 0.63, lineHeight: 1.8, }}>{value}</Text>
														</div>
													)
												})
											}
										</div>
									</div>
								</div>
								<div style={{ display: 'flex', justifyContent: 'center', width: 400, margin: '0 auto', marginTop: 30 }}>
									<ContainedButton
										onClick={this.handleBackPress}
										style={{ fontWeight: 700, width: 170 }}>
										DONE
									</ContainedButton>
								</div>
							</Card>
						</div>
					}
				</ValidatorForm>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	upload_kyc: (data, changeState) => dispatch(upload_kyc(data, changeState))
})

export default connect(mapStateToProps, mapDispatchToProps)(USI_Kyc)

const STYLES = {
	container: {
		backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40
	},
	card: {
		width: 600, padding: `40px 0`, borderRadius: 10
	},
	content: {
		padding: 50, background: Theme.Colors.white, marginTop: 40, marginBottom: 50
	},
	headline: {
		fontSize: 25
	},
	subHeadline: {
		marginTop: 40, marginBottom: 20
	},
	arrowBack: {
		marginLeft: -5, marginTop: 20
	},
	actionButton: {
		position: 'absolute', bottom: 30
	}
}