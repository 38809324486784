import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Text from 'components/Text';
import Theme from 'resources/Theme';
import { Button, ListItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class CreditCardSection extends Component {

	render() {
		const { plaidAccounts, userInfo } = this.props

		if (userInfo.cipTag == -1) {
			return null
		}

		let crediCardAccounts = _.filter(plaidAccounts, (value, index) => {
			if (value.type == 'credit') {
				return true
			}
		})

		return (
			<div style={{}} >
				<Text semi="true" style={STYLES.headline}>Credit cards</Text>
				<div style={STYLES.contentContainer}>
					{
						_.map(crediCardAccounts, (value, index) => {
							return (
								<ListItem button
									key={`item${index}`}
									onClick={() => this.props.history.push(`/user/credit-card/${value.account_id}`)}
									style={{ border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1, }}>
									<div style={STYLES.creditCard}>
										<div>
											<Text style={STYLES.currency}>{_.get(value, 'balances.iso_currency_code') || "USD"}</Text>
											<Text bold="true" style={{ fontSize: 24, letterSpacing: 0.8 }}>${_.get(value, 'balances.current') || "0.00"}</Text>
										</div>
										<img
											src={`data:image/png;base64,${value.logo}`}
											style={{ width: 50, height: 50 }} />
									</div>
								</ListItem>
							)
						})
					}
					<div style={{}}>
						<Button onClick={() => document.getElementById("plaid-id").click()} style={{ width: '100%', padding: `15px 0px` }}>
							<Text bold="true" style={STYLES.addButton}>+ LINK A CREDIT CARD</Text>
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo']),
	..._.pick(state.MyProfile, ['notifications', 'plaidAccounts'])
})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditCardSection))

const STYLES = {
	headline: {
		marginTop: 20, fontSize: 16
	},
	contentContainer: {
		marginHorizontal: '5%', marginTop: 10, backgroundColor: Theme.Colors.white, borderRadius: 5
	},
	creditCard: {
		padding: 20, borderRadius: 5, display: 'flex', justifyContent: 'space-between', width: '100%'
	},
	currency: {
		color: Theme.Colors.warmGreyTwo, fontSize: 16, letterSpacing: 0.6
	},
	addButton: {
		fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple
	}
}