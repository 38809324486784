import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { Button, Grid, Divider, Slider, withStyles } from '@material-ui/core'

import Text from 'components/Text'
import Theme from 'resources/Theme'
import { cancel_fd } from '../actions'
import Header from 'components/Header'

class NRI_FD_Detail extends Component {
	state = {
		fd: this.props.history.location.state.fd
	}

	changeState = (state) => {
		this.setState(state)
	}

	render() {
		const { userInfo } = this.props
		const { fd } = this.state
		let payment_address = this.props.history.location.state.payment_address
		let status = ""

		switch (fd.status) {
			case "request_received":
				status = `Please deposit ₹${fd.amount} into the following bank account`
				break;

			case "request_processing":
				status = `We have received your money. Your FD will be created soon. Please check again in some time`
				break;

			case "fd_processed":
				status = "Here are your FD details"
				break;

			case "fd_cancelling":
				status = "Your cancellation request has been received. Your money will be refunded soon"
				break;

			case "fd_returned":
				status = "The FD has been refundned successuflly"
				break;

			case "fd_closed":
				status = "Your FD has matured and transferred to your bank account"
				break;
		}

		return (
			<div>
				<Header name="My Fixed Deposits" />
				<div>
					<Grid container>
						<Grid item xs={12} sm={12}>
							<Text style={{ margin: "2vh 0" }} >{status}</Text>
						</Grid>
						<Grid item xs={12} sm={12}>
							{
								fd.status == "request_received" &&
								<div style={{ backgroundColor: Theme.Colors.white, borderRadius: 5 }}>
									{
										_.map([
											..._.map(payment_address, (value, key) => {
												return { label: _.capitalize(key.split("_").join(" ")), detail: value }
											}), {
												label: "Instructions", detail: <div><Text style={{ fontSize: 16 }}>Please send the following note with the transfer: </Text><Text bold="true" style={{ fontSize: 17, marginTop: 5 }}>FD from {userInfo.basic_information.name}</Text></div>, instruction: true
											}], (value, index) => {
												return (
													<div key={`bank${index}`} >
														<div style={{
															...STYLES.row
														}}>
															<Text style={{ fontSize: 14, color: Theme.Colors.warmGreyTwo }}>{value.label === "Swift" && "SWIFT" || value.label === "Account_no" && "Account No" || value.label}</Text>
															<div style={{ display: "flex", marginTop: 10 }}>
																{
																	value.instruction ?
																		value.detail :
																		<Text style={{ fontSize: 16, color: value.color || Theme.Colors.black }}>{value.detail}</Text>
																}
															</div>

														</div>
														<Divider />
													</div>
												)
											})
									}
								</div>
							}
						</Grid>
					</Grid>
					<Grid container style={{ margin: "2vh 0" }}>
						<Grid item xs={12} sm={12}>
							<div style={{ backgroundColor: Theme.Colors.white, borderRadius: 5, marginBottom: 20 }}>
								<div style={{ padding: 25, display: "flex", }}>
									<div style={{ width: 50, height: 50, borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
										<img src={require("images/NRI_Banking/fd.png")} alt="fd-img" style={{ width: 50, height: 50, }} />
									</div>
									<div style={{ marginLeft: 20 }}>
										<Text style={{ fontSize: 12, color: Theme.Colors.warmGreyTwo }}>{fd.tenure_text} FD</Text>
										<Text bold="true" style={{ fontSize: 24 }}>₹{fd.amount}</Text>
									</div>
								</div>
								<Divider />
								{
									_.map([{
										name: "Interest Rate", detail: fd.rate
									}, {
										name: "Maturity Date", detail: fd.starting_date ? moment(fd.starting_date).add(fd.tenure, "days").format("LL") : "-"
									}, {
										name: "Account Type", detail: fd.type
									}], (value, index) => {
										return (
											<div key={`item${index}`}>
												<div style={{ display: "flex", justifyContent: 'space-between', padding: 25, borderColor: Theme.Colors.whiteTwo, borderTopWidth: 1 }}>
													<Text>{value.name}</Text>
													<Text bold>{value.detail}</Text>
												</div>
												<Divider />
											</div>
										)
									})
								}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} container justify="center">
							<div style={{ display: "flex", justifyContent: "center", alignItems: "space-around", margin: "5vh 0", paddingBottom: "5vh" }}>
								<Button color="primary" variant="outlined" style={STYLES.outlineBtn} onClick={() => this.props.history.push("/user/my-profile")}>
									Back
								</Button>
								{
									fd.status == "fd_processed" &&
									<Button color="primary" variant="contained" onClick={() => this.props.cancel_fd(fd.manual_fd_id, this.changeState)} style={STYLES.primaryBtn}>
										Request cancelation of FD
									</Button>
								}
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo"])
})

const mapDispatchToProps = dispatch => ({
	cancel_fd: (id, changeState) => dispatch(cancel_fd(id, changeState))
})

const STYLES = {
	headline: {
		fontSize: 24,
		marginTop: 5
	},
	headerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "left",
		paddingTop: 22,
	},
	row: {
		borderBottomColor: Theme.Colors.whiteTwo, borderBottomWidth: 1, padding: 20
	},
	outlineBtn: {
		fontWeight: 700, width: 170
	},
	primaryBtn: {
		marginLeft: "10px",
	},
	valueLabel: {
		fontSize: "16px",
		fontWeight: 700,
		backgroundColor: "transparent",
		'& *': {
			background: 'transparent',
			color: Theme.Colors.primaryPurple,
		},
	},
	thumb: {
		color: "transparent",
		backgroundColor: 'transparent',
	},
	track: {
		height: 12,
		borderRadius: 8,
		color: Theme.Colors.primaryPurple,

	},
	rail: {
		height: 12,
		borderRadius: 8,
		color: Theme.Colors.powderPink,
	},
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(NRI_FD_Detail));