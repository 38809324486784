import utils from "resources/utils";
import _ from 'lodash'
import axios from "axios";
import { toggleLoader, requestAccountInfo, requestSendOtp, toggleError, accountInfoReceived, userInfoReceived, getIP } from "actions/actions";
import { pushChangeAddress } from "../MyAccount/actions";
// import firebase from 'react-native-firebase';

export const USER_CREATED = 'USER_CREATED'
export const SSN_RECEIVED = 'SSN_RECEIVED'
export const ADD_IMAGE = 'ADD_IMAGE'
export const REMOVE_IMAGE = 'REMOVE_IMAGE'
export const CONFIRM_DETAILS = 'CONFIRM_DETAILS'
export const ADDRESS_ENTERED = 'ADDRESS_ENTERED'
export const SIGNUP_COMPLETE = 'SIGNUP_COMPLETE'
export const MOVE_TO_CONFIRM_DETAILS = 'MOVE_TO_CONFIRM_DETAILS'
export const MOVE_TO_ADDRESS = 'MOVE_TO_ADDRESS'
export const CONTINUE_SIGNUP = 'CONTINUE_SIGNUP'
export const PUSH_DOB_PHONE_NUMBER = 'PUSH_DOB_PHONE_NUMBER'
export const SET_CIP_TAG = 'SET_CIP_TAG'
export const TOGGLE_SIGNUP_LOADER = 'TOGGLE_SIGNUP_LOADER'
export const AUTOCOMEPLETE_APARTMENT = 'AUTOCOMEPLETE_APARTMENT'
export const PUSH_NAME = 'PUSH_NAME'
export const PUSH_RESIDENCE = 'PUSH_RESIDENCE'

const requestCreateAccount = async (formData) => {
	let fcmToken = await utils._retrieveData('fcmToken');
	return axios.request({
		url: "/auth/sign-up",
		method: "POST",
		data: {
			username: formData.username,
			password: formData.password,
			device_token: fcmToken || 'abc'
		},
	})
}

const requestUpdateUser = (data) => (
	utils.request({
		url: "/banking/user",
		method: "PATCH",
		data: data
	})
)

const requestCheckAddress = (address) => (
	axios.request({
		url: `/auth/check-address`,
		method: "GET",
		params: {
			'street': address.address1,
			'street2': address.address2 || "",
			'city': address.city,
			'zipcode': address.zip,
			'state': address.state
		}
	})
)

const requestCompleteSignUp = (data, cip_tag) => (
	utils.request({
		url: cip_tag == "3" ? "/banking/synapse-user/3" : "/banking/synapse-user",
		method: "POST",
		data: data
	})
)

const requestReSubmitDocs = (invalidDocs) => (
	utils.request({
		url: `/banking/account/docs`,
		method: "PATCH",
		data: {
			physical_docs: invalidDocs
		}
	})
)

const requestSubmitReferrer = (invited_by) => (
	utils.request({
		url: `/reward/referral`,
		method: "POST",
		data: {
			referrer_auth0_id: invited_by
		}
	})
)

export const createAccount = (formData, changeStep) => dispatch => {
	dispatch(toggleLoader())
	//firebase.analytics().logEvent("signup_create_auth", {});
	requestCreateAccount(formData).then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			let invited_by = await utils._retrieveData("invited_by")
			if (invited_by) {
				await utils._removeData("invited_by")
				await requestSubmitReferrer(invited_by).then(
					res3 => {
						if (res3.status !== 200) {
							console.log("Something went wrong")
							return
						}
						console.log(`user invited by ${invited_by}`)
					},
					err => {
						console.log(JSON.stringify(err), 'error')
					}
				)
			}
			res.data.data.access_token = _.split(res.data.data.access_token, ' ')[0] == 'Bearer' ? res.data.data.access_token : `Bearer ${res.data.data.access_token}`

			dispatch(userCreated(res.data.data, formData, "normal"))
			utils.setRequestAuthHeader(res.data.data.access_token, null, null, formData.username)
			changeStep(2)
			dispatch(toggleLoader())
		},
		err => {
			if (_.get(err, 'response.status') === 400) {
				console.log(JSON.stringify(err), 'error')
				dispatch(toggleError('Email already exists. Please try a different email.'))
				dispatch(toggleLoader())
				return
			}
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)

}

export const updateUser = (cip_tag, navigation) => async (dispatch, getState) => {
	dispatch(toggleLoader())
	let state = getState().Auth
	let data = state.personalDetails
	data.cip_tag = cip_tag

	requestUpdateUser(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(userInfoReceived(res.data.data))
			dispatch(setCipTag(cip_tag))
			navigation.push("/user/my-profile")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)

}

export const checkAddress = (address, changeStep, type = "signup") => dispatch => {
	//type = signup, autocomplete, change-autocomplete, change-signup
	dispatch(toggleLoader())
	if (type === 'signup' || type === 'autocomplete') {
		//firebase.analytics().logEvent("signup_address", {});
	}
	requestCheckAddress(address).then(
		res => {
			if (_.get(res, "data[0].analysis.dpv_match_code") === 'D') {
				dispatch(toggleLoader())
				if (type === 'autocomplete' || type === 'change-autocomplete') {
					dispatch(autoCompleteApartmentChange(address))
				} else {
					dispatch(toggleError("Please enter a suite/house/apartment number"))
				}
				return
			}
			if (
				_.get(res, "data[0].analysis.dpv_match_code") === 'N' ||
				_.isEmpty(res.data) ||
				_.isEmpty(_.get(res, 'data[0].components'))
			) {
				dispatch(toggleLoader())
				dispatch(toggleError("Address is not deliverable"))
				return
			}

			address = {
				country: "US",
				state: res.data[0].components.state_abbreviation,
				city: res.data[0].components.city_name,
				street: (res.data[0].components.primary_number || "") +
					(!_.isEmpty(res.data[0].components.street_predirection) ? (" " + res.data[0].components.street_predirection) : "") +
					(!_.isEmpty(res.data[0].components.street_name) ? (" " + res.data[0].components.street_name) : "") +
					(!_.isEmpty(res.data[0].components.street_suffix) ? (" " + res.data[0].components.street_suffix) : "") +
					(!_.isEmpty(res.data[0].components.secondary_designator) ? (", " + res.data[0].components.secondary_designator) : "") +
					(!_.isEmpty(res.data[0].components.secondary_number) ? (" " + res.data[0].components.secondary_number) : ""),
				zip: res.data[0].components.zipcode
			}
			if (type === 'signup' || type === 'autocomplete') {
				dispatch(pushAddress(address))
				changeStep(4)
			} else if (type == 'change-autocomplete' || type == 'change-signup') {
				dispatch(pushChangeAddress(address))
				changeStep(3)
			}
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const completeSignUp = (navigation) => async (dispatch, getState) => {
	try {
		dispatch(toggleSignUpLoader())
		let state = getState()
		let payload = {}

		if (state.Auth.cip_tag === 2) {
			payload = _.merge(state.Auth.signUpPayloadCommon, state.Auth.signUpPayloadCip2)
		} else {
			payload = _.merge(state.Auth.signUpPayloadCommon, state.Auth.signUpPayloadCip3)
		}

		let ip = await getIP()

		let email = _.get(state, 'Auth.userData.username') || _.get(state, 'App.userInfo.basic_information.email')
		payload.email = email
		payload.documents.email = email
		payload.password = _.get(state, 'Auth.userData.password')
		let name = _.get(state, 'App.userInfo.basic_information.name') || _.get(state, 'App.userInfo.name')
		payload.documents.name = name
		payload.legal_names = [name]
		payload.user_ip = ip
		payload.documents.ip = ip


		requestCompleteSignUp(payload, state.Auth.cip_tag).then(
			res => {
				if (res.status !== 200) {
					console.log("Something went wrong")
					dispatch(toggleSignUpLoader())
					return
				}

				//firebase.analytics().logEvent("signup_account_created", {});
				utils.setRequestAuthHeader(state.Auth.user.access_token, res.data.data.oauth_key, res.data.data.user_id, state.Auth.user.email)
				requestAccountInfo().then(
					async res2 => {
						if (res2.status !== 200) {
							console.log("Something went wrong")
							dispatch(toggleSignUpLoader())
							return
						}
						dispatch(accountInfoReceived(res2.data.data))
						dispatch(signUpCompleted("secondStageVerifyPhone"))
						switch (res2.data.data.sign_up_status_2) {
							case "secondStageVerifyPhone":
								await requestSendOtp().then(
									res3 => {
										dispatch(toggleSignUpLoader())
										if (res3.status == 201) {
											navigation.push("/thank-you")
											return
										}
										if (res3.status !== 200) {
											console.log("Something went wrong")
											navigation.push("/thank-you")
											return
										}

										navigation.push("/user/verify-phone")
									},
									err => {
										console.log(JSON.stringify(err), 'error')
										dispatch(toggleSignUpLoader())
										navigation.push("/thank-you")
									}
								)
								break;

							case "completed":
								dispatch(toggleSignUpLoader())
								navigation.push("/thank-you")
								dispatch(signUpCompleted("completed"))
							default:
								dispatch(toggleSignUpLoader())
								navigation.push("/thank-you")
								dispatch(signUpCompleted(res2.data.data.sign_up_status_2))
								break;
						}
					},
					err => {
						console.log(JSON.stringify(err), 'error')
						dispatch(toggleError(err))
						dispatch(toggleSignUpLoader())
					}
				)
			},
			err => {
				console.log(JSON.stringify(err), 'error')
				dispatch(toggleError(err))
				dispatch(toggleSignUpLoader())
			}
		)
	} catch (err) {
		console.log(err, 'error')
		dispatch(toggleSignUpLoader())
	}
}

export const reScanDocs = (navigation) => (dispatch, getState) => {
	dispatch(toggleLoader())
	let invalidDocs = getState().App.accountInfo.invalidDocs

	requestReSubmitDocs(invalidDocs).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			navigation.navigate("Step5ThankYou")
			dispatch(signUpCompleted("secondStageProcessing"))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)

}

export const signUpCompleted = (sign_up_status_2) => ({
	type: SIGNUP_COMPLETE,
	sign_up_status_2
})

export const userCreated = (user, userData, signup_type) => ({
	type: USER_CREATED,
	user,
	userData,
	signup_type
})

export const pushName = (name) => ({
	type: PUSH_NAME,
	name
})

export const pushResidence = (residence) => ({
	type: PUSH_RESIDENCE,
	residence
})

export const pushSSN = (SSN) => ({
	type: SSN_RECEIVED,
	SSN
})

export const addImage = (key, image, reupload) => ({
	type: ADD_IMAGE,
	key,
	image,
	reupload
})

export const removeImage = (key, reupload) => ({
	type: REMOVE_IMAGE,
	key,
	reupload
})

export const pushConfirmDetails = (confirmDetails) => ({
	type: CONFIRM_DETAILS,
	confirmDetails
})

export const pushAddress = (address) => ({
	type: ADDRESS_ENTERED,
	address
})

export const pushDOB_PhoneNumber = (data) => ({
	type: PUSH_DOB_PHONE_NUMBER,
	data
})

export const moveToConfirmDetails = (navigation) => ({
	type: MOVE_TO_CONFIRM_DETAILS,
	navigation
})

export const moveToAddress = (navigation) => ({
	type: MOVE_TO_ADDRESS,
	navigation
})

export const continueSignUp = (signUpState) => ({
	type: CONTINUE_SIGNUP,
	signUpState
})

export const setCipTag = (cip_tag) => ({
	type: SET_CIP_TAG,
	cip_tag
})

const toggleSignUpLoader = () => ({
	type: TOGGLE_SIGNUP_LOADER
})

export const autoCompleteApartmentChange = (autoCompleteApartment) => ({
	type: AUTOCOMEPLETE_APARTMENT,
	autoCompleteApartment
})