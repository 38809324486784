import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { getAccountDetails } from './actions';
import utils from 'resources/utils';
import { toggleLoader } from 'actions/actions';
import { Button } from '@material-ui/core';
import Header from 'components/Header';

class AccountDetails extends React.Component {

	componentWillMount() {
		if (_.isEmpty(this.props.accountDetails))
			this.props.getAccountDetails()
	}

	downloadPdf = async () => {
		try {
			this.props.toggleLoader()
			let pdf = await utils.request({
				url: `banking/account/details`,
				responseType: "arraybuffer",
			})

			const url = window.URL.createObjectURL(new Blob([pdf.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'account-details.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();

			this.props.toggleLoader()
		} catch (error) {
			this.props.toggleLoader()
		}
	}

	render() {
		const { accountDetails } = this.props
		return (
			<div>
				<Header name="Account Details" onBack={true} />
				<div style={STYLES.contentContainer}>
					<div style={STYLES.rowHeader}>
						<img src="https://nolo-iwt-images.s3.us-east-2.amazonaws.com/bitmap.png" style={{ width: 60, height: 60 }} />
						<div style={{ marginLeft: 20, marginTop: 5 }}>
							<Text>Bank Name</Text>
							<Text bold="true" style={{ fontSize: 16, marginTop: 5 }}>{_.get(accountDetails, 'domestic.bank_name') || ""}</Text>
						</div>
					</div>
					<div style={STYLES.row}>
						<Text style={STYLES.rowText}>Account Number :
							<span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 30 }}>{_.get(accountDetails, 'domestic.account_number') || ""}</span>
						</Text>
					</div>
					<div style={{ ...STYLES.row, borderBottomWidth: 0 }}>
						<Text style={STYLES.rowText}>Routing Number :
							<span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 30 }}>{_.get(accountDetails, 'domestic.routing') || ""}</span>
						</Text>
					</div>
				</div>
				<Button target="_blank" href="https://northloop.zendesk.com/hc/en-us/articles/360027983492-What-is-Evolve-Bank-Trust" style={{ marginTop: 10 }}>
					<Text style={{ fontSize: 14, color: Theme.Colors.primaryPurple }}>What's Evolve Bank?</Text>
				</Button>
				<Button variant="contained" color="primary" style={{ marginTop: 40, width: '100%' }} onClick={this.downloadPdf}>
					Wire Details
				</Button>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.MyAccount, ['accountDetails'])
})

const mapDispatchToProps = dispatch => ({
	getAccountDetails: () => dispatch(getAccountDetails()),
	toggleLoader: () => dispatch(toggleLoader())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)


const STYLES = {
	contentContainer: {
		backgroundColor: Theme.Colors.white, marginTop: 50, width: '100%', borderColor: Theme.Colors.lightGrey, borderWidth: 1
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	rowHeader: {
		backgroundColor: Theme.Colors.whiteTwo, padding: 30, display: 'flex'
	},
	row: {
		borderBottomColor: Theme.Colors.lightGrey, borderBottomWidth: 1, padding: 30, display: 'flex', justifyContent: 'space-between'
	},
	rowText: {
		marginTop: 5
	}
}