import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash'

export default class Loader extends Component {

	componentWillMount() {
		// document.getElementById("body").style.overflow = 'hidden'
	}

	componentWillUnmount() {
		// document.getElementById("body").style.overflow = 'scroll'
	}

	render() {
		return (
			<div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#44414124', zIndex: 10000 }}>
				<CircularProgress />
			</div>
		);
	}
}