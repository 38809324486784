import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import { createFD, get_fd_terms } from "../../actions"

class NRI_Auth extends Component {
	state = {
		formData: {
			tenure_in_days: "",
			amount: 0,
			type: "",
		},
		terms: {},
		selectedTenure: {},
		selectedTenureArray: [],
		selectedRate: "",
		step: 1,
	}

	constructor(props) {
		super(props)

		this.props.get_fd_terms(this.state, this.changeState)
	}

	handleChange = (name, value) => {
		const { formData } = this.state

		formData[name] = value
		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData } = this.state

		this.props.createFD(formData, this.changeStep)
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	changeState = (state) => {
		this.setState(state)
	}

	handleBackPress = () => {
		const { step } = this.state
		if (step == 1 || step == 4) {
			this.props.history.push("/user/my-profile")
		} else {
			this.changeStep(step - 1)
		}
	}

	render() {
		const { formData, step } = this.state

		let parentProps = {
			formData: formData,
			state: this.state,
			changeState: this.changeState,
			changeStep: this.changeStep,
			handleChange: this.handleChange,
			handleSubmit: this.handleSubmit,
			handleBack: this.handleBackPress,
			navigation: this.props.navigation,
		}

		return (
			<div style={STYLES.container}>
				{step == 1 && <Step1 parentProps={parentProps} />}

				{step == 2 && <Step2 parentProps={parentProps} />}

				{step == 3 && <Step3 parentProps={parentProps} />}

				{step == 4 && <Step4 parentProps={parentProps} />}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["accountInfo", "userInfo"]),
})

const mapDispatchToProps = (dispatch) => ({
	get_fd_terms: (state, changeState) => dispatch(get_fd_terms(state, changeState)),
	createFD: (data, changeStep) => dispatch(createFD(data, changeStep)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NRI_Auth)

const STYLES = {
	container: {
		flex: 1,
	},
}