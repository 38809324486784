import { LINKED_DEBIT_ACCOUNTS_RECEIVED, BANK_LIST_RECEIVED, BANK_ACCOUNT_REMOVED, LINKED_DEBIT_CARDS_RECEIVED, CARD_REMOVED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";
import utils from "resources/utils";

const initialState = {
	linkedAccountsDebit: [],
	linkedDebitCards: [],
	bankList: []
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case LINKED_DEBIT_ACCOUNTS_RECEIVED: {
			let linkedAccountsDebit = _.filter(action.linkedAccountsDebit, value => {
				if (value.allowed === 'CREDIT-AND-DEBIT') {
					return value
				}
			})
			return { ...state, linkedAccountsDebit: linkedAccountsDebit }
		}

		case LINKED_DEBIT_CARDS_RECEIVED: {
			return { ...state, linkedDebitCards: action.linkedDebitCards }
		}

		case BANK_LIST_RECEIVED: {
			return { ...state, bankList: action.bankList }
		}

		case BANK_ACCOUNT_REMOVED: {
			let linkedAccountsDebit = utils.updateItemList(state.linkedAccountsDebit, { node_id: action.node_id }, 'DELETE')
			return { ...state, linkedAccountsDebit: linkedAccountsDebit }
		}

		case CARD_REMOVED: {
			let linkedDebitCards = _.filter(state.linkedDebitCards, value => {
				if (value.node_id !== action.node_id) {
					return value
				}
			})
			return { ...state, linkedDebitCards: linkedDebitCards }
		}

		case LOGOUT_USER: {
			return { ...initialState }
		}

		default:
			return state;
	}
}