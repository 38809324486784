import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";
import { INVESTING_ORDER_HISTORY_RECEIVED, INVESTING_ORDER_CREATED, INVESTING_MARKET_SUMMARY_RECEIVED, INVESTING_INVESTMENT_HISTORY_RECEIVED, INVESTING_HOLDINGS_RECEIVED, INVESTING_US_INVESTOR_DETAILS_RECEIVED, INVESTING_MY_STOCKS_INFO_RECEIVED } from "./actions";

const initialState = {
	market_summary: {},
	orders: [],
	investment_history: {},
	holdings: [],
	us_investor_details: {}
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case INVESTING_MARKET_SUMMARY_RECEIVED: {
			return { ...state, market_summary: { ...state.market_summary, ...action.market_summary } }
		}

		case INVESTING_US_INVESTOR_DETAILS_RECEIVED: {
			return { ...state, us_investor_details: action.us_investor_details }
		}

		case INVESTING_INVESTMENT_HISTORY_RECEIVED: {
			return { ...state, investment_history: action.investment_history }
		}

		case INVESTING_HOLDINGS_RECEIVED: {
			return { ...state, holdings: action.holdings }
		}

		case INVESTING_ORDER_HISTORY_RECEIVED: {
			return { ...state, orders: action.orders }
		}

		case INVESTING_ORDER_CREATED: {
			return { ...state, orders: [...state.orders, action.order] }
		}

		case INVESTING_MY_STOCKS_INFO_RECEIVED: {
			return { ...state, market_summary: { ...state.market_summary, my_stocks_info: action.my_stocks_info } }
		}

		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}