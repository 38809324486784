import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Text from 'components/Text';
import Theme from 'resources/Theme';
import { creditCardInfoReceived, getCreditCardScreenInfo, creditCardTransactionsReceived } from '../actions';
import moment from 'moment';
import { Button } from '@material-ui/core';

class CreditCardDetails extends Component {

	componentWillMount() {
		let account_id = _.get(this.props, "match.params.account_id")
		this.props.getCreditCardScreenInfo(account_id)
	}

	componentWillUnmount() {
		this.props.creditCardInfoReceived(null)
		this.props.creditCardTransactionsReceived([])
	}

	render() {
		const { liabilityInfo, creditCardTransactions } = this.props

		let account_id = _.get(this.props, "match.params.account_id")
		let details = _.get(liabilityInfo, 'liabilities.credit[0]') || {}
		let name = _.get(liabilityInfo, 'accounts[0].name')

		if (_.isObject(liabilityInfo) && _.isEmpty(liabilityInfo)) {
			this.props.history.push("/user/my-profile")
		}

		return (
			<div style={{ marginBottom: 50 }}>
				<div>
					<Text bold="true" style={STYLES.headline}>{name || "Credit Card"}</Text>
				</div>
				<div style={{ marginTop: 20, backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.lightGrey, borderBottomWidth: 0.8, }}>
					{
						_.map([{
							name: "Outstanding Balance",
							details: _.get(liabilityInfo, 'accounts[0].balances.current') === undefined ? "-" : `$${_.get(liabilityInfo, 'accounts[0].balances.current')}`,
							color: Theme.Colors.primaryPurple
						}, {
							name: "APR",
							details: details.aprs ? `${(_.find(details.aprs, { apr_type: "purchase_apr" }) || {}).apr_percentage}%` : '-'
						}, {
							name: "Next Payment",
							details: details.next_payment_due_date ? moment(details.next_payment_due_date).format("MMM Do, YYYY") : "-",
							color: Theme.Colors.primaryPurple
						}, {
							name: "Last Payment",
							details: details.last_payment_date ? moment(details.last_payment_date).format("MMM Do, YYYY") : "-"
						}, {
							name: "Minimum Payment",
							details: details.minimum_payment_amount === undefined ? "-" : `$${details.minimum_payment_amount}`
						}, {
							name: "Overdue",
							details: details.is_overdue == undefined ? '-' : details.is_overdue ? "Yes" : "No",
							color: details.is_overdue == undefined ? Theme.Colors.black :
								(details.is_overdue ? Theme.Colors.coralRed : Theme.Colors.darkishGreen),
						}], (value, index) => {
							return (
								<div key={`item${index}`} style={{
									border: `0px solid ${Theme.Colors.lightGrey}`,
									...index !== 0 && { borderTopWidth: 0.8 },
									padding: `30px 2%`, justifyContent: 'space-between', display: 'flex'
								}}>
									<Text>{value.name}</Text>
									<Text bold="true" style={{ fontSize: 16, color: value.color || Theme.Colors.black }}>{value.details}</Text>
								</div>
							)
						})
					}
				</div>
				<div style={{ marginHorizontal: '5%', marginTop: 25, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Text style={{ color: Theme.Colors.greyishBrown, fontSize: 16 }}>Transaction History</Text>
					<Button
						style={{ display: 'flex', justifyContent: 'space-between' }}
						onClick={() => {
							this.props.history.push(`/user/transactions/linked-${account_id}`)
						}}>
						<Text style={{ color: Theme.Colors.primaryPurple, fontSize: 16 }}>View all</Text>
						<i className="material-icons" style={{ fontSize: 24, color: Theme.Colors.primaryPurple }}>chevron_right</i>
					</Button>
				</div>
				<div style={{ marginTop: 20, backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.lightGrey, borderBottomWidth: 0.8, }}>
					{
						_.isEmpty(creditCardTransactions) ?
							<Text style={{ padding: `25px 2%`, }}>No Transaction History</Text>
							:
							_.map(creditCardTransactions, (value, index) => {
								return (
									<div key={`item${index}`} style={{
										border: `0px solid ${Theme.Colors.lightGrey}`,
										...index !== 0 && { borderTopWidth: 0.8 },
										padding: `25px 2%`,
										justifyContent: 'space-between', display: 'flex', alignItems: 'center'
									}}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={value.logo} style={{ height: 40, width: 40, borderRadius: 15 }} />
											<Text style={{ marginLeft: 15 }}>{_.truncate(value.name, { length: 25 })}</Text>
										</div>
										<Text bold="true" style={{ fontSize: 16, color: value.color || Theme.Colors.black, letterSpacing: 0.6 }}>${value.amount.toFixed(2)}</Text>
									</div>
								)
							})
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.MyProfile, ['liabilityInfo', 'creditCardTransactions'])
})

const mapDispatchToProps = dispatch => ({
	getCreditCardScreenInfo: (account_id) => dispatch(getCreditCardScreenInfo(account_id)),
	creditCardInfoReceived: (info) => dispatch(creditCardInfoReceived(info)),
	creditCardTransactionsReceived: (transactions) => dispatch(creditCardTransactionsReceived(transactions))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardDetails)

const STYLES = {

	backBtn: {
		marginLeft: -5
	},
	headline: {
		fontSize: 22
	},
}