import React, { Component } from 'react'
import _ from 'lodash'
import Theme from 'resources/Theme';
import currency from 'resources/currency'
import country from 'resources/CountryData/country'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, ListItem } from '@material-ui/core';
import Text from './Text'
import { List } from "react-virtualized";

export default class CountryModal extends Component {

	constructor(props) {
		super(props)
		let allValues
		let type = this.props.type
		switch (type) {
			case 'currency':
				allValues = currency
				break;

			case 'phone':
			case 'residence':
				allValues = country
				break;
		}

		this.state = {
			search: "",
			allValues: allValues,
			type: type
		}
	}

	handleCurrencyAndCountrySearch = (text) => {
		let currencySearch = _.reduce(currency, (result, value, index) => {
			if (_.includes(value.name.toLowerCase(), text.toLowerCase())) {
				result.push(value)
				return result
			}
			if (_.includes(value.currency.toLowerCase(), text.toLowerCase())) {
				result.push(value)
				return result
			}
			return result
		}, [])

		this.setState({
			allValues: currencySearch,
			search: text
		})
	}

	handlePhoneAndCountrySearch = (text) => {
		let countries = _.reduce(country, (result, value, index) => {
			if (_.includes(value.name.toLowerCase(), text.toLowerCase())) {
				result.push(value)
				return result
			}

			if (_.includes(value.code.toLowerCase(), text.toLowerCase())) {
				result.push(value)
				return result
			}

			if (_.includes(value.dial_code, text)) {
				result.push(value)
				return result
			}
			return result
		}, [])

		this.setState({
			allValues: countries,
			search: text
		})
	}

	renderRow = ({ index, key, style }) => {
		const { search, allValues, type } = this.state
		const value = allValues[index]
		return (
			<ListItem
				key={key}
				button
				style={style}
				onClick={() => {
					switch (type) {
						case 'currency':
							this.props.selectCurrency(value.currency, value.flag)
							break;

						case 'phone':
							this.props.selectCountryCode(value.dial_code, value.flag)
							break;
						case 'residence':
							this.props.selectCountry(value.code, value.flag)
							break;
					}
					this.props.close()
				}}>
				<div style={{ display: 'flex', justifyContent: 'space-between', paddingHorizontal: '10%', width: '100%' }}>
					<div style={{ display: 'flex', }}>
						<img src={value.flag} style={{ width: 36, height: 24 }} />
						<Text style={{ marginLeft: 15, fontSize: 16 }}>{value.name}</Text>
					</div>
					{
						(type == 'phone' || type == 'currency') &&

						<Text style={{ fontSize: 16, color: Theme.Colors.warmGreyTwo }}>{type == 'phone' ? value.dial_code : value.currency}</Text>
					}
				</div>
			</ListItem>
		)
	}

	render() {
		const { search, allValues, type } = this.state
		const { open, close } = this.props
		return (
			<Dialog
				open={open}
				onClose={close}>
				<DialogTitle>
					<Text bold={true} style={STYLES.headline}>
						{type == 'currency' && "Choose a currency"}
						{type == 'residence' && "Choose Country"}
						{type == 'phone' && "Choose Dial Code"}
					</Text>
					<TextField
						onChange={e => type == 'currency' ?
							this.handleCurrencyAndCountrySearch(e.target.value) :
							this.handlePhoneAndCountrySearch(e.target.value)
						}
						value={search}
						placeholder={type == 'currency' ? "Type a currency / country" : "Search"}
						fullWidth
						style={{ fontSize: 14, marginTop: 20, marginLeft: 5, color: Theme.Colors.greyishBrown }} />
				</DialogTitle>
				<DialogContent style={{ height: 400, width: 400 }}>
					{
						type == 'currency' &&
						<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown, marginTop: 30, marginBottom: 10 }}>All currencies</Text>
					}
					<List
						width={400}
						height={400}
						rowHeight={70}
						rowRenderer={this.renderRow}
						rowCount={allValues.length} />
				</DialogContent>
				<DialogActions>

				</DialogActions>
			</Dialog>
		)
		// return (
		// 	<Modal styles={{ width: '100%', marginLeft: 0, height: '100%', padding: 0 }}>
		// 		<div style={{ height: 40, backgroundColor: Theme.Colors.whiteTwo, marginTop: 25, borderRadius: 5, flexDirection: 'row', paddingLeft: 10, width: '90%', marginHorizontal: '5%' }}>
		// 			<Icon type="MaterialIcons" name="search" style={{ fontSize: 18, marginTop: 10, color: Theme.Colors.greyishBrown }} />

		// 	</Modal>
		// )
	}
}

const STYLES = {
	headerContainer: {
		flexDirection: 'row', justifyContent: 'center', paddingTop: 22
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	}
}