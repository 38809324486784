import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { get_mf_details } from './actions';
import moment from 'moment'
import constants from 'resources/constants';

class MF_Detailed_Page extends React.Component {

	state = {
		data: [],
		min: 0,
		max: 0,
		selectedRange: "5D",
		addButtonOpen: false,
		stock: this.props.navigation.getParam("stock"),
		mf_details: {}
	}

	componentWillMount() {
		this.props.get_mf_details(this.state.stock.code, this.update_initial_data)
	}

	update_initial_data = (mf_details) => {
		this.setState({ mf_details }, () => {
			this.changeGraphValues("5D")
		})
	}

	toggleModal = () => {
		this.setState({ addButtonOpen: !this.state.addButtonOpen })
	}

	changeGraphValues = (range) => {
		let chart_data = _.get(this.state.mf_details, "navResult.dataset.data") || []
		let data = []
		let date_before

		switch (range) {
			case "5D":
				date_before = moment().add('-5', 'days').unix()
				break
			case "6M":
				date_before = moment().add('-6', 'months').unix()
				break
			case "1Y":
				date_before = moment().add('-1', 'year').unix()
				break
			case "5Y":
				date_before = moment().add('-5', 'year').unix()
				break
			case "YTD":
				date_before = moment(`01-01-${moment().format("YYYY")}`).unix()
				break
			case "All":
				date_before = 0
				break
		}

		let min
		let max

		for (let i = 0; i < chart_data.length; i++) {
			if (moment(chart_data[i][0]).unix() >= date_before) {
				let temp = chart_data[i][1]
				if (min === undefined && max === undefined) {
					min = temp
					max = temp
				} else {
					if (temp < min) {
						min = temp * 0.999
					}

					if (temp > max) {
						max = temp * 1.001
					}
				}
				data.push({
					x: moment(chart_data[i][0]).unix(),
					y: temp
				})
			}
		}

		if (min === undefined && max === undefined) {
			min = max = 0
		}

		this.setState({ data, min, max, selectedRange: range })
	}

	handleBackPress = () => {
		this.props.navigation.navigate("Main")
	}

	render() {
		const { userInfo, loader } = this.props
		const { mf_details, selectedRange, data, addButtonOpen, min, max } = this.state

		if (loader || _.isEmpty(mf_details)) {
			return (<div></div>)
		}

		let name = _.get(mf_details, "navResult.dataset.name") || ""
		name = name.split(' - ').map((item, index) => {
			if (index !== 0) {
				if (index !== name.split('-').length - 1) {
					return item + " - " + " "
				} else return item
			}
		})

		return (
			<div>
				<div>
					<div style={STYLES.contentContainer}>
						<div style={{ flexDirection: "row", marginTop: 20 }}>
							<div style={STYLES.logoContainer}>
								<img src={{ uri: mf_details.logo }} style={{ width: 50, height: 50, borderRadius: 25 }} />
							</div>
							<div style={{ marginLeft: 15, width: '80%' }}>
								<Text style={{ letterSpacing: 0.6, lineHeight: 22 }}>
									{name}
								</Text>
							</div>
						</div>
						{/* <div style={{ flexDirection: "row" }}>
						<Text style={{ color: Theme.Colors.warmGreyTwo, marginTop: 10, }}>
							Low Duration
						</Text>
						<Text style={STYLES.dot}>.</Text>
						<Text style={{ color: Theme.Colors.warmGreyTwo, marginTop: 10, marginLeft: 5 }}>
							Debt
						</Text>
						<Text style={STYLES.dot}>.</Text>
						<Text style={{ color: Theme.Colors.warmGreyTwo, marginTop: 10, marginLeft: 5 }}>
							Moderate Risk
						</Text>
					</div> */}
					</div>
					{
						!_.isEmpty(data) &&
						<div style={{ backgroundColor: Theme.Colors.white, marginTop: 20 }}>
							{/* <SlideAreaChart
								animated={false}
								chartLineColor={Theme.Colors.primaryPurple}
								yRange={[min, max]}
								data={data}
								yAxisProps={{
									showBaseLine: false,
									verticalLineColor: "transparent"
								}}
								alwaysShowIndicator={false}
								fillColor={Theme.Colors.white}
								toolTipProps={{
									toolTipTextRenderers: [
										({ scaleY, y }) => ({
											text: `Price: ${scaleY.invert(y).toFixed(2).toString()}`,
										}),
										({ scaleX, x }) => ({
											text: `Date: ${moment.unix(scaleX.invert(x).toFixed(0)).format("DD-MM-YYYY")}`,
										}),
									],
									height: 60
								}}

								cursorProps={{
									cursorColor: Theme.Colors.primaryPurple
								}}
							/> */}
							<div style={{ borderColor: Theme.Colors.lightGrey, borderTopWidth: 1, padding: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
								{
									_.map([{ range: "5D" }, { range: "6M" }, { range: "1Y" }, { range: "5Y" }, { range: "YTD" }, { range: "All" }], (value, index) => {
										return (
											<div
												onPress={() => this.changeGraphValues(value.range)}
												key={`range${index}`}
												style={{
													...selectedRange === value.range && { backgroundColor: Theme.Colors.primaryPurple },
													borderRadius: 5, paddingHorizontal: 15, paddingVertical: 8
												}}>
												<Text style={{
													color: selectedRange === value.range ? Theme.Colors.white : Theme.Colors.black,
													fontSize: 12
												}}>{value.range}</Text>
											</div>
										)
									})
								}
							</div>
						</div>
					}
					<div style={STYLES.fundDetailContainer}>
						<div style={STYLES.fundHeadline}>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>Fund Details</Text>
						</div>
						<div style={STYLES.fundDetailBody}>
							{
								_.map([{
									title: "Risk", body: mf_details.risk
								}, {
									title: "Minimum SIP", body: `₹${mf_details.min_sip_amount}`
								}, {
									title: "Expense Ratio", body: `${mf_details.expense_ratio}%`
								}, {
									title: "NAV", body: `${_.get(mf_details, "navResult.dataset.data[0][1]")} (${moment(_.get(mf_details, "navResult.dataset.data[0][0]")).format('DD MMM, YYYY')})`
								}, {
									title: "Fund Started", body: moment(mf_details.launch_date).format('DD MMM, YYYY')
								}, {
									title: "Fund Size", body: mf_details.fund_size
								}], (value, index) => {
									return (
										<div key={`item${index}`} style={{ width: '50%', marginTop: 25 }}>
											<Text>{value.title}</Text>
											<Text bold style={{ fontSize: 16, marginTop: 5 }}>
												{value.body}
											</Text>
										</div>
									)
								})
							}
						</div>
					</div>

					<div style={STYLES.fundDetailContainer}>
						<div style={STYLES.returnHeadline}>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>Returns</Text>
						</div>
						<div style={{ flexDirection: "row", backgroundColor: Theme.Colors.paleGreyThree, borderRadius: 5, padding: 10, justifyContent: 'space-between', marginHorizontal: '3%' }}>
							{
								_.map(["Fund Name", "1Y", "3Y", "5Y", "Lifetime"], (value, index) => {
									return (
										<Text style={{
											color: Theme.Colors.greyishBrown,
											...index === 0 && { width: '35%' },
											...index === 4 && { width: 60 }
										}} key={`return_headline_${index}`}>{value}</Text>
									)
								})
							}
						</div>
						<div style={{}}>
							{
								_.map([{
									name: "Fund", returns: mf_details.returns
								}, {
									name: "Benchmark Average", returns: mf_details.returns
								}, {
									name: "Category Rank", returns: mf_details.returns
								}], (value, index) => {
									return (
										<div key={`item${index}`} style={{ marginTop: 25, flexDirection: "row", padding: 10, justifyContent: 'space-between', marginLeft: '3%', marginRight: '5%', alignItems: 'center' }}>
											{/* <Text style={{ width: '34%', lineHeight: 22 }}>{value.name}</Text>
										<Text style={{
											color: Theme.Colors.greyishBrown,
											textAlign: 'center',
											fontSize: 12,
										}}>{_.get(value, 'returns["1"]')}</Text>
										<Text style={{
											color: Theme.Colors.greyishBrown,
											textAlign: 'center',
											fontSize: 12,
										}}>{_.get(value, 'returns["1"]')}</Text>
										<Text style={{
											color: Theme.Colors.greyishBrown,
											textAlign: 'center',
											fontSize: 12,
										}}>{_.get(value, 'returns["1"]')}</Text>
										<Text style={{
											color: Theme.Colors.greyishBrown,
											textAlign: 'center',
											fontSize: 12,
											width: 40
										}}>{_.get(value, 'returns["1"]')}</Text> */}

										</div>
									)
								})
							}
						</div>
					</div>

					<div style={STYLES.fundDetailContainer}>
						<div style={STYLES.fundHeadline}>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>About</Text>
						</div>
						<div style={STYLES.fundDetailBody}>
							{
								_.map([{
									title: "Address", body: mf_company.address
								}, {
									title: "Phone", body: mf_company.phone
								}, {
									title: "Launch date", body: mf_company.launch_date
								}, {
									title: "E-mail", body: mf_company.email
								}, {
									title: "Website", body: mf_company.website
								}], (value, index) => {
									return (
										<div
											key={`item${index}`}
											style={{ width: '50%', marginTop: 25, ...index === 0 && { width: '100%' } }}>
											<Text>{value.title}</Text>
											<Text bold={index === 0 ? false : true} style={{ marginTop: 5 }}>
												{value.body}
											</Text>
										</div>
									)
								})
							}
						</div>
					</div>

				</div>
				{
					addButtonOpen &&
					<div onPress={() => this.toggleModal()}>
						<div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: '#00000052' }}>
						</div>
					</div>
				}

				<div style={{ position: 'absolute', bottom: 25, right: 25, alignItems: 'flex-end' }}>
					{
						addButtonOpen &&
						<div>
							<div
								onPress={() => {
									this.toggleModal()
									if (userInfo.mutual_fund_status !== constants.mutual_fund_status.COMPLETED) {
										alert("Please create an investor first from homepage")
										return
									}
									this.props.navigation.navigate("MF_Buy_Sell", {
										mf_details: {
											name: name,
											isin: mf_details.isin,
											nav: _.get(mf_details, "navResult.dataset.data[0][1]")
										},
										type: "BUY"
									})
								}}
								style={STYLES.investButton}>
								<Text bold style={{ color: Theme.Colors.primaryPurple, fontSize: 16, textAlign: 'center' }}>INVEST</Text>
							</div>

							<div
								onPress={() => {
									this.toggleModal()
									if (userInfo.mutual_fund_status !== constants.mutual_fund_status.COMPLETED) {
										alert("Please create an investor first from homepage")
										return
									}
									this.props.navigation.navigate("MF_Sip", {
										mf_details: {
											name: name,
											isin: mf_details.isin,
											nav: _.get(mf_details, "navResult.dataset.data[0][1]")
										}
									})
								}}
								style={STYLES.investButton}>
								<Text bold style={{ color: Theme.Colors.primaryPurple, fontSize: 16, textAlign: 'center' }}>SET UP SIP</Text>
							</div>
							<div
								onPress={() => {
									this.toggleModal()
									if (userInfo.mutual_fund_status !== constants.mutual_fund_status.COMPLETED) {
										alert("Please create an investor first from homepage")
										return
									}
									this.props.navigation.navigate("MF_Buy_Sell", {
										mf_details: {
											name: name,
											isin: mf_details.isin,
											nav: _.get(mf_details, "navResult.dataset.data[0][1]")
										},
										type: "SELL"
									})
								}}
								style={STYLES.investButton}>
								<Text bold style={{ color: Theme.Colors.primaryPurple, fontSize: 16, textAlign: 'center' }}>SELL</Text>
							</div>
						</div>
					}
					<div
						onPress={() => this.toggleModal()}
						borderless={true}
						style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: Theme.Colors.primaryPurple, justifyContent: 'center', alignItems: 'center' }}>
						{/* <Icon type="MaterialIcons" name="add" style={{ fontSize: 28, color: Theme.Colors.white }} /> */}
					</div>
				</div>
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo", "loader"]),
})

const mapDispatchToProps = dispatch => ({
	get_mf_details: (id, update_initial_data) => dispatch(get_mf_details(id, update_initial_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MF_Detailed_Page)

const STYLES = {
	container: {
		flex: 1,
		backgroundColor: Theme.Colors.whiteTwo,
		height: '100%'
	},
	headerContainer: {
		flexDirection: 'row', justifyContent: 'center', paddingTop: 22
	},
	contentContainer: {
		width: '90%', marginLeft: '5%', marginTop: 20
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	searchContainer: {
		height: 40, backgroundColor: Theme.Colors.whiteTwo, marginTop: 10, borderRadius: 5, flexDirection: 'row', paddingLeft: 10
	},
	logoContainer: {
		borderRadius: 25, height: 50, width: 50, borderWidth: 1, borderColor: Theme.Colors.lightGrey
	},
	dot: {
		fontSize: 40, marginTop: -20, marginLeft: 5, color: Theme.Colors.warmGrey
	},
	fundDetailContainer: {
		backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.whiteTwo, borderTopWidth: 1, borderBottomWidth: 1, marginTop: 20
	},
	fundDetailBody: {
		flexDirection: "row", justifyContent: "space-between", flexWrap: 'wrap', paddingHorizontal: '5%', paddingBottom: 25
	},
	fundHeadline: {
		paddingHorizontal: '5%', paddingVertical: 15, borderColor: Theme.Colors.whiteTwo, borderBottomWidth: 1
	},
	returnHeadline: {
		paddingHorizontal: '5%', paddingVertical: 15,
	},
	investButton: {
		backgroundColor: Theme.Colors.white, borderRadius: 5, paddingHorizontal: 22, paddingVertical: 12, marginBottom: 20
	}
}

let mf_company = {
	"id": 3,
	"name": "Aditya Birla Sun Life Mutual Fund",
	"launch_date": "23 December 1994",
	"sponsors": "Aditya Birla Capital Ltd. *\nSun Life AMC Investments Inc.*",
	"trustee": "Aditya Birla Sun Life Trustee Private Limited",
	"name_of_trustees": null,
	"md_and_ceo": "A. Balasubramanian",
	"cio": null,
	"investor_service_officer": "Ms. Keerti Gupta",
	"compliance_officer": "Ms. Hemanti Wadhwa",
	"address": "One India Bulls Centre ,Tower 1, 17th Flr, Jupiter Mill Compound,841,S.B. Marg,Elphinstone Road Mumbai 400 013",
	"email": "connect@birlasunlife.com",
	"phone": "022 43568000",
	"website": "https://www.birlasunlife.com",
	"custodian_name": "Citibank NA",
	"custodian_register_and_transfer_agent": "Cams",
	"custodian_address": "7th Floor, Tower II, Rayala Towers, 158, Anna Salai,",
	"custodian_email": "enq_h@camsonline.com",
	"custodian_website": "www.camsonline.com",
	"funds in_amc": null,
	"logo": null
}