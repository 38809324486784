import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withStyles, Button, Card, TextField } from '@material-ui/core';
import loginBackground from 'images/login-background.png'
import { login } from 'actions/actions';

class Step0Password extends Component {

	state = {
		help: false,
		password: ""
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { userInfo, classes } = this.props
		const { password } = this.state
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Please enter your password</h1>
					<div>
						<TextField
							name="password"
							variant="outlined"
							type="password"
							fullWidth
							placeholder="Enter password"
							label="Password"
							style={{ marginTop: 40 }}
							value={password}
							onChange={e => this.setState({ [e.target.name]: e.target.value })}
						/>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => this.props.history.push('/user')}
							style={{ fontWeight: 700, width: 170 }}>
							Back
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								if (!_.isEmpty(this.state.password)) {
									this.props.login({
										username: userInfo.basic_information.email,
										password: this.state.password
									}, null, this.props.changeStep)
								}
							}}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
						</Button>
					</div>
				</Card>
			</div>

		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo']),
})

const mapDispatchToProps = dispatch => ({
	login: (formData, navigation, changeStep) => dispatch(login(formData, navigation, changeStep)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step0Password))