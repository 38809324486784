import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { checkAddress, autoCompleteApartmentChange } from '../actions';
import utils from 'resources/utils'
import { withStyles, TextField, Button, Card, ListItem, List, Divider } from '@material-ui/core'
import loginBackground from 'images/login-background.png'

class Step3AddressAutoComplete extends Component {

	state = {
		formData: {
			search: "",
			apartment: ""
		},
		places: [],
		visible: false,
		actionCondition: false,
		help: false
	}

	toggleModal = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		this.state.formData[name] = value

		if (value == "") {
			this.state.places = []
			this.setState(this.state)
		} else {
			this.setState(this.state)
			if (name === 'search') {
				utils.request({
					'url': "/auth/address-autocomplete",
					params: {
						search: value
					}
				}).then(res => {
					this.state.places = res.data.suggestions
					this.setState(this.state)
				})
			}
		}
	}

	handleSubmit = (address) => {
		if (!address) {
			this.props.changeStep(this.props.changeAddress ? 2 : 3.1)
			return
		}
		this.props.checkAddress({
			address1: address.street_line,
			address2: address.secondary || "",
			city: address.city,
			zip: address.zipcode,
			state: address.state
		}, this.props.changeStep, this.props.changeAddress ? "change-autocomplete" : "autocomplete")
	}

	checkAddressWithApartment = () => {
		const { formData } = this.state
		if (_.isEmpty(formData.apartment)) {
			return
		}
		this.props.checkAddress({
			...this.props.autoCompleteApartment,
			address2: formData.apartment
		}, this.props.changeStep, this.props.changeAddress ? "change-autocomplete" : "autocomplete")
	}


	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { formData } = this.state
		const { autoCompleteApartment, changeAddress, classes } = this.props
		let places = _.take(this.state.places || [], 4)

		return (
			<div className={classes.container} style={{ ...changeAddress && { background: "none", minHeight: 'auto', marginTop: 50 } }}>
				<Card className={classes.card}>
					<h1>US mailing address</h1>
					<div>
						{
							autoCompleteApartment ?
								<>
									<div style={{ marginTop: 30, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)", padding: 20 }}>
										<Text>Address</Text>
										<Text bold="true" style={{ padding: `10px 0px`, fontSize: 16 }}>
											{autoCompleteApartment.address1},
											{autoCompleteApartment.address2 && " " + autoCompleteApartment.address2 + ","}
											{" " + autoCompleteApartment.city},
											{" " + autoCompleteApartment.state},
											{" " + autoCompleteApartment.zip}
										</Text>
									</div>
									<Button variant="contained" color="primary" onClick={() => this.props.autoCompleteApartmentChange(false)} style={{ width: 100, marginTop: 20 }}>
										Change
                                	</Button>
									<TextField
										style={{ marginTop: 40 }}
										value={formData.apartment}
										variant="outlined"
										onChange={this.handleChange}
										name="apartment"
										fullWidth
										label="Apartment/House number"
										placeholder="Enter apartment/house number" />
									<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
										<Button
											color="primary"
											variant="outlined"
											onClick={() => this.props.changeStep(2.9)}
											style={{ fontWeight: 700, width: 170 }}>
											Back
                        				</Button>
										<Button
											color={_.isEmpty(formData.apartment) ? 'default' : 'primary'}
											variant="contained"
											onClick={this.checkAddressWithApartment}
											style={{ fontWeight: 700, width: 170 }}>
											Continue
                        				</Button>
									</div>

								</>
								:
								<>
									<div style={{ marginTop: 30, borderBottomWidth: 1, borderColor: Theme.Colors.lightGrey }}>
										<TextField
											value={formData.search}
											onChange={this.handleChange}
											name="search"
											label="Address"
											variant="outlined"
											fullWidth
											placeholder="Type your address" />
									</div>
									<div style={{ height: 330 }}>
										{
											!_.isEmpty(this.state.formData.search) &&
											<div style={{
												marginTop: 5,
												boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
												borderRadius: 5
											}}>
												{
													_.map(places, (value, index) => {
														return (
															<React.Fragment key={`place${index}`}>
																<ListItem
																	button
																	onClick={() => this.handleSubmit(value)}
																	style={{ padding: 20 }}>
																	<div style={{ display: 'flex' }}>
																		<i className="material-icons" style={{ color: Theme.Colors.warmGrey }} >
																			place
																		</i>
																		<Text style={{
																			marginTop: 3, marginLeft: 5
																		}}>{value.street_line}, {value.city}, {value.state}</Text>
																	</div>
																</ListItem>
																<Divider />
															</React.Fragment>
														)
													})
												}
												<ListItem button onClick={() => this.handleSubmit()} style={{ padding: `20px` }}>
													<div style={{ display: 'flex' }}>
														<i className="material-icons" style={{ color: Theme.Colors.warmGrey }} >place</i>
														<Text style={{
															marginTop: 3, marginLeft: 5
														}}>I can't find my address</Text>
													</div>
												</ListItem>
											</div>
										}
									</div>
									{
										!changeAddress &&
										<div style={{ display: 'flex', justifyContent: 'center', width: 400, margin: '0 auto', marginTop: 50 }}>
											<Button
												color="primary"
												variant="outlined"
												onClick={() => this.props.changeStep(2.9)}
												style={{ fontWeight: 700, width: 170 }}>
												Back
                        					</Button>
										</div>
									}
								</>
						}
					</div>
				</Card>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ['cip_tag', 'autoCompleteApartment'])
})

const mapDispatchToProps = dispatch => ({
	checkAddress: (formData, changeStep, type) => dispatch(checkAddress(formData, changeStep, type)),
	autoCompleteApartmentChange: (autocompleteApartment) => dispatch(autoCompleteApartmentChange(autocompleteApartment))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 700,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step3AddressAutoComplete))