import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import Login from 'screens/Login'
import SideBar from 'screens/SideBar'
import _ from 'lodash'
import utils from 'resources/utils';
import Theme from 'resources/Theme';
import AlertDialog from 'components/AlertDialog';
import Loader from 'components/Loader';
import PublicRoute from 'components/PublicRoute';
import SignUp from 'screens/Auth/Profile/SignUp';
import PrivateRoute from 'components/PrivateRoute';
import SignUpBank from 'screens/Auth/BankAccount/SignUpBank';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { getUserDetails } from 'actions/actions';
import Step5ThankYou from 'screens/Auth/BankAccount/Step5ThankYou';
import VerifyPhone from 'screens/Auth/VerifyPhone';
import QaEnvHeader from 'components/QaEnvHeader';

import 'App.css';

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: Theme.Colors.uglyPurple,
			contrastText: '#ffffff'
		},
		secondary: {
			main: Theme.Colors.powderPink,
			contrastText: '#ffffff'
		},
	},
	typography: {
		fontFamily: [
			'Muli', 'sans-serif', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			'sans-serif'
		].join(',')
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'initial',
				fontSize: 16,
				fontWeight: 400,
				letterSpacing: 1
			}
		}
	}
});

class PrimaryLayout extends Component {

	componentWillMount() {
		if (utils.get_user_id()) {
			this.props.getUserDetails()
		}
	}

	render() {
		const { loader } = this.props

		return (
			<MuiThemeProvider theme={theme}>
				<QaEnvHeader />
				<div className="page-container" style={{ ...utils.ENVIRONMENT === "Staging" && { top: 30 }}}>
					<Switch>
						<PublicRoute path="/" exact component={Login} />
						<Route path="/sign-up" component={SignUp} />
						<PrivateRoute path="/user/create-bank-account" exact component={SignUpBank} />
						<PrivateRoute path="/user/verify-phone" exact component={VerifyPhone} />
						<PrivateRoute path="/thank-you" exact component={Step5ThankYou} />
						<PrivateRoute path="/user" component={SideBar} />

						<Redirect to="/user/my-profile" />{/* //@notfound */}
					</Switch>
				</div>
				<AlertDialog />
				{
					loader &&
					<Loader />
				}
			</MuiThemeProvider>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['loader'])
})
const mapDispatchToProps = dispatch => ({
	getUserDetails: () => dispatch(getUserDetails())
})

const PrimaryComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout));

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		return this.props.children
	}
}
const ScrollToTopComponent = withRouter(ScrollToTop);

class App extends Component {

	render() {
		return (
			<BrowserRouter>
				<ScrollToTopComponent>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<PrimaryComponent {...this.props} />
					</MuiPickersUtilsProvider>
				</ScrollToTopComponent>
			</BrowserRouter>
		);
	}
}
export default App;
