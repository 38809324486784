import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

export default class Step3 extends Component {

	render() {
		const { formData, handleBack, changeStep, handleChange, changeState } = this.props.parentProps

		const actionCondition = (
			!_.isEmpty(formData.correspondence_address.line1) &&
			!_.isEmpty(formData.correspondence_address.pincode)
		)

		return (
			<div>
				<Text bold style={STYLES.headline}>Your address</Text>

				<ValidatorForm onSubmit={() => { }}>

					<TextValidator
						onChange={(e) => handleChange('correspondence_address.line1', e.target.value)}
						value={formData.correspondence_address.line1}
						style={{ marginTop: 40 }}
						label="Mailing Address"
						placeholder="Enter address"
						variant="outlined"
						fullWidth
					/>

					<TextValidator
						onChange={(e) => handleChange('correspondence_address.pincode', e.target.value)}
						value={formData.correspondence_address.pincode}
						style={{ marginTop: 40 }}
						label="Pincode"
						placeholder="Enter pincode"
						variant="outlined"
						fullWidth
					/>

					{
						_.map([{
							title: "No other tax residences?", name: "fatca_detail.no_other_tax_residences"
						}, {
							title: "Permanent Address same as Mailing address?", name: "perm_addr_is_corres_addr"
						}], (value, index) => {
							let data = _.get(formData, value.name)

							return (
								<div style={{ display: "flex", marginTop: 30, alignItems: 'center' }} key={`item${index}`}>
									<div onClick={() => handleChange(value.name, !data)} style={{ cursor: "pointer" }}>
										{
											data ?
												<img src={require("images/Auth/group5.png")} style={{ height: 26, width: 26, }} />
												:
												<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 26, width: 26, borderRadius: 5 }}>
												</div>
										}
									</div>

									<Text style={{ marginLeft: 20 }}>{value.title}</Text>
								</div>
							)
						})
					}

					{
						!formData.perm_addr_is_corres_addr &&
						<div>
							<TextValidator
								onChange={(e) => handleChange('permanent_address.line1', e.target.value)}
								value={formData.permanent_address.line1}
								style={{ marginTop: 40 }}
								label="Permanent Address"
								placeholder="Enter address"
								variant="outlined"
								fullWidth
							/>

							<TextValidator
								onChange={(e) => handleChange('permanent_address.pincode', e.target.value)}
								value={formData.permanent_address.pincode}
								style={{ marginTop: 40 }}
								label="Pincode"
								placeholder="Enter pincode"
								variant="outlined"
								fullWidth
							/>
						</div>
					}

					<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							color={!actionCondition && "default"}
							style={{ width: 200 }}
							onClick={actionCondition ? () => changeStep(4) : () => { }}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	},
	box: {
		paddingHorizontal: 25, paddingVertical: 10, borderRadius: 20, borderWidth: 0.3, borderColor: Theme.Colors.warmGrey,
	},
}