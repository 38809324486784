import { LINKED_ACCOUNTS_RECEIVED, BANK_ACCOUNT_ADDED } from "./actions";
import _ from 'lodash'
import utils from "resources/utils";
import { LOGOUT_USER } from "actions/actions";
import { BANK_ACCOUNT_REMOVED } from "../AddFunds/actions";

const initialState = {
	linkedAccounts: []
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case LINKED_ACCOUNTS_RECEIVED: {
			let linkedAccounts = _.map(action.linkedAccounts, (value) => { return { ...value, country: 'us' } })
			return { ...state, linkedAccounts: linkedAccounts }
		}
		case BANK_ACCOUNT_ADDED: {
			return { ...state, linkedAccounts: [...state.linkedAccounts, action.account] }
		}
		case BANK_ACCOUNT_REMOVED: {
			let linkedAccounts = utils.updateItemList(state.linkedAccounts, { node_id: action.node_id }, "DELETE")
			return { ...state, linkedAccounts: linkedAccounts }
		}
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}