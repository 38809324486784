import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { getExpenseDetails } from './actions';
import moment from 'moment'
import { ListItem, Button, Card, Menu, MenuItem } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import Header from 'components/Header';

const sliceColor = ['#fc7788', '#6286ed', '#f48c37', '#8ec144', '#ce63a8', '#e8c809', '#39b2c6', '#79acf7']

const Categories = [{
	name: "Entertainment", category: "service", categoryAlias: "service", image: require('images/Expense/entertainment.png'), color: '#fc7788'
}, {
	name: "Transportation", category: "travel/transportation", categoryAlias: "transportation", image: require('images/Expense/travel.png'), color: '#6287ed'
}, {
	name: "Food and Dining", category: "dining", categoryAlias: "dining", image: require('images/Expense/dining.png'), color: '#f48c37'
}, {
	name: "Transfers", category: "transfer", categoryAlias: "transfer", image: require('images/Transaction/Transfer.png'), color: '#8fc144'
}, {
	name: "General Spending", category: "pos", categoryAlias: "pos", image: require('images/Transaction/POS.png'), color: '#ce63c7'
}, {
	name: "Groceries", category: "grocery", categoryAlias: "grocery", image: require('images/Expense/groceries.png'), color: '#eaca0a'
}, {
	name: "Withdrawal", category: "withdrawal", categoryAlias: "withdrawal", image: require('images/Expense/withdraw.png'), color: '#39b1c6'
}, {
	name: "Others", category: "", categoryAlias: "", image: require('images/Expense/others.png'), color: '#79abf7'
}]

function dateRange(startDate, endDate) {
	var start = startDate.split('-');
	var end = endDate.split('-');
	var startYear = parseInt(start[0]);
	var endYear = parseInt(end[0]);
	var dates = [];

	for (var i = startYear; i <= endYear; i++) {
		var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
		var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
		for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			var month = j + 1;
			var displayMonth = month < 10 ? '0' + month : month;
			dates.push([i, displayMonth, '01'].join('-'));
		}
	}
	return dates;
}

class Expense extends React.PureComponent {

	state = {
		isModalOpen: null,
		yOffset: 0,
		date: moment.utc().format("YYYY-MM-DD")
	}

	toggleModal = e => {
		this.setState({
			isModalOpen: e === null ? e : e.currentTarget
		})
	}

	handleExpenseDetails = (date) => {
		this.state.date = date
		this.state.isModalOpen = null
		let month = moment.utc(date).format("MM")
		let year = moment.utc(date).format("YYYY")

		this.setState(this.state, () => {
			this.props.getExpenseDetails(month, year)
		})
	}

	componentDidMount() {
		let date = this.state.date
		let month = moment.utc(date).format("MM")
		let year = moment.utc(date).format("YYYY")
		this.props.getExpenseDetails(month, year)

	}

	handleScroll = e => {
		this.setState({ yOffset: e.nativeEvent.contentOffset.y })
	}

	render() {
		const { isModalOpen } = this.state
		const { accountInfo, userInfo, totalSpend, spendByCategories, linkedAccountsDebit, expenseComparison } = this.props

		let created_at = moment.utc(Number(userInfo.created_at)).format("YYYY-MM-DD")
		let now = moment.utc().format("YYYY-MM-DD")

		let months = _.reverse(dateRange(created_at, now))


		let totalBalance = 0
		totalBalance += Number(_.get(accountInfo, 'info.balance.amount') || 0)
		_.map(linkedAccountsDebit || [], (value, index) => {
			totalBalance += Number(_.get(value, 'balance.amount') || 0)
		})
		totalBalance = totalBalance.toFixed(2)

		let totalAmount = 0

		_.map(Categories, (value, index) => {
			totalAmount += Number((
				(_.find(spendByCategories, { to_merchant_category: value.category }) || {}).sum_amount || 0
			).toFixed(2))
		})

		let compare = Math.round((_.get(expenseComparison, 'current') || 0) - (_.get(expenseComparison, 'previous') || 0))
		let compareText
		if (compare >= 0) {
			compareText = "more"
		} else {
			compareText = "less"
			compare *= -1
		}

		return (
			<div>
				<Header name="My Expenses" />
				<div>
					<div style={STYLES.totalSpendContainer} className="box-shadow">
						<div style={{ display: 'flex', justifyContent: 'space-between', padding: `20px 20%` }}>
							<div>
								<Text style={STYLES.headerLabel}>Current Balance</Text>
								<Text bold="true" style={STYLES.headerAmount}>${totalBalance}</Text>
							</div>
							<div style={STYLES.divider}></div>
							<div>
								<Text style={STYLES.headerLabel}>Spent this month</Text>
								<Text bold="true" style={{ ...STYLES.headerAmount, color: Theme.Colors.primaryPurple }}>${totalSpend || 0}</Text>
							</div>
						</div>
						<div style={{ backgroundColor: "#fffafe", borderRadius: 10, display: 'flex', justifyContent: 'center', padding: `10px 0px` }}>
							<Text style={{ color: Theme.Colors.primaryPurple }}>You have spent <span style={{ color: Theme.Colors.primaryPurple, fontWeight: 'bold' }}>${compare}</span> {compareText} than last month </Text>
						</div>
					</div>
					{
						_.map([{
							name: "Subscriptions", screen: "subscriptions", icon: require("images/Expense/subscription.png")
						}, {
							name: "Analyze spends", screen: "analyze-spends", icon: require("images/Expense/analyze-spends.png")
						}], (value, index) => {
							return (
								<div style={STYLES.menuItemContainer} className="box-shadow" key={`menuItems${index}`}>
									<ListItem
										onClick={() => this.props.history.push(value.screen)}
										button style={{ borderRadius: 5 }}>
										<div style={{ display: 'flex', alignItems: 'center', padding: 15, borderRadius: 5 }}>
											<img src={value.icon} style={{ height: 45, width: 45 }} />
											<Text style={{ marginLeft: 15, fontSize: 16 }}>{value.name}</Text>
											<i className="material-icons" style={{ fontSize: 60, color: Theme.Colors.lightGrey2, position: 'absolute', right: 10 }}>chevron_right</i>
										</div>
									</ListItem>
								</div>
							)
						})
					}


					<h1 style={{ color: Theme.Colors.warmGrey, marginTop: 40 }}>Categories</h1>

					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, marginBottom: 30, }}>
						<Card style={{ width: '44%', marginTop: 20, height: 350, padding: 25 }}>
							<Button onClick={this.toggleModal}>
								{moment.utc(this.state.date).format("MMM YYYY")} <i className="material-icons" style={{ color: Theme.Colors.warmGrey }}>expand_more</i>
							</Button>
							<Menu
								style={{ maxHeight: 300 }}
								id="simple-menu"
								anchorEl={isModalOpen}
								keepMounted
								open={Boolean(isModalOpen)}
								onClose={() => this.toggleModal(null)}>
								{
									_.map(months, (value, index) => {
										return (
											<MenuItem onClick={() => this.handleExpenseDetails(value)} key={`month${index}`}>
												<Text style={{ color: Theme.Colors.greyishBrown }}>
													{moment.utc(value).format("MMM YYYY")}
												</Text>
											</MenuItem>
										)
									})
								}
							</Menu>
							<Doughnut
								data={{
									datasets: [{
										data:
											!_.isEmpty(spendByCategories) ?
												_.map(
													["service", "travel/transportation", "dining", "transfer", "pos", "grocery", "withdrawal", ""], (value) => {
														return (_.find(spendByCategories, { to_merchant_category: value }) || {}).sum_amount || 0
													}) : [100],
										backgroundColor: !_.isEmpty(spendByCategories) ? sliceColor : [Theme.Colors.lightGrey]
									}]
								}}
								options={{ tooltips: { enabled: false } }} />
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Text style={{ fontSize: 16, marginTop: 20 }}>Total</Text>
								<Text bold="true" style={{ fontSize: 20, marginTop: 5 }}>${totalSpend || 0}</Text>
							</div>
						</Card>
						{
							!_.isEmpty(spendByCategories) &&
							<div style={{ width: '45%' }}>
								{
									_.map(Categories, (value, index) => {
										let amount = (
											(_.find(spendByCategories, { to_merchant_category: value.category }) || {}).sum_amount || 0
										).toFixed(2)
										if (amount == 0) {
											return (
												<React.Fragment key={`transaction_${index}`}>
												</React.Fragment>
											)
										}
										let percentage = (amount / totalAmount * 100).toFixed(0)
										return (
											<div style={STYLES.contentRow} key={`transaction_${index}`} className="box-shadow">
												<ListItem button onClick={
													() => this.props.history.push(
														`/user/expense-details/${value.categoryAlias}/${moment.utc(this.state.date).format("MM")}/${moment.utc(this.state.date).format("YYYY")}`
													)} style={{ padding: 20, borderRadius: 10, display: 'flex' }}>
													<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
														<img src={value.image} style={{ height: 44, width: 44 }} />
													</div>
													<div style={{ flex: 7, marginLeft: 5 }}>
														<div style={{ display: 'flex', justifyContent: 'space-between' }}>
															<Text style={STYLES.rowTitle}>{value.name}</Text>
															<Text bold="true" style={STYLES.rowAmount}>
																${amount}
															</Text>
														</div>
														<div style={{ display: 'flex', justifyContent: 'space-between' }}>
															<div style={{ height: 7, marginTop: 8, borderRadius: 10, backgroundColor: Theme.Colors.lightGrey, width: '87%' }}>
																<div style={{ backgroundColor: value.color, width: `${percentage}%`, height: '100%', borderRadius: 10, }}></div>
															</div>
															<Text style={{ fontSize: 12, marginTop: 3, color: Theme.Colors.greyishBrown }}>{percentage}%</Text>
														</div>
													</div>
												</ListItem>
											</div>
										)
									})
								}
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['transactions', 'accountInfo', 'userInfo']),
	..._.pick(state.Expense, ['spendByCategories', 'totalSpend', 'expenseComparison']),
	..._.pick(state.AddFunds, ['linkedAccountsDebit'])
})

const mapDispatchToProps = dispatch => ({
	getExpenseDetails: (month, year) => dispatch(getExpenseDetails(month, year))
})

export default connect(mapStateToProps, mapDispatchToProps)(Expense)


const STYLES = {
	headerAmount: {
		fontSize: 24, textAlign: 'center', marginTop: 5
	},
	divider: {
		height: 65, border: `0px solid ${Theme.Colors.lightGrey}`, borderRightWidth: 2,
	},
	headerLabel: {
		color: Theme.Colors.warmGreyTwo, fontSize: 14, marginTop: 5, textAlign: 'center'
	},
	contentRow: {
		marginTop: 20, borderRadius: 10, backgroundColor: Theme.Colors.white
	},
	rowTitle: {
		fontSize: 16, color: Theme.Colors.black
	},
	rowAmount: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	totalSpendContainer: {
		borderRadius: 10, marginTop: 20, backgroundColor: Theme.Colors.white
	},
	menuItemContainer: {
		backgroundColor: Theme.Colors.white, borderRadius: 5, marginTop: 15
	}
}