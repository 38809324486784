import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { createAccount } from '../actions';
import loginBackground from 'images/login-background.png'
//import AuthHelp from 'components/AuthHelp';
import {
	withStyles,
	Card,
	Button,
	Checkbox
} from '@material-ui/core'
import { Link } from 'react-router-dom';
import { toggleError } from 'actions/actions';
class Step1CreateAccount extends Component {

	state = {
		showPassword: false,
		showConfirmPassword: false,
		emailCondition: false,
		passwordCondition1: false,
		passwordCondition2: false,
		passwordCondition3: false,
		passwordCondition4: false,
		formData: {
			username: "",
			password: "",
			confirmPassword: ""
		},
		check: false,
		help: false
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		this.state.formData[name] = value

		if (name === 'username') {
			let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			this.state.emailCondition = format.test(_.trim(value))
			this.state.formData[name] = _.trim(value)
		}

		if (name === 'password') {
			if (value.length >= 8) {
				this.state.passwordCondition1 = true
			} else {
				this.state.passwordCondition1 = false
			}

			let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			this.state.passwordCondition2 = format.test(value)

			format = /\d/;
			this.state.passwordCondition3 = format.test(value)

			format = /[A-Z]/;
			this.state.passwordCondition4 = format.test(value)
		}

		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData, passwordCondition1, passwordCondition2, passwordCondition3, passwordCondition4, emailCondition } = this.state
		if (
			formData.password === formData.confirmPassword &&
			passwordCondition1 && passwordCondition2 &&
			passwordCondition3 && passwordCondition4 &&
			emailCondition && this.state.check
		) {
			this.props.createAccount(formData, this.props.changeStep)
		} else {
			if (_.isEmpty(formData.username)) {
				this.props.toggleError('Please enter email');
				return
			}
			if (formData.password !== formData.confirmPassword) {
				this.props.toggleError('Passwords do not match');
				return
			}
		}
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		let { formData, showPassword, showConfirmPassword, passwordCondition1, passwordCondition2, passwordCondition3, passwordCondition4, emailCondition } = this.state
		const { classes } = this.props


		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Let's get started</h1>
					<ValidatorForm onSubmit={this.handleSubmit}>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40, }}>
							<TextValidator
								type="email"
								value={formData.username}
								onChange={this.handleChange}
								name="username"
								label="Email"
								placeholder="Enter email"
								variant="outlined"
								style={{ width: '45%' }} />
							<TextValidator
								type="password"
								value={formData.password}
								onChange={this.handleChange}
								name="password"
								label="Password"
								placeholder="Enter password"
								variant="outlined"
								style={{ width: '45%' }} />
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40, }}>
							<TextValidator
								type="password"
								value={formData.confirmPassword}
								onChange={this.handleChange}
								name="confirmPassword"
								label="Confirm password"
								placeholder="Re-enter your password"
								variant="outlined"
								style={{ width: '45%' }} />

							{
								!(passwordCondition1 && passwordCondition2 && passwordCondition3 && passwordCondition4) &&
								<div style={{ width: '45%', }}>
									<ul style={{ marginLeft: 20, lineHeight: 2 }}>
										<li style={{ color: passwordCondition1 ? Theme.Colors.darkishGreen : Theme.Colors.primaryPurple }}>Minumum 8 characters</li>
										<li style={{ color: passwordCondition2 ? Theme.Colors.darkishGreen : Theme.Colors.primaryPurple }}>One special character !,@,$,%,&</li>
										<li style={{ color: passwordCondition3 ? Theme.Colors.darkishGreen : Theme.Colors.primaryPurple }}>One number 0,1,2,3…</li>
										<li style={{ color: passwordCondition4 ? Theme.Colors.darkishGreen : Theme.Colors.primaryPurple }}>One uppercase letter</li>
									</ul>
								</div>
							}
						</div>
						<div style={{ display: 'flex', marginTop: 20, marginLeft: -10 }}>
							<Checkbox color="primary" onChange={(e) => this.setState({ check: e.target.checked })} />
							<p style={{ marginTop: 12 }}>I have read and agree to the <a href="https://www.nolobank.com/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://www.nolobank.com/privacy-policy" target="_blank">Privacy Policy</a></p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
							<Button
								color={!(formData.password === formData.confirmPassword &&
									passwordCondition1 && passwordCondition2 && passwordCondition3 && passwordCondition4 && emailCondition && this.state.check)
									? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>

					</ValidatorForm>
					<div style={{ marginTop: 40 }}>
						Already have an account? <Link to="/" style={{ color: Theme.Colors.primaryPurple, fontWeight: 700, fontSize: 16 }}>Sign In</Link>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	createAccount: (formData, changeStep) => dispatch(createAccount(formData, changeStep)),
	toggleError: (err) => dispatch(toggleError(err))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: '60%',
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step1CreateAccount))