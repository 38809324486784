import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Card, Button } from '@material-ui/core';
import loginBackground from 'images/login-background.png'

class Step5ThankYou extends Component {

	render() {

		return (
			<div style={STYLES.container}>
				<Card style={STYLES.card}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
						<img src={require('images/Auth/thankyou.png')} style={{ height: 220, width: 280 }} />
						<h1 style={{ marginTop: 20 }}>Thank you for signing up</h1>
						<div style={{ width: 330, marginTop: 40 }}>
							{
								_.map([
									"Your account will be created",
									this.props.cip_tag == '3' ? "Once you deposit $20, we'll ship you a card!" : "Your debit card will be shipped",
									"If you haven't reached the US yet, your virtual card will be blocked till you arrive."
								], (value, index) => {
									return (
										<div key={`item${index}`} style={{ display: 'flex', ...index !== 0 && { marginTop: 20 } }}>
											<img src={require('images/Auth/hand.png')} style={{ width: 30, height: 18, marginRight: 10, marginTop: 4 }} />
											<Text key={`item${index}`} style={{ letterSpacing: 0.63, lineHeight: 1.8, }}>{value}</Text>
										</div>
									)
								})
							}
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color={'primary'}
							variant="contained"
							onClick={() => this.props.history.push("/user")}
							style={{ fontWeight: 700, width: 170 }}>
							DONE
						</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ['cip_tag'])
})

const mapDispatchToProps = dispatch => ({})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Step5ThankYou)