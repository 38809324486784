import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { checkAddress } from '../actions';
import { withStyles, Card, Button, Dialog, DialogTitle, DialogContent, ListItem, TextField } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import loginBackground from 'images/login-background.png'

let stateList = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"]

class Step3Address extends Component {

	state = {
		formData: {
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: ""
		},
		visible: false,
		actionCondition: false,
		stateList: stateList,
		help: false
	}

	toggleModal = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		const { formData } = this.state
		formData[name] = value
		this.state.stateList = stateList
		if (!(_.isEmpty(formData.address1) || _.isEmpty(formData.city) || _.isEmpty(formData.state) || _.isEmpty(formData.zip))) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData } = this.state
		if (!(_.isEmpty(formData.address1) || _.isEmpty(formData.city) || _.isEmpty(formData.state) || _.isEmpty(formData.zip))) {
			this.props.checkAddress(formData, this.props.changeStep, this.props.changeAddress ? "change-signup" : "signup")
		}
	}

	handleSearch = (e) => {
		let text = e.target.value
		let list = _.filter(stateList, (value) => {
			if (_.includes(value.toLowerCase(), text)) {
				return value
			}
		})

		this.setState({
			stateList: list,
			search: text
		})
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { changeAddress, classes } = this.props
		const { formData, actionCondition, stateList, search } = this.state

		return (
			<div className={classes.container} style={{ ...changeAddress && { background: "none", minHeight: 'auto', marginTop: 50 } }}>
				<Card className={classes.card}>
					<h1>US mailing address</h1>
					<ValidatorForm onSubmit={actionCondition ? this.handleSubmit : () => { }}>
						<div style={{}}>
							{
								_.map([{
									label: "Street Address*", placeholder: "For example, 1 Market Street", name: "address1"
								}, {
									label: "House/Apartment/Unit Number", placeholder: "For example Apt 42", name: "address2",
								}, {
									label: "City*", placeholder: "Enter your city", name: "city",
								}, {
									label: "State*", placeholder: "Select your state", name: "state",
								}, {
									label: "Zip Code*", placeholder: "Enter your zip code", name: "zip",
								}], (value, index) => {
									if (index === 3) {
										return (
											<ListItem button style={STYLES.stateInputContainer} key={`input${index}`} onClick={this.toggleModal}>
												{
													!_.isEmpty(formData.state) ?
														<Text style={{ fontSize: 16 }}>{formData.state}</Text>
														:
														<Text style={{ fontSize: 16, color: Theme.Colors.warmGreyTwo, }}>Enter your state*</Text>
												}
											</ListItem>
										)
									}
									return (
										<TextValidator
											key={`input${index}`}
											onChange={this.handleChange}
											value={formData[value.name]}
											name={value.name}
											style={{ marginTop: 34 }}
											label={value.label}
											placeholder={value.placeholder}
											variant="outlined"
											fullWidth />
									)
								})
							}
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
							<Button
								color={'primary'}
								variant="outlined"
								onClick={() => this.props.changeStep(changeAddress ? 1 : 3)}
								style={{ fontWeight: 700, width: 170 }}>
								Back
                        	</Button>
							<Button
								color={!actionCondition ? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>
				</Card>
				<Dialog
					open={this.state.visible}
					onClose={this.toggleModal}>
					<DialogTitle>
						<Text bold="true" style={STYLES.headline}>Select or Search State</Text>
						<TextField
							onChange={this.handleSearch}
							value={search}
							placeholder={"Search"}
							fullWidth
							style={{ fontSize: 14, marginTop: 20, marginLeft: 5, color: Theme.Colors.greyishBrown }} />
					</DialogTitle>
					<DialogContent style={{ height: 400, width: 400 }}>
						{
							_.map(stateList, (value, index) => {
								return (
									<ListItem
										button
										key={`state_${index}`}
										onClick={() => {
											this.handleChange({ target: { name: 'state', value } })
											this.toggleModal()
										}}
										style={{ padding: `15px 10px`, justifyContent: "space-between", border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1 }}>
										<div style={{ marginLeft: 10, fontSize: 18 }}>
											{value}
										</div>
									</ListItem>
								)
							})
						}
					</DialogContent>
				</Dialog>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ['cip_tag'])
})

const mapDispatchToProps = dispatch => ({
	checkAddress: (formData, changeStep, type) => dispatch(checkAddress(formData, changeStep, type))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	stateInputContainer: {
		width: '100%', height: 56, border: `1px solid #c4c4c4`, marginTop: 34, borderRadius: 5
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step3Address))