import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import OutlinedButton from 'components/OutlinedButton';
import { DatePicker } from '@material-ui/pickers';

export default class Step7 extends Component {

	render() {
		const { formData, handleBack, handleChange, handleSubmit } = this.props.parentProps

		let actionCondition = formData.nomination.skip_nomination ||
			(!_.isEmpty(formData.nomination.nominee1.date_of_birth) && !_.isEmpty(formData.nomination.nominee1.name) && !_.isEmpty(formData.nomination.nominee1.relationship))

		return (
			<div>
				<Text bold style={STYLES.headline}>Last question</Text>
				<ValidatorForm onSubmit={() => { }}>
					{
						_.map([{
							title: "Skip Nominee", name: "nomination.skip_nomination"
						}], (value, index) => {
							let data = _.get(formData, value.name)

							return (
								<div style={{ display: "flex", marginTop: 30, alignItems: 'center' }} key={`item${index}`}>
									<div onClick={() => handleChange(value.name, !data)} style={{ cursor: "pointer" }}>
										{
											data ?
												<img src={require("images/Auth/group5.png")} style={{ height: 26, width: 26, }} />
												:
												<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 26, width: 26, borderRadius: 5 }}>
												</div>
										}
									</div>
									<Text style={{ marginLeft: 20 }}>{value.title}</Text>
								</div>
							)
						})
					}

					{
						!formData.nomination.skip_nomination &&
						<>
							<TextValidator
								onChange={(e) => handleChange('nomination.nominee1.name', e.target.value)}
								value={formData.nomination.nominee1.name}
								style={{ marginTop: 40 }}
								label="Name"
								placeholder="Enter name"
								variant="outlined"
								fullWidth
							/>

							<DatePicker
								margin="normal"
								inputVariant="outlined"
								label="Date of Birth"
								format="yyyy-MM-dd"
								value={formData.nomination.nominee1.date_of_birth === "" ? null : formData.nomination.nominee1.date_of_birth}
								onChange={(date) => {
									handleChange('nomination.nominee1.date_of_birth', date)
								}}
								autoOk
								disablePast
								style={{ marginTop: 40 }}
								fullWidth />

							<TextValidator
								onChange={(e) => handleChange('nomination.nominee1.allocation_percentage', e.target.value)}
								value={formData.nomination.nominee1.allocation_percentage > 0 ? String(formData.nomination.nominee1.allocation_percentage) : ""}
								style={{ marginTop: 40 }}
								label="Allocation percentage"
								placeholder="Enter percentage"
								type="number"
								variant="outlined"
								fullWidth
							/>

							<TextValidator
								onChange={(e) => handleChange('nomination.nominee1.relationship', e.target.value)}
								value={formData.nomination.nominee1.relationship}
								style={{ marginTop: 40 }}
								label="Relationship"
								placeholder="Enter relationship"
								variant="outlined"
								fullWidth
							/>
						</>
					}

					<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							style={{ width: 200 }}
							color={!actionCondition && "default"}
							onClick={actionCondition ? handleSubmit : () => { }}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	}
}