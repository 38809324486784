import React, { Component } from 'react'
import Step1CreateAccount from './Step1CreateAccount'
import Step2PersonalDetails from './Step2PersonalDetails'
import Step3Residence from './Step3Residence'
import Step4Cip from './Step4Cip'
import _ from 'lodash'
export default class SignUp extends Component {

	state = {
		step: _.get(this.props, 'location.state.step') || 1
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	render() {
		const { step } = this.state
		return (
			<div>
				{
					step == 1 &&
					<Step1CreateAccount changeStep={this.changeStep} />
				}
				{
					step == 2 &&
					<Step2PersonalDetails changeStep={this.changeStep} />
				}
				{
					step == 3 &&
					<Step3Residence changeStep={this.changeStep} />
				}
				{
					step == 4 &&
					<Step4Cip changeStep={this.changeStep} history={this.props.history} />
				}
			</div>
		)
	}
}
