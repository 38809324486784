import React, { Component } from 'react';
import utils from 'resources/utils'
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import _ from 'lodash'

class PublicRoute extends Component {

	state = {
		isChecking: true,
		isAuthenticated: false
	}
	componentWillMount() {
		if (utils.get_user_id()) {
			this.setState({
				isChecking: false,
				isAuthenticated: true
			})
		} else {
			utils.clearCookies()
			this.setState({
				isChecking: false,
				isAuthenticated: false
			})
		}
	}

	render() {
		const { userInfo } = this.props

		if (utils.get_user_id()) {
			if (_.isObject(userInfo) && _.isEmpty(userInfo)) {
				return <div></div>
			}

			if (!_.isEmpty(userInfo) && userInfo.sign_up_status_2 !== 'firstStageIncomplete') {
				return (
					<Redirect to='/user/my-profile' />
				)
			}
		}

		return (
			<Route path={this.props.path} component={this.props.component} />
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo'])
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute)