import { toggleLoader, toggleError } from "actions/actions"
import utils from "resources/utils"
import _ from 'lodash'
import axios from "axios"

export const LINKED_DEBIT_ACCOUNTS_RECEIVED = 'LINKED_DEBIT_ACCOUNTS_RECEIVED'
export const BANK_LIST_RECEIVED = 'BANK_LIST_RECEIVED'
export const BANK_ACCOUNT_REMOVED = 'BANK_ACCOUNT_REMOVED'
export const LINKED_DEBIT_CARDS_RECEIVED = 'LINKED_DEBIT_CARDS_RECEIVED'
export const CARD_REMOVED = 'CARD_REMOVED'

const requestGetLinkedAccounts = () => (
	utils.request({
		url: `/banking/link-ach`
	})
)

const requestGetLinkedDebitCards = () => (
	utils.request({
		url: `/interchange/cards`
	})
)

const requestBankList = () => (
	axios.request({
		url: `https://api.synapsefi.com/v3.1/institutions`
	})
)

export const requestRemoveAccount = (data) => (
	utils.request({
		url: `/banking/account-aggregate`,
		method: "DELETE",
		data: {
			nodes_to_delete: data
		}
	})
)

const requestRemoveCard = (data) => (
	utils.request({
		url: `/interchange/link`,
		method: "DELETE",
		data: {
			node_id: data
		}
	})
)

const requestAddMoney = (data, ip, debitCard) => (
	utils.request({
		url: debitCard ? "/interchange/transaction?type=pull" : "/banking/ach-debit/transaction",
		method: "POST",
		data: {
			...debitCard ? { node_id: data.from_id } : { from_id: data.from_id },
			value: data.value,
			currency: "USD",
			ip: ip
		}
	})
)

const requestAddDebitCard = (data) => (
	utils.request({
		url: `/interchange/link`,
		method: "POST",
		data: data
	})
)

export const getLinkedBankAccountsAndDebitCards = (screen = "") => dispatch => {
	dispatch(toggleLoader())
	Promise.all([
		dispatch(getLinkedBankAccount()),
		screen === 'homepage' ? Promise.resolve() : dispatch(getLinkedDebitCards())
	]).then(res => {
		dispatch(toggleLoader())
	})
}

export const getLinkedBankAccount = () => dispatch => {
	return requestGetLinkedAccounts().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(linkedBankAccountsReceived(_.get(res, 'data.data') || []))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const getLinkedDebitCards = () => dispatch => {
	return requestGetLinkedDebitCards().then(
		res2 => {
			if (res2.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(linkedDebitCardsReceived(_.get(res2, 'data.data') || []))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const getBankList = () => dispatch => {
	requestBankList().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(bankListReceived(_.get(res, 'data.banks') || []))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const addMoney = (data, navigation, debitCard) => dispatch => {
	dispatch(toggleLoader())

	//@todo add ip
	let ip = ''

	requestAddMoney(data, ip, debitCard).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			navigation.navigate("AddFundsDone")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
	// });
}

export const addDebitCard = (data, navigation) => dispatch => {
	requestAddDebitCard(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			navigation.navigate("CardLinked")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const removeAccount = (node_id) => dispatch => {
	dispatch(toggleLoader())
	requestRemoveAccount([node_id]).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(bankAccountRemoved(node_id))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const removeCard = (node_id) => dispatch => {
	dispatch(toggleLoader())
	requestRemoveCard(node_id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(cardRemoved(node_id))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const cardRemoved = (node_id) => ({
	type: CARD_REMOVED,
	node_id
})

const bankAccountRemoved = (node_id) => ({
	type: BANK_ACCOUNT_REMOVED,
	node_id
})

const linkedBankAccountsReceived = (linkedAccountsDebit) => ({
	type: LINKED_DEBIT_ACCOUNTS_RECEIVED,
	linkedAccountsDebit
})

const linkedDebitCardsReceived = (linkedDebitCards) => ({
	type: LINKED_DEBIT_CARDS_RECEIVED,
	linkedDebitCards
})

const bankListReceived = (bankList) => ({
	type: BANK_LIST_RECEIVED,
	bankList
})