import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import { MenuItem, Select } from '@material-ui/core';
import OutlinedButton from 'components/OutlinedButton';

export default class Step3 extends Component {

	render() {
		const { formData, changeStep, handleBack, handleChange } = this.props.parentProps

		const actionCondition = (formData.employmentStatus === 'EMPLOYED' || formData.employmentStatus === "SELF_EMPLOYED") ? !_.isEmpty(formData.employmentCompany) : true

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>Employment Details</Text>
				<ValidatorForm onSubmit={() => { }}>

					<SelectValidator
						variant="outlined"
						style={{ marginTop: 50 }}
						value={formData.employmentStatus}
						onChange={(e) =>
							handleChange("employmentStatus", e.target.value)
						}
						fullWidth
						label="Employment Status">

						{
							_.map([{
								name: "Employed", value: "EMPLOYED"
							}, {
								name: "Retired", value: "RETIRED"
							}, {
								name: "Student", value: "STUDENT"
							}, {
								name: "Unemployed", value: "UNEMPLOYED"
							}, {
								name: "Self Employed", value: "SELF_EMPLOYED"
							}], (value, index) => {
								return (
									<MenuItem
										key={`employmentStatus_${index}`}
										label={value.name}
										value={value.value}>
										{value.name}
									</MenuItem>
								)
							})
						}

					</SelectValidator>

					{
						(formData.employmentStatus === 'EMPLOYED' || formData.employmentStatus === 'SELF_EMPLOYED') &&
						<>
							<SelectValidator
								variant="outlined"
								style={{ marginTop: 50 }}
								value={formData.employmentPosition}
								onChange={(e) =>
									handleChange("employmentPosition", e.target.value)
								}
								fullWidth
								label="Employment Position">

								{
									_.map(["ACCOUNTANT", "ACTUARY", "ADJUSTER", "ADMINISTRATOR", "ADVERTISER", "AGENT", "ATC", "AMBASSADOR", "ANALYST", "APPRAISER", "ARCHITECT", "ARTIST", "ASSISTANT", "ATHLETE", "ATTENDANT", "ATTORNEY", "AUCTIONEER", "AUDITOR", "BARBER", "BROKER", "BUSINESS_EXEC", "BUSINESS_OWNER", "CAREGIVER", "CARPENTER", "CASHIER", "CHEF", "CHIROPRACTOR", "CIVIL", "CLERGY", "CLERK", "COMPLIANCE", "CONSULTANT", "CONTRACTOR", "COUNSELOR", "CUSTOMER_SERVICE", "DEALER", "DEVELOPER", "DISTRIBUTOR", "DOCTOR", "DRIVER", "ENGINEER", "EXAMINER", "EXTERMINATOR", "FACTORY", "FARMER", "FINANCIAL", "FISHERMAN", "FLIGHT", "HR", "IMPEX", "INSPECTOR", "INTERN", "INVESTMENT", "INVESTOR", "IT", "JANITOR", "JEWELER", "LABORER", "LANDSCAPER", "LENDING", "MANAGER", "MECHANIC", "MILITARY", "MORTICIAN", "NURSE", "NUTRITIONIST", "OFFICE", "PHARMACIST", "PHYSICAL", "PILOT", "POLICE", "POLITICIAN", "PM", "REP", "RESEARCHER", "SAILOR", "SALES", "SCIENTIST", "SEAMSTRESS", "SECURITY", "SOCIAL", "TEACHER", "TECHNICIAN", "TELLER", "TRADESPERSON", "TRAINER", "TRANSPORTER", "UNDERWRITER", "WRITER"], (value, index) => {
										let label = _.capitalize(_.split(value, "_").join(" "))
										return (
											<MenuItem
												key={`employmentPosition_${index}`}
												label={label}
												value={value}>
												{label}
											</MenuItem>
										)
									})
								}

							</SelectValidator>

							<TextValidator
								value={formData.employmentCompany}
								onChange={e => handleChange("employmentCompany", e.target.value)}
								value={formData.employmentCompany}
								label="Company"
								placeholder="Enter company"
								variant="outlined"
								style={{ marginTop: 40 }} fullWidth />

							<SelectValidator
								variant="outlined"
								style={{ marginTop: 50 }}
								value={formData.employmentType}
								onChange={(e) =>
									handleChange("employmentType", e.target.value)
								}
								fullWidth
								label="Employment Type">

								{
									_.map(["AGRICULTURE", "MINING", "UTILITIES", "CONSTRUCTION", "MANUFACTURING", "WHOLESALE", "RETAIL", "TRANSPORT", "INFORMATION", "FINANCE", "REAL_ESTATE", "PROFESSIONAL", "MANAGEMENT", "EDUCATION", "HEALTH", "ART", "FOOD", "PUBLIC", "WASTE"], (value, index) => {
										let label = _.capitalize(_.split(value, "_").join(" "))
										return (
											<MenuItem
												key={`employmentType_${index}`}
												label={label}
												value={value}>
												{label}
											</MenuItem>
										)
									})
								}

							</SelectValidator>
						</>
					}

					<div style={{ width: 450, paddingTop: 50, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							color={!actionCondition && "default"}
							style={{ width: 200 }}
							onClick={actionCondition ? () => changeStep(4) : () => { }}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	container: {
		flex: 1,
	},
	contentContainer: {
		flex: 1,
		width: "90%",
		marginLeft: '5%'
	},
	headline: {
		fontSize: 24
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	arrowBack: {
		marginLeft: -5
	},
	actionButton: {
		position: "absolute", bottom: 30
	}
}