import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { pushResidence } from '../actions'
import country from 'resources/CountryData/country'
import loginBackground from 'images/login-background.png'
import {
	withStyles,
	Card,
	Button
} from '@material-ui/core'
import CountryModal from 'components/CountryModal';
import Text from 'components/Text';

class Step3Residence extends Component {

	state = {
		formData: {
			residence: country[0].code
		},
		countryFlag: country[0].flag,
		modal: false,
		help: false
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	handleChange = (name, value, countryFlag) => {
		this.state.formData[name] = value
		this.state.countryFlag = countryFlag
		this.setState(this.state)
	}

	toggleModal = () => {
		this.setState({
			modal: !this.state.modal
		})
	}

	render() {
		const { classes } = this.props

		let { formData, countryFlag } = this.state
		let residence = _.find(country, { code: formData.residence }).name
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Choose Country of Residence</h1>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40, }}>
						<Button onClick={this.toggleModal} >
							<div style={{ borderWidth: 0, borderBottomWidth: 1, minWidth: 240, borderColor: Theme.Colors.warmGrey, borderStyle: "solid", display: 'flex', paddingBottom: 10 }}>
								<img src={countryFlag} style={{ width: 36, height: 27, borderRadius: 3 }} />
								<Text style={{ marginLeft: 20, fontSize: 16 }}>{residence}</Text>
							</div>
						</Button>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 150 }}>
						<Button
							color={'primary'}
							variant="outlined"
							onClick={() => this.props.changeStep(2)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color={'primary'}
							variant="contained"
							onClick={() => {
								this.props.changeStep(4)
								this.props.pushResidence(formData.residence)
							}}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
                        	</Button>
					</div>
				</Card>
				<CountryModal
					open={this.state.modal}
					close={this.toggleModal}
					selectCountry={(country, countryFlag) => {
						this.handleChange("residence", country, countryFlag)
					}}
					type="residence" />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	pushResidence: (residence) => dispatch(pushResidence(residence))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: '60%',
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step3Residence))