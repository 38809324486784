import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { getAccountSettings, updateSettings } from './actions';
import { Button, Switch, Card } from '@material-ui/core';
import Header from 'components/Header';

class Settings extends React.Component {

	componentDidMount() {
		this.props.getAccountSettings()
	}

	handleChange = (key, value) => {
		this.props.updateSettings(key, value)
	}

	render() {
		const { accountSettings } = this.props
		return (
			<div>
				<Header name="Settings" />
				<div style={STYLES.contentContainer}>
					{
						_.map([{
							name: "Low Balance Alert ($100)", value: "low_balance"
						}, {
							name: "Transaction Alert", value: "transaction"
						}, {
							name: "Deals and Offers", value: "deals_offers"
						}, {
							name: "Tip Hint for Restaurants", value: "tip_suggestion"
						}], (value, index) => {
							return (
								<Card style={STYLES.row} key={`settigs${index}`}>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Text style={STYLES.rowText}>{value.name}</Text>
										<Switch
											checked={_.get(accountSettings, value.value) === true ? true : false}
											color="primary"
											onChange={e => this.handleChange(value.value, e.target.checked)}
										/>
									</div>
									{
										value.value == "tip_suggestion" && _.get(accountSettings, "tip_suggestion") === true &&
										<div style={{ display: 'flex', justifyContent: 'space-between', width: 250 }}>
											{
												_.map([15, 18, 20], (value, index) => {
													return (
														<Button
															key={`box${index}`}
															onClick={() => this.handleChange("tip_percentage", value)}
															style={{
																backgroundColor: Theme.Colors.lightGrey, borderRadius: 5,
																..._.get(accountSettings, "tip_percentage") === value && { backgroundColor: Theme.Colors.primaryPurple }
															}}>
															<Text style={{ color: Theme.Colors.black, ..._.get(accountSettings, "tip_percentage") === value && { color: Theme.Colors.white } }}>{value}%</Text>
														</Button>
													)
												})
											}
										</div>
									}
								</Card>
							)
						})
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.MyAccount, ['accountSettings'])
})

const mapDispatchToProps = dispatch => ({
	getAccountSettings: () => dispatch(getAccountSettings()),
	updateSettings: (key, value) => dispatch(updateSettings(key, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)

const STYLES = {
	contentContainer: {
		display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'
	},
	row: {
		width: '40%', padding: 30, marginTop: 60, height: 100, display: 'flex', flexDirection: "column", justifyContent: 'space-between'
	},
	rowText: {
		marginTop: 5, fontSize: 16
	}
}