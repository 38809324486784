import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MenuItem } from '@material-ui/core';

export default class Step5 extends Component {

	render() {
		const { formData, handleBack, changeStep, handleChange } = this.props.parentProps

		const actionCondition = !_.isEmpty(formData.bank_accounts[0].number) && !_.isEmpty(formData.bank_accounts[0].ifsc_code)
		return (
			<div>
				<Text bold style={STYLES.headline}>Your bank details</Text>

				<ValidatorForm onSubmit={() => { }}>

					<TextValidator
						onChange={(e) => handleChange(`bank_accounts[0].number`, e.target.value)}
						value={formData.bank_accounts[0].number}
						style={{ marginTop: 40 }}
						label="Account number"
						placeholder="Enter account number"
						variant="outlined"
						fullWidth
					/>

					<SelectValidator
						value={formData.bank_accounts[0].primary_account ? "Yes" : "No"}
						variant="outlined"
						style={{ marginTop: 40 }}
						onChange={(e) =>
							handleChange("bank_accounts[0].primary_account", e.target.value === "Yes" ? true : false)
						}
						label="Primary Account"
						fullWidth>

						<MenuItem value="Yes">Yes</MenuItem>
						<MenuItem value="No">No</MenuItem>

					</SelectValidator>

					{
						formData.kyc_identity_detail.residential_status === 'NON_RESIDENT_INDIVIDUAL' ?

							<SelectValidator
								value={formData.bank_accounts[0].type}
								variant="outlined"
								style={{ marginTop: 40 }}
								onChange={(e) =>
									handleChange("bank_accounts[0].type", e.target.value)
								}
								label="Account Type"
								fullWidth>

								<MenuItem value="NRE">NRE</MenuItem>
								<MenuItem value="NRO">NRO</MenuItem>

							</SelectValidator>
							:
							<SelectValidator
								value={formData.bank_accounts[0].type}
								variant="outlined"
								style={{ marginTop: 40 }}
								onChange={(e) =>
									handleChange("bank_accounts[0].type", e.target.value)
								}
								label="Account Type"
								fullWidth>

								<MenuItem value="SAVINGS">Savings</MenuItem>
								<MenuItem value="CURRENT">Current</MenuItem>

							</SelectValidator>
					}

					<TextValidator
						onChange={(e) => handleChange(`bank_accounts[0].ifsc_code`, e.target.value.toUpperCase())}
						value={formData.bank_accounts[0].ifsc_code}
						style={{ marginTop: 40 }}
						label="IFSC code"
						placeholder="Enter ifsc code"
						variant="outlined"
						fullWidth
					/>

					<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							style={{ width: 200 }}
							color={!actionCondition && "default"}
							onClick={actionCondition ? () => changeStep(6) : () => { }}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	}
}