import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { get_us_stock_details } from './actions';
import moment from 'moment'
import constants from 'resources/constants';
import { IconButton, ListItem } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import USI_Buy_Sell from './USI_Buy_Sell';

class USI_Detailed_Page extends React.Component {

	state = {
		data: [],
		min: 0,
		max: 0,
		selectedRange: "1D",
		addButtonOpen: false,
		stock_details: null,
		chart_data_intra_day: [],
		chart_data_past: [],
		company_details: null,
		stock_code: this.props.match.params.id,
		read_more: false,
		step: 1,
		side: "BUY"
	}

	update_initial_data = (state) => {
		this.setState(state, () => {
			this.changeGraphValues("1D")
		})
	}

	componentWillMount() {
		this.props.get_us_stock_details(this.state.stock_code, this.update_initial_data)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.match.params.id !== this.state.stock_code) {
			this.setState({
				stock_code: nextProps.match.params.id
			}, () => this.props.get_us_stock_details(this.state.stock_code, this.update_initial_data))
		}
	}

	toggleModal = () => {
		this.setState({ addButtonOpen: !this.state.addButtonOpen })
	}

	changeGraphValues = (range) => {
		let chart_data = this.state.chart_data_past
		let data = []
		let date_before

		switch (range) {
			case "1D":
				chart_data = this.state.chart_data_intra_day
				date_before = moment().add(-10, 'day').unix() // -10 days, so that all data is accounted for intra day
				break
			case "1W":
				date_before = moment().add(-1, 'week').unix()
				break
			case "1M":
				date_before = moment().add(-1, 'months').unix()
				break
			case "3M":
				date_before = moment().add(-3, 'months').unix()
				break
			case "1Y":
				date_before = moment().add(-1, 'year').unix()
				break
			case "5Y":
				date_before = moment().add(-5, 'year').unix()
				break
		}

		let min
		let max

		for (let i = 0; i < chart_data.length; i++) {
			if (chart_data[i][0] >= date_before) {
				let temp = chart_data[i][1]
				if (min === undefined && max === undefined) {
					min = temp
					max = temp
				} else {
					if (temp < min) {
						min = temp * 0.999
					}

					if (temp > max) {
						max = temp * 1.001
					}
				}
				data.push({
					x: moment.unix(chart_data[i][0]).format("DD-MM-YYYY HH:mm"),
					y: temp.toFixed(2)
				})
			}
		}

		if (min === undefined && max === undefined) {
			min = max = 0
		}

		this.setState({ data, min, max, selectedRange: range })
	}

	handle_read_more = () => {
		this.setState({
			read_more: true
		})
	}

	changeStep = (step, side = "BUY") => {
		this.setState({ step, side })
	}

	render() {
		const { userInfo, loader } = this.props
		const { selectedRange, data, addButtonOpen, stock_code, stock_details, company_details, read_more, step } = this.state;

		if (_.isEmpty(stock_details) || _.isEmpty(company_details)) {
			return (<div></div>)
		}

		if (step === 2) {
			return <USI_Buy_Sell changeStep={this.changeStep} symbol={stock_code} price={_.get(stock_details, "current")} side={this.state.side} />
		}

		let during_color = Theme.Colors.purplishGrey
		let during_arrow

		let during_percent = _.split(_.get(stock_details, "change.during.percent", "0%"), "%")[0]

		if (during_percent > 0) {
			during_arrow = "arrow_circle_up"
			during_color = Theme.Colors.grassGreen
		}

		if (during_percent < 0) {
			during_arrow = "arrow_circle_down"
			during_color = Theme.Colors.coralRed
		}

		let after_color = Theme.Colors.purplishGrey
		let after_arrow

		let after_percent = _.split(_.get(stock_details, "change.after.percent", "0%"), "%")[0]

		if (after_percent > 0) {
			after_arrow = "arrow_circle_up"
			after_color = Theme.Colors.grassGreen
		}

		if (after_percent < 0) {
			after_arrow = "arrow_circle_down"
			after_color = Theme.Colors.coralRed
		}

		return (
			<div style={{ marginBottom: 40 }}>
				<div style={{ background: Theme.Colors.white, borderRadius: 5, padding: 40 }}>
					<div>
						<Text bold="true" style={{ letterSpacing: 0.6, fontSize: 28 }}>
							{stock_details.name}
						</Text>
						<Text bold="true" style={{ letterSpacing: 0.6, fontSize: 28 }}>
							${_.get(stock_details, "current")}
						</Text>
					</div>
					{
						_.get(stock_details, "change.during.amt") !== null && _.get(stock_details, "change.during.percent") !== null &&
						<div style={{ display: "flex", marginTop: 10 }}>
							{
								during_arrow &&
								<i className="material-icons" style={{ color: during_color, fontSize: 20, marginRight: 10 }}>{during_arrow}</i>
							}

							<Text style={{ color: during_color }}>${Number(stock_details.change.during.amt).toFixed(2)} ({stock_details.change.during.percent}) {moment().format("MMM DD, YYYY")}</Text>
						</div>
					}

					{
						_.get(stock_details, "change.after.amt") !== null && _.get(stock_details, "change.after.percent") !== null &&
						<div style={{ display: "flex", marginTop: 10 }}>
							{
								after_arrow &&
								<i className="material-icons" style={{ color: after_color, fontSize: 20, marginRight: 10 }}>{after_arrow}</i>
							}

							<Text style={{ color: after_color }}>
								${Number(stock_details.change.after.amt).toFixed(2)} {stock_details.change.after.percent && `(${stock_details.change.after.percent})`} after hours
								</Text>
						</div>
					}

					{
						!_.isEmpty(data) &&
						<div style={{ backgroundColor: Theme.Colors.white, marginTop: 50 }}>
							<Line data={{
								labels: _.map(data, "x"),
								datasets: [{
									fill: false,
									borderColor: Theme.Colors.primaryPurple,
									data: data,
									pointBorderColor: "transparent",
									pointBackgroundColor: "transparent",
								}]
							}}
								options={{
									legend: { display: false },
									scales: {
										xAxes: [{
											display: false
										}],
										yAxes: [
											{
												gridLines: {
													display: false,
												},
											},
										],
									},
									tooltips: {
										mode: 'index',
										intersect: false,
										displayColors: false,
										callbacks: {
											// use label callback to return the desired label
											label: function (tooltipItem, data) {
												return "Date: " + tooltipItem.xLabel
											},
											afterLabel: function (tooltipItem, data) {
												return "Price: " + tooltipItem.yLabel;
											},
											// remove title
											title: function (tooltipItem, data) {
												return;
											}
										}
									},
									hover: {
										mode: 'nearest',
										intersect: true
									}
								}} />
							<div style={{ borderColor: Theme.Colors.lightGrey, borderTopWidth: 1, padding: 20, display: "flex", justifyContent: 'space-between' }}>
								{
									_.map([{ range: "1D" }, { range: "1W" }, { range: "1M" }, { range: "3M" }, { range: "1Y" }, { range: "5Y" }], (value, index) => {
										return (
											<ListItem
												button
												onClick={() => this.changeGraphValues(value.range)}
												key={`range${index}`}
												style={{
													...selectedRange === value.range && { backgroundColor: Theme.Colors.primaryPurple },
													width: 50, borderRadius: 5, padding: `8px 15px`
												}}>
												<Text style={{
													color: selectedRange === value.range ? Theme.Colors.white : Theme.Colors.black,
													fontSize: 12
												}}>{value.range}</Text>
											</ListItem>
										)
									})
								}
							</div>
						</div>
					}
					<div style={STYLES.fundDetailContainer}>
						<div style={STYLES.fundHeadline}>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>Statistics</Text>
						</div>
						<div style={{ ...STYLES.fundDetailBody, display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
							{
								_.map([{
									title: "Open", body: stock_details.open
								}, {
									title: "Volume", body: stock_details.volume
								}, {
									title: "Today's High", body: stock_details.today.high
								}, {
									title: "Average Volume", body: stock_details.avgVolume
								}, {
									title: "Today's Low", body: stock_details.today.low
								}, {
									title: "P/E Ratio", body: stock_details.peRatio
								}], (value, index) => {
									return (
										<div key={`item${index}`} style={{ width: '50%', marginTop: 25 }}>
											<Text>{value.title}</Text>
											<Text bold="true" style={{ fontSize: 16, marginTop: 5 }}>
												{value.body}
											</Text>
										</div>
									)
								})
							}
						</div>
					</div>

					<div style={STYLES.fundDetailContainer}>
						<div style={STYLES.fundHeadline}>
							<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>About</Text>
						</div>
						<div style={STYLES.fundDetailBody}>
							{
								_.map([{
									title: "Headquarters", body: company_details.heaadquarters
								}, {
									title: "Employees", body: company_details.employees
								}, {
									title: "Sector", body: company_details.sector
								}], (value, index) => {
									return (
										<div
											key={`item${index}`}
											style={{ display: "flex", justifyContent: "space-between", marginTop: 25, ...index === 0 && { width: '100%' } }}>
											<Text>{value.title}</Text>
											<Text style={{ marginLeft: 20 }}>
												{value.body}
											</Text>
										</div>
									)
								})
							}
							<div style={{ marginTop: 25 }}>
								<Text>Summary</Text>
								<Text style={{ marginTop: 10, lineHeight: 1.5 }}>
									{read_more ?
										company_details.summary
										:
										<>
											{_.truncate(company_details.summary, { length: 170 })}
											<Text tag="span" style={{ color: Theme.Colors.primaryPurple, marginLeft: 10, cursor: "pointer" }} onClick={this.handle_read_more}>Read more</Text>
										</>
									}
								</Text>
							</div>
						</div>
					</div>

					{
						this.props.market_summary.top_10_stocks &&
						<div style={{ ...STYLES.fundDetailContainer }}>
							<div style={STYLES.fundHeadline}>
								<Text style={{ fontSize: 16, color: Theme.Colors.greyishBrown }}>People also bought</Text>
							</div>
							<div style={{ display: 'flex' }}>
								{_.map(_.filter(this.props.market_summary.top_10_stocks, (arrObj1, arrIdx1) =>
									[{ code: 'AMZN' }, { code: 'TSLA' }, { code: 'GOOGL' }, { code: 'MSFT' }, { code: 'AAPL' }, { code: 'FB' }]
										.some((arrObj2, arrIdx2) =>
											arrObj2.code === arrObj1.code
											&& arrObj2.code !== this.state.stock_code)), (value, index) => {
												return (
													<ListItem button style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, border: `1px solid ${Theme.Colors.lightGrey}`, display: "flex", width: 145, justifyContent: 'space-between', padding: `15px 10px`, marginLeft: 15 }} key={`people_bought_${index}`}
														onClick={() => this.props.history.push(`/user/invest/us/stock/details/${value.code}`)}
													>
														<Text style={{ fontSize: 14, marginBottom: 10 }}>{value.name}</Text>
														<Text style={{ fontSize: 20, textAlign: 'center', ...value.change > 0 ? { color: Theme.Colors.grassGreen } : { color: Theme.Colors.coralRed } }}>{value.code}</Text>
														<Text style={{ fontSize: 20, textAlign: 'center', ...value.change > 0 ? { color: Theme.Colors.grassGreen } : { color: Theme.Colors.coralRed } }}>{value.change > 0 && '+'}{(value.change * 100).toFixed(2)}%</Text>
													</ListItem>
												)
											})
								}
							</div>
						</div>
					}
				</div>

				{
					addButtonOpen &&
					<div onClick={() => this.toggleModal()}>
						<div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: '#00000052' }}>
						</div>
					</div>
				}

				<div style={{ position: 'fixed', bottom: 25, right: 25, display: "flex", flexDirection: "column", alignItems: 'flex-end' }}>
					{
						addButtonOpen &&
						<div>
							<ListItem
								button
								onClick={() => {
									this.toggleModal()
									if (userInfo.us_investing_status !== constants.us_investing_status.COMPLETED) {
										if (_.includes(["AE", "IN"], userInfo.country_of_residence)) {
											alert("Please create an investor first from homepage")
										} else {
											alert("Coming Soon")
										}
										return
									}
									this.changeStep(2, "BUY")
								}}
								style={STYLES.investButton}>
								<Text bold="true" style={{ color: Theme.Colors.primaryPurple, fontSize: 16, textAlign: 'center' }}>BUY</Text>
							</ListItem>

							<ListItem
								button
								onClick={() => {
									this.toggleModal()
									if (userInfo.us_investing_status !== constants.us_investing_status.COMPLETED) {
										if (_.includes(["AE", "IN"], userInfo.country_of_residence)) {
											alert("Please create an investor first from homepage")
										} else {
											alert("Coming Soon!")
										}
										return
									}
									this.changeStep(2, "SELL")
								}}
								style={STYLES.investButton}>
								<Text bold="true" style={{ color: Theme.Colors.primaryPurple, fontSize: 16, textAlign: 'center' }}>
									SELL
								</Text>
							</ListItem>
						</div>
					}
					<IconButton
						onClick={this.toggleModal}
						style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: Theme.Colors.primaryPurple, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
						<i className="material-icons" style={{ fontSize: 28, color: Theme.Colors.white }}>add</i>
					</IconButton>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo", "loader"]),
	..._.pick(state.Investing, ['market_summary'])
})

const mapDispatchToProps = dispatch => ({
	get_us_stock_details: (symbol, update_initial_data) => dispatch(get_us_stock_details(symbol, update_initial_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(USI_Detailed_Page)

const STYLES = {
	contentContainer: {
		width: '90%', marginLeft: '5%', marginTop: 20
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	searchContainer: {
		height: 40, backgroundColor: Theme.Colors.whiteTwo, marginTop: 10, borderRadius: 5, display: "flex", paddingLeft: 10
	},
	logoContainer: {
		borderRadius: 25, height: 50, width: 50, borderWidth: 1, borderColor: Theme.Colors.lightGrey
	},
	dot: {
		fontSize: 40, marginTop: -20, marginLeft: 5, color: Theme.Colors.warmGrey
	},
	fundDetailContainer: {
		backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.whiteTwo, borderTopWidth: 1, borderBottomWidth: 1, marginTop: 20
	},
	fundDetailBody: {
		paddingBottom: 25
	},
	fundHeadline: {
		padding: '15px 0', borderColor: Theme.Colors.whiteTwo, borderBottomWidth: 1
	},
	returnHeadline: {
		paddingHorizontal: '15px 0'
	},
	investButton: {
		backgroundColor: Theme.Colors.white, borderRadius: 5, padding: `12px 34px`, marginBottom: 20
	}
}