import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { pushDOB_PhoneNumber } from '../actions';
import moment from 'moment'
import country from 'resources/CountryData/country'
import CountryModal from 'components/CountryModal'
import { Card, Button, withStyles, ListItem, Checkbox } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import loginBackground from 'images/login-background.png'
import {
	DatePicker,
} from '@material-ui/pickers';
import { toggleError } from 'actions/actions';

class Step1DOB extends Component {

	state = {
		formData: {
			dob: "01/01/2001",
			countryCode: "+1",
			phoneNumber: ""
		},
		countryFlag: country[0].flag,
		actionCondition: false,
		check: false,
		modal: false,
		help: false,
	}

	handleChange = (name, value, countryFlag) => {
		const { formData } = this.state
		if (name === 'phoneNumber' && !_.isEmpty(value)) {
			value = Number(_.trim(value))
			if (_.isInteger(value))
				formData[name] = String(_.toInteger(value))
		} else {
			formData[name] = value
		}

		if (countryFlag) {
			this.state.countryFlag = countryFlag
		}

		if (!_.isEmpty(formData.dob) && !_.isEmpty(formData.phoneNumber) && !_.isEmpty(formData.countryCode)) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData } = this.state
		if (!(moment().diff(moment(formData.dob, 'MM/DD/YYYY'), 'years') < 18)) {
			this.props.pushDOB_PhoneNumber(formData)
			this.props.changeStep(2)
		} else {
			this.props.toggleError('You need to be at least 18 years of age to use North Loop.');
		}
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	toggleModal = () => {
		this.setState({
			modal: !this.state.modal
		})
	}

	render() {
		const { classes } = this.props
		const { formData, countryFlag, modal, actionCondition } = this.state

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Let's get started</h1>
					<ValidatorForm onSubmit={(!actionCondition || !this.state.check) ? () => { } : this.handleSubmit}>

						<DatePicker
							margin="normal"
							inputVariant="outlined"
							label="Date of Birth"
							format="MM/dd/yyyy"
							value={formData.dob}
							onChange={(date) => this.handleChange('dob', moment(date).format("MM/DD/YYYY"))}
							autoOk
							disableFuture
							style={{ marginTop: 50 }}
							fullWidth />
						<div style={{ display: 'flex', marginTop: 50, justifyContent: 'space-between', alignItems: 'flex-end' }}>
							<ListItem button onClick={this.toggleModal} style={{ width: '25%', border: `0px solid #CBCACA`, borderBottomWidth: 1, borderRadius: 3, height: 70, display: 'flex', flexDirection: 'column', alignItems: "flex-start", paddingLeft: 5 }}>
								<p style={{ fontSize: 10, color: Theme.Colors.purplishGrey }}>Country Code</p>
								<div style={{ display: 'flex' }}>
									<img src={countryFlag} style={{ width: 40, height: 30, borderRadius: 5, marginTop: 8 }} />
									<p style={{ fontSize: 16, marginTop: 10, marginLeft: 15 }}>{formData.countryCode}</p>
								</div>
							</ListItem>
							<TextValidator
								value={formData.phoneNumber}
								onChange={(e) => this.handleChange(e.target.name, e.target.value)}
								name="phoneNumber"
								type="number"
								label="Phone Number"
								placeholder="Enter your phone number"
								variant="outlined"
								style={{ width: '65%' }}
								fullWidth />
						</div>
						<div style={{ display: 'flex', marginTop: 20, marginLeft: -10 }}>
							<Checkbox color="primary" onChange={(e) => this.setState({ check: e.target.checked })} />
							<p style={{ marginTop: 12 }}>I have read and agree to the <a href="https://www.nolobank.com/consumer-deposit-agreement" target="_blank">Deposit Agreement</a> and <a href="https://www.nolobank.com/cardholder-agreement" target="_blank">Cardholder Agreement</a></p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => this.props.history.push("/user/my-profile")}
								style={{ fontWeight: 700, width: 170 }}>
								Back
                        	</Button>
							<Button
								color={(!actionCondition || !this.state.check) ? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>
				</Card>
				<CountryModal
					open={this.state.modal}
					close={this.toggleModal}
					selectCountryCode={(code, countryFlag) => {
						this.handleChange("countryCode", code, countryFlag)
					}}
					type="phone" />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	pushDOB_PhoneNumber: (data) => dispatch(pushDOB_PhoneNumber(data)),
	toggleError: (err) => dispatch(toggleError(err))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step1DOB))