import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { removeAccount } from '../AddFunds/actions'
import { removePlaidAccount } from '../MyProfile/actions'
import { Button, Dialog, ListItem, DialogContent, DialogActions } from '@material-ui/core';
import { getAllLinkedAccounts } from './actions';
import Plaid from 'screens/MyProfile/BankAccount/Plaid';
import Header from 'components/Header';

class LinkedAccounts extends Component {

	state = {
		modal: false,
		item_id: ""
	}

	componentWillMount() {
		this.props.getAllLinkedAccounts()
	}

	toggleModal = (item_id) => {
		this.setState({
			modal: !this.state.modal,
			item_id
		})
	}

	render() {
		const { linkedAccounts, plaidAccounts, userInfo } = this.props

		let depositAccounts = _.filter(plaidAccounts, (value, index) => {
			if (value.type != 'credit') {
				return true
			}
		})

		let creditAccounts = _.filter(plaidAccounts, (value, index) => {
			if (value.type == 'credit') {
				return true
			}
		})

		return (
			<div>
				<Header name="My Linked Accounts" onBack={true} />
				<div style={STYLES.contentContainer}>
					{
						userInfo.sign_up_status_2 == 'completed' &&

						<div style={{ marginTop: 40 }}>
							<Text style={STYLES.cardTitle}>My Funding Accounts</Text>
							<div style={STYLES.card}>
								{
									_.map(linkedAccounts, (value, index) => {
										return (
											<ListItem button key={`linkedaccount${index}`} style={STYLES.linkedAccountContainer}>
												<div style={STYLES.linkedAccountImageContainer}>
													<div style={STYLES.linkedAccountsImage}>
														{
															!_.isEmpty(value.bank_logo) &&
															<img src={value.bank_logo} style={{ height: 35, width: 35 }} />
														}
													</div>
												</div>
												<div style={STYLES.bankContainer}>
													<Text style={{ fontSize: 16 }}>{value.bank_name} - {value.account_num}</Text>
												</div>
												<div style={{ flex: 1 }}>
													<Button style={STYLES.linkAccountRemove} onClick={() => this.props.removeAccount(value.node_id)}>
														<i className="material-icons">close</i>
													</Button>
												</div>
											</ListItem>
										)
									})
								}
								{/* <div>
									<Button onClick={() => { }} style={{ width: '100%', padding: `15px 0px` }}>
										<Text bold="true"style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>+ ADD A BANK ACCOUNT</Text>
									</Button>
								</div> */}
							</div>
						</div>
					}

					<div style={{ marginTop: 30 }}>
						<Text style={STYLES.cardTitle}>My PFM Accounts</Text>
						<div style={STYLES.card}>
							{
								_.map(depositAccounts, (value, index) => {
									return (
										<div key={`depositaccount${index}`} style={STYLES.linkedAccountContainer}>
											<div style={STYLES.linkedAccountImageContainer}>
												<div style={STYLES.linkedAccountsImage}>
													<img src={`data:image/png;base64,${value.logo}`} style={{ height: 47, width: 47 }} />
												</div>
											</div>
											<div style={STYLES.bankContainer}>
												<Text style={{ fontSize: 12, color: Theme.Colors.warmGreyTwo }}>
													{value.institution_name}
												</Text>
												<Text bold="true" style={{ fontSize: 24, marginTop: 5 }}>
													${(Number(_.get(value, 'balances.available') || 0)).toFixed(2)}
												</Text>
											</div>
											<div style={{ flex: 1 }}>
												<Button style={STYLES.linkAccountRemove} onClick={() => this.toggleModal(value.item_id)}>
													<i className="material-icons">close</i>
												</Button>
											</div>
										</div>
									)
								})
							}
							<div>
								<Button onClick={() => document.getElementById("plaid-id").click()} style={{ width: '100%', padding: `15px 0px` }}>
									<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>+ LINK A BANK ACCOUNT</Text>
								</Button>
								<Plaid />
							</div>
						</div>
					</div>
					<div style={{ marginTop: 30 }}>
						<Text style={STYLES.cardTitle}>My Credit Cards</Text>
						<div style={STYLES.card}>
							{
								_.map(creditAccounts, (value, index) => {
									return (
										<div key={`creditaccount${index}`} style={STYLES.linkedAccountContainer}>
											<div style={STYLES.linkedAccountImageContainer}>
												<div style={STYLES.linkedAccountsImage}>
													<img src={`data:image/png;base64,${value.logo}`} style={{ height: 47, width: 47 }} />
												</div>
											</div>
											<div style={STYLES.bankContainer}>
												<Text style={{ fontSize: 12, color: Theme.Colors.warmGreyTwo }}>
													{value.name}
												</Text>
												<Text bold="true" style={{ fontSize: 24, marginTop: 5 }}>
													${(Number(_.get(value, 'balances.current') || 0)).toFixed(2)}
												</Text>
											</div>
											<div style={{ flex: 1 }}>
												<Button style={STYLES.linkAccountRemove} onClick={() => this.toggleModal(value.item_id)}>
													<i className="material-icons">close</i>
												</Button>
											</div>
										</div>
									)
								})
							}
							<div>
								<Button onClick={() => document.getElementById("plaid-id").click()} style={{ width: '100%', padding: `15px 0px` }}>
									<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>+ LINK A CREDIT CARD</Text>
								</Button>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 20, display: 'flex', marginBottom: 20 }}>
						<Text style={STYLES.cardTitle}>My Investment Accounts</Text>
						<div style={{ marginLeft: 10, backgroundColor: Theme.Colors.paleGreyThree, padding: `3px 10px` }}>
							<Text style={{ color: Theme.Colors.primaryPurple }}>Coming Soon</Text>
						</div>
					</div>
				</div>
				<Dialog open={this.state.modal} styles={{ borderRadius: 10 }}>
					<DialogContent>
						<Text style={{ marginTop: 8, lineHeight: 2, fontSize: 16 }}>
							Are you sure? This will de-link all accounts associate with this bank, including credit cards
						</Text>
					</DialogContent>
					<DialogActions>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, width: 200 }}>
							<Button variant="contained" color="primary" onClick={() => {
								this.toggleModal()
								this.props.removePlaidAccount(this.state.item_id)
							}}>
								Remove
							</Button>

							<Button variant="outlined" color="primary" onClick={this.toggleModal}>
								Cancel
							</Button>
						</div>
					</DialogActions>
				</Dialog >
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['loader', 'userInfo']),
	..._.pick(state.Transfer, ['linkedAccounts']),
	..._.pick(state.MyProfile, ['plaidAccounts'])
})

const mapDispatchToProps = dispatch => ({
	getAllLinkedAccounts: () => dispatch(getAllLinkedAccounts()),
	removeAccount: (node_id) => dispatch(removeAccount(node_id)),
	removePlaidAccount: (item_id) => dispatch(removePlaidAccount(item_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccounts)

const STYLES = {
	contentContainer: {},
	cardTitle: {
		fontSize: 16, color: Theme.Colors.greyishBrown
	},
	card: {
		borderRadius: 5, backgroundColor: Theme.Colors.white, marginTop: 20, border: `0.5px solid ${Theme.Colors.lightGrey}`
	},
	linkedAccountContainer: {
		padding: `20px 0px`, display: 'flex', justifyContent: 'space-between', border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1,
	},
	linkedAccountImageContainer: {
		display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1
	},
	linkedAccountsImage: {
		height: 48, width: 48, backgroundColor: Theme.Colors.white, borderRadius: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
	},
	bankContainer: {
		display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 7
	},
	linkAccountRemove: {
		display: 'flex', marginLeft: -20, justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: 3
	}
}