import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	withStyles,
	Card,
	CardContent
} from '@material-ui/core';
import _ from 'lodash'
import Text from 'components/Text';
import CurrentBalance from 'components/CurrentBalance';
import Theme from 'resources/Theme';

class TransferPay extends Component {

	render() {
		return (
			<div>
				<div style={{ display: 'flex' }}>
					<CurrentBalance />
					{
						_.map([{
							name: 'Transfer', img: require('images/TransferPay/transferFunds.svg')
						}, {
							name: 'Send money abroad'
						}], (value, index) => {
							return (
								<Card key={`item${index}`} style={{
									width: 150,
									padding: 10,
									paddingBottom: 0,
									borderRadius: 10,
									marginTop: 20,
									marginLeft: 20
								}}>
									<CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'space-between' }}>
										<img src={value.img} />
										<Text bold="true" style={{ textAlign: 'center', marginTop: 15, color: Theme.Colors.primaryPurple }}>{value.name}</Text>
									</CardContent>
								</Card>
							)
						})
					}
				</div>
				<div style={{ marginTop: 45, marginBottom: 25 }}>
					<Text semi="true" style={{ fontSize: 18, letterSpacing: 0.6, color: Theme.Colors.brownishGrey }}>Transactions History</Text>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({

})

const STYLES = {}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(TransferPay))