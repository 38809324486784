import { combineReducers } from "redux";
import _ from 'lodash';
import { Transaction } from 'screens/Transactions/reducer'
import MyUSCard from 'screens/Card/US/reducer'
import Auth from 'screens/Auth/reducer'
import MyProfile from 'screens/MyProfile/reducer'
import MyAccount from 'screens/MyAccount/reducer'
import Transfer from 'screens/Transfer/reducer'
import AddFunds from 'screens/AddFunds/reducer'
import Expense from 'screens/Expense/reducer'
import NRI_Banking from 'screens/NRI_Banking/reducer'
import Investing from 'screens/Investing/reducer'

import {
	TOGGLE_LOADER,
	ACCOUNT_INFO_RECEIVED,
	USER_INFO_RECEIVED,
	LOGOUT_USER,
	ALERT_OPEN,
	ALERT_CLOSE, USER_INFO_UPDATED
} from 'actions/actions'
import { ADD_IMAGE, REMOVE_IMAGE, SIGNUP_COMPLETE } from "screens/Auth/actions";
import { US_CARD_ORDERED } from "screens/Card/US/actions";

const initialState = {
	loader: false,
	accountInfo: {},
	userInfo: {},
	alert: { open: false }
}

const App = (state = { ...initialState }, action) => {

	switch (action.type) {
		case TOGGLE_LOADER:
			return { ...state, loader: !state.loader };

		case ACCOUNT_INFO_RECEIVED: {
			return { ...state, accountInfo: action.accountInfo }
		}

		case ADD_IMAGE: {
			if (action.reupload) {
				state = JSON.parse(JSON.stringify(state))
				_.set(state, action.key, action.image)
			}
			return { ...state }
		}

		case REMOVE_IMAGE: {
			state = JSON.parse(JSON.stringify(state))
			_.set(state, action.key, "")
			return { ...state }
		}

		case USER_INFO_RECEIVED: {
			return { ...state, userInfo: action.userInfo }
		}

		case USER_INFO_UPDATED: {
			return { ...state, userInfo: { ...state.userInfo, ...action.userInfo } }
		}

		case LOGOUT_USER: {
			return { ...initialState }
		}

		case US_CARD_ORDERED: {
			return { ...state, accountInfo: { ...state.accountInfo, isCardIssued: true } }
		}

		case SIGNUP_COMPLETE: {
			return { ...state, userInfo: { ...state.userInfo, sign_up_status_2: action.sign_up_status_2 } }
		}

		case ALERT_OPEN:
			return { ...state, alert: { open: true, title: action.title, message: action.message } }

		case ALERT_CLOSE:
			return { ...state, alert: { ...state.alert, open: false } }

		default:
			return state;
	}
}

export default combineReducers({
	App,
	Transaction,
	MyUSCard,
	Auth,
	MyProfile,
	MyAccount,
	Transfer,
	AddFunds,
	Expense,
	NRI_Banking,
	Investing
});