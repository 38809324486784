import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// import firebase from 'react-native-firebase';
//import AuthHelp from 'components/AuthHelp';
import { pushName } from '../actions'
import loginBackground from 'images/login-background.png'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
	withStyles,
	Card,
	Button,
	Checkbox
} from '@material-ui/core'

class Step2PersonalDetails extends Component {

	state = {
		formData: {
			firstName: "",
			lastName: ""
		},
		actionCondition: false,
		help: false
	}

	handleSubmit = () => {
		const { formData } = this.state
		this.props.changeStep(3)
		this.props.pushName(`${_.capitalize(formData.firstName)} ${formData.lastName}`)
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		let { formData } = this.state
		formData[name] = value

		if (
			!(
				formData.firstName.length < 2 ||
				formData.lastName.length < 2
			)
		) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { classes } = this.props

		const { formData, actionCondition } = this.state
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Personal Details</h1>
					<ValidatorForm onSubmit={actionCondition ? this.handleSubmit : () => { }}>
						<TextValidator
							value={formData.firstName}
							onChange={this.handleChange}
							name="firstName"
							label="First Name"
							placeholder="Enter first name"
							variant="outlined"
							style={{ marginTop: 40 }}
							fullWidth />
						<TextValidator
							value={formData.lastName}
							onChange={this.handleChange}
							name="lastName"
							label="Last Name"
							placeholder="Enter last name"
							variant="outlined"
							style={{ marginTop: 40 }}
							fullWidth />
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
							<Button
								color={'primary'}
								variant="outlined"
								onClick={() => this.props.changeStep(1)}
								style={{ fontWeight: 700, width: 170 }}>
								Back
                        	</Button>
							<Button
								color={!(actionCondition) ? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>

				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	pushName: (name) => dispatch(pushName(name))
})


const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 400,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step2PersonalDetails))