import { EXPENSES_RECEIVED, EXPENSE_TRANSACTIONS_RECEIVED, SUBSCRIPTIONS_RECEIVED, MERCHANT_HISTORY_RECEIVED, ANALYSIS_DETAILS_RECEIVED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";

const initialState = {
	spendByCategories: [],
	totalSpend: 0,
	expenseComparison: {},
	expenseTrans: [],
	analysisDetails: {},
	subscriptions: [],
	merchantHistory: []
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case EXPENSES_RECEIVED: {
			return { ...state, spendByCategories: _.get(action, 'expenses.spend_by_categories.data') || [], totalSpend: _.get(action, 'expenses.m_to_date_spend.data[0].sum_amount') || 0, expenseComparison: _.get(action, 'expenses.expense_comparison') }
		}
		case EXPENSE_TRANSACTIONS_RECEIVED: {
			return { ...state, expenseTrans: action.expenseTrans }
		}
		case ANALYSIS_DETAILS_RECEIVED: {
			return { ...state, analysisDetails: action.analysisDetails }
		}
		case SUBSCRIPTIONS_RECEIVED: {
			return { ...state, subscriptions: action.subscriptions }
		}
		case MERCHANT_HISTORY_RECEIVED: {
			return { ...state, merchantHistory: action.merchantHistory }
		}
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}