import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { get_holdings } from './actions';
import constants from 'resources/constants';
import Header from 'components/Header';

class Investing_Holdings extends React.Component {

	componentWillMount() {
		if (
			_.isEmpty(this.props.holdings) &&
			this.props.userInfo.us_investing_status === constants.us_investing_status.COMPLETED
		) {
			this.props.get_holdings()
		}
	}

	render() {
		const { holdings } = this.props

		let total_shares = 0

		_.map(holdings, value => {
			total_shares += value.shares
		})

		return (
			<div>
				<Header name="My Investments" onBack={true} />
				<div>
					{
						_.isEmpty(holdings) ?
							<Text style={{ marginTop: 20 }}>No investments yet</Text>
							:
							_.map(holdings, (value, index) => {

								let price
								let name
								let percent_portfolio
								let percent_value
								let color = Theme.Colors.warmGrey
								let sign

								switch (value.country) {
									case "IN":

										break;

									case "US":
										name = value.symbol
										price = `$${value.current_value.toFixed(2)}` || "-"
										percent_portfolio = (value.shares / total_shares * 100).toFixed(2)
										percent_value = ((value.current_value - value.investment_value) / value.investment_value * 100).toFixed(2)

										if (percent_value > 0) {
											color = Theme.Colors.grassGreen
											sign = "+"
										}

										if (percent_value < 0) {
											color = Theme.Colors.coralRed
											sign = "-"
										}
										break;
								}

								return (
									<div style={{
										padding: 20,
										background: Theme.Colors.white,
										borderRadius: 5,
										marginTop: 20
									}}
										key={`item${index}`}>
										<div style={{ display: "flex", justifyContent: 'space-between' }}>
											<Text bold="true" style={{ fontSize: 20, letterSpacing: 0.6 }}>{name}</Text>
											<Text bold="true" style={{ letterSpacing: 0.6, textAlign: 'right' }}>{price}</Text>
										</div>
										<div style={{ display: "flex", justifyContent: 'space-between', marginTop: 10 }}>
											<Text style={{ fontSize: 12, color: Theme.Colors.greyishBrown }}>
												% of portfolio <Text tag="span" bold="true">{percent_portfolio} %</Text>
											</Text>
											<Text bold="true" style={{ letterSpacing: 0.6, textAlign: 'right', color }}>
												{sign === "+" && sign} {percent_value} %
											</Text>
										</div>
									</div>
								)
							})
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo"]),
	..._.pick(state.Investing, ["holdings"])
})

const mapDispatchToProps = dispatch => ({
	get_holdings: () => dispatch(get_holdings())
})

export default connect(mapStateToProps, mapDispatchToProps)(Investing_Holdings)

const STYLES = {}