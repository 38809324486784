import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import Step1DOB from './Step1DOB'

import SubStep1SSN from './Step2Cip3/SubStep1SSN'
import SubStep2Cip3IdProof from './Step2Cip3/SubStep2Cip3IdProof'

import SubStep1Cip2ScanDocuments from './Step2Cip2/SubStep1Cip2ScanDocuments'
import SubStep1Cip2AddressProof from './Step2Cip2/SubStep1Cip2AddressProof'
import SubStep1Cip2Passport from './Step2Cip2/SubStep1Cip2Passport'
import SubStep1Cip2StudentVisa from './Step2Cip2/SubStep1Cip2StudentVisa'
import SubStep1Cip2i20 from './Step2Cip2/SubStep1Cip2i20'
import SubStep2Cip2ConfirmDetails from './Step2Cip2/SubStep2Cip2ConfirmDetails'
import Step3AddressAutoComplete from './Step3AddressAutoComplete'
import Step3Address from './Step3Address'
import Step4ConfirmAddress from './Step4ConfirmAddress'
import Step0Password from './Step0Password'

// import Step5ThankYou from './Step5ThankYou'

class SignUpBank extends Component {

	state = {
		step: _.get(this.props, 'location.state.step') || (_.get(this.props, 'userData.password') ? 1 : 0)
	}

	changeStep = (step) => {
		step = Number(step)
		this.setState({ step })
	}

	render() {
		const { step } = this.state
		const { userInfo } = this.props

		if (_.isObject(userInfo) && _.isEmpty(userInfo)) {
			return <div></div>
		}

		if (userInfo == null || userInfo.sign_up_status_2 != "firstStageComplete") {
			this.props.history.push('/user/my-profile')//@notfound
			return null
		}

		return (
			<div>
				{
					step == 0 &&
					<Step0Password changeStep={this.changeStep} history={this.props.history} />
				}
				{
					step == 1 &&
					<Step1DOB changeStep={this.changeStep} history={this.props.history} />
				}
				{
					userInfo.cipTag == 3 ?
						<>
							{
								step == 2 &&
								<SubStep1SSN changeStep={this.changeStep} />
							}
							{
								step == 2.9 &&
								<SubStep2Cip3IdProof changeStep={this.changeStep} />
							}
						</>
						:

						<>
							{
								step == 2 &&
								<SubStep1Cip2ScanDocuments changeStep={this.changeStep} />
							}
							{
								step == 2.1 &&
								<SubStep1Cip2Passport changeStep={this.changeStep} />
							}
							{
								step == 2.2 &&
								<SubStep1Cip2i20 changeStep={this.changeStep} />
							}
							{
								step == 2.3 &&
								<SubStep1Cip2StudentVisa changeStep={this.changeStep} />
							}
							{
								step == 2.4 &&
								<SubStep1Cip2AddressProof changeStep={this.changeStep} />
							}
							{
								step == 2.9 &&
								<SubStep2Cip2ConfirmDetails changeStep={this.changeStep} />
							}
						</>
				}
				{
					step == 3 &&
					<Step3AddressAutoComplete changeStep={this.changeStep} />
				}
				{
					step == 3.1 &&
					<Step3Address changeStep={this.changeStep} />
				}
				{
					step == 4 &&
					<Step4ConfirmAddress changeStep={this.changeStep} />
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ['signUpLoader', 'userData']),
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpBank)