import React from 'react';
import _ from 'lodash'
import { connect } from 'react-redux'
import { linkPlaidBankAccounts, getLinkedPlaidAccounts } from '../actions';
import { PlaidLink } from 'react-plaid-link';
import utils from 'resources/utils';

class Plaid extends React.Component {

	onMessage = (token, metadata) => {
		// const token = this.props.navigation.getParam("token")
		// if (_.split(data.action, "::")[1] === 'connected') {
		// 	if (token) {
		// 		this.props.navigation.goBack()
		// 		this.props.getLinkedPlaidAccounts()
		// 	} else {
		this.props.linkPlaidBankAccounts(metadata, this.props.navigation)
		// 	}
		// }
	}

	render() {
		// const token = this.props.navigation.getParam("token")
		return (
			<div style={{ display: 'none' }}>
				<PlaidLink
					clientName="North Loop"
					env={utils.ENVIRONMENT === utils.env.STAGING ? "sandbox" : "production"}
					product={['auth', 'transactions', 'liabilities']}
					publicKey={utils.get_plaid_public_key()}
					// token={token}
					onSuccess={this.onMessage}>
					<div id="plaid-id">

					</div>
				</PlaidLink>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	linkPlaidBankAccounts: (data) => dispatch(linkPlaidBankAccounts(data)),
	getLinkedPlaidAccounts: () => dispatch(getLinkedPlaidAccounts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Plaid)