import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { completeSignUp } from '../actions';
import { withStyles, Button, Card } from '@material-ui/core';
import loginBackground from 'images/login-background.png'
import { login } from 'actions/actions';
import { withRouter } from 'react-router-dom';
import { changeAddress } from 'screens/MyAccount/actions';

class Step4ConfirmAddress extends Component {

	state = {
		help: false,
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { address, signUpLoader, changeAddress, addressChange, classes } = this.props

		let confirmAddress = changeAddress ? addressChange : address

		return (
			<div className={classes.container} style={{ ...changeAddress && { background: "none", minHeight: 'auto', marginTop: 50 } }}>
				<Card className={classes.card}>
					<h1>Please confirm the address we found</h1>
					<div>
						{
							_.map([{
								label: "Street Address*", placeholder: "For example, 1 Market Street", name: "street"
							}, {
								label: "City*", name: "city",
							}, {
								label: "State*", name: "state",
							}, {
								label: "Zip Code*", name: "zip",
							}, {
								label: "Country*", name: "country",
							}], (value, index) => {
								return (
									<div
										key={`item${index}`}
										style={{ marginTop: 38, border: `1px solid ${Theme.Colors.primaryPurple}`, borderRadius: 5, padding: 20 }}>
										<Text bold="true" style={{ fontSize: 16 }}>{confirmAddress[value.name]}</Text>
									</div>
								)
							})
						}
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => this.props.changeStep(changeAddress ? 1 : 3)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								if (changeAddress) {
									this.props.changeAddress_Fn(this.props.changeStep)
								} else {
									this.props.completeSignUp(this.props.history)
								}
							}}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
						</Button>
					</div>
				</Card>
				{
					signUpLoader &&
					<div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#00000052' }}></div>
						<div style={{ position: 'absolute', zIndex: 1 }}>
							<img src={require("images/v1.gif")} style={{ height: 400 }} />
						</div>
					</div>
				}
			</div>

		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth.signUpPayloadCommon, ['address']),
	..._.pick(state.Auth, ['signUpLoader']),
	..._.pick(state.App, ['userInfo']),
	..._.pick(state.MyAccount, ['addressChange'])
})

const mapDispatchToProps = dispatch => ({
	completeSignUp: (navigation) => dispatch(completeSignUp(navigation)),
	changeAddress_Fn: (changeStep) => dispatch(changeAddress(changeStep)),
	login: (formData, navigation, changeStep) => dispatch(login(formData, navigation, changeStep)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default withRouter(withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step4ConfirmAddress)))