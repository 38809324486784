import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import { moveToConfirmDetails } from '../../actions'
import { Button, Card, withStyles, ListItem } from '@material-ui/core'
import loginBackground from 'images/login-background.png'

class SubStep1Cip2ScanDocuments extends Component {

	state = {
		docs: [{
			title: "Passport",
			subTitle: "Front Page",
			image: require(`images/Auth/passport.png`),
			imageFilled: require(`images/Auth/passport-filled.png`),
			component: "SubStep1Cip2Passport"
		}, {
			title: "I-20",
			subTitle: "All 3 pages",
			image: require(`images/Auth/I-20.png`),
			imageFilled: require(`images/Auth/I-20-filled.png`),
			component: "SubStep1Cip2i20"
		}, {
			title: "Student Visa",
			subTitle: "Canadian citizens can use their I-94",
			image: require(`images/Auth/studentVisa.png`),
			imageFilled: require(`images/Auth/studentVisa-filled.png`),
			component: "SubStep1Cip2StudentVisa"
		}, {
			title: "Address proof",
			subTitle: "e.g. Dorm room assignment, Lease, AirBnb / Hotel Booking",
			image: require(`images/Auth/addressProof.png`),
			imageFilled: require(`images/Auth/addressProof-filled.png`),
			component: "SubStep1Cip2AddressProof"
		}],
		help: false
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { doc_cip_tag2, classes } = this.props

		let actionCondition = doc_cip_tag2[0] && doc_cip_tag2[1] && doc_cip_tag2[2] && doc_cip_tag2[3]

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Upload your ID</h1>
					<Text style={{ marginTop: 30, color: Theme.Colors.brownishGrey }}>You'll need to upload photos of the following documents</Text>
					<div style={{ marginTop: 30, border: `1px solid ${Theme.Colors.lightGrey}`, borderRadius: 5, marginBottom: 100 }}>
						{
							_.map(this.state.docs, (value, index) => {
								let flag = doc_cip_tag2[index]
								return (
									<ListItem button onClick={() => this.props.changeStep(`2.${index + 1}`)} key={`document_${index}`} style={{ display: 'flex', padding: 20, border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1 }}>
										<div style={{ borderRadius: 12, height: 24, width: 24, background: Theme.Colors.primaryPurple, display: 'flex', justifyContent: 'center', alignItems: 'center', color: Theme.Colors.white, fontSize: 12, ...flag && { background: Theme.Colors.grassGreen, } }}>{index + 1}</div>
										<Text bold="true" style={{ color: Theme.Colors.primaryPurple, fontSize: 16, marginLeft: 10, ...flag && { color: Theme.Colors.grassGreen } }}>{value.title}</Text>
										<Text style={{ color: Theme.Colors.brownishGrey, marginTop: 3, marginLeft: 10, fontSize: 12 }}>{value.subTitle}</Text>
									</ListItem>
								)
							})
						}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color={'primary'}
							variant="outlined"
							onClick={() => this.props.changeStep(1)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
						</Button>
						<Button
							variant="contained"
							color={!actionCondition ? "default" : "primary"}
							onClick={actionCondition ? () => this.props.changeStep(2.9) : () => { }}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
						</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ['doc_cip_tag2'])
})

const mapDispatchToProps = dispatch => ({
	moveToConfirmDetails: (navigation) => dispatch(moveToConfirmDetails(navigation))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 800,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SubStep1Cip2ScanDocuments))