import utils from 'resources/utils'
import _ from 'lodash'
import { toggleLoader, toggleError, getAccountInfo, getAccountInfo_Function } from 'actions/actions'

export const CARD_DETAILS_RECEIVED = 'CARD_DETAILS_RECEIVED'
export const CARD_INFO_RECEIVED = 'CARD_INFO_RECEIVED'
export const US_CARD_ORDERED = 'US_CARD_ORDERED'

const requestCardInfo = () => (
	utils.request({
		url: `/banking/card`
	})
)

const requestCardPreferences = (cardInfo) => (
	utils.request({
		url: `/banking/card`,
		method: "PATCH",
		data: cardInfo
	})
)

const requestCardDetails = () => (
	utils.request({
		url: `${utils.get_proxy_url()}/banking/card/view`,
		method: "GET"
	})
)

const requestFirstTimeActivate = () => (
	utils.request({
		url: `/banking/card/firstTimeActivate`,
		method: "PATCH",
		data: {
			"status": "ACTIVE",
			"preferences": {
				"allow_foreign_transactions": true
			}
		}
	})
)

const requestLostCard = (formData) => (
	utils.request({
		url: `/banking/card`,
		method: "PUT",
		data: formData
	})
)

const requestOrderCard = (formData) => (
	utils.request({
		url: `/banking/issue-card-and-ship`,
		method: "POST",
		data: formData
	})
)

const requestChangePin = (pin) => (
	utils.request({
		url: '/banking/card/pin',
		method: "PATCH",
		data: {
			card_pin: pin,
			isRN: true
		}
	})
)

export const getMyCardPageDetails = () => (dispatch, getState) => {
	let state = getState().App

	dispatch(toggleLoader())

	Promise.all([
		_.isEmpty(state.accountInfo) ? dispatch(getAccountInfo_Function()) : Promise.resolve(),
	]).then(res => {
		state = getState()

		let accountInfo = state.App.accountInfo
		let userInfo = _.get(state, 'userInfo')

		if (
			!_.isEmpty(accountInfo) &&
			_.get(accountInfo, 'sign_up_status_2') === 'completed' &&
			accountInfo.CardFirstTimeActivated
		) {
			if (!(!accountInfo.isCardIssued && userInfo.cipTag === 3)) {
				dispatch(getCardInfo())
			}
		} else {
			dispatch(toggleLoader())
		}
	})
}

export const getCardInfo = () => dispatch => {

	return requestCardInfo().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(cardInfoReceived(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getCardDetails = () => dispatch => {
	dispatch(toggleLoader())
	requestCardDetails().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(cardDetailsReceived(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const updateCardPreferences = (cardInfo) => dispatch => {
	dispatch(toggleLoader())
	requestCardPreferences(cardInfo).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(cardPrefrencesUpdated(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const firstTimeActivate = (navigation) => dispatch => {
	dispatch(toggleLoader())
	requestFirstTimeActivate().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(getAccountInfo())
			dispatch(getCardDetails())
			navigation.push("/user/my-cards/north-loop-us-debit/set-pin")
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const submitLostCard = (formData, changeStep) => dispatch => {
	dispatch(toggleLoader())
	requestLostCard(formData).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}

			Promise.all([
				dispatch(getAccountInfo_Function()),
			]).then(res => {
				dispatch(toggleLoader())
				changeStep(3)
			})

		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const orderCard = (formData, changeStep) => dispatch => {
	dispatch(toggleLoader())
	requestOrderCard(formData).then(
		res => {
			if (res.status === 202) {
				console.log("Something went wrong", res)
				dispatch(toggleError(res.data.message))
				dispatch(toggleLoader())
				return
			}

			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			changeStep(2)
			dispatch(cardOrdered())
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const changePin = (pin, changeStep) => dispatch => {
	dispatch(toggleLoader())
	requestChangePin(pin).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			changeStep(2)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const cardOrdered = () => ({
	type: US_CARD_ORDERED
})

const cardPrefrencesUpdated = (cardInfo) => ({
	type: CARD_INFO_RECEIVED,
	cardInfo
})

const cardInfoReceived = (cardInfo) => ({
	type: CARD_INFO_RECEIVED,
	cardInfo
})

const cardDetailsReceived = (cardDetails) => ({
	type: CARD_DETAILS_RECEIVED,
	cardDetails
})
