import { CARD_DETAILS_RECEIVED, CARD_INFO_RECEIVED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";

const initialState = {
	cardDetails: {},
	cardInfo: {}
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case CARD_DETAILS_RECEIVED:
			return { ...state, cardDetails: action.cardDetails }
		case CARD_INFO_RECEIVED:
			return { ...state, cardInfo: action.cardInfo }
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}