import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { withStyles, Card, Button } from '@material-ui/core'
import loginBackground from 'images/login-background.png'
import logo from 'images/logo.png'
import Theme from 'resources/Theme'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { login } from 'actions/actions'

class Login extends Component {

	state = {
		formData: {
			username: "",
			password: ""
		},
		emailCondition: false,
		passwordCondition: false
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		const { formData } = this.state

		if (name === 'username') {
			let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			this.state.emailCondition = format.test(_.trim(value))
			formData[name] = _.trim(value)
		}

		if (name === 'password') {
			formData[name] = value
			this.state.passwordCondition = _.isEmpty(value) ? false : true
		}
		this.setState(this.state)
	}

	handleLogin = () => {
		if (!(this.state.emailCondition && this.state.passwordCondition)) {
			return
		}
		this.props.login(this.state.formData, this.props.history)
	}

	render() {
		const { classes } = this.props
		const { formData, emailCondition, passwordCondition } = this.state
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<div style={{ textAlign: 'center' }}>
						<img src={logo} style={{ width: 222, height: 60 }} />
					</div>
					<ValidatorForm onSubmit={this.handleLogin}>
						<TextValidator
							type="email"
							value={formData.username}
							onChange={this.handleChange}
							name="username"
							label="Email address"
							variant="outlined"
							style={{ marginTop: 40 }} fullWidth />
						<TextValidator
							type="password"
							value={formData.password}
							onChange={this.handleChange}
							name="password"
							label="Password"
							variant="outlined"
							style={{ marginTop: 40 }} fullWidth />
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30, marginBottom: 30 }}>
							<div>
								{/* <p>Remember Me</p> */}
							</div>
							<Link to="/forgot-password" style={{ color: Theme.Colors.uglyPurple }}>
								Forgot your password ?
							</Link>
						</div>
						<Button
							color={!(emailCondition && passwordCondition) ? 'default' : 'primary'}
							variant="contained"
							fullWidth
							type="submit"
							style={{ fontWeight: 700 }}>
							SIGN IN
						</Button>
					</ValidatorForm>
					<div style={{ marginTop: 40 }}>
						Need an account? <Link to="/sign-up" style={{ color: Theme.Colors.primaryPurple, fontWeight: 700, fontSize: 16 }}>Sign Up</Link>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
	login: (formData, history) => dispatch(login(formData, history))
})

const STYLES = {
	container: {
		position: 'absolute',
		top: 0, right: 0, left: 0, bottom: 0,
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 350,
		padding: 60,
		borderRadius: 10
	}
}
export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Login))