import utils from 'resources/utils'
import _ from 'lodash'
import { toggleLoader, toggleError, getAccountInfo_Function } from 'actions/actions'
import moment from 'moment'
import { getLinkedPlaidAccounts_Fn } from 'screens/MyProfile/actions'

export const TRANSACTIONS_RECEIVED = 'TRANSACTIONS_RECEIVED'
export const TRANSACTION_DETAIL_RECEIVED = 'TRANSACTION_DETAIL_RECEIVED'
export const PLAID_TRANSACTIONS_RECEIVED = 'PLAID_TRANSACTIONS_RECEIVED'

const requestTransactionHistory = (page) => (
	utils.request({
		url: `/banking/transactions?page=${page}&per_page=20`
	})
)

const requestTransactionDetail = (id) => (
	utils.request({
		url: `/banking/transactions/${id}`
	})
)

export const requestPlaidTransactionHistory = (account_id, offset, count = 20) => (
	utils.request({
		url: `/plaid/transaction/get`,
		method: "POST",
		data: {
			account_id: account_id,
			offset: offset,
			count: count,
			startDate: moment().subtract(2, 'years').format("YYYY-MM-DD"),
			endDate: moment().format("YYYY-MM-DD"),
		}
	})
)

export const getTransactionHistory = (page) => (dispatch, getState) => {
	if (page === 1)
		dispatch(toggleLoader())

	let app = getState().App
	Promise.all([
		_.isEmpty(app.accountInfo) ? dispatch(getAccountInfo_Function()) : Promise.resolve(),
		dispatch(getTransactionHistory_Fn(page))
	]).then(res => {
		dispatch(toggleLoader())
	})
}

const getTransactionHistory_Fn = (page) => dispatch => {
	return requestTransactionHistory(page).then(
		res => {
			dispatch(transactionsReceived(res.data.data.trans, page))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

export const getTransactionDetail = (transactionId) => (dispatch, getState) => {
	dispatch(toggleLoader())
	requestTransactionDetail(transactionId).then(
		res => {
			dispatch(transactionDetailReceived(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err, 'erroar'))
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getPlaidTransactionHistory = (account_id, offset) => (dispatch, getState) => {
	if (offset === 0)
		dispatch(toggleLoader())

	let state = getState().MyProfile

	Promise.all([
		(offset === 0 && _.isEmpty(state.plaidAccounts)) ? dispatch(getLinkedPlaidAccounts_Fn()) : Promise.resolve(),
		dispatch(getPlaidTransactionHistory_Fn(account_id, offset))
	]).then(res => {
		dispatch(toggleLoader())
	})
}

const getPlaidTransactionHistory_Fn = (account_id, offset) => dispatch => {
	return requestPlaidTransactionHistory(account_id, offset).then(
		res => {
			dispatch(plaidTransactionsReceived(res.data, offset))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
		}
	)
}

const transactionDetailReceived = (transactionDetail) => ({
	type: TRANSACTION_DETAIL_RECEIVED,
	transactionDetail
})

export const transactionsReceived = (transactions, page) => ({
	type: TRANSACTIONS_RECEIVED,
	transactions,
	page
})

export const plaidTransactionsReceived = (transactions, offset) => ({
	type: PLAID_TRANSACTIONS_RECEIVED,
	transactions,
	offset
})