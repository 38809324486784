import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { changePhoneNumber } from './actions';
import country from 'resources/CountryData/country'
import { Button } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import VerifyPhone from 'screens/Auth/VerifyPhone';
import Header from 'components/Header';

class ChangePhoneNumber extends Component {

	state = {
		search: "",
		formData: {
			countryCode: "+1",
			phoneNumber: ""
		},
		step: 1,
		actionCondition: false
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	handleChange = (name, value) => {
		const { formData } = this.state
		if (name === 'phoneNumber' && !_.isEmpty(value)) {
			value = Number(_.trim(value))
			if (_.isInteger(value))
				formData[name] = String(_.toInteger(value))
		} else {
			formData[name] = value
		}
		if (!_.isEmpty(formData.countryCode) && !_.isEmpty(formData.phoneNumber)) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	handleSubmit = () => {
		if (this.state.actionCondition) {
			this.props.changePhoneNumber(
				this.state.formData.countryCode + this.state.formData.phoneNumber,
				this.changeStep
			)
		}
	}

	render() {
		const { formData, actionCondition, step } = this.state
		return (
			<div>
				<Header name="Change Phone Number" onBack={true} />
				{
					step === 1 &&
					<div>
						<div>
							<ValidatorForm onSubmit={this.handleSubmit}>
								<div style={{ display: 'flex', marginTop: 50, width: 700, justifyContent: 'space-between', alignItems: 'flex-end' }}>
									<div style={{ width: '25%', border: `0px solid #CBCACA`, borderBottomWidth: 1, borderRadius: 3, height: 70, display: 'flex', flexDirection: 'column', alignItems: "flex-start", paddingLeft: 5 }}>
										<p style={{ fontSize: 10, color: Theme.Colors.purplishGrey }}>Country Code</p>
										<div style={{ display: 'flex' }}>
											<img src={country[0].flag} style={{ width: 40, height: 30, borderRadius: 5, marginTop: 8 }} />
											<p style={{ fontSize: 16, marginTop: 10, marginLeft: 15 }}>{formData.countryCode}</p>
										</div>
									</div>
									<TextValidator
										value={formData.phoneNumber}
										onChange={(e) => this.handleChange(e.target.name, e.target.value)}
										name="phoneNumber"
										type="number"
										label="Phone Number"
										placeholder="Enter your phone number"
										variant="outlined"
										style={{ width: '65%' }}
										fullWidth />
								</div>
								<Button variant="contained" color={!actionCondition ? "default" : 'primary'} style={STYLES.actionButton} type="submit">CONTINUE</Button>
							</ValidatorForm>
						</div>
					</div>
				}

				{
					step === 2 &&
					<VerifyPhone changeStep={this.changeStep} changePhoneNumber={true} />
				}

				{
					step == 3 &&
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
						<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
						<Text style={{ fontSize: 20, marginTop: 20 }}>Done</Text>
						<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, textAlign: 'center', marginTop: 20 }}>Successfully changed your phone number.</Text>
						<Button
							variant="contained"
							color="primary"
							style={STYLES.actionButton}
							onClick={() => this.props.history.push("/user/my-account")}>
							DONE
                	</Button>
					</div>
				}
			</div>
		)

	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	changePhoneNumber: (phone, changeStep) => dispatch(changePhoneNumber(phone, changeStep))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePhoneNumber)

const STYLES = {
	countryInputContainer: {
		width: '100%', height: 75, borderBottomColor: Theme.Colors.lightGrey, borderBottomWidth: 1
	},
	actionButton: {
		marginTop: 40
	}
}