import React, { Component } from 'react'
import Theme from 'resources/Theme';
import Text from './Text';
import utils from 'resources/utils';

export default class QaEnvHeader extends Component {
	render() {
		const env = utils.ENVIRONMENT

		if (env === 'Staging') {
			return (
				<div style={{ backgroundColor: 'orange', height: 30, position: "absolute", top: 0, zIndex: 100, width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
					<Text style={{ color: Theme.Colors.white }}>QA Build</Text>
				</div>
			)
		} else {
			return null
		}

	}
}
