import React, { Component } from 'react'
import {
	Card,
	CardContent
} from '@material-ui/core';
import Text from './Text';
import Theme from 'resources/Theme';
import { connect } from 'react-redux';
import _ from 'lodash'

class CurrentBalance extends Component {
	render() {
		return (
			<Card style={{
				width: 250,
				padding: 10,
				paddingBottom: 0,
				borderRadius: 10,
				marginTop: 20
			}}>
				<CardContent>
					<Text semi style={{ fontSize: 16, color: Theme.Colors.brownishGrey, letterSpacing: 0.57 }}>Current Balance</Text>
					<Text bold={true} style={{ fontSize: 36, color: Theme.Colors.primaryPurple, marginTop: 20 }}>$
						{this.props.balance || _.get(this.props, 'accountInfo.info.balance.amount') || "0.00"}
					</Text>
				</CardContent>
			</Card>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo'])
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CurrentBalance)