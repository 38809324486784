import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { Grid, TextField, withStyles, Button } from '@material-ui/core'

import Header from 'components/Header'
import Text from 'components/Text'
import Theme from "resources/Theme";
import { showAlert } from "actions/actions";

class Step3 extends Component {
	handleSubmit = () => {
		const { formData, state, handleSubmit } = this.props.parentProps;
		console.log(state.selectedTenure.lower_limit)
		console.log(formData.amount)

		if (formData.amount < state.selectedTenure.lower_limit) {
			this.props.showAlert("Alert", "Minimum amount for Fixed Deposit is ₹ 10,000");
			return;
		}

		if (formData.amount >= state.selectedTenure.upper_limit) {
			this.props.showAlert("Alert", "Fixed Deposit Amount has to less Rs 2 Crores");
			return;
		}

		handleSubmit();
	};



	render() {
		const {
			formData,
			handleChange,
			changeStep,
			state,
		} = this.props.parentProps;

		const formattedInitialValue = new Intl.NumberFormat('en-US').format(formData.amount);
		const formattedGainedValue = new Intl.NumberFormat('en-US').format((Number(formData.amount) * _.split(state.selectedRate, "%")[0]) / 100)
		const { classes } = this.props;
		return (
			<div>
				<Header name={`${formData.type} Fixed Deposit`} />
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Text style={STYLES.labelTitle}>
							Enter Amount
						</Text>
					</Grid>
					<Grid item xs={12} sm={12} container style={{ paddingTop: "2vh" }}>
						<Grid item xs={12} sm={6}>
							<TextField
								type="number"
								variant="outlined"
								value={formData.amount > 0 ? String(formData.amount) : ""}
								onChange={(e) => {
									let text = e.target.value;
									if (Number(text) > 20000000) {
										this.props.showAlert("Alert", "Amount has to less Rs 2 Crores");
									} else {
										handleChange("amount", Number(text));
									}
								}}
								InputProps={{
									className: formData.amount > 0 ? classes.multilineColor : classes.mulitlineColorGrey
								}}
								placeholder="0"
								style={{ borderBottomWidth: 0, width: "80%" }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} container>
							<Grid item xs={12} style={{ padding: "5px" }}>
								<ui>
									<li style={{ color: Theme.Colors.brownishGrey, letterSpacing: "0.47px", paddingTop: "5px" }}><span style={{
										color: Theme.Colors.black10, fontSize: "14px"
									}} >Minimum ₹10,000</span></li>
								</ui>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Text style={{ ...STYLES.labelTitle, paddingTop: "2vh" }}>
								FD Details
							</Text>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={STYLES.rectWhite}>
								<Grid container>
									<Grid item xs={12} sm={12} container>
										<Grid item xs={12} sm={3}>
											<Grid item xs={12} sm={12}>
												<Text style={STYLES.fdTitle}>Wealth Gained</Text>
												<Text style={{ ...STYLES.fdTitle, fontWeight: "bold", color: Theme.Colors.primaryPurple, }}>₹ {formattedGainedValue}</Text>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={3}>
											<Text style={STYLES.fdTitle}>Initial Deposit</Text>
											<Text style={{ ...STYLES.fdTitle, fontWeight: "bold" }}>₹ {formattedInitialValue}</Text>
										</Grid>
										<Grid item xs={12} sm={3}>
											<Text style={STYLES.fdTitle}>Interest Rate</Text>
											<Text style={{ ...STYLES.fdTitle, fontWeight: "bold" }}>{state.selectedRate}</Text>
										</Grid>
										<Grid item xs={12} sm={3}>
											<Text style={STYLES.fdTitle}>You'll get your money back on</Text>
											<Text style={{ ...STYLES.fdTitle, fontWeight: "bold" }}>
												{moment()
													.add(formData.tenure_in_days, "days")
													.add(state.terms.processing_time_in_days, "days")
													.format("LL")}
											</Text>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={12}>
										<Text style={{ fontSize: "12px", color: Theme.Colors.warmGreyTwo, marginTop: "2vh" }}>All funds are securely held by SBM Bank India, regulated by the Reserve Bank of India.</Text>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12} sm={12}>
							<div style={{ display: "flex", justifyContent: "center", alignItems: "space-around", margin: "5vh 0", paddingBottom: "5vh" }}>
								<Button style={STYLES.outlineBtn} onClick={() => changeStep(2)}>
									back
								</Button>
								<Button style={STYLES.primaryBtn} onClick={this.handleSubmit}>
									continue
								</Button>
							</div>
						</Grid>
					</Grid>

				</Grid>
			</div >
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	showAlert: (title, msg) => dispatch(showAlert(title, msg)),
});
const STYLES = {
	backBtn: {
		position: "absolute",
		left: 0,
		marginLeft: "3%",
		marginTop: 20,
	},
	actionButton: {
		position: "absolute",
		bottom: 30,
		width: "90%",
		marginLeft: "5%",
	},
	inputContainer: {
		borderColor: Theme.Colors.primaryPurple,
		borderWidth: 1,
		borderRadius: 5,
		marginTop: 10,
		display: "flex",
		flexDirection: "row",
		paddingVertical: 10,
		paddingHorizontal: 10,
	},
	dollar: {
		color: Theme.Colors.primaryPurple,
		fontSize: 24,
	},
	labelTitle: {
		fontFamily: 'Muli',
		fontSize: 18,
		fontWeight: 600,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 0.6,
		color: Theme.Colors.warmGreyTwo,
	},
	notchedOutline: {
		borderWidth: '1px',
		borderColor: 'green !important'
	},
	rectWhite: {
		padding: "32px",
		marginTop: "2vh",
		minHeight: "147px",
		border: 'solid 1px #f5f5f5',
		backgroundColor: Theme.Colors.white,
	},
	fdTitle: {
		marginTop: "1vh",
		fontSize: 14,
		lineHeight: 1.5,
		letterSpacing: 0.47,
	},
	outlineBtn: {
		width: 170,
		height: 45,
		borderRadius: 5,
		border: `solid 1px ${Theme.Colors.primaryPurple}`,
		color: Theme.Colors.primaryPurple,
		backgroundColor: Theme.Colors.white,
		textTransform: "uppercase",
		marginRight: "10px",
		fontWeight: 700
	},
	primaryBtn: {
		width: 170,
		height: 45,
		borderRadius: 5,
		backgroundColor: Theme.Colors.primaryPurple,
		color: Theme.Colors.white,
		textTransform: "uppercase",
		marginLeft: "10px",
		fontWeight: 700
	},
	multilineColor: {
		color: Theme.Colors.primaryPurple,
		caretColor: Theme.Colors.warmGrey,
		fontSize: 26,
	},
	mulitlineColorGrey: {
		color: Theme.Colors.warmGrey,
		caretColor: Theme.Colors.warmGrey,
		fontSize: 26,
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "yellow !important"
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Step3));


