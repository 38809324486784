import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from 'components/Text'
import Theme from 'resources/Theme'
import {
	withStyles,
	Card,
	CardContent,
	Button,
	Tabs,
	Tab
} from '@material-ui/core';
import _ from 'lodash'
import { getCashbackDetails, redeemCashback } from './actions'

class Cashback extends Component {

	state = {
		selectedTab: 0
	}

	componentDidMount() {
		// firebase.analytics().logEvent("cashback_view", {});
		// this.props.getCashbackDetails(this.props.navigation)
	}

	handleChange = (event, newValue) => {
		this.setState({
			selectedTab: newValue
		})
	}

	render() {
		const { classes, spendByCategories } = this.props
		const { selectedTab } = this.state
		return (
			<div>
				<Card style={{ width: 280, padding: 20, borderRadius: 10, marginTop: 20 }}>
					<CardContent style={{ display: 'flex', justifyContent: 'space-between', marginBottom: -10 }}>
						<div>
							<Text>Earned Cashback</Text>
							<Text bold="true" style={{ fontSize: 30, marginTop: 20 }}>$52.00</Text>
						</div>
						<div>
							<Button variant="outlined" color="primary" size="small" style={{ marginTop: 20, fontWeight: 'bold', borderWidth: 1, borderColor: Theme.Colors.primaryPurple }}>
								Redeem
							</Button>
						</div>
					</CardContent>
				</Card>
				<div style={{ marginTop: 45, marginBottom: 25 }}>
					<div style={{ border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1, display: 'flex', justifyContent: 'space-between' }}>
						<Tabs
							style={{ width: 390, height: 55 }}
							classes={{
								indicator: classes.indicator
							}}
							value={this.state.selectedTab}
							onChange={this.handleChange}
							indicatorColor="primary"
							textColor="primary"
							aria-label="full width tabs example">
							<Tab
								label="General"
								style={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: 18 }} />
							<Tab
								label="Campus cashback"
								style={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: 18 }} />
						</Tabs>
						<Text bold="true" style={{ color: Theme.Colors.primaryPurple, marginTop: 12 }}>Share your location to see special offers</Text>
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo']),
	..._.pick(state.Cashback, ['details', 'pollPlacesList']),
})

const mapDispatchToProps = dispatch => ({
	getCashbackDetails: (navigation) => dispatch(getCashbackDetails(navigation)),
	redeemCashback: () => dispatch(redeemCashback())
})

const STYLES = {
	indicator: {
		height: 3,
		borderRadius: 100,
		marginTop: 5
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Cashback))