import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import ContainedButton from 'components/ContainedButton'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import OutlinedButton from 'components/OutlinedButton'

export default class Step4 extends Component {

	render() {
		const { formData, changeStep, handleBack, handleChange } = this.props.parentProps

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>Net Worth</Text>
				<ValidatorForm onSubmit={() => { }}>
					{
						_.map([{
							label: "Annual Income", placeholder: "Enter annual income", name: "annualIncome"
						}, {
							label: "Your Liquid Assets (cash)", placeholder: "Enter your liquid assets (cash)", name: "networthLiquid"
						}, {
							label: "Your Illiquid assets", placeholder: "Enter your illiquid assets", name: "illiquidAssets"
						}, {
							label: "Your Liabilities (loans etc)", placeholder: "Enter your liabilities", name: "liabilities"
						}], (value, index) => {
							return (
								<TextValidator
									key={`income_${index}`}
									type="number"
									variant="outlined"
									fullWidth
									onChange={e => handleChange(value.name, Number(e.target.value))}
									value={String(formData[value.name])}
									style={STYLES.textInput}
									label={value.label}
									placeholder={value.placeholder}
								/>
							)
						})
					}

					<div style={{ width: 450, paddingTop: 50, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							style={{ width: 200 }}
							onClick={() => changeStep(5)}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	arrowBack: {
		marginLeft: -5
	},
	textInput: {
		marginTop: 40
	},
	actionButton: {
		position: "absolute", bottom: 30
	}
}