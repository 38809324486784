import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Text from 'components/Text'
import Theme from 'resources/Theme'
import Investing_Homepage from './Investing_Homepage'
import { toggleError } from 'actions/actions'
import { pay_fees } from './US_Investing/actions'
import constants from 'resources/constants'
import Investing_First_Time from './Investing_First_Time'
import { checkKyc } from './Mutual_Funds/actions'
import Header from 'components/Header'
import { Button } from '@material-ui/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import USI_Auth from "./US_Investing/Auth/USI_Create_Investor/USI_Auth";
import USI_Kyc from "./US_Investing/Auth/USI_Kyc";
import USI_Add_Funds from "./US_Investing/USI_Add_Funds";
import USI_Detailed_Page from "./US_Investing/USI_Detailed_Page";
import { get_investor_route_details } from './actions'
import MF_Create_Investor from './Mutual_Funds/Auth/MF_Create_Investor'
import MF_Detailed_Page from './Mutual_Funds/MF_Detailed_Page'
import MF_Buy_Sell from './Mutual_Funds/Buy/MF_Buy_Sell'
import MF_Sip from './Mutual_Funds/Buy/MF_Sip'

class Investing_Main_Route extends Component {

	render() {
		const { userInfo } = this.props

		return (
			<div>
				<Header invest_serach={_.get(userInfo, 'us_investing_status') === constants.us_investing_status.COMPLETED} />
				<Switch>
					<Route path="/user/invest" exact component={Investing_Redux} />
					<Route path="/user/invest/us" component={Investing_US_Route_Redux} />
					<Route path="/user/invest/in" component={Investing_IN_Route_Redux} />

					<Redirect to="/user/invest" />

				</Switch>
			</div>
		)
	}
}

const mapStateToProps_1 = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps_1 = dispatch => ({})

export default connect(mapStateToProps_1, mapDispatchToProps_1)(Investing_Main_Route)


//US Investing routes
class Investing_US_Route extends Component {

	state = {
		data_received: false
	}

	changeState = (state) => {
		this.setState(state)
	}

	componentDidMount() {
		this.props.get_investor_route_details(this.changeState)
	}

	render() {
		const { userInfo } = this.props

		if (!this.state.data_received) {
			return null
		}

		return (
			<div>
				<Switch>
					<Route path="/user/invest" exact component={Investing_Redux} />

					{
						_.get(userInfo, 'us_investing_status') === constants.us_investing_status.NO_ACCOUNT &&
						<Route path="/user/invest/us/signup" exact component={USI_Auth} />
					}

					{
						(
							_.get(userInfo, 'us_investing_status') === constants.us_investing_status.UPLOAD_KYC ||
							_.get(userInfo, 'us_investing_status') === constants.us_investing_status.PROCESSING ||
							_.get(userInfo, 'us_investing_status') === constants.us_investing_status.REJECTED
						) &&
						<Route path="/user/invest/us/kyc" exact component={USI_Kyc} />
					}

					{
						_.get(userInfo, 'us_investing_status') === constants.us_investing_status.COMPLETED &&
						<Route path="/user/invest/us/add-funds" exact component={USI_Add_Funds} />
					}

					{
						_.get(userInfo, 'us_investing_status') === constants.us_investing_status.COMPLETED &&
						<Route path="/user/invest/us/stock/details/:id" exact component={USI_Detailed_Page} />
					}

					{
						_.get(userInfo, 'us_investing_status') === constants.us_investing_status.COMPLETED &&
						<Route path="/user/invest/us/add-funds" exact component={USI_Add_Funds} />
					}

					{
						_.get(userInfo, 'mutual_fund_status') === constants.mutual_fund_status.CREATE_INVESTOR &&
						<Route path="/user/invest/in/signup" exact component={MF_Create_Investor} />
					}

					<Redirect to="/user/invest" />

				</Switch>
			</div>
		)
	}
}

const mapStateToProps_2 = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps_2 = dispatch => ({
	get_investor_route_details: (changeState) => dispatch(get_investor_route_details(changeState))
})

const Investing_US_Route_Redux = connect(mapStateToProps_2, mapDispatchToProps_2)(Investing_US_Route)


//IMF routes
class Investing_IN_Route extends Component {

	render() {
		const { userInfo } = this.props

		return (
			<div>
				<Switch>

					{
						_.get(userInfo, 'mutual_fund_status') === constants.mutual_fund_status.CREATE_INVESTOR &&
						<Route path="/user/invest/in/signup" exact component={MF_Create_Investor} />
					}

					{
						_.get(userInfo, 'mutual_fund_status') === constants.mutual_fund_status.CREATE_INVESTOR &&
						<Route path="/user/invest/in/signup" exact component={MF_Detailed_Page} />
					}

					{
						_.get(userInfo, 'mutual_fund_status') === constants.mutual_fund_status.CREATE_INVESTOR &&
						<Route path="/user/invest/in/signup" exact component={MF_Buy_Sell} />
					}

					{
						_.get(userInfo, 'mutual_fund_status') === constants.mutual_fund_status.CREATE_INVESTOR &&
						<Route path="/user/invest/in/signup" exact component={MF_Sip} />
					}

					<Redirect to="/user/invest" />

				</Switch>
			</div>
		)
	}
}

const mapStateToProps_3 = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps_3 = dispatch => ({})
const Investing_IN_Route_Redux = connect(mapStateToProps_3, mapDispatchToProps_3)(Investing_IN_Route)


//Homepage
class Investing extends Component {

	state = {
		investing_first_time_seen: 'ZX'
	}

	async componentDidMount() {
		const { userInfo } = this.props

		/* let mutual_fund_completed = userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED
		let us_investing_completed = userInfo.us_investing_status === constants.us_investing_status.COMPLETED

		let investing_first_time_seen = await utils._retrieveData("investing_first_time_seen")

		if ((mutual_fund_completed || us_investing_completed) && userInfo.risk_score !== null) {
			investing_first_time_seen = "true"
		}

		this.setState({ investing_first_time_seen }) */

		if (
			userInfo.us_investing_status === constants.us_investing_status.PAYMENT_REQUIRED ||
			userInfo.us_investing_status === constants.us_investing_status.PAYMENT_FAILED
		) {
			const script = document.createElement("script");

			script.src = "https://checkout.razorpay.com/v1/checkout.js";
			script.async = true;

			document.body.appendChild(script);
		}
	}

	changeState = (state) => {
		this.setState(state)
	}

	get_us_invest_onboard = (type = 1) => {
		const { userInfo } = this.props

		if (
			userInfo.us_investing_status === constants.us_investing_status.COMPLETED ||
			!_.includes(["AE", "IN"], userInfo.basic_information.country_of_residence)
		) {
			return null
		}

		let usi_onPress
		let usi_title

		switch (userInfo.us_investing_status) {
			case constants.us_investing_status.NO_ACCOUNT:
				usi_title = "Create Investor"
				usi_onPress = () => this.props.history.push("/user/invest/us/signup")
				break
			case constants.us_investing_status.UPLOAD_KYC:
				usi_title = "Upload Documents"
				usi_onPress = () => this.props.history.push("/user/invest/us/kyc")
				break
			case constants.us_investing_status.CUSTOMER_CARE_ONBOARDING:
				usi_title = "Pending"
				usi_onPress = () => this.props.toggleError("Our Customer Support Team will reach out to you.", "Alert")
				break

			case constants.us_investing_status.PAYMENT_REQUIRED:
				usi_title = "Pay Annual Fee"
				usi_onPress = this.props.pay_fees
				break
			case constants.us_investing_status.PAYMENT_FAILED:
				usi_title = "Payment failed. Please try again"
				usi_onPress = this.props.pay_fees
				break
			case constants.us_investing_status.PAYMENT_PROCESSING:
			case constants.us_investing_status.PAYMENT_AUTHORIZED:
				usi_title = "Payment processing"
				usi_onPress = () => this.props.toggleError("Your payment is in process. Please check after some time.", "Alert")
				break
			case constants.us_investing_status.PROCESSING:
				usi_title = "Your US trading account will be ready soon."
				usi_onPress = () => { }
				break
			case constants.us_investing_status.REJECTED:
				usi_title = "We weren't able to verify your ID. Please re-upload."
				usi_onPress = () => this.props.history.push("/user/invest/us/kyc")
				break
		}

		switch (type) {
			case 1:
				return (
					<div>
						<Text style={{ marginTop: 30, marginBottom: 10 }}>US Investing</Text>
						<div style={{ backgroundColor: Theme.Colors.white, borderRadius: 5 }}>
							<Button onClick={usi_onPress} style={{ width: '100%', padding: `15px 0` }}>
								<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple, paddingHorizontal: 20 }}>
									{usi_title}
								</Text>
							</Button>
						</div>
					</div>
				)
			case 2:
				return (
					<div onClick={usi_onPress} style={{ marginTop: 50, marginLeft: 20, width: 300, borderRadius: 8, height: 120, justifyContent: 'center', backgroundColor: Theme.Colors.white }}>
						<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>
							{usi_title}
						</Text>
					</div>
				)
		}
	}

	get_mf_onboard = (type = 1) => {
		const { userInfo } = this.props

		if (userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED) {
			return null
		}

		let mf_onPress
		let mf_title

		switch (userInfo.mutual_fund_status) {
			case constants.mutual_fund_status.CHECK_KYC:
				mf_title = "Check Kyc Status"
				mf_onPress = () => this.props.checkKyc(this.props.history)
				break
			case constants.mutual_fund_status.CREATE_INVESTOR:
				mf_title = "Create Investor"
				mf_onPress = () => this.props.history.push("/user/invest/in/signup")
				break
		}

		// mf_onPress = () => { }
		// mf_title = "Coming Soon"

		switch (type) {
			case 1:
				return (
					<div>
						<Text style={{ marginTop: 30, marginBottom: 10 }}>India Mutual Funds</Text>
						<div style={{ backgroundColor: Theme.Colors.white, borderRadius: 5 }}>
							<Button onClick={mf_onPress} style={{ width: '100%', padding: `15px 0` }}>
								<Text bold
									style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>
									{mf_title}
								</Text>
							</Button>
						</div>
					</div>
				)
			case 2:
				return (
					<div onClick={mf_onPress} style={{ marginTop: 50, marginLeft: 20, width: 300, borderRadius: 8, height: 120, justifyContent: 'center', backgroundColor: Theme.Colors.white }}>
						<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>
							{mf_title}
						</Text>
					</div>
				)
		}
	}

	render() {
		const { userInfo } = this.props

		if (this.state.investing_first_time_seen === undefined) {
			return null
		}

		let mutual_fund_completed = userInfo.mutual_fund_status === constants.mutual_fund_status.COMPLETED
		let us_investing_completed = userInfo.us_investing_status === constants.us_investing_status.COMPLETED

		return (
			<div>
				{
					(mutual_fund_completed || us_investing_completed) ?
						<Investing_Homepage
							history={this.props.history}
							get_us_invest_onboard={this.get_us_invest_onboard}
							get_mf_onboard={this.get_mf_onboard} />
						:
						(
							(
								userInfo.mutual_fund_status === constants.mutual_fund_status.CHECK_KYC &&
								userInfo.us_investing_status === constants.us_investing_status.NO_ACCOUNT &&
								this.state.investing_first_time_seen === null
							) ?
								<Investing_First_Time changeState={this.changeState} />
								:
								<div>
									{this.get_mf_onboard()}
									{this.get_us_invest_onboard()}
								</div>
						)
				}
			</div>
		)
	}
}

const mapStateToProps_4 = (state) => ({
	..._.pick(state.App, ['userInfo'])
})

const mapDispatchToProps_4 = dispatch => ({
	pay_fees: () => dispatch(pay_fees()),
	checkKyc: (navigation) => dispatch(checkKyc(navigation)),
	toggleError: (err) => dispatch(toggleError(err))
})

const Investing_Redux = connect(mapStateToProps_4, mapDispatchToProps_4)(Investing)