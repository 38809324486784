import React, { Component } from 'react'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { Typography } from '@material-ui/core';

export default class Text extends Component {
	render() {
		let fontWeight = 300

		if (this.props.semi) {
			fontWeight = 600
		}
		if (this.props.bold) {
			fontWeight = 700
		}

		const { style, tag, ...rest } = this.props

		return (
			<Typography component={tag || "p"} style={{ fontWeight: fontWeight, fontSize: 14, color: Theme.Colors.black, ...style || {} }} {...rest}>{this.props.children}</Typography>
		)
	}
}

