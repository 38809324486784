import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import { get_homepage_details } from './actions'
import utils from 'resources/utils'
import moment from 'moment'
import constants from 'resources/constants'
import { IconButton, ListItem } from '@material-ui/core'
import { Line } from 'react-chartjs-2';

class Investing_Homepage extends Component {

	state = {
		data: [],
		min: 0,
		max: 0,
		selectedRange: "1W",
	}

	componentWillMount() {
		this.props.get_homepage_details(this.changeGraphValues)
	}

	// componentWillReceiveProps = (nextProps) => {
	// 	if (_.isEmpty(this.props.investment_history) && !_.isEmpty(nextProps.investment_history)) {
	// 		this.changeGraphValues("1W")
	// 	}
	// }

	changeGraphValues = (range) => {
		let chart_data = this.props.investment_history.past_investment || []
		let data = []
		let date_before

		switch (range) {
			case "1W":
				date_before = moment().add(-1, 'week').unix()
				break
			case "1M":
				date_before = moment().add(-1, 'months').unix()
				break
			case "3M":
				date_before = moment().add(-3, 'months').unix()
				break
			case "1Y":
				date_before = moment().add(-1, 'year').unix()
				break
			case "5Y":
				date_before = moment().add(-5, 'year').unix()
				break
		}

		let min
		let max

		for (let i = 0; i < chart_data.length; i++) {
			let temp = chart_data[i][1]
			if (chart_data[i][0] >= date_before) {
				if (min === undefined && max === undefined) {
					min = temp
					max = temp
				} else {
					if (temp < min) {
						min = temp * 0.999
					}

					if (temp > max) {
						max = temp * 1.001
					}
				}
				data.push({
					x: chart_data[i][0],
					y: temp
				})
			}
		}

		if (min === undefined && max === undefined) {
			min = max = 0
		}

		this.setState({ data, min, max, selectedRange: range })
	}

	render() {
		const { data, selectedRange, min, max } = this.state
		const { market_summary, investment_history, userInfo } = this.props

		let color = Theme.Colors.purplishGrey
		let arrow

		let last_total_investment = _.get(investment_history, `past_investment[${_.get(investment_history, "past_investment", []).length - 1}][1]`, 0)
		let second_last_total_investment = _.get(investment_history, `past_investment[${_.get(investment_history, "past_investment", []).length - 2}][1]`, 0)

		if (last_total_investment > second_last_total_investment) {
			arrow = "arrow-circle-o-up"
			color = Theme.Colors.grassGreen
		}

		if (last_total_investment < second_last_total_investment) {
			arrow = "arrow-circle-o-down"
			color = Theme.Colors.coralRed
		}

		let diff = last_total_investment - second_last_total_investment
		let percent = (second_last_total_investment === 0 ? "0" : (diff / second_last_total_investment * 100).toFixed(2)) + "%"

		return (
			<div style={{ background: Theme.Colors.white, borderRadius: 5, marginBottom: 40, paddingBottom: 40 }}>
				<div style={{ padding: 40 }}>
					<div style={STYLES.headerContainer}>
						{
							userInfo.us_investing_status === constants.us_investing_status.COMPLETED &&
							<IconButton onClick={() => this.props.history.push("/user/invest/us/add-funds")}>
								<i className="material-icons">
									add
								</i>
							</IconButton>

						}
					</div>
					<div>
						<Text bold="true" style={STYLES.headline}>${utils.usd_comma_format(last_total_investment)}</Text>
						<div style={{ display: "flex", marginBottom: 15 }}>
							{
								arrow &&
								<i type="FontAwesome" name={arrow} style={{ fontSize: 18, color }}></i>
							}

							<Text style={{ color, marginLeft: arrow ? 10 : 0 }}>
								${utils.usd_comma_format(diff)}  ({percent})  {moment().format("MMM DD, YYYY")}
							</Text>
						</div>
					</div>
					{
						market_summary.summary &&

						<div style={{ display: "flex", marginTop: 10 }}>
							{
								_.map(market_summary.summary || [], (value, index) => {
									let color
									let arrow

									if (value.change.amt > 0) {
										arrow = "arrow-upward"
										color = Theme.Colors.grassGreen
									}

									if (value.change.amt < 0) {
										arrow = "arrow-downward"
										color = Theme.Colors.coralRed
									}

									if (value.change.amt === 0) {
										color = Theme.Colors.purplishGrey
									}

									return (
										<div
											style={{ borderRadius: 5, border: `1px solid ${Theme.Colors.lightGrey}`, display: "flex", width: 280, justifyContent: 'space-between', padding: `25px 20px`, backgroundColor: Theme.Colors.white, ...index !== 0 && { marginLeft: 20 } }}
											key={`market_${index}`}>
											<Text style={{ marginTop: 2 }}>{value.name}</Text>
											<Text bold="true" style={{ fontSize: 18, marginTop: -2, marginLeft: 15 }}>{value.price}</Text>
											<div style={{ display: "flex", marginTop: 2, marginLeft: 15 }}>
												{
													arrow &&
													<i type="MaterialIcons" name={arrow} style={{ fontSize: 16, marginTop: 2, color }}></i>
												}
												<Text style={{ color, marginLeft: 3 }}>{value.change.amt}</Text>
												<Text style={{ color, marginLeft: 5 }}>{value.change.percent}</Text>
											</div>
										</div>
									)
								})
							}
						</div>
					}

					<div style={{ backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.lightGrey, borderWidth: 1, marginTop: 20 }}>
						<Line
							options={{
								legend: { display: false },
								scales: {
									yAxes: [
										{
											gridLines: {
												display: false,
											},
										},
									],
								},
							}}
							data={{
								// labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
								datasets: [{
									fill: false,
									borderColor: 'rgba(75,192,192,1)',
									data: data
								}]
							}} />

						<div style={{ borderColor: Theme.Colors.lightGrey, borderTopWidth: 1, padding: 20, display: 'flex', justifyContent: 'space-between' }}>
							{
								_.map([{ range: "1W" }, { range: "1M" }, { range: "3M" }, { range: "1Y" }, { range: "5Y" }], (value, index) => {
									return (
										<ListItem
											button
											onClick={() => this.changeGraphValues(value.range)}
											key={`range${index}`}
											style={{
												...selectedRange === value.range && { backgroundColor: Theme.Colors.primaryPurple },
												width: 50, borderRadius: 5, padding: `8px 15px`
											}}>
											<Text style={{
												color: selectedRange === value.range ? Theme.Colors.white : Theme.Colors.black,
												fontSize: 12
											}}>{value.range}</Text>
										</ListItem>
									)
								})
							}
						</div>
					</div>

					{/* <div style={{ display: "flex" }}>
						<div
							onClick={() => this.props.navigation.navigate("MF_Set_Goal")}
							style={{ marginTop: 50, width: 300, borderRadius: 8, height: 120 }}>
							<div
								style={{ width: '100%', height: '100%', justifyContent: 'center', background: `url(${require("images/Investing/Mutual_Funds/set_goal.png")})` }}>
								<div style={{ display: 'flex', marginLeft: 30 }}>
									<Text bold="true"style={{ fontSize: 26 }}>Set Goal</Text>
									<i type="MaterialIcons" name="chevron-right" style={{ fontSize: 30, fontWeight: 800, marginTop: 4 }}></i>
								</div>
							</div>
						</div>

						{this.props.get_mf_onboard(2)}
						{this.props.get_us_invest_onboard(2)}
					</div> */}
				</div>

				{
					!_.isEmpty(market_summary.my_stocks_info) &&
					<div>
						<Text style={{ fontSize: 16, marginLeft: '5%', marginTop: 40, marginBottom: 15, color: Theme.Colors.greyishBrown }}>My Top Stocks</Text>
						<div style={{ backgroundColor: Theme.Colors.white }}>
							{
								_.map(market_summary.my_stocks_info, (value, index) => {
									let color = Theme.Colors.black

									if (value.change > 0) {
										color = Theme.Colors.grassGreen
									}

									if (value.change < 0) {
										color = Theme.Colors.coralRed
									}

									return (
										<ListItem
											button
											key={`item${index}`}
											onClick={() => this.props.history.push(`/user/invest/us/stock/details/${value.symbol}`)}
											style={{ display: 'flex', justifyContent: 'space-between', padding: "25px 5%", border: `0px solid ${Theme.Colors.whiteTwo}`, borderTopWidth: index === 0 ? 0 : 1 }}>
											<Text>{value.name}</Text>
											<Text style={{ color }}>${value.price}</Text>
										</ListItem>
									)
								})
							}
						</div>
					</div>
				}

				{
					market_summary.top_10_stocks && market_summary.my_stocks_info &&
					<div>
						<Text style={{ fontSize: 16, marginLeft: '5%', marginTop: 40, marginBottom: 15, color: Theme.Colors.greyishBrown }}>Popular Stocks</Text>
						<div style={{ backgroundColor: Theme.Colors.white }}>
							{
								_.map(_.filter(market_summary.top_10_stocks,
									(filterObj) => (market_summary.my_stocks_info)
										.every(someObj => filterObj.code !== someObj.symbol)), (value, index) => {
											let color = Theme.Colors.black

											if (value.change > 0) {
												color = Theme.Colors.grassGreen
											}

											if (value.change < 0) {
												color = Theme.Colors.coralRed
											}

											return (
												<ListItem
													button
													key={`item${index}`}
													onClick={() => this.props.history.push(`/user/invest/us/stock/details/${value.code}`)}
													style={{ display: 'flex', justifyContent: 'space-between', padding: "25px 5%", border: `0px solid ${Theme.Colors.whiteTwo}`, borderTopWidth: index === 0 ? 0 : 1 }}>
													<Text>{value.name}</Text>
													<Text style={{ color }}>${value.price}</Text>
												</ListItem>
											)
										})
							}
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo"]),
	..._.pick(state.Investing, ["market_summary", "investment_history"])
})

const mapDispatchToProps = dispatch => ({
	get_homepage_details: (changeGraphValues) => dispatch(get_homepage_details(changeGraphValues))
})

export default connect(mapStateToProps, mapDispatchToProps)(Investing_Homepage)

const STYLES = {
	headline: {
		fontSize: 25, marginBottom: 15
	},
	headerContainer: {
		marginRight: 5,
		display: "flex",
		justifyContent: 'flex-end',
	},
	menuImg: {
		height: 7, width: 15
	},
	arrowBack: {
		marginLeft: -5
	}
}