import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { updateUser } from '../actions'
import country from 'resources/CountryData/country'
import loginBackground from 'images/login-background.png'
import {
	withStyles,
	Card,
	Button,
	ListItem
} from '@material-ui/core'
import Text from 'components/Text';

class Step4Cip extends Component {

	state = {
		formData: {
			cip_tag: ""
		},
		help: false
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	handleChange = (name, value) => {
		this.state.formData[name] = Number(value)
		this.setState(this.state)
	}

	getButton = (value) => {
		const { classes } = this.props
		const { formData } = this.state

		return (
			<ListItem
				button
				onClick={() => this.setState({ formData: { cip_tag: value.cip_tag } })}
				className={classes.box}
				style={{
					backgroundColor: value.backgroundColor,
					...formData.cip_tag == value.cip_tag &&
					{ border: `1px solid ${Theme.Colors.primaryPurple}` }
				}}>
				<img src={value.image} style={{ width: '100%' }} />
				<div style={{ marginTop: 20, width: '90%', marginBottom: 20 }}>
					<Text bold="true" style={{ letterSpacing: 1.25, fontSize: 22 }}>{value.title}</Text>
					<Text style={{
						letterSpacing: 1.09, marginTop: 5,
					}}>{value.subtitle}</Text>
					<Text semi="true" style={{ marginTop: 15 }}>PICK THIS IF</Text>

					{
						_.map(value.conditions, (value, index) => {
							return (
								<div key={`item${index}`} style={{ display: 'flex', marginTop: 10 }}>
									<img src={require('images/Auth/hand.png')} style={{ width: 20, height: 12, marginRight: 10, marginTop: 4 }} />
									<Text key={`item${index}`} style={{ letterSpacing: 0.63, lineHeight: 1.8, }}>{value}</Text>
								</div>
							)
						})
					}

				</div>
			</ListItem>
		)
	}

	render() {
		const { classes, personalDetails } = this.props
		const { formData } = this.state

		let residence = _.find(country, { code: personalDetails.country_of_residence }).name
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Choose one</h1>
					<div style={{ marginTop: 30, }}>
						{
							personalDetails.country_of_residence === 'US' ?
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									{
										_.map([{
											title: "I'm a US resident", subtitle: "I have a social security number", cip_tag: "3", backgroundColor: '#fffbf9', image: require('images/Auth/chooseCip_pic1.png'), conditions: ["You’re a US citizen", "You’re a US resident with a SSN"]
										}, {
											title: "I'm an international student", subtitle: "I don't have a social security number", cip_tag: "2", backgroundColor: '#fdfcf8', image: require('images/Auth/chooseCip_pic2.png'), conditions: ["You are studying in the US", "You are going to study in the US this year"]
										}], (value, index) => {
											return (
												<React.Fragment key={`btn${index}`}>
													{this.getButton(value)}
												</React.Fragment>
											)
										})
									}

								</div>
								:
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									{
										_.map([{
											title: "I'm a student going to the US", subtitle: "I don't have a social security number", cip_tag: "2", backgroundColor: '#fdfcf8', image: require('images/Auth/chooseCip_pic2.png'), conditions: ["You are an international student going to study in the US soon!"]
										}, {
											title: `I'm a resident of ${residence}`, cip_tag: "-1", backgroundColor: '#fffbf9', image: require('images/Auth/chooseCip_pic1.png'), conditions: ["You are not planning on studying in the US, but want to transfer money abroad, invest and more!"]
										}], (value, index) => {
											return (
												<React.Fragment key={`btn${index}`}>
													{this.getButton(value)}
												</React.Fragment>
											)
										})
									}
								</div>
						}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color={'primary'}
							variant="outlined"
							onClick={() => this.props.changeStep(3)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
						</Button>
						<Button
							color={_.isEmpty(formData.cip_tag) ? 'default' : 'primary'}
							variant="contained"
							onClick={_.isEmpty(formData.cip_tag) ? () => { } : () => this.props.updateUser(formData.cip_tag, this.props.history)}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
						</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ["personalDetails"])
})

const mapDispatchToProps = dispatch => ({
	updateUser: (cip_tag, navigation) => dispatch(updateUser(cip_tag, navigation))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: '60%',
		padding: 60,
		borderRadius: 10
	},
	box: {
		display: 'flex',
		flexDirection: "column",
		borderRadius: 12,
		padding: 0,
		width: '45%',
		boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step4Cip))