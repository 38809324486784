import { TRANSACTIONS_RECEIVED, TRANSACTION_DETAIL_RECEIVED, PLAID_TRANSACTIONS_RECEIVED } from "./actions";
import _ from 'lodash'
import moment from 'moment'
import { LOGOUT_USER } from "actions/actions";

const initialState = {
	transactions: [],
	transactionsGrouped: [],
	transactionDetail: {}
}

export const Transaction = (state = { ...initialState }, action) => {

	switch (action.type) {
		case TRANSACTIONS_RECEIVED: {
			if (action.page === 1) {
				state.transactions = []
			}
			let transactions = []
			transactions = _.union(state.transactions, action.transactions)
			let transactionsGrouped = _.reverse(_.groupBy(transactions, (value) => {
				return moment(new Date(value.extra.created_on)).format("LL")
			}))
			return { ...state, transactions: transactions, transactionsGrouped: transactionsGrouped }
		}
		case TRANSACTION_DETAIL_RECEIVED: {
			return { ...state, transactionDetail: action.transactionDetail }
		}
		case PLAID_TRANSACTIONS_RECEIVED: {
			if (action.offset === 0) {
				state.transactions = []
			}
			let transactions = []
			transactions = _.union(state.transactions, action.transactions)
			let transactionsGrouped = _.reverse(_.groupBy(transactions, (value) => {
				return moment(value.date).format("LL")
			}))
			return { ...state, transactions: transactions, transactionsGrouped: transactionsGrouped }
		}
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}