export default [
	{
		"name": "United States",
		"dial_code": "+1",
		"code": "US",
		"flag": require(`resources/CountryData/CountryFlag/US.imageset/US.png`)
	},
	{
		"name": "India",
		"dial_code": "+91",
		"code": "IN",
		"flag": require(`resources/CountryData/CountryFlag/IN.imageset/IN.png`)
	},
	{
		"name": "China",
		"dial_code": "+86",
		"code": "CN",
		"flag": require(`resources/CountryData/CountryFlag/CN.imageset/CN.png`)
	},
	{
		"name": "Afghanistan",
		"dial_code": "+93",
		"code": "AF",
		"flag": require(`resources/CountryData/CountryFlag/AF.imageset/AF.png`)
	},
	{
		"name": "Aland Islands",
		"dial_code": "+358",
		"code": "AX",
		"flag": require(`resources/CountryData/CountryFlag/AX.imageset/AX.png`)
	},
	{
		"name": "Albania",
		"dial_code": "+355",
		"code": "AL",
		"flag": require(`resources/CountryData/CountryFlag/AL.imageset/AL.png`)
	},
	{
		"name": "Algeria",
		"dial_code": "+213",
		"code": "DZ",
		"flag": require(`resources/CountryData/CountryFlag/DZ.imageset/DZ.png`)
	},
	{
		"name": "Andorra",
		"dial_code": "+376",
		"code": "AD",
		"flag": require(`resources/CountryData/CountryFlag/AD.imageset/AD.png`)
	},
	{
		"name": "Angola",
		"dial_code": "+244",
		"code": "AO",
		"flag": require(`resources/CountryData/CountryFlag/AO.imageset/AO.png`)
	},
	{
		"name": "Anguilla",
		"dial_code": "+1264",
		"code": "AI",
		"flag": require(`resources/CountryData/CountryFlag/AI.imageset/AI.png`)
	},
	{
		"name": "Antigua and Barbuda",
		"dial_code": "+1268",
		"code": "AG",
		"flag": require(`resources/CountryData/CountryFlag/AG.imageset/AG.png`)
	},
	{
		"name": "Argentina",
		"dial_code": "+54",
		"code": "AR",
		"flag": require(`resources/CountryData/CountryFlag/AR.imageset/AR.png`)
	},
	{
		"name": "Armenia",
		"dial_code": "+374",
		"code": "AM",
		"flag": require(`resources/CountryData/CountryFlag/AM.imageset/AM.png`)
	},
	{
		"name": "Aruba",
		"dial_code": "+297",
		"code": "AW",
		"flag": require(`resources/CountryData/CountryFlag/AW.imageset/AW.png`)
	},
	{
		"name": "Australia",
		"dial_code": "+61",
		"code": "AU",
		"flag": require(`resources/CountryData/CountryFlag/AU.imageset/AU.png`)
	},
	{
		"name": "Austria",
		"dial_code": "+43",
		"code": "AT",
		"flag": require(`resources/CountryData/CountryFlag/AT.imageset/AT.png`)
	},
	{
		"name": "Azerbaijan",
		"dial_code": "+994",
		"code": "AZ",
		"flag": require(`resources/CountryData/CountryFlag/AZ.imageset/AZ.png`)
	},
	{
		"name": "Bahamas",
		"dial_code": "+1242",
		"code": "BS",
		"flag": require(`resources/CountryData/CountryFlag/BS.imageset/BS.png`)
	},
	{
		"name": "Bahrain",
		"dial_code": "+973",
		"code": "BH",
		"flag": require(`resources/CountryData/CountryFlag/BH.imageset/BH.png`)
	},
	{
		"name": "Bangladesh",
		"dial_code": "+880",
		"code": "BD",
		"flag": require(`resources/CountryData/CountryFlag/BD.imageset/BD.png`)
	},
	{
		"name": "Barbados",
		"dial_code": "+1246",
		"code": "BB",
		"flag": require(`resources/CountryData/CountryFlag/BB.imageset/BB.png`)
	},
	{
		"name": "Belgium",
		"dial_code": "+32",
		"code": "BE",
		"flag": require(`resources/CountryData/CountryFlag/BE.imageset/BE.png`)
	},
	{
		"name": "Belize",
		"dial_code": "+501",
		"code": "BZ",
		"flag": require(`resources/CountryData/CountryFlag/BZ.imageset/BZ.png`)
	},
	{
		"name": "Benin",
		"dial_code": "+229",
		"code": "BJ",
		"flag": require(`resources/CountryData/CountryFlag/BJ.imageset/BJ.png`)
	},
	{
		"name": "Bermuda",
		"dial_code": "+1441",
		"code": "BM",
		"flag": require(`resources/CountryData/CountryFlag/BM.imageset/BM.png`)
	},
	{
		"name": "Bhutan",
		"dial_code": "+975",
		"code": "BT",
		"flag": require(`resources/CountryData/CountryFlag/BT.imageset/BT.png`)
	},
	{
		"name": "Bolivia, Plurinational State of",
		"dial_code": "+591",
		"code": "BO",
		"flag": require(`resources/CountryData/CountryFlag/BO.imageset/BO.png`)
	},
	{
		"name": "Bosnia and Herzegovina",
		"dial_code": "+387",
		"code": "BA",
		"flag": require(`resources/CountryData/CountryFlag/BA.imageset/BA.png`)
	},
	{
		"name": "Botswana",
		"dial_code": "+267",
		"code": "BW",
		"flag": require(`resources/CountryData/CountryFlag/BW.imageset/BW.png`)
	},
	{
		"name": "Brazil",
		"dial_code": "+55",
		"code": "BR",
		"flag": require(`resources/CountryData/CountryFlag/BR.imageset/BR.png`)
	},
	{
		"name": "British Indian Ocean Territory",
		"dial_code": "+246",
		"code": "IO",
		"flag": require(`resources/CountryData/CountryFlag/IO.imageset/IO.png`)
	},
	{
		"name": "Brunei Darussalam",
		"dial_code": "+673",
		"code": "BN",
		"flag": require(`resources/CountryData/CountryFlag/BN.imageset/BN.png`)
	},
	{
		"name": "Bulgaria",
		"dial_code": "+359",
		"code": "BG",
		"flag": require(`resources/CountryData/CountryFlag/BG.imageset/BG.png`)
	},
	{
		"name": "Burkina Faso",
		"dial_code": "+226",
		"code": "BF",
		"flag": require(`resources/CountryData/CountryFlag/BF.imageset/BF.png`)
	},
	{
		"name": "Cambodia",
		"dial_code": "+855",
		"code": "KH",
		"flag": require(`resources/CountryData/CountryFlag/KH.imageset/KH.png`)
	},
	{
		"name": "Cameroon",
		"dial_code": "+237",
		"code": "CM",
		"flag": require(`resources/CountryData/CountryFlag/CM.imageset/CM.png`)
	},
	{
		"name": "Canada",
		"dial_code": "+1",
		"code": "CA",
		"flag": require(`resources/CountryData/CountryFlag/CA.imageset/CA.png`)
	},
	{
		"name": "Cape Verde",
		"dial_code": "+238",
		"code": "CV",
		"flag": require(`resources/CountryData/CountryFlag/CV.imageset/CV.png`)
	},
	{
		"name": "Cayman Islands",
		"dial_code": "+ 345",
		"code": "KY",
		"flag": require(`resources/CountryData/CountryFlag/KY.imageset/KY.png`)
	},
	{
		"name": "Chad",
		"dial_code": "+235",
		"code": "TD",
		"flag": require(`resources/CountryData/CountryFlag/TD.imageset/TD.png`)
	},
	{
		"name": "Chile",
		"dial_code": "+56",
		"code": "CL",
		"flag": require(`resources/CountryData/CountryFlag/CL.imageset/CL.png`)
	},
	{
		"name": "Christmas Island",
		"dial_code": "+61",
		"code": "CX",
		"flag": require(`resources/CountryData/CountryFlag/CX.imageset/CX.png`)
	},
	{
		"name": "Cocos (Keeling) Islands",
		"dial_code": "+61",
		"code": "CC",
		"flag": require(`resources/CountryData/CountryFlag/CC.imageset/CC.png`)
	},
	{
		"name": "Colombia",
		"dial_code": "+57",
		"code": "CO",
		"flag": require(`resources/CountryData/CountryFlag/CO.imageset/CO.png`)
	},
	{
		"name": "Comoros",
		"dial_code": "+269",
		"code": "KM",
		"flag": require(`resources/CountryData/CountryFlag/KM.imageset/KM.png`)
	},
	{
		"name": "Cook Islands",
		"dial_code": "+682",
		"code": "CK",
		"flag": require(`resources/CountryData/CountryFlag/CK.imageset/CK.png`)
	},
	{
		"name": "Costa Rica",
		"dial_code": "+506",
		"code": "CR",
		"flag": require(`resources/CountryData/CountryFlag/CR.imageset/CR.png`)
	},
	{
		"name": "Cote d'Ivoire",
		"dial_code": "+225",
		"code": "CI",
		"flag": require(`resources/CountryData/CountryFlag/CI.imageset/CI.png`)
	},
	{
		"name": "Croatia",
		"dial_code": "+385",
		"code": "HR",
		"flag": require(`resources/CountryData/CountryFlag/HR.imageset/HR.png`)
	},
	{
		"name": "Cyprus",
		"dial_code": "+357",
		"code": "CY",
		"flag": require(`resources/CountryData/CountryFlag/CY.imageset/CY.png`)
	},
	{
		"name": "Czech Republic",
		"dial_code": "+420",
		"code": "CZ",
		"flag": require(`resources/CountryData/CountryFlag/CZ.imageset/CZ.png`)
	},
	{
		"name": "Denmark",
		"dial_code": "+45",
		"code": "DK",
		"flag": require(`resources/CountryData/CountryFlag/DK.imageset/DK.png`)
	},
	{
		"name": "Djibouti",
		"dial_code": "+253",
		"code": "DJ",
		"flag": require(`resources/CountryData/CountryFlag/DJ.imageset/DJ.png`)
	},
	{
		"name": "Dominica",
		"dial_code": "+1767",
		"code": "DM",
		"flag": require(`resources/CountryData/CountryFlag/DM.imageset/DM.png`)
	},
	{
		"name": "Dominican Republic",
		"dial_code": "+1849",
		"code": "DO",
		"flag": require(`resources/CountryData/CountryFlag/DO.imageset/DO.png`)
	},
	{
		"name": "Ecuador",
		"dial_code": "+593",
		"code": "EC",
		"flag": require(`resources/CountryData/CountryFlag/EC.imageset/EC.png`)
	},
	{
		"name": "Egypt",
		"dial_code": "+20",
		"code": "EG",
		"flag": require(`resources/CountryData/CountryFlag/EG.imageset/EG.png`)
	},
	{
		"name": "El Salvador",
		"dial_code": "+503",
		"code": "SV",
		"flag": require(`resources/CountryData/CountryFlag/SV.imageset/SV.png`)
	},
	{
		"name": "Equatorial Guinea",
		"dial_code": "+240",
		"code": "GQ",
		"flag": require(`resources/CountryData/CountryFlag/GQ.imageset/GQ.png`)
	},
	{
		"name": "Eritrea",
		"dial_code": "+291",
		"code": "ER",
		"flag": require(`resources/CountryData/CountryFlag/ER.imageset/ER.png`)
	},
	{
		"name": "Estonia",
		"dial_code": "+372",
		"code": "EE",
		"flag": require(`resources/CountryData/CountryFlag/EE.imageset/EE.png`)
	},
	{
		"name": "Ethiopia",
		"dial_code": "+251",
		"code": "ET",
		"flag": require(`resources/CountryData/CountryFlag/ET.imageset/ET.png`)
	},
	{
		"name": "Falkland Islands (Malvinas)",
		"dial_code": "+500",
		"code": "FK",
		"flag": require(`resources/CountryData/CountryFlag/FK.imageset/FK.png`)
	},
	{
		"name": "Faroe Islands",
		"dial_code": "+298",
		"code": "FO",
		"flag": require(`resources/CountryData/CountryFlag/FO.imageset/FO.png`)
	},
	{
		"name": "Fiji",
		"dial_code": "+679",
		"code": "FJ",
		"flag": require(`resources/CountryData/CountryFlag/FJ.imageset/FJ.png`)
	},
	{
		"name": "Finland",
		"dial_code": "+358",
		"code": "FI",
		"flag": require(`resources/CountryData/CountryFlag/FI.imageset/FI.png`)
	},
	{
		"name": "France",
		"dial_code": "+33",
		"code": "FR",
		"flag": require(`resources/CountryData/CountryFlag/FR.imageset/FR.png`)
	},
	{
		"name": "French Guiana",
		"dial_code": "+594",
		"code": "GF",
		"flag": require(`resources/CountryData/CountryFlag/GF.imageset/GF.png`)
	},
	{
		"name": "French Polynesia",
		"dial_code": "+689",
		"code": "PF",
		"flag": require(`resources/CountryData/CountryFlag/PF.imageset/PF.png`)
	},
	{
		"name": "Gabon",
		"dial_code": "+241",
		"code": "GA",
		"flag": require(`resources/CountryData/CountryFlag/GA.imageset/GA.png`)
	},
	{
		"name": "Gambia",
		"dial_code": "+220",
		"code": "GM",
		"flag": require(`resources/CountryData/CountryFlag/GM.imageset/GM.png`)
	},
	{
		"name": "Georgia",
		"dial_code": "+995",
		"code": "GE",
		"flag": require(`resources/CountryData/CountryFlag/GE.imageset/GE.png`)
	},
	{
		"name": "Germany",
		"dial_code": "+49",
		"code": "DE",
		"flag": require(`resources/CountryData/CountryFlag/DE.imageset/DE.png`)
	},
	{
		"name": "Ghana",
		"dial_code": "+233",
		"code": "GH",
		"flag": require(`resources/CountryData/CountryFlag/GH.imageset/GH.png`)
	},
	{
		"name": "Gibraltar",
		"dial_code": "+350",
		"code": "GI",
		"flag": require(`resources/CountryData/CountryFlag/GI.imageset/GI.png`)
	},
	{
		"name": "Greece",
		"dial_code": "+30",
		"code": "GR",
		"flag": require(`resources/CountryData/CountryFlag/GR.imageset/GR.png`)
	},
	{
		"name": "Greenland",
		"dial_code": "+299",
		"code": "GL",
		"flag": require(`resources/CountryData/CountryFlag/GL.imageset/GL.png`)
	},
	{
		"name": "Grenada",
		"dial_code": "+1473",
		"code": "GD",
		"flag": require(`resources/CountryData/CountryFlag/GD.imageset/GD.png`)
	},
	{
		"name": "Guadeloupe",
		"dial_code": "+590",
		"code": "GP",
		"flag": require(`resources/CountryData/CountryFlag/GP.imageset/GP.png`)
	},
	{
		"name": "Guam",
		"dial_code": "+1671",
		"code": "GU",
		"flag": require(`resources/CountryData/CountryFlag/GU.imageset/GU.png`)
	},
	{
		"name": "Guatemala",
		"dial_code": "+502",
		"code": "GT",
		"flag": require(`resources/CountryData/CountryFlag/GT.imageset/GT.png`)
	},
	{
		"name": "Guernsey",
		"dial_code": "+44",
		"code": "GG",
		"flag": require(`resources/CountryData/CountryFlag/GG.imageset/GG.png`)
	},
	{
		"name": "Guinea",
		"dial_code": "+224",
		"code": "GN",
		"flag": require(`resources/CountryData/CountryFlag/GN.imageset/GN.png`)
	},
	{
		"name": "Guinea-Bissau",
		"dial_code": "+245",
		"code": "GW",
		"flag": require(`resources/CountryData/CountryFlag/GW.imageset/GW.png`)
	},
	{
		"name": "Guyana",
		"dial_code": "+595",
		"code": "GY",
		"flag": require(`resources/CountryData/CountryFlag/GY.imageset/GY.png`)
	},
	{
		"name": "Haiti",
		"dial_code": "+509",
		"code": "HT",
		"flag": require(`resources/CountryData/CountryFlag/HT.imageset/HT.png`)
	},
	{
		"name": "Holy See (Vatican City State)",
		"dial_code": "+379",
		"code": "VA",
		"flag": require(`resources/CountryData/CountryFlag/VA.imageset/VA.png`)
	},
	{
		"name": "Honduras",
		"dial_code": "+504",
		"code": "HN",
		"flag": require(`resources/CountryData/CountryFlag/HN.imageset/HN.png`)
	},
	{
		"name": "Hong Kong",
		"dial_code": "+852",
		"code": "HK",
		"flag": require(`resources/CountryData/CountryFlag/HK.imageset/HK.png`)
	},
	{
		"name": "Hungary",
		"dial_code": "+36",
		"code": "HU",
		"flag": require(`resources/CountryData/CountryFlag/HU.imageset/HU.png`)
	},
	{
		"name": "Iceland",
		"dial_code": "+354",
		"code": "IS",
		"flag": require(`resources/CountryData/CountryFlag/IS.imageset/IS.png`)
	},
	{
		"name": "Indonesia",
		"dial_code": "+62",
		"code": "ID",
		"flag": require(`resources/CountryData/CountryFlag/ID.imageset/ID.png`)
	},
	{
		"name": "Iraq",
		"dial_code": "+964",
		"code": "IQ",
		"flag": require(`resources/CountryData/CountryFlag/IQ.imageset/IQ.png`)
	},
	{
		"name": "Ireland",
		"dial_code": "+353",
		"code": "IE",
		"flag": require(`resources/CountryData/CountryFlag/IE.imageset/IE.png`)
	},
	{
		"name": "Isle of Man",
		"dial_code": "+44",
		"code": "IM",
		"flag": require(`resources/CountryData/CountryFlag/IM.imageset/IM.png`)
	},
	{
		"name": "Israel",
		"dial_code": "+972",
		"code": "IL",
		"flag": require(`resources/CountryData/CountryFlag/IL.imageset/IL.png`)
	},
	{
		"name": "Italy",
		"dial_code": "+39",
		"code": "IT",
		"flag": require(`resources/CountryData/CountryFlag/IT.imageset/IT.png`)
	},
	{
		"name": "Jamaica",
		"dial_code": "+1876",
		"code": "JM",
		"flag": require(`resources/CountryData/CountryFlag/JM.imageset/JM.png`)
	},
	{
		"name": "Japan",
		"dial_code": "+81",
		"code": "JP",
		"flag": require(`resources/CountryData/CountryFlag/JP.imageset/JP.png`)
	},
	{
		"name": "Jersey",
		"dial_code": "+44",
		"code": "JE",
		"flag": require(`resources/CountryData/CountryFlag/JE.imageset/JE.png`)
	},
	{
		"name": "Jordan",
		"dial_code": "+962",
		"code": "JO",
		"flag": require(`resources/CountryData/CountryFlag/JO.imageset/JO.png`)
	},
	{
		"name": "Kazakhstan",
		"dial_code": "+77",
		"code": "KZ",
		"flag": require(`resources/CountryData/CountryFlag/KZ.imageset/KZ.png`)
	},
	{
		"name": "Kenya",
		"dial_code": "+254",
		"code": "KE",
		"flag": require(`resources/CountryData/CountryFlag/KE.imageset/KE.png`)
	},
	{
		"name": "Kiribati",
		"dial_code": "+686",
		"code": "KI",
		"flag": require(`resources/CountryData/CountryFlag/KI.imageset/KI.png`)
	},
	{
		"name": "Korea, Republic of South Korea",
		"dial_code": "+82",
		"code": "KR",
		"flag": require(`resources/CountryData/CountryFlag/KR.imageset/KR.png`)
	},
	{
		"name": "Kuwait",
		"dial_code": "+965",
		"code": "KW",
		"flag": require(`resources/CountryData/CountryFlag/KW.imageset/KW.png`)
	},
	{
		"name": "Kyrgyzstan",
		"dial_code": "+996",
		"code": "KG",
		"flag": require(`resources/CountryData/CountryFlag/KG.imageset/KG.png`)
	},
	{
		"name": "Laos",
		"dial_code": "+856",
		"code": "LA",
		"flag": require(`resources/CountryData/CountryFlag/LA.imageset/LA.png`)
	},
	{
		"name": "Latvia",
		"dial_code": "+371",
		"code": "LV",
		"flag": require(`resources/CountryData/CountryFlag/LV.imageset/LV.png`)
	},
	{
		"name": "Lebanon",
		"dial_code": "+961",
		"code": "LB",
		"flag": require(`resources/CountryData/CountryFlag/LB.imageset/LB.png`)
	},
	{
		"name": "Lesotho",
		"dial_code": "+266",
		"code": "LS",
		"flag": require(`resources/CountryData/CountryFlag/LS.imageset/LS.png`)
	},
	{
		"name": "Liberia",
		"dial_code": "+231",
		"code": "LR",
		"flag": require(`resources/CountryData/CountryFlag/LR.imageset/LR.png`)
	},
	{
		"name": "Liechtenstein",
		"dial_code": "+423",
		"code": "LI",
		"flag": require(`resources/CountryData/CountryFlag/LI.imageset/LI.png`)
	},
	{
		"name": "Lithuania",
		"dial_code": "+370",
		"code": "LT",
		"flag": require(`resources/CountryData/CountryFlag/LT.imageset/LT.png`)
	},
	{
		"name": "Luxembourg",
		"dial_code": "+352",
		"code": "LU",
		"flag": require(`resources/CountryData/CountryFlag/LU.imageset/LU.png`)
	},
	{
		"name": "Macao",
		"dial_code": "+853",
		"code": "MO",
		"flag": require(`resources/CountryData/CountryFlag/MO.imageset/MO.png`)
	},
	{
		"name": "Macedonia",
		"dial_code": "+389",
		"code": "MK",
		"flag": require(`resources/CountryData/CountryFlag/MK.imageset/MK.png`)
	},
	{
		"name": "Madagascar",
		"dial_code": "+261",
		"code": "MG",
		"flag": require(`resources/CountryData/CountryFlag/MG.imageset/MG.png`)
	},
	{
		"name": "Malawi",
		"dial_code": "+265",
		"code": "MW",
		"flag": require(`resources/CountryData/CountryFlag/MW.imageset/MW.png`)
	},
	{
		"name": "Malaysia",
		"dial_code": "+60",
		"code": "MY",
		"flag": require(`resources/CountryData/CountryFlag/MY.imageset/MY.png`)
	},
	{
		"name": "Maldives",
		"dial_code": "+960",
		"code": "MV",
		"flag": require(`resources/CountryData/CountryFlag/MV.imageset/MV.png`)
	},
	{
		"name": "Mali",
		"dial_code": "+223",
		"code": "ML",
		"flag": require(`resources/CountryData/CountryFlag/ML.imageset/ML.png`)
	},
	{
		"name": "Malta",
		"dial_code": "+356",
		"code": "MT",
		"flag": require(`resources/CountryData/CountryFlag/MT.imageset/MT.png`)
	},
	{
		"name": "Marshall Islands",
		"dial_code": "+692",
		"code": "MH",
		"flag": require(`resources/CountryData/CountryFlag/MH.imageset/MH.png`)
	},
	{
		"name": "Martinique",
		"dial_code": "+596",
		"code": "MQ",
		"flag": require(`resources/CountryData/CountryFlag/MQ.imageset/MQ.png`)
	},
	{
		"name": "Mauritania",
		"dial_code": "+222",
		"code": "MR",
		"flag": require(`resources/CountryData/CountryFlag/MR.imageset/MR.png`)
	},
	{
		"name": "Mauritius",
		"dial_code": "+230",
		"code": "MU",
		"flag": require(`resources/CountryData/CountryFlag/MU.imageset/MU.png`)
	},
	{
		"name": "Mayotte",
		"dial_code": "+262",
		"code": "YT",
		"flag": require(`resources/CountryData/CountryFlag/YT.imageset/YT.png`)
	},
	{
		"name": "Mexico",
		"dial_code": "+52",
		"code": "MX",
		"flag": require(`resources/CountryData/CountryFlag/MX.imageset/MX.png`)
	},
	{
		"name": "Micronesia, Federated States of Micronesia",
		"dial_code": "+691",
		"code": "FM",
		"flag": require(`resources/CountryData/CountryFlag/FM.imageset/FM.png`)
	},
	{
		"name": "Moldova",
		"dial_code": "+373",
		"code": "MD",
		"flag": require(`resources/CountryData/CountryFlag/MD.imageset/MD.png`)
	},
	{
		"name": "Monaco",
		"dial_code": "+377",
		"code": "MC",
		"flag": require(`resources/CountryData/CountryFlag/MC.imageset/MC.png`)
	},
	{
		"name": "Mongolia",
		"dial_code": "+976",
		"code": "MN",
		"flag": require(`resources/CountryData/CountryFlag/MN.imageset/MN.png`)
	},
	{
		"name": "Montenegro",
		"dial_code": "+382",
		"code": "ME",
		"flag": require(`resources/CountryData/CountryFlag/ME.imageset/ME.png`)
	},
	{
		"name": "Montserrat",
		"dial_code": "+1664",
		"code": "MS",
		"flag": require(`resources/CountryData/CountryFlag/MS.imageset/MS.png`)
	},
	{
		"name": "Morocco",
		"dial_code": "+212",
		"code": "MA",
		"flag": require(`resources/CountryData/CountryFlag/MA.imageset/MA.png`)
	},
	{
		"name": "Mozambique",
		"dial_code": "+258",
		"code": "MZ",
		"flag": require(`resources/CountryData/CountryFlag/MZ.imageset/MZ.png`)
	},
	{
		"name": "Myanmar",
		"dial_code": "+95",
		"code": "MM",
		"flag": require(`resources/CountryData/CountryFlag/MM.imageset/MM.png`)
	},
	{
		"name": "Namibia",
		"dial_code": "+264",
		"code": "NA",
		"flag": require(`resources/CountryData/CountryFlag/NA.imageset/NA.png`)
	},
	{
		"name": "Nauru",
		"dial_code": "+674",
		"code": "NR",
		"flag": require(`resources/CountryData/CountryFlag/NR.imageset/NR.png`)
	},
	{
		"name": "Nepal",
		"dial_code": "+977",
		"code": "NP",
		"flag": require(`resources/CountryData/CountryFlag/NP.imageset/NP.png`)
	},
	{
		"name": "Netherlands",
		"dial_code": "+31",
		"code": "NL",
		"flag": require(`resources/CountryData/CountryFlag/NL.imageset/NL.png`)
	},
	{
		"name": "Netherlands Antilles",
		"dial_code": "+599",
		"code": "AN",
		"flag": require(`resources/CountryData/CountryFlag/AN.imageset/AN.png`)
	},
	{
		"name": "New Caledonia",
		"dial_code": "+687",
		"code": "NC",
		"flag": require(`resources/CountryData/CountryFlag/NC.imageset/NC.png`)
	},
	{
		"name": "New Zealand",
		"dial_code": "+64",
		"code": "NZ",
		"flag": require(`resources/CountryData/CountryFlag/NZ.imageset/NZ.png`)
	},
	{
		"name": "Niger",
		"dial_code": "+227",
		"code": "NE",
		"flag": require(`resources/CountryData/CountryFlag/NE.imageset/NE.png`)
	},
	{
		"name": "Nigeria",
		"dial_code": "+234",
		"code": "NG",
		"flag": require(`resources/CountryData/CountryFlag/NG.imageset/NG.png`)
	},
	{
		"name": "Niue",
		"dial_code": "+683",
		"code": "NU",
		"flag": require(`resources/CountryData/CountryFlag/NU.imageset/NU.png`)
	},
	{
		"name": "Norfolk Island",
		"dial_code": "+672",
		"code": "NF",
		"flag": require(`resources/CountryData/CountryFlag/NF.imageset/NF.png`)
	},
	{
		"name": "Northern Mariana Islands",
		"dial_code": "+1670",
		"code": "MP",
		"flag": require(`resources/CountryData/CountryFlag/MP.imageset/MP.png`)
	},
	{
		"name": "Norway",
		"dial_code": "+47",
		"code": "NO",
		"flag": require(`resources/CountryData/CountryFlag/NO.imageset/NO.png`)
	},
	{
		"name": "Oman",
		"dial_code": "+968",
		"code": "OM",
		"flag": require(`resources/CountryData/CountryFlag/OM.imageset/OM.png`)
	},
	{
		"name": "Pakistan",
		"dial_code": "+92",
		"code": "PK",
		"flag": require(`resources/CountryData/CountryFlag/PK.imageset/PK.png`)
	},
	{
		"name": "Palau",
		"dial_code": "+680",
		"code": "PW",
		"flag": require(`resources/CountryData/CountryFlag/PW.imageset/PW.png`)
	},
	{
		"name": "Panama",
		"dial_code": "+507",
		"code": "PA",
		"flag": require(`resources/CountryData/CountryFlag/PA.imageset/PA.png`)
	},
	{
		"name": "Papua New Guinea",
		"dial_code": "+675",
		"code": "PG",
		"flag": require(`resources/CountryData/CountryFlag/PG.imageset/PG.png`)
	},
	{
		"name": "Paraguay",
		"dial_code": "+595",
		"code": "PY",
		"flag": require(`resources/CountryData/CountryFlag/PY.imageset/PY.png`)
	},
	{
		"name": "Peru",
		"dial_code": "+51",
		"code": "PE",
		"flag": require(`resources/CountryData/CountryFlag/PE.imageset/PE.png`)
	},
	{
		"name": "Philippines",
		"dial_code": "+63",
		"code": "PH",
		"flag": require(`resources/CountryData/CountryFlag/PH.imageset/PH.png`)
	},
	{
		"name": "Pitcairn",
		"dial_code": "+872",
		"code": "PN",
		"flag": require(`resources/CountryData/CountryFlag/PN.imageset/PN.png`)
	},
	{
		"name": "Poland",
		"dial_code": "+48",
		"code": "PL",
		"flag": require(`resources/CountryData/CountryFlag/PL.imageset/PL.png`)
	},
	{
		"name": "Portugal",
		"dial_code": "+351",
		"code": "PT",
		"flag": require(`resources/CountryData/CountryFlag/PT.imageset/PT.png`)
	},
	{
		"name": "Puerto Rico",
		"dial_code": "+1939",
		"code": "PR",
		"flag": require(`resources/CountryData/CountryFlag/PR.imageset/PR.png`)
	},
	{
		"name": "Qatar",
		"dial_code": "+974",
		"code": "QA",
		"flag": require(`resources/CountryData/CountryFlag/QA.imageset/QA.png`)
	},
	{
		"name": "Romania",
		"dial_code": "+40",
		"code": "RO",
		"flag": require(`resources/CountryData/CountryFlag/RO.imageset/RO.png`)
	},
	{
		"name": "Russia",
		"dial_code": "+7",
		"code": "RU",
		"flag": require(`resources/CountryData/CountryFlag/RU.imageset/RU.png`)
	},
	{
		"name": "Rwanda",
		"dial_code": "+250",
		"code": "RW",
		"flag": require(`resources/CountryData/CountryFlag/RW.imageset/RW.png`)
	},
	{
		"name": "Reunion",
		"dial_code": "+262",
		"code": "RE",
		"flag": require(`resources/CountryData/CountryFlag/RE.imageset/RE.png`)
	},
	{
		"name": "Saint Barthelemy",
		"dial_code": "+590",
		"code": "BL",
		"flag": require(`resources/CountryData/CountryFlag/BL.imageset/BL.png`)
	},
	{
		"name": "Saint Helena, Ascension and Tristan Da Cunha",
		"dial_code": "+290",
		"code": "SH",
		"flag": require(`resources/CountryData/CountryFlag/SH.imageset/SH.png`)
	},
	{
		"name": "Saint Kitts and Nevis",
		"dial_code": "+1869",
		"code": "KN",
		"flag": require(`resources/CountryData/CountryFlag/KN.imageset/KN.png`)
	},
	{
		"name": "Saint Lucia",
		"dial_code": "+1758",
		"code": "LC",
		"flag": require(`resources/CountryData/CountryFlag/LC.imageset/LC.png`)
	},
	{
		"name": "Saint Martin",
		"dial_code": "+590",
		"code": "MF",
		"flag": require(`resources/CountryData/CountryFlag/MF.imageset/MF.png`)
	},
	{
		"name": "Saint Pierre and Miquelon",
		"dial_code": "+508",
		"code": "PM",
		"flag": require(`resources/CountryData/CountryFlag/PM.imageset/PM.png`)
	},
	{
		"name": "Saint Vincent and the Grenadines",
		"dial_code": "+1784",
		"code": "VC",
		"flag": require(`resources/CountryData/CountryFlag/VC.imageset/VC.png`)
	},
	{
		"name": "Samoa",
		"dial_code": "+685",
		"code": "WS",
		"flag": require(`resources/CountryData/CountryFlag/WS.imageset/WS.png`)
	},
	{
		"name": "San Marino",
		"dial_code": "+378",
		"code": "SM",
		"flag": require(`resources/CountryData/CountryFlag/SM.imageset/SM.png`)
	},
	{
		"name": "Sao Tome and Principe",
		"dial_code": "+239",
		"code": "ST",
		"flag": require(`resources/CountryData/CountryFlag/ST.imageset/ST.png`)
	},
	{
		"name": "Saudi Arabia",
		"dial_code": "+966",
		"code": "SA",
		"flag": require(`resources/CountryData/CountryFlag/SA.imageset/SA.png`)
	},
	{
		"name": "Senegal",
		"dial_code": "+221",
		"code": "SN",
		"flag": require(`resources/CountryData/CountryFlag/SN.imageset/SN.png`)
	},
	{
		"name": "Serbia",
		"dial_code": "+381",
		"code": "RS",
		"flag": require(`resources/CountryData/CountryFlag/RS.imageset/RS.png`)
	},
	{
		"name": "Seychelles",
		"dial_code": "+248",
		"code": "SC",
		"flag": require(`resources/CountryData/CountryFlag/SC.imageset/SC.png`)
	},
	{
		"name": "Sierra Leone",
		"dial_code": "+232",
		"code": "SL",
		"flag": require(`resources/CountryData/CountryFlag/SL.imageset/SL.png`)
	},
	{
		"name": "Singapore",
		"dial_code": "+65",
		"code": "SG",
		"flag": require(`resources/CountryData/CountryFlag/SG.imageset/SG.png`)
	},
	{
		"name": "Slovakia",
		"dial_code": "+421",
		"code": "SK",
		"flag": require(`resources/CountryData/CountryFlag/SK.imageset/SK.png`)
	},
	{
		"name": "Slovenia",
		"dial_code": "+386",
		"code": "SI",
		"flag": require(`resources/CountryData/CountryFlag/SI.imageset/SI.png`)
	},
	{
		"name": "Solomon Islands",
		"dial_code": "+677",
		"code": "SB",
		"flag": require(`resources/CountryData/CountryFlag/SB.imageset/SB.png`)
	},
	{
		"name": "South Africa",
		"dial_code": "+27",
		"code": "ZA",
		"flag": require(`resources/CountryData/CountryFlag/ZA.imageset/ZA.png`)
	},
	{
		"name": "South Georgia and the South Sandwich Islands",
		"dial_code": "+500",
		"code": "GS",
		"flag": require(`resources/CountryData/CountryFlag/GS.imageset/GS.png`)
	},
	{
		"name": "Spain",
		"dial_code": "+34",
		"code": "ES",
		"flag": require(`resources/CountryData/CountryFlag/ES.imageset/ES.png`)
	},
	{
		"name": "Sri Lanka",
		"dial_code": "+94",
		"code": "LK",
		"flag": require(`resources/CountryData/CountryFlag/LK.imageset/LK.png`)
	},
	{
		"name": "Suriname",
		"dial_code": "+597",
		"code": "SR",
		"flag": require(`resources/CountryData/CountryFlag/SR.imageset/SR.png`)
	},
	{
		"name": "Svalbard and Jan Mayen",
		"dial_code": "+47",
		"code": "SJ",
		"flag": require(`resources/CountryData/CountryFlag/SJ.imageset/SJ.png`)
	},
	{
		"name": "Swaziland",
		"dial_code": "+268",
		"code": "SZ",
		"flag": require(`resources/CountryData/CountryFlag/SZ.imageset/SZ.png`)
	},
	{
		"name": "Sweden",
		"dial_code": "+46",
		"code": "SE",
		"flag": require(`resources/CountryData/CountryFlag/SE.imageset/SE.png`)
	},
	{
		"name": "Switzerland",
		"dial_code": "+41",
		"code": "CH",
		"flag": require(`resources/CountryData/CountryFlag/CH.imageset/CH.png`)
	},
	{
		"name": "Taiwan",
		"dial_code": "+886",
		"code": "TW",
		"flag": require(`resources/CountryData/CountryFlag/TW.imageset/TW.png`)
	},
	{
		"name": "Tajikistan",
		"dial_code": "+992",
		"code": "TJ",
		"flag": require(`resources/CountryData/CountryFlag/TJ.imageset/TJ.png`)
	},
	{
		"name": "Tanzania, United Republic of Tanzania",
		"dial_code": "+255",
		"code": "TZ",
		"flag": require(`resources/CountryData/CountryFlag/TZ.imageset/TZ.png`)
	},
	{
		"name": "Thailand",
		"dial_code": "+66",
		"code": "TH",
		"flag": require(`resources/CountryData/CountryFlag/TH.imageset/TH.png`)
	},
	{
		"name": "Timor-Leste",
		"dial_code": "+670",
		"code": "TL",
		"flag": require(`resources/CountryData/CountryFlag/TL.imageset/TL.png`)
	},
	{
		"name": "Togo",
		"dial_code": "+228",
		"code": "TG",
		"flag": require(`resources/CountryData/CountryFlag/TG.imageset/TG.png`)
	},
	{
		"name": "Tokelau",
		"dial_code": "+690",
		"code": "TK",
		"flag": require(`resources/CountryData/CountryFlag/TK.imageset/TK.png`)
	},
	{
		"name": "Tonga",
		"dial_code": "+676",
		"code": "TO",
		"flag": require(`resources/CountryData/CountryFlag/TO.imageset/TO.png`)
	},
	{
		"name": "Trinidad and Tobago",
		"dial_code": "+1868",
		"code": "TT",
		"flag": require(`resources/CountryData/CountryFlag/TT.imageset/TT.png`)
	},
	{
		"name": "Tunisia",
		"dial_code": "+216",
		"code": "TN",
		"flag": require(`resources/CountryData/CountryFlag/TN.imageset/TN.png`)
	},
	{
		"name": "Turkey",
		"dial_code": "+90",
		"code": "TR",
		"flag": require(`resources/CountryData/CountryFlag/TR.imageset/TR.png`)
	},
	{
		"name": "Turkmenistan",
		"dial_code": "+993",
		"code": "TM",
		"flag": require(`resources/CountryData/CountryFlag/TM.imageset/TM.png`)
	},
	{
		"name": "Turks and Caicos Islands",
		"dial_code": "+1649",
		"code": "TC",
		"flag": require(`resources/CountryData/CountryFlag/TC.imageset/TC.png`)
	},
	{
		"name": "Tuvalu",
		"dial_code": "+688",
		"code": "TV",
		"flag": require(`resources/CountryData/CountryFlag/TV.imageset/TV.png`)
	},
	{
		"name": "Uganda",
		"dial_code": "+256",
		"code": "UG",
		"flag": require(`resources/CountryData/CountryFlag/UG.imageset/UG.png`)
	},
	{
		"name": "Ukraine",
		"dial_code": "+380",
		"code": "UA",
		"flag": require(`resources/CountryData/CountryFlag/UA.imageset/UA.png`)
	},
	{
		"name": "United Arab Emirates",
		"dial_code": "+971",
		"code": "AE",
		"flag": require(`resources/CountryData/CountryFlag/AE.imageset/AE.png`)
	},
	{
		"name": "United Kingdom",
		"dial_code": "+44",
		"code": "GB",
		"flag": require(`resources/CountryData/CountryFlag/GB.imageset/GB.png`)
	},
	{
		"name": "Uruguay",
		"dial_code": "+598",
		"code": "UY",
		"flag": require(`resources/CountryData/CountryFlag/UY.imageset/UY.png`)
	},
	{
		"name": "Uzbekistan",
		"dial_code": "+998",
		"code": "UZ",
		"flag": require(`resources/CountryData/CountryFlag/UZ.imageset/UZ.png`)
	},
	{
		"name": "Vanuatu",
		"dial_code": "+678",
		"code": "VU",
		"flag": require(`resources/CountryData/CountryFlag/VU.imageset/VU.png`)
	},
	{
		"name": "Venezuela, Bolivarian Republic of Venezuela",
		"dial_code": "+58",
		"code": "VE",
		"flag": require(`resources/CountryData/CountryFlag/VE.imageset/VE.png`)
	},
	{
		"name": "Vietnam",
		"dial_code": "+84",
		"code": "VN",
		"flag": require(`resources/CountryData/CountryFlag/VN.imageset/VN.png`)
	},
	{
		"name": "Virgin Islands, British",
		"dial_code": "+1284",
		"code": "VG",
		"flag": require(`resources/CountryData/CountryFlag/VG.imageset/VG.png`)
	},
	{
		"name": "Virgin Islands, U.S.",
		"dial_code": "+1340",
		"code": "VI",
		"flag": require(`resources/CountryData/CountryFlag/VI.imageset/VI.png`)
	},
	{
		"name": "Wallis and Futuna",
		"dial_code": "+681",
		"code": "WF",
		"flag": require(`resources/CountryData/CountryFlag/WF.imageset/WF.png`)
	},
	{
		"name": "Zambia",
		"dial_code": "+260",
		"code": "ZM",
		"flag": require(`resources/CountryData/CountryFlag/ZM.imageset/ZM.png`)
	}
]