import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withStyles, ListItem, Button } from '@material-ui/core'
import Text from "components/Text";
import Theme from "resources/Theme";

class NRI_Banking_Section extends Component {
	render() {
		const { fd } = this.props;
		return (
			<div>
				<div style={{ marginTop: 20 }}>
					<div style={STYLES.rowFlex}>
						<Text semi="true" style={{ fontSize: 16 }}>My Fixed Deposits</Text>
					</div>
					<div style={STYLES.box}>
						{!_.isEmpty(fd.fds) &&
							_.map(fd.fds, (value, index) => {
								return (
									<div key={`item${index}`} style={STYLES.itemContainer}>
										<ListItem button
											onClick={() =>
												this.props.history.push({
													pathname: "/user/india-bank/fd-detail",
													state: {
														fd: value,
														payment_address: fd.paymentAddress,
													}
												})
											}
											style={{
												width: "100%",
												padding: 25,
												display: "flex",
												flexDirection: "row",
												border: `0px solid ${Theme.Colors.lightGrey}`,
												borderBottomWidth: 1
											}}
										>
											<div style={STYLES.imageContainer}>
												<img
													src={require("images/NRI_Banking/fd.png")}
													style={{
														width: 50,
														height: 50,
													}}
												/>
											</div>
											<div style={{ marginLeft: 20 }}>
												<Text
													style={{
														fontSize: 12,
														color: Theme.Colors.warmGreyTwo,
													}}
												>
													{value.tenure_text} FD
                        						</Text>
												<Text bold="true" style={{ fontSize: 24 }}>
													₹{value.amount}
												</Text>
											</div>
											<span className="material-icons" style={STYLES.iconRight}>
												chevron_right
                      						</span>
										</ListItem>
									</div>
								);
							})}
						<div>
							<Button onClick={() => this.props.history.push("/user/india-bank/create-fd")} style={{ width: "100%", padding: `15px 0px` }}>
								<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>GET A NEW FD</Text>
							</Button>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo"]),
	..._.pick(state.NRI_Banking, ["fd"]),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NRI_Banking_Section);

const STYLES = {
	box: {
		marginTop: 10,
		backgroundColor: Theme.Colors.white,
		elevation: 3,
		borderRadius: 5,
		justifyContent: "space-between",
	},
	rowFlex: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	itemContainer: {
		borderBottomWidth: 1,
		borderColor: Theme.Colors.lightGrey,
	},
	imageContainer: {
		width: 50,
		height: 50,
		borderRadius: 25,
		alignItems: "center",
		justifyContent: "center",
	},
	iconRight: {
		fontSize: 40,
		color: Theme.Colors.warmGrey,
		position: "absolute",
		right: 10
	},
	button: {
		fontSize: 18,
		textAlign: "center",
		color: Theme.Colors.primaryPurple,
	},
	accountsContainer: {
		backgroundColor: Theme.Colors.white, borderRadius: 5, display: 'flex', flexDirection: 'column'
	},
	account: {
		width: '100%', padding: 20, display: 'flex'
	},
	imageContainer: {
		width: 50, height: 50, borderRadius: 25, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'
	},
	rightIcon: {
		fontSize: 40, color: Theme.Colors.warmGrey, position: 'absolute', right: 10
	}
};
