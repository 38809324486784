import React, { Component } from 'react'
import Theme from 'resources/Theme'
import Text from './Text'
import { ListItem } from '@material-ui/core'
import _ from 'lodash'
import { FileDrop } from 'react-file-drop'
export default class Upload extends Component {

	handleChange = (e) => {
		let files = _.get(e, 'target.files') || e;
		let allFiles = [];
		let extension = ''
		_.each(files, file => {
			let reader = new FileReader();
			reader.onload = event => {
				extension = _.split(file.name, '.')
				extension = extension[extension.length - 1]
				let fileInfo = {
					name: file.name,
					type: file.type,
					size: Math.round(file.size / 1000),
					base64: reader.result,
					file: file,
					extension: extension
				};
				allFiles.push(fileInfo);
				if (allFiles.length === files.length) {
					console.log('All files', allFiles);
					if (allFiles[0].extension === 'jpg' || allFiles[0].extension === 'png' || allFiles[0].extension === 'jpeg') {
						if (_.isFunction(this.props.addImage))
							this.props.addImage(allFiles[0].base64);
					} else {
						alert("File should be of jpg, jpeg or png format")
					}
				}
			}
			reader.readAsDataURL(file);
		});
		console.log('File INput', files);

	}

	render() {
		let id = _.uniqueId()

		let style = this.props.style
		let image = this.props.image
		let label = this.props.label

		return (
			<FileDrop onDrop={this.handleChange}>
				<ListItem button
					onClick={() => document.getElementById(`input${id}`).click()}
					style={{
						width: '100%', border: `1px dashed ${Theme.Colors.warmGrey}`, borderRadius: 5, display: 'flex', paddingTop: 20, paddingBottom: 20, ...style
					}}>

					{
						_.isEmpty(image) ?
							<img src={require("images/Auth/upload.png")} style={{ width: 115, height: 100, marginLeft: '3%' }} />
							:
							<img src={image} style={{ width: 115, height: 100, marginLeft: '3%' }} />
					}
					<div style={{ marginLeft: '3%', height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Text bold={true}>{label}</Text>
						<Text style={{ marginTop: 10 }}>Drag and drop your image here or <span style={{ color: Theme.Colors.primaryPurple, fontWeight: 'bold' }}>Browse here</span> </Text>
					</div>
					<input
						type="file"
						id={`input${id}`}
						onChange={this.handleChange}
						// multiple={this.props.multiple}
						accept={".jpg,.jpeg,.png"}
						style={{ display: 'none' }}
					/>
				</ListItem>
			</FileDrop>
		)
	}
}