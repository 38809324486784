import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	withStyles,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import Text from 'components/Text';
import Theme from 'resources/Theme';
import { getTransactionHistory, transactionsReceived, getPlaidTransactionHistory } from './actions';
import _ from 'lodash'
import { toggleLoader } from 'actions/actions';
import moment from 'moment'
import DetailedTransaction from './DetailedTransaction';
import { withRouter } from 'react-router-dom';
import CurrentBalance from 'components/CurrentBalance';
import Header from 'components/Header';

const StyledTableCell = withStyles(theme => ({
	head: {
		backgroundColor: Theme.Colors.grey2,
		color: Theme.Colors.brownishGrey,
		lineHeight: '1rem'
	},
	body: {
		fontSize: 14,
		backgroundColor: Theme.Colors.white,
	},
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
}))(TableRow);

let loading = false

class Transactions extends Component {

	state = {
		page: 1,
		plaidOffset: 0,
		detailed: "",
	}

	componentDidMount() {
		let account_id = _.get(this.props, "match.params.account_id")
		let plaid = _.split(account_id, "-")[0] === 'linked'

		if (plaid) {
			account_id = _.split(account_id, "-")[1]
			this.props.getPlaidTransactionHistory(account_id, this.state.plaidOffset)
		} else if (account_id == "north-loop") {
			this.props.getTransactionHistory(this.state.page)
		} else {
			this.props.history.push("/user/my-profile")
		}
		window.addEventListener('scroll', this.handleScroll);
	}

	loadMoreTransactions = () => {
		this.props.toggleLoader()
		let account_id = _.get(this.props, "match.params.account_id")
		let plaid = _.split(account_id, "-")[0] === 'linked'

		if (plaid) {
			this.state.plaidOffset = this.state.plaidOffset + 20
		} else {
			this.state.page = this.state.page + 1
		}

		this.setState(this.state, () => {
			if (plaid) {
				account_id = _.split(account_id, "-")[1]
				this.props.getPlaidTransactionHistory(account_id, this.state.plaidOffset)
			} else {
				this.props.getTransactionHistory(this.state.page)
			}
			setTimeout(() => {
				loading = false
			}, 2000)
		})

	}

	handleScroll = (e) => {
		let scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
		let yOffset = window.pageYOffset
		if (yOffset > scrollHeight - 10) {
			if (!loading) {
				loading = true
				this.loadMoreTransactions()
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		this.props.transactionsReceived([], 1)
	}

	render() {
		const { classes, transactions, plaidAccounts } = this.props
		const { detailed } = this.state

		let account_id = _.get(this.props, "match.params.account_id")
		let plaid = _.split(account_id, "-")[0] === 'linked'
		let plaidAccount

		if (plaid) {
			account_id = _.split(account_id, "-")[1]
			plaidAccount = _.find(plaidAccounts, { account_id: account_id })
		}

		return (
			<div>
				<Header name="Transactions" rightBar={!_.isEmpty(detailed) && 650} />
				<h1>{plaid ? _.get(plaidAccount, "institution_name") : "North Loop"}</h1>
				<CurrentBalance balance={plaid && (_.get(plaidAccount, "balances.available") || _.get(plaidAccount, "balances.current"))} />
				<div style={{ display: 'flex' }}>
					<div style={{ width: _.isEmpty(detailed) ? 1000 : 650 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 45, marginBottom: 25 }}>
							<Text semi="true" style={{ fontSize: 16, letterSpacing: 0.6, color: Theme.Colors.brownishGrey }}>Transaction History</Text>
							<Text></Text>
						</div>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell>Merchant name</StyledTableCell>
									<StyledTableCell>Time and date</StyledTableCell>
									{
										!plaid &&
										<StyledTableCell>Status</StyledTableCell>
									}
									<StyledTableCell>Amount</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(transactions || [], (value, index) => {
									let strike = false
									let status = (_.get(value, 'recent_status.status') || "").toLowerCase()
									let color = (value.isIncoming || (value.amount || 0) < 0) ? Theme.Colors.grassGreen : Theme.Colors.purplishGrey
									if (status === 'returned' || status === 'canceled') {
										strike = true
									}

									let name = _.truncate(_.get(value, "categorization.name") || value.name, { length: 25 })
									let icon = _.get(value, "categorization.icon") || _.get(value, "icon")
									let amount = _.get(value, "amount.amount") || value.amount || 0
									if (amount < 0) {
										amount *= -1
									}
									amount = amount.toFixed(2)
									let createdOn = moment(_.get(value, "extra.created_on") || value.date || "")

									return (
										<StyledTableRow key={`item${index}`} onClick={
											plaid ? () => { } : () => this.setState({ detailed: value._id })
										} className={classes.bodyRow}>
											<StyledTableCell>
												<div style={{ display: 'flex' }}>
													<img src={icon} style={{ width: 35, borderRadius: 18 }} />
													<Text style={{ marginLeft: 10, marginTop: 5 }}>{name}</Text>
												</div>
											</StyledTableCell>
											<StyledTableCell>
												{!plaid && createdOn.format("HH:mm")} {createdOn.format("dddd")}, {createdOn.format("Do MMMM, YYYY")}
											</StyledTableCell>
											{
												!plaid &&
												<StyledTableCell>{_.capitalize(status)}</StyledTableCell>
											}

											<StyledTableCell>
												<Text style={{ fontSize: 12, color: color, marginTop: 7, letterSpacing: 0.4, ...strike && { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } }}>
													${amount}
												</Text>
											</StyledTableCell>
										</StyledTableRow>
									)
								})}
							</TableBody>
						</Table>
					</div>
					<div>
						{
							!_.isEmpty(detailed) &&
							<DetailedTransaction id={detailed} close={() => this.setState({ detailed: "" })} />
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),
	..._.pick(state.Transaction, ['transactions']),
	..._.pick(state.MyProfile, ['plaidAccounts'])
})

const mapDispatchToProps = dispatch => ({
	getTransactionHistory: (page) => dispatch(getTransactionHistory(page)),
	getPlaidTransactionHistory: (account_id, offset) => dispatch(getPlaidTransactionHistory(account_id, offset)),
	toggleLoader: () => dispatch(toggleLoader()),
	transactionsReceived: (transactions, page) => dispatch(transactionsReceived(transactions, page))
})

const STYLES = {
	bodyRow: {
		'&:hover': {
			cursor: 'pointer',
			'& > td': {
				background: Theme.Colors.lightGrey
			}
		}
	}
}

export default withRouter(withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Transactions)))