import { ACCOUNT_DETAILS_RECEIVED, ACCOUNT_SETTINGS_RECEIVED, ADDRESS_CHANGE_ENTERED } from "./actions";
import _ from 'lodash'
import { LOGOUT_USER } from "actions/actions";

const initialState = {
	accountDetails: {},
	accountSettings: {},
	addressChange: {}
}

export default (state = { ...initialState }, action) => {

	switch (action.type) {
		case ACCOUNT_DETAILS_RECEIVED: {
			return { ...state, accountDetails: action.accountDetails }
		}
		case ACCOUNT_SETTINGS_RECEIVED: {
			return { ...state, accountSettings: action.accountSettings }
		}

		case ADDRESS_CHANGE_ENTERED: {
			return { ...state, addressChange: action.address }
		}
		case LOGOUT_USER: {
			return { ...initialState }
		}
		default:
			return state;
	}
}