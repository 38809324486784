import React, { PureComponent } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import Upload from 'components/Upload'
import { addImage, removeImage } from '../../actions';
import { Button, Card, withStyles } from '@material-ui/core'
import loginBackground from 'images/login-background.png'

class Step6StudentVisa extends PureComponent {

	render() {
		const { classes } = this.props
		let front = "signUpPayloadCip2.documents.physical_docs[4].document_value"

		let actionCondition = !_.isEmpty(this.props, front)

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Student Visa</h1>
					<div style={{ marginTop: 30 }}>
						<Upload
							navigation={this.props.navigation}
							label="Front"
							image={_.get(this.props, front)}
							addImage={(image) => this.props.addImage(front, image)}
							removeImage={() => this.props.removeImage(front)}
							style={{ marginTop: 40 }} />

					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color={'primary'}
							variant="outlined"
							onClick={() => this.props.changeStep(2)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color={!actionCondition ? 'default' : 'primary'}
							variant="contained"
							onClick={actionCondition ? () => this.props.changeStep(2.4) : () => { }}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
                        	</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ["signUpPayloadCip2"])
})

const mapDispatchToProps = dispatch => ({
	addImage: (key, image) => dispatch(addImage(key, image)),
	removeImage: (key) => dispatch(removeImage(key)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 800,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step6StudentVisa))