import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from './Text';
import { requestSendOtp, toggleLoader, toggleError, showAlert } from 'actions/actions';
import { Button, Card } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class CreateAccountCard extends Component {
	render() {
		const { userInfo } = this.props

		if (_.isEmpty(userInfo) || userInfo.sign_up_status_2 == 'completed' || userInfo.cipTag == -1) {
			return null
		}

		let title = ""
		let onPress = () => { }
		switch (userInfo.sign_up_status_2) {
			case "secondStageWaitlisted":
				title = "You're on the waitlist"
				onPress = () => {
					this.props.showAlert("Alert", "You're on the waitlist. We'll email you when you are off the waitlist and can get your account");
				}
				break;
			case "secondStageProcessing":
				title = "Pending Approval"
				onPress = () => {
					this.props.showAlert("Alert", "Your North Loop Account is being created! We'll email you soon!");
				}
				break;
			case "secondStageInvalidSSN":
				title = "Unable to Open Account"
				onPress = () => {
					this.props.showAlert("Alert", "Please contact customer care");
				}
				break;

			case "secondStageReviewDocs":
				title = "Please re-upload ID"
				onPress = () => {
					// this.props.navigation.navigate("ReviewDoc")
				}
				break;

			case "secondStageVerifyPhone":
				title = "Verify Phone"
				onPress = () => {
					this.props.toggleLoader()
					requestSendOtp().then(
						res => {
							if (res.status == 201) {
								this.props.toggleLoader()
								this.props.toggleError(_.get(res, 'data.data'))
								return
							}
							if (res.status !== 200) {
								console.log("Something went wrong")
								this.props.toggleLoader()
								return
							}
							this.props.toggleLoader()
							this.props.history.push("/user/verify-phone")
						},
						err => {
							this.props.toggleLoader()
							this.props.toggleError(err)
							console.log('error', JSON.stringify(err))
						}
					)
				}
				break;

			default://when sign_up_status_2 key is firstStageComplete
				title = "CREATE AN ACCOUNT"
				onPress = () => this.props.history.push("/user/create-bank-account")
				break;
		}

		return (
			<div style={{
				marginTop: 20, marginBottom: 20, backgroundColor: Theme.Colors.white, borderRadius: 5, display: 'flex', width: '100%', alignItems: 'center', padding: '50px 0px', position: 'relative'
			}}>

				<div style={{ width: '30%', marginLeft: 60 }}>
					<div style={{ marginBottom: 15 }}>
						{
							_.map([
								'No ATM Fees',
								'No Account Fees',
								'No Account Minimums',
							], (value, index) => {
								return (
									<div key={`item${index}`} style={{ marginTop: 8, marginBottom: 30, width: '90%', display: 'flex' }}>
										<img src={require('images/tick_purple.png')} style={{ width: 20, height: 20 }} />
										<Text style={{ marginLeft: 10, fontSize: 16, marginTop: -2 }}>{value}</Text>
									</div>
								)
							})
						}
					</div>
					<Button variant="contained" color="primary" onClick={onPress} style={{ width: '100%' }}>
						{title}
					</Button>
				</div>
				<img style={{ width: 510, height: 302, position: 'absolute', right: 0 }} src={require("images/Auth/myprofile.png")} />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo'])
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: () => dispatch(toggleLoader()),
	showAlert: (title, msg) => dispatch(showAlert(title, msg)),
	toggleError: (err) => dispatch(toggleError(err))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountCard))