import React, { Component } from 'react'
import { connect } from 'react-redux'
import Theme from 'resources/Theme'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import { getTransactionDetail } from './actions'
import Text from 'components/Text'

class DetailedTransaction extends Component {

	componentWillMount() {
		this.props.getTransactionDetail(this.props.id)
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.id !== nextProps.id)
			this.props.getTransactionDetail(nextProps.id)
	}

	render() {
		const { classes, transactionDetail } = this.props

		let value = transactionDetail || {}
		let type = {}
		if (this.props.loader || _.isEmpty(value)) {
			return (<div className={classes.container}></div>)
		}

		switch (value.recent_status.status_id) {
			case "1":
			case "2":
			case "3":
				type = { name: "Pending", color: Theme.Colors.orangeYellow, background: "#fbae2636" }
				break
			case "4":
				type = { name: "Success", color: Theme.Colors.darkishGreen, background: "#3d992e26" }
				break
			case "5":
				type = { name: "Failed", color: Theme.Colors.coralRed, background: "#e44a4a30" }
				break
			case "6":
				type = { name: "Returned", color: Theme.Colors.coralRed, background: "#e44a4a30" }
				break
		}


		let createdOn = moment(transactionDetail.extra.created_on)
		let detail = ""

		if (_.get(transactionDetail, 'to.type') == "EXTERNAL-US") {
			detail = _.get(transactionDetail, 'to.meta.merchantCategory') || ""
		} else if (_.get(transactionDetail, 'from.meta.type') == "wire") {
			detail = "wire"
		} else if (_.get(transactionDetail, 'to.type') == "ACH-US" || _.get(transactionDetail, 'from.type') == "ACH-US") {
			detail = "Transfer"
		}

		let time1
		let time2
		let transfer_case
		let success_obj
		let failed_obj
		if (value.categorization.name === 'Transfer' || detail === 'Transfer') {
			time1 = moment(_.get(value, 'timeline[0].date') || new Date())
			success_obj = _.find(value.timeline, { status_id: "4" })
			failed_obj = _.find(value.timeline, { status_id: "5" })

			if (!success_obj && !failed_obj) {
				transfer_case = "pending"
			}

			if (success_obj) {
				transfer_case = "success"
				time2 = moment(_.get(success_obj, 'date') || new Date())
			}

			if (failed_obj) {
				transfer_case = "failed"
				time2 = moment(_.get(failed_obj, 'date') || new Date())
			}
		}

		return (
			<div className={classes.container}>
				<div style={{
					display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center'
				}}>
					<div style={{ position: 'absolute', top: 20, right: 30, cursor: 'pointer' }} onClick={this.props.close}>
						<i className="material-icons">close</i>
					</div>
					<div style={{
						boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: 50,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: 86,
						width: 86
					}}>
						<img src={_.get(value, 'categorization.icon') || ""} style={{ width: 90, position: 'absolute' }} />
					</div>
					<Text semi="true" style={{ marginTop: 26, fontSize: 18 }}>{value.categorization.name}</Text>
					<Text style={{ marginTop: 10, color: Theme.Colors.brownishGrey }}>
						{createdOn.format("HH:mm")} {createdOn.format("dddd")}, {createdOn.format("Do MMMM, YYYY")}
					</Text>
					<div style={{ background: type.background, padding: '10px 25px 10px 25px', borderRadius: 5, marginTop: 25 }}>
						<Text bold="true" style={{ color: type.color }}>{type.name}</Text>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Transaction, ['transactionDetail', 'transactions']),
	..._.pick(state.App, ['loader'])
})

const mapDispatchToProps = dispatch => ({
	getTransactionDetail: (id) => dispatch(getTransactionDetail(id))
})

const STYLES = {
	container: {
		position: 'fixed', right: 0, top: 0, bottom: 0, width: 350, background: Theme.Colors.white
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(DetailedTransaction))