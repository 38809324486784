import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import Success from 'images/Transaction/success.png'
import Pending from 'images/Transaction/pending.png'
import Failed from 'images/Transaction/failed.png'
import { get_order_history } from './actions';
import moment from 'moment'
import Header from 'components/Header';

class Investing_Order_History extends React.Component {

	componentWillMount() {
		if (_.isEmpty(this.props.orders))
			this.props.get_order_history()
	}

	render() {
		const { orders } = this.props

		return (
			<div>
				<Header name="My Orders" onBack={true} />

				<div style={STYLES.contentContainer}>
					{
						_.isEmpty(orders) ?
							<Text style={{ marginTop: 20 }}>No orders yet</Text>
							:
							_.map(orders, (value, index) => {

								let type = {}
								let price
								let name
								let sign

								switch (value.country) {
									case "IN":
										switch ("bounced_back") {
											case "incoming_payment_waiting":
											case "processing":
											case "funds_converted":
											case "bounced_back":
												type = { name: "Pending", icon: Pending, color: Theme.Colors.orangeYellow }
												break
											case "outgoing_payment_sent":
												type = { name: "Success", icon: Success, color: Theme.Colors.darkishGreen }
												break
											case "cancelled":
											case "funds_refunded":
												type = { name: "Failed", icon: Failed, color: Theme.Colors.coralRed }
												break
										}
										break;

									case "US":
										switch (value.status) {
											case "FILLED":
											case "PARTIAL_FILL":
												type = { name: "Success", icon: Success, color: Theme.Colors.darkishGreen }
												break
											case "REJECTED":
											case "CANCELED":
												type = { name: "Failed", icon: Failed, color: Theme.Colors.coralRed }
												break
											case "NEW":
											case "PENDING_CANCEL":
											default:
												type = { name: "Pending", icon: Pending, color: Theme.Colors.orangeYellow }
												break
										}

										if (value.total_investment > 0) {
											sign = "-"
										}

										if (value.total_investment < 0) {
											sign = "+"
										}

										name = value.symbol
										price = value.total_investment !== null ? `$${value.total_investment.toFixed(2)}` : "-"
										break;
								}

								return (
									<div style={{
										padding: 20,
										background: Theme.Colors.white,
										borderRadius: 5,
										marginTop: 20
									}}
										key={`item${index}`}>
										<div style={{ display: "flex", justifyContent: "space-between", }}>
											<Text bold="true" style={{ fontSize: 20, letterSpacing: 0.6 }}>{name}</Text>
											<Text bold="true" style={{ letterSpacing: 0.6, textAlign: 'right' }}>
												{sign && sign} {price}
											</Text>
										</div>

										<div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
											<Text style={{ fontSize: 12, color: Theme.Colors.greyishBrown }}>
												{moment(value.created_at).format("DD MMM YYYY HH:MM")}
											</Text>
											<div style={{ display: "flex" }}>
												<Text style={{ textAlign: 'right', fontSize: 12, color: Theme.Colors.greyishBrown }}>
													{type.name}
												</Text>
												<img src={type.icon} style={{ width: 12, height: 12, marginLeft: 5, marginTop: 3 }} />
											</div>
										</div>
									</div>
								)
							})
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Investing, ["orders"])
})

const mapDispatchToProps = dispatch => ({
	get_order_history: () => dispatch(get_order_history())
})

export default connect(mapStateToProps, mapDispatchToProps)(Investing_Order_History)

const STYLES = {}