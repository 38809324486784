import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import ContainedButton from 'components/ContainedButton';
import { create_lumpsum_netbanking_order, create_sell_order } from '../actions';

class MF_Buy_Sell extends React.Component {

	state = {
		formData: {
			orders: [{
				isin: this.props.navigation.getParam("mf_details").isin,
				amount: 0
			}]
		},
		units: 0,
		priceModal: false,
		actionCondition: false,
		type: this.props.navigation.getParam("type"),
		input_type: "", //amount or units (required for sell)
		step: 1
	}

	changeStep = (step) => {
		this.setState(step)
	}

	toggleModal = () => {
		this.setState({
			priceModal: !this.state.priceModal
		})
	}

	handleChange = (name, value) => {
		const mf_details = this.props.navigation.getParam("mf_details")
		const { formData } = this.state

		let amount, units

		if (name === 'units') {
			units = Number(value)
			amount = Math.round(Number(units) * mf_details.nav)
		} else {
			amount = Number(_.split(value, "₹ ")[1] || _.split(value, "₹ ")[0])
			units = amount / mf_details.nav
		}

		this.state.units = units
		formData.orders[0].amount = amount

		if (amount > 0) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}

		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData, actionCondition, type, input_type } = this.state

		if (actionCondition) {
			switch (type) {
				case "BUY":
					this.props.create_lumpsum_netbanking_order(formData, this.changeStep)
					break;

				case "SELL":
					let data = {
						orders: [{
							folio_number: "45323/45",
							isin: formData.orders[0].isin,
							type: input_type,
						}]
					}
					switch (input_type) {
						case "amount":
							data.orders[0].amount = formData.orders[0].amount
							break;

						case "units":
							data.orders[0].units = this.state.units
							break;

						default:
							return
					}

					this.props.create_sell_order(data, this.changeStep)
					break;
			}
		}
	}

	render() {
		const mf_details = this.props.navigation.getParam("mf_details")
		const { priceModal, formData, actionCondition, step, type } = this.state

		return (
			<div>
				{
					step === 1 &&
					<>
						<div style={STYLES.headerContainer}>
							{/* <IconButton onPress={() => this.props.navigation.goBack()} icon="arrow-back" color={Theme.Colors.purplishGrey} style={STYLES.backBtn} /> */}
						</div>

						<div style={{ flexDirection: "row", marginTop: 20, marginLeft: '5%', width: "90%" }}>
							<Text bold style={{ fontSize: 20, lineHeight: 28 }}>
								{_.capitalize(type)} {mf_details.name}</Text>
						</div>
						<div style={STYLES.menu}>
							<Text>Number of units</Text>
							{/* <Input
								onChangeText={text => this.handleChange("units", Number(text))}
								value={this.state.units === 0 ? "" : String(Math.round(this.state.units * 100) / 100)}
								placeholder="0"
								style={{
									marginLeft: 35, fontSize: 16, marginTop: -4, color: Theme.Colors.black, textAlign: 'right', height: 40
								}} /> */}
						</div>

						<div style={STYLES.menu}>
							<div style={{ flexDirection: "row" }}>
								<Text>NAV</Text>
								<div
									onPress={this.toggleModal}
									style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: Theme.Colors.primaryPurple, alignItems: "center", justifyContent: 'center', marginLeft: 5 }}>
									<Text style={{ color: Theme.Colors.white }}>?</Text>
								</div>
							</div>

							<Text bold style={{ fontSize: 16, }}>
								{mf_details.nav}
							</Text>
						</div>

						<div style={STYLES.menu}>
							<Text>Estimated <Text>{type === "BUY" ? "cost" : "price"}</Text></Text>
							{/* <Input
								onChangeText={text => this.handleChange("price", text)}
								value={formData.orders[0].amount === 0 ? "" : "₹ " + String(Math.round(formData.orders[0].amount))}
								placeholder="₹ 0"
								style={{
									marginLeft: 35, fontSize: 16, marginTop: -4, color: Theme.Colors.black, fontFamily: Theme.FontFamily.FONT1_BOLD, textAlign: 'right', height: 40,
								}} /> */}
						</div>

						<div style={STYLES.actionButton}>
							<ContainedButton
								color={!actionCondition && Theme.Colors.lightGrey}
								onPress={this.handleSubmit}
								style={{ marginTop: 20 }}>
								NEXT
							</ContainedButton>
						</div>
					</>
				}
				{
					priceModal &&
					<div styles={{ borderRadius: 10, height: 'auto' }} containerStyles={{ elevation: 1000 }}>
						<div style={STYLES.rowFlex}>
							<Text bold style={{ color: Theme.Colors.primaryPurple }}>Price</Text>
							{/* <IconButton onPress={this.toggleModal} icon="close" color={Theme.Colors.primaryPurple} style={{ marginTop: -2, marginRight: -5 }} size={20} /> */}
						</div>
						<Text style={{ width: '95%', lineHeight: 22 }}>
							NAV changes at the end of the day. All transactions done before 3PM IST will have today's NAV, otherwise the purchase price will be tomorrow's NAV
						</Text>
					</div>
				}

				{
					step === 2 &&
					<>
						<img src={require('images/tick_purple.png')} style={{ width: 150, height: 150 }} />
						<Text bold style={{ fontSize: 20, color: Theme.Colors.black, width: 300, lineHeight: 22, textAlign: 'center', marginTop: 30 }}>Order Created</Text>
						{/* <Text bold style={{ fontSize: 20, color: Theme.Colors.black, width: 300, lineHeight: 22, textAlign: 'center', marginTop: 30, marginBottom: 20 }}>You will receive a confirmation email.</Text> */}
						<ContainedButton
							style={STYLES.actionButton}
							onPress={this.handleBackPress}>
							DONE
						</ContainedButton>
					</>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	create_lumpsum_netbanking_order: (data, changeStep) => dispatch(create_lumpsum_netbanking_order(data, changeStep)),
	create_sell_order: (data, changeStep) => dispatch(create_sell_order(data, changeStep))
})

export default connect(mapStateToProps, mapDispatchToProps)(MF_Buy_Sell)

const STYLES = {
	container: {
		flex: 1,
		backgroundColor: Theme.Colors.white,
		height: '100%'
	},
	headerContainer: {
		flexDirection: 'row', justifyContent: 'center', paddingTop: 22, borderBottomWidth: 1, borderColor: Theme.Colors.whiteTwo, height: 65
	},
	contentContainer: {
		width: '90%', marginLeft: '5%', marginTop: 20
	},
	backBtn: {
		position: 'absolute', left: 0, marginLeft: '3%', marginTop: 20
	},
	headline: {
		fontSize: 16, color: Theme.Colors.purplishGrey
	},
	rowFlex: {
		flexDirection: 'row', justifyContent: 'space-between'
	},
	menu: {
		borderBottomWidth: 1, borderColor: Theme.Colors.whiteTwo, paddingHorizontal: '5%', flexDirection: "row", justifyContent: "space-between", paddingVertical: 30, alignItems: 'center'
	},
	actionButton: {
		width: '90%', marginLeft: '5%', bottom: 30, position: "absolute"
	}
}