import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	withStyles,
	Grid,
	Card,
	Switch,
	Button
} from '@material-ui/core';
import Text from 'components/Text';
import Theme from 'resources/Theme';
import { getMyCardPageDetails } from './actions';
import _ from 'lodash'
import moment from 'moment'
import { getCardDetails, updateCardPreferences, firstTimeActivate, getCardInfo } from './actions';
import CreateAccountCard from 'components/CreateAccountCard';
import Header from 'components/Header';

class US_CardDetails extends Component {

	state = {
		modal: false,
		cardLockedByAdminModal: false
	}

	componentWillMount() {
		const { cardInfo } = this.props
		if (_.isEmpty(cardInfo) && this.props.userInfo.sign_up_status_2 == 'completed') {
			this.props.getMyCardPageDetails()
		}
	}

	handleToggle = (isOn, name) => {
		let cardInfo = {
			status: _.get(this.props.cardInfo, 'status') || 'ACTIVE',
			preferences: {
				allow_foreign_transactions: _.get(this.props.cardInfo, 'preferences.allow_foreign_transactions') || false,
			}
		}

		switch (name) {
			case 'status':
				cardInfo.status = isOn ? 'INACTIVE' : 'ACTIVE'
				break;

			case 'allow_foreign_transactions':
				cardInfo.preferences.allow_foreign_transactions = isOn
				break;
		}

		this.props.updateCardPreferences(cardInfo)
	}

	render() {
		const { cardInfo, cardDetails, userInfo } = this.props

		if (_.get(userInfo, 'sign_up_status_2') !== 'completed') {
			this.props.history.push("/user/my-cards")
			return null
		}

		return (
			<div>
				<Header name="My Card" />
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					{
						!_.isEmpty(cardDetails) ?
							<>
								<div
									style={{
										width: 440, height: 280, borderRadius: 10,
										background: `url(${require("images/MyCard/card.png")})`,
										backgroundSize: "cover",
										boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
										...(cardInfo.status !== 'ACTIVE') && { opacity: 0.4 }
									}}>
									<div style={{ display: 'flex', marginTop: 150, marginLeft: 15 }}>
										{
											_.map([0, 4, 8, 12], (value, index) => {
												return (
													<Text key={`item${index}`} style={{ color: Theme.Colors.greyishBrown, fontSize: 24, marginLeft: 15 }}>{cardDetails.card_number.substring(value, value + 4)}</Text>
												)
											})
										}
									</div>
									<div style={{ display: 'flex', marginTop: 16 }}>
										<Text style={{ color: Theme.Colors.greyishBrown, fontSize: 18, marginLeft: 150 }}>
											{moment(cardDetails.exp).format("MM/YY")}
										</Text>
										<Text style={{ color: Theme.Colors.greyishBrown, fontSize: 18, marginLeft: 78 }}>
											{cardDetails.cvc}
										</Text>
									</div>
									<Text style={{ color: Theme.Colors.greyishBrown, marginTop: 20, fontSize: 20, marginLeft: 30 }}>{cardDetails.nickname}</Text>
								</div>
								{
									(cardInfo.status !== 'ACTIVE') &&
									<div className="box-shadow" style={STYLES.lockImgContainer}>
										<img src={require("images/MyCard/lock.png")} style={{ width: 52, height: 56 }} />
									</div>
								}
							</>
							:
							<Button color="primary" variant="contained" onClick={this.props.getCardDetails} style={{ marginTop: 50, marginBottom: 50, width: 300 }}>
								View My Card
							</Button>
					}
				</div>
				<Grid container>
					{
						_.map([{
							icon: require("images/icons/icon-LockYourCard.svg"), name: 'Lock Your Card', toggle: true,
							isOn: (cardInfo.status || '') === 'ACTIVE' ? false : true,
							onToggle: e => {
								// firebase.analytics().logEvent("card_lock", {})
								this.handleToggle(e.target.checked, 'status')
							}
						}, {
							icon: require("images/icons/icon-ReportLostCard.svg"), name: 'Report Lost or Stolen', toggle: false,
							onPress: () => {
								// firebase.analytics().logEvent("card_lost", {})
								this.props.history.push("/user/my-cards/north-loop-us-debit/lost-card")
							},
						}, {
							icon: require("images/icons/icon-SetNewPin.svg"), name: 'Set a New PIN', toggle: false,
							onPress: () => {
								// firebase.analytics().logEvent("card_pin", {})
								this.props.history.push("/user/my-cards/north-loop-us-debit/set-pin")
							},
						}, {
							icon: require("images/icons/icon-SpendAbroad.svg"), name: 'Spend Abroad', toggle: true,
							isOn: _.get(cardInfo, 'preferences.allow_foreign_transactions') ? true : false,
							onToggle: e => {
								this.handleToggle(e.target.checked, 'allow_foreign_transactions')
							}
						}], (value, index) => {
							return (
								<React.Fragment key={`item${index}`}>
									<Grid item lg={5} style={{ marginTop: 50 }}>
										<Card
											style={{
												borderRadius: 10, padding: '20px', display: 'flex', alignItems: 'center',
												...!value.toggle && { cursor: 'pointer' }
											}}
											onClick={value.toggle ? () => { } : value.onPress}>
											<img src={value.icon} style={{ height: 88, width: 88 }} />
											<div style={{ marginLeft: 20 }}>
												<Text bold="true" >{value.name}</Text>
												{
													value.toggle &&
													<div style={{ marginLeft: -10 }}>
														<Switch checked={value.isOn} onChange={value.onToggle} />
													</div>
												}
											</div>
										</Card>
									</Grid>
									{
										index % 2 == 0 &&
										<Grid item lg={2}></Grid>
									}
								</React.Fragment>
							)
						})
					}
				</Grid>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),
	..._.pick(state.MyUSCard, ['cardDetails', 'cardInfo']),
})

const mapDispatchToProps = dispatch => ({
	getCardDetails: () => dispatch(getCardDetails()),
	getMyCardPageDetails: () => dispatch(getMyCardPageDetails()),
	updateCardPreferences: (cardInfo) => dispatch(updateCardPreferences(cardInfo)),
	firstTimeActivate: (navigation) => dispatch(firstTimeActivate(navigation))
})

const STYLES = {
	lockImgContainer: {
		height: 110, width: 110, position: 'absolute', borderRadius: 55, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 100, backgroundColor: Theme.Colors.white
	},
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(US_CardDetails))