import React from 'react';
import _ from 'lodash'
import US_LostCardStep1 from './US_LostCardStep1';
import US_LostCardStep2 from './US_LostCardStep2';
import US_LostCardStep3 from './US_LostCardStep3';
import Header from 'components/Header';

export default class US_LostCard extends React.Component {

	state = {
		step: 1,
		issueNewCard: false
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	getStep1Details = (issueNewCard) => {
		this.state.issueNewCard = issueNewCard
		this.setState({ step: 2 })
	}

	render() {
		const { step } = this.state
		return (
			<div>
				<Header name="Lost Card" />
				{
					step == 1 &&
					<US_LostCardStep1 changeStep={this.changeStep} getStep1Details={this.getStep1Details} />
				}

				{
					step == 2 &&
					<US_LostCardStep2 changeStep={this.changeStep} issueNewCard={this.state.issueNewCard} />
				}

				{
					step == 3 &&
					<US_LostCardStep3 history={this.props.history} />
				}
			</div>
		);
	}
}


const STYLES = {

}