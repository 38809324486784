import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text'
import { getMerchantHistory, merchantHistoryReceived } from './actions'
import moment from 'moment'
import merchantHistoryImg from 'images/Expense/merchantHistory.png'

class ExpenseMerchantHistory extends Component {

	componentDidMount() {
		let merchantId = this.props.merchantDetails.merchantId
		if (merchantId) {
			this.props.getMerchantHistory(merchantId)
		}
	}

	render() {
		let { merchantHistory } = this.props
		let totalBalance = 0

		merchantHistory = _.map(merchantHistory, (value, index) => {
			totalBalance += value.amount
			return {
				...value,
				month: moment.utc(value.month).format("M") - 1
			}
		})

		let logo = this.props.merchantDetails.logo
		let name = this.props.merchantDetails.name

		let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
		return (
			<div>
				<div>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ height: 70, width: 70, borderRadius: 35, border: `1px solid ${Theme.Colors.lightGrey}`, backgroundColor: Theme.Colors.lightGrey, marginTop: 20 }}>
							<img src={logo} style={{ height: 70, width: 70, borderRadius: 35 }} />
						</div>
						<Text bold="true" style={{ fontSize: 18, marginTop: 10 }}>{name}</Text>
					</div>
					<div
						style={{
							marginTop: 30, height: 260, border: `0px solid ${Theme.Colors.lightGrey}`, borderTopWidth: 1, borderBottomWidth: 1, display: 'flex', alignItems: 'flex-end', width: '100%',
							justifyContent: 'space-around', paddingBottom: 20
						}}>
						{
							_.map([6, 5, 4, 3, 2, 1], (value, index) => {
								let monthIndex = moment.utc().format("M") - value
								if (monthIndex < 0) { monthIndex += 12 }

								let merchant = _.find(merchantHistory, { month: monthIndex })
								let amount = Math.round(merchant ? merchant.amount : 0)
								let height = 0
								if (totalBalance != 0) {
									height = Math.round((amount / totalBalance) * 165) + 5
								}
								return (
									<div key={`item${index}`} style={{ width: 40, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Text style={{ marginBottom: 10, letterSpacing: 0.6, fontSize: 16, color: Theme.Colors.greyishBrown }}>${amount}</Text>
										<div style={{ height: height, width: '100%', backgroundColor: Theme.Colors.powderPink, borderRadius: 10 }}>

										</div>
										<Text style={{ color: Theme.Colors.greyishBrown, marginTop: 5 }}>{months[monthIndex]}</Text>
									</div>
								)
							})
						}
					</div>
					<div style={{ marginTop: 40 }}>
						<Text style={{ fontSize: 16, marginLeft: 20, letterSpacing: 0.6, color: Theme.Colors.greyishBrown }}>History</Text>
						{
							_.map(_.reverse(this.props.merchantHistory || []), (value, index) => {
								return (
									<div key={`item2${index}`} style={{ padding: 20, borderBottomColor: Theme.Colors.whiteTwo, borderBottomWidth: 1, display: 'flex', justifyContent: 'space-between', }}>
										<div style={{ display: 'flex' }}>
											<img src={merchantHistoryImg} style={{ height: 50, width: 50 }} />
											<Text bold="true" style={{ marginLeft: 10, marginTop: 10, fontSize: 16 }}>{moment.utc(value.month).format("MMMM")}, {moment.utc(value.month).format("YYYY")}</Text>
										</div>
										<Text bold="true" style={{ fontSize: 16, marginTop: 10 }}>${value.amount}</Text>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Expense, ['merchantHistory'])
})

const mapDispatchToProps = dispatch => ({
	getMerchantHistory: (merchantId) => dispatch(getMerchantHistory(merchantId)),
	merchantHistoryReceived: (merchantHistory) => dispatch(merchantHistoryReceived(merchantHistory))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseMerchantHistory)

const STYLES = {
	container: {
		flex: 1
	},
	contentContainer: {
		height: '99%',
	},
	scrollContentContainer: {
		display: 'flex', justifyContent: 'space-around', width: 1000, backgroundColor: Theme.Colors.whiteTwo, alignItems: 'flex-end', paddingBottom: 20,
	},
	arrowBack: {
		marginLeft: -5, marginTop: 20
	}
}