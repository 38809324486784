import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import Upload from 'components/Upload';
import { upload_documents } from '../actions';
import OutlinedButton from 'components/OutlinedButton';

class Step6 extends Component {

	handleImageUpload = (name, value) => {
		const { state, changeState } = this.props.parentProps
		state.images[name] = value

		state.images.uploaded = false

		changeState(state)
	}

	handleSubmit = () => {
		const { state, changeStep, changeState } = this.props.parentProps

		if (!state.images.uploaded) {
			if (!_.isEmpty(state.images.cancelled_cheque) && !_.isEmpty(state.images.signature)) {
				this.props.upload_documents(state, changeState)
			}
		} else {
			changeStep(7)
		}
	}

	render() {
		const { handleBack, state } = this.props.parentProps

		return (
			<div>
				<Text bold style={STYLES.headline}>Upload documents</Text>

				<Upload
					navigation={this.props.navigation}
					label="Cancelled Cheque"
					image={state.images.cancelled_cheque}
					style={{ marginTop: 40 }}
					addImage={(image) => this.handleImageUpload("cancelled_cheque", image)}
					removeImage={() => this.handleImageUpload("cancelled_cheque", "")} />

				<Upload
					navigation={this.props.navigation}
					label="Signature"
					image={state.images.signature}
					style={{ marginTop: 40 }}
					addImage={(image) => this.handleImageUpload("signature", image)}
					removeImage={() => this.handleImageUpload("signature", "")} />

				<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
					<OutlinedButton
						style={{ width: 200 }}
						onClick={handleBack}>
						Previous
            			</OutlinedButton>
					<ContainedButton
						style={{ width: 200 }}
						color={(_.isEmpty(state.images.cancelled_cheque) || _.isEmpty(state.images.signature)) && "default"}
						onClick={this.handleSubmit}>
						Continue
            		</ContainedButton>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({
	upload_documents: (state, changeState) => dispatch(upload_documents(state, changeState))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step6)

const STYLES = {
	headline: {
		fontSize: 24
	}
}