import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import moment from "moment"
import { getAnalysisDetails } from './actions'
import { Button, Menu, MenuItem } from '@material-ui/core';
import Header from 'components/Header';
import { Line } from 'react-chartjs-2';

class AnalyzeSpends extends Component {

	state = {
		isModalOpen: null,
		year: moment.utc().format("YYYY")
	}

	toggleModal = e => {
		this.setState({
			isModalOpen: e === null ? e : e.currentTarget
		})
	}

	componentDidMount() {
		this.props.getAnalysisDetails(this.state.year)
	}

	handleYearChange = (year) => {
		if (this.state.year != year) {
			this.props.getAnalysisDetails(year)
		}
		this.setState({
			isModalOpen: !this.state.isModalOpen,
			year: year
		})
	}

	render() {
		const { isModalOpen } = this.state
		const { userInfo, analysisDetails, loader } = this.props

		let years = []

		let created_at = Number(moment.utc(Number(userInfo.created_at)).format("YYYY"))
		let now = Number(moment.utc().format("YYYY"))

		for (let index = now; index >= created_at; index--) {
			years.push(index)
		}

		const income = _.map(_.get(this.props.analysisDetails, "graph") || [], (value) => {
			return Math.round(value.in)
		})
		const expense = _.map(_.get(this.props.analysisDetails, "graph") || [], (value) => {
			return Math.round(value.out)
		})

		return (
			<div>
				<Header name="Analyze Spends" rightBar={!_.isEmpty(_.get(analysisDetails, "mostCommonMerchant")) && 650} />

				<div style={{ ...STYLES.graphContainer, width: !_.isEmpty(_.get(analysisDetails, "mostCommonMerchant")) ? 610 : "auto" }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Text bold="true" style={{ fontSize: 18 }}>Total spent ${Math.round(_.get(analysisDetails, "totalSpent") || 0)}</Text>
						<Button onClick={this.toggleModal}>
							{this.state.year} <i className="material-icons" style={{ color: Theme.Colors.warmGrey }}>expand_more</i>
						</Button>
						<Menu
							style={{ maxHeight: 300 }}
							id="simple-menu"
							anchorEl={isModalOpen}
							keepMounted
							open={Boolean(isModalOpen)}
							onClose={() => this.toggleModal(null)}>
							{
								_.map(years, (value, index) => {
									return (
										<MenuItem onClick={() => this.handleYearChange(value)} key={`month${index}`}>
											<Text style={{ color: Theme.Colors.greyishBrown }}>
												{value}
											</Text>
										</MenuItem>
									)
								})
							}
						</Menu>
					</div>
					<div style={STYLES.legendY}>
						{
							_.map([{
								name: 'Income', color: '#8ec144'
							}, {
								name: 'Expenses', color: '#f48c37'
							}], (value, index) => {
								return (
									<div key={`item${index}`} style={{ display: 'flex', }}>
										<Text style={{ fontSize: 50, marginTop: -40, color: value.color }}>.</Text>
										<Text style={{ color: value.color, marginLeft: 5 }}>{value.name}</Text>
									</div>
								)
							})
						}
					</div>
					<div style={{ marginTop: 20 }}>
						{
							!loader &&
							<Line data={{
								labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
								datasets: [{
									fill: false,
									borderColor: 'rgba(75,192,192,1)',
									data: income
								}, {
									fill: false,
									borderColor: '#f48c37',
									data: expense
								}]
							}}
								options={{ legend: { display: false } }} />
						}
					</div>
				</div>
				{
					!_.isEmpty(_.get(analysisDetails, "mostCommonMerchant")) &&

					<div style={{ paddingTop: 20, position: 'fixed', right: 0, top: 0, bottom: 0, width: 350, background: Theme.Colors.white, overflow: 'scroll' }}>
						<Text style={STYLES.frequentHeadline}>Frequent spends</Text>
						<div style={STYLES.frequentContainer}>
							{
								_.map(analysisDetails.mostCommonMerchant || [], (value, index) => {
									return (
										<div key={`item${index}`} style={STYLES.frequentTransaction}>
											<div style={{ display: 'flex', }}>
												<div style={STYLES.transactionImage}>
													{
														!_.isEmpty(value.to_merchant_logo) &&
														<img src={value.to_merchant_logo} style={{ width: 50, height: 50, borderRadius: 25 }} />
													}
												</div>
												<div style={{ marginLeft: 20, marginTop: 5 }}>
													<Text style={{ fontSize: 16, letterSpacing: 0.53, }}>
														{_.truncate(value.to_merchant_name, { length: 18 })}
													</Text>
													<Text style={{ fontSize: 11, marginTop: 5, color: Theme.Colors.warmGreyTwo }}>
														<span style={{ color: Theme.Colors.warmGreyTwo, fontSize: 12, fontWeight: 'bold' }}>
															{value.count == 1 ? "Once" : `${value.count || 0} times`}
														</span> this year
													</Text>
												</div>
											</div>
											<Text bold="true" style={{ fontSize: 18, marginTop: 10 }}>${value.sum || 0}</Text>
										</div>
									)
								})
							}
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo', 'loader']),
	..._.pick(state.Expense, ['analysisDetails']),
})

const mapDispatchToProps = dispatch => ({
	getAnalysisDetails: (year) => dispatch(getAnalysisDetails(year))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeSpends)

const STYLES = {
	modalItem: {
		height: 50, padding: 20, borderBottomColor: Theme.Colors.lightGrey, borderBottomWidth: 1
	},
	graphContainer: {
		padding: 20, borderColor: Theme.Colors.lightGrey, borderTopWidth: 1, borderBottomWidth: 1, backgroundColor: Theme.Colors.white
	},
	selectYearBox: {
		width: 80, display: 'flex', justifyContent: 'space-around', alignItems: 'center', right: 5, backgroundColor: Theme.Colors.whiteTwo, padding: 4
	},
	selectYearText: {
		color: Theme.Colors.greyishBrown, fontSize: 12
	},
	selectYearIcon: {
		fontSize: 20, color: Theme.Colors.greyishBrown
	},
	legendY: {
		display: 'flex', justifyContent: 'space-between', width: 150, marginTop: 10
	},
	frequentHeadline: {
		fontSize: 16, color: Theme.Colors.greyishBrown, letterSpacing: 0.53, padding: `0px 5%`
	},
	frequentContainer: {
		backgroundColor: Theme.Colors.white, borderColor: Theme.Colors.lightGrey, borderWidth: 1, marginTop: 20, marginBottom: 30, borderRadius: 10
	},
	frequentTransaction: {
		display: 'flex', padding: `25px 5%`, border: `0px solid ${Theme.Colors.whiteTwo}`, borderBottomWidth: 1, justifyContent: 'space-between'
	},
	transactionImage: {
		width: 50, height: 50, borderRadius: 25, borderColor: Theme.Colors.lightGrey, borderWidth: 1
	}
}