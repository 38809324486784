import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { createInvestor } from '../actions'
import moment from 'moment'
import Step0 from './Step0'
import Step1 from './Step1';
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import { userInfoUpdated } from 'actions/actions'
import constants from 'resources/constants'
import Theme from 'resources/Theme'

class MF_Create_Investor extends Component {


	constructor(props) {
		super(props)

		const { userInfo } = this.props

		this.state = {
			formData: {
				perm_addr_is_corres_addr: true,
				ip_address: "",
				fatca_detail: {
					country_of_birth_ansi_code: userInfo.basic_information.country_of_residence,
					no_other_tax_residences: true,
					source_of_wealth: "SALARY",
					gross_annual_income: 0
				},
				nomination: {
					skip_nomination: false,
					nominee1: {
						allocation_percentage: 0,
						date_of_birth: "",
						name: "",
						relationship: ""
					}
				},
				kyc_identity_detail: {
					country_of_citizenship_ansi_code: userInfo.basic_information.country_of_residence,
					date_of_birth: moment(userInfo.basic_information.date_of_birth, "DD/MM/YYYY").format("YYYY-MM-DD"),
					father_or_spouse_name: "",
					kyc_relation: "",
					gender: "MALE",
					marital_status: "single",
					name: userInfo.basic_information.name,
					occupation: "BUSINESS",
					residential_status: userInfo.basic_information.country_of_residence === 'IN' ?
						"RESIDENT_INDIVIDUAL" : "NON_RESIDENT_INDIVIDUAL",
					pep_exposed: false,
					pep_related: false
				},
				permanent_address: {
					line1: "",
					pincode: ""
				},
				correspondence_address: {
					line1: "",
					pincode: ""
				},
				contact_detail: {
					email: userInfo.basic_information.email,
					isd_code: userInfo.basic_information.phone_no_code,
					mobile: userInfo.basic_information.phone_no
				},
				bank_accounts: [{
					account_holder_name: userInfo.basic_information.name,
					number: "",
					primary_account: true,
					type: userInfo.basic_information.country_of_residence === 'IN' ? "SAVINGS" : "NRE",
					ifsc_code: ""
				}]
			},
			formData_risk_profile: {
				answers: [
					{
						question: "What are you looking for?",
						option: {
							text: "",
							value: 0
						}
					},
					{
						question: "When investing what do you care about more?",
						option: {
							text: "",
							value: 0
						}
					},
					{
						question: "How new are you to investing?",
						option: {
							text: "",
							value: 0
						}
					},
					{
						question: "If the stock market fell 10% over a month during a downturn, what would you do?",
						option: {
							text: "",
							value: 0
						}
					},
				],
				country: "IN"
			},
			images: {
				signature: "",
				camcelled_cheque: "",
				uploaded: false
			},
			step: userInfo.risk_score === null ? 0 : 1,
			risk_profile_step: 1,
			suggested_mutual_funds: []
		}
	}

	handleChange = (name, value) => {
		const { formData } = this.state

		switch (name) {
			case "nomination.skip_nomination":
				if (value === true) {
					formData.nomination.nominee1 = {
						allocation_percentage: 0,
						date_of_birth: "",
						name: "",
						relationship: ""
					}
				}
				break

			case "fatca_detail.gross_annual_income":
			case "allocation_percentage":
				value = Number(value)
				break
		}

		_.set(formData, name, value)

		this.setState(this.state)
	}

	handleSubmit = () => {
		const { formData, formData_risk_profile } = this.state

		let data = JSON.parse(JSON.stringify(formData))

		if (data.nomination.skip_nomination) {
			_.unset(data, "nomination.nominee1")
		}

		if (data.perm_addr_is_corres_addr) {
			_.unset(data, "permanent_address")
		}

		if (data.kyc_identity_detail.residential_status === "NON_RESIDENT_INDIVIDUAL") {
			data.overseas_address = data.correspondence_address
		}

		if (data.kyc_identity_detail.kyc_relation === "") {
			_.unset(data, "kyc_identity_detail.kyc_relation")
		}

		this.props.createInvestor(data, formData_risk_profile, this.changeState)
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	changeState = (state) => {
		this.setState(state)
	}

	handleBackPress = () => {
		const { step } = this.state
		const { userInfo } = this.props

		if (step !== 0) {
			if ((step === 1 && userInfo.risk_score !== null) || step === 8) {
				if (step === 8) {
					this.props.userInfoUpdated({ mutual_fund_status: constants.mutual_fund_status.COMPLETED })
				}
				this.props.history.push("/user/invest")
			} else {
				this.changeStep(step - 1)
			}
		}
	}

	render() {
		const { formData, step, formData_risk_profile } = this.state

		let parentProps = {
			formData: formData,
			formData_risk_profile: formData_risk_profile,
			state: this.state,
			changeStep: this.changeStep,
			changeState: this.changeState,
			handleChange: this.handleChange,
			handleSubmit: this.handleSubmit,
			handleBack: this.handleBackPress,
			navigation: this.props.history
		}

		return (
			<div style={STYLES.container}>

				{
					step === 0 &&
					<Step0 parentProps={parentProps} />
				}

				{
					step === 1 &&
					<Step1 parentProps={parentProps} />
				}

				{
					step === 2 &&
					<Step2 parentProps={parentProps} />
				}

				{
					step === 3 &&
					<Step3 parentProps={parentProps} />
				}

				{
					step === 4 &&
					<Step4 parentProps={parentProps} />
				}

				{
					step === 5 &&
					<Step5 parentProps={parentProps} />
				}

				{
					step === 6 &&
					<Step6 parentProps={parentProps} navigation={this.props.navigation} />
				}

				{
					step === 7 &&
					<Step7 parentProps={parentProps} />
				}

				{
					step === 8 &&
					<Step8 parentProps={parentProps} />
				}

			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo'])
})

const mapDispatchToProps = dispatch => ({
	userInfoUpdated: (userInfo) => dispatch(userInfoUpdated(userInfo)),
	createInvestor: (data, formData_risk_profile, changeState) => dispatch(createInvestor(data, formData_risk_profile, changeState))
})

export default connect(mapStateToProps, mapDispatchToProps)(MF_Create_Investor)

const STYLES = {
	container: {
		padding: 50, background: Theme.Colors.white, marginTop: 40, marginBottom: 50, borderRadius: 5
	}
}