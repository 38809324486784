import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	withStyles,
	Button,
	ListItem
} from '@material-ui/core';
import Text from 'components/Text';
import Theme from 'resources/Theme';
import _ from 'lodash'
import { getAccountInfo } from 'actions/actions';
import { getCardDetails, updateCardPreferences, firstTimeActivate, getCardInfo } from './US/actions';
import CreateAccountCard from 'components/CreateAccountCard';
import Header from 'components/Header';

class MyCard extends Component {

	state = {
		modal: false,
		cardLockedByAdminModal: false
	}

	componentWillMount() {
		if (_.isEmpty(this.props.accountInfo) && this.props.userInfo.sign_up_status_2 == 'completed') {
			this.props.getAccountInfo()
		}
	}

	activateCard = () => {
		if (this.props.cardInfo.cardLockedByAdmin) {
			this.setState({ cardLockedByAdminModal: true })
			return
		}
		this.props.firstTimeActivate(this.props.history)
	}

	render() {
		const { userInfo, accountInfo, loader } = this.props

		if (loader) {
			return (
				<div>
					<Header name="My Cards" />
				</div>
			)
		}

		if (_.get(userInfo, 'sign_up_status_2') !== 'completed') {
			return (
				<div>
					<Header name="My Cards" />
					<CreateAccountCard navigation={this.props.navigation} />
				</div>
			)
		}

		return (
			<div>
				<Header name="My Cards" />
				<Text bold="true" style={{ marginTop: 30, marginBottom: 10 }}>Debit (US)</Text>
				{
					!accountInfo.isCardIssued && userInfo.cipTag === 3 ?
						<div style={STYLES.box}>
							<div style={{ flex: 1, justifyContent: 'center' }}>
								<Button variant="contained" color="primary" onClick={() => this.props.history.push("/user/my-cards/north-loop-us-debit/order-card")} style={{ width: '40%', marginLeft: '30%' }}>
									Order Card
                            	</Button>
							</div>
						</div>
						:
						<>
							{
								!_.isEmpty(accountInfo) && !accountInfo.CardFirstTimeActivated ?
									<div style={STYLES.box}>
										<div style={{ flex: 1, justifyContent: 'center' }}>
											<Button variant="contained" color="primary" onClick={this.activateCard} style={{ width: '40%', marginLeft: '30%' }}>
												Activate Your Card
                            				</Button>
										</div>
									</div>
									:
									<ListItem button onClick={() => this.props.history.push("/user/my-cards/north-loop-us-debit/details")} style={STYLES.box}>
										<div>
											<Text style={{ color: Theme.Colors.warmGreyTwo, fontSize: 16 }}>USD</Text>
											<Text style={{ fontSize: 20, marginTop: 5 }}>xxxx</Text>
										</div>
										<img src={require("images/MyCard/mastercard_logo.png")}
											style={{ width: 62, height: 39, marginTop: 10 }} />

									</ListItem>
							}
						</>
				}

				{/* <Text bold="true"style={{ marginTop: 30, marginBottom: 10 }}>Debit (NRO)</Text>
					<Button onClick={() => this.props.navigation.navigate("CardDetails", {
						card: "NorthLoopUSDebit"
					})} style={STYLES.box}>
						<div>
							<Text style={{ color: Theme.Colors.warmGreyTwo, fontSize: 16 }}>INR</Text>
							<Text style={{ fontSize: 20, marginTop: 5 }}>xxx2927</Text>
						</div>
						<img src={require("images/MyCard/rupay_logo.png")}
							style={{ width: 81, height: 22, marginTop: 20 }} />
					</Button> */}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo', 'loader']),
	..._.pick(state.MyUSCard, ['cardDetails', 'cardInfo']),
})

const mapDispatchToProps = dispatch => ({
	getAccountInfo: () => dispatch(getAccountInfo()),
	getCardDetails: () => dispatch(getCardDetails()),
	getCardInfo: () => dispatch(getCardInfo()),
	updateCardPreferences: (cardInfo) => dispatch(updateCardPreferences(cardInfo)),
	firstTimeActivate: (navigation) => dispatch(firstTimeActivate(navigation))
})

const STYLES = {
	container: {
		flex: 1,
		backgroundColor: Theme.Colors.whiteTwo,
	},
	menuImg: {
		height: 7, width: 15
	},
	box: {
		borderRadius: 5, flexDirection: 'row', justifyContent: 'space-between', padding: 20, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)", width: '100%'
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(MyCard))