import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ListItem } from '@material-ui/core';

export default class Step2 extends Component {
	render() {
		const { formData, handleBack, changeStep, handleChange } = this.props.parentProps

		const actionCondition = !_.isEmpty(formData.kyc_identity_detail.father_or_spouse_name)
		return (
			<div>
				<Text bold style={STYLES.headline}>Lets get to know you</Text>

				<ValidatorForm onSubmit={() => { }}>

					<TextValidator
						onChange={(e) => handleChange('kyc_identity_detail.father_or_spouse_name', e.target.value)}
						value={formData.kyc_identity_detail.father_or_spouse_name}
						style={{ marginTop: 40 }}
						label="Father or spouse name"
						placeholder="Enter father or spouse name"
						variant="outlined"
						fullWidth
					/>

					{
						_.map([{
							title: "", name: "kyc_identity_detail.kyc_relation", options: ['Father', "Spouse", ""]
						}, {
							title: "Gender", name: "kyc_identity_detail.gender", options: ['Male', "Female", "Others"]
						}, {
							title: "Marital Status", name: "kyc_identity_detail.marital_status", options: ['Single', "Married", "Others"]
						}], (v, i) => {
							return (
								<div key={`menu${i}`}>
									<Text style={{ ...i !== 0 ? { marginTop: 30 } : { marginTop: 20 } }}>{v.title}</Text>
									<div style={{ display: "flex", marginTop: 15 }}>
										{
											_.map(v.options, (value, index) => {
												let name = value == "Others" ? "Other" : (value == "" ? "No Title" : value)
												return (
													<ListItem
														button
														onClick={() => handleChange(v.name, value.toUpperCase())}
														key={`item${index}`}
														style={{
															backgroundColor: _.get(formData, v.name).toLowerCase() === value.toLowerCase() ? Theme.Colors.paleGreyThree : Theme.Colors.whiteTwo,
															...STYLES.box, ...index != 0 && { marginLeft: 20 }
														}}>
														<Text bold
															style={{
																color: _.get(formData, v.name).toLowerCase() === value.toLowerCase() ? Theme.Colors.primaryPurple : Theme.Colors.warmGreyTwo
															}}>{name}</Text>
													</ListItem>
												)
											})
										}
									</div>
								</div>
							)
						})
					}

					<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							color={!actionCondition && "default"}
							style={{ width: 200 }}
							onClick={actionCondition ? () => changeStep(3) : () => { }}>
							Continue
            			</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24,
		marginTop: 5
	},
	box: {
		padding: 10, display: "flex", justifyContent: "center", width: 100, borderRadius: 20, border: `0.2px solid ${Theme.Colors.warmGrey}`
	}
}