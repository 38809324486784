import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { pushConfirmDetails } from '../../actions';
import { Button, Card, withStyles } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import loginBackground from 'images/login-background.png'
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment'

class Step7Cip2ConfirmDetails extends Component {

	state = {
		formData: {
			passport: "",
			university: "",
			arrival_date: ""
		},
		help: false
	}

	handleSubmit = () => {
		const { formData } = this.state
		this.props.pushConfirmDetails(formData)
		this.props.changeStep(3)
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		const { formData } = this.state
		formData[name] = value
		if (!(_.isEmpty(formData.passport) || _.isEmpty(formData.university))) {
			this.state.actionCondition = true
		} else {
			this.state.actionCondition = false
		}
		this.setState(this.state)
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { classes } = this.props
		const { formData, actionCondition } = this.state
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Confirm these details</h1>
					<ValidatorForm onSubmit={actionCondition ? this.handleSubmit : () => { }}>
						<div style={{ marginTop: 30 }}>
							<TextValidator
								onChange={this.handleChange}
								value={formData.passport}
								name="passport"
								style={{ marginTop: 38 }}
								label="Passport Number*"
								placeholder="Enter your Passport number"
								variant="outlined"
								fullWidth />

							<TextValidator
								onChange={this.handleChange}
								value={formData.university}
								name="university"
								style={{ marginTop: 38 }}
								label="University*"
								placeholder="Enter the name of your University"
								variant="outlined"
								fullWidth />

							<DatePicker
								margin="normal"
								inputVariant="outlined"
								label="Date of Arrival"
								format="MM/dd/yyyy"
								value={formData.arrival_date == "" ? null : formData.arrival_date}
								onChange={(date) => {
									let e = { target: { name: "arrival_date", value: moment(date).format("MM/DD/YYYY") } }
									this.handleChange(e)
								}}
								autoOk
								disablePast
								style={{ marginTop: 50 }}
								fullWidth />

							<Text style={{ lineHeight: 2, color: Theme.Colors.warmGreyTwo, fontSize: 13, width: "98%", marginTop: 15 }}>
								When are you arriving in the US? We'll ship your card to make sure it's waiting for you when you reach. <img src={require('images/Auth/smiling2.png')} style={{ width: 20, height: 20, marginTop: 2, marginLeft: 5, position: 'absolute' }} />
							</Text>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
							<Button
								color={'primary'}
								variant="outlined"
								onClick={() => this.props.changeStep(2.1)}
								style={{ fontWeight: 700, width: 170 }}>
								Back
                        	</Button>
							<Button
								color={!actionCondition ? 'default' : 'primary'}
								variant="contained"
								type="submit"
								style={{ fontWeight: 700, width: 170 }}>
								Continue
                        	</Button>
						</div>
					</ValidatorForm>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	pushConfirmDetails: (formData) => dispatch(pushConfirmDetails(formData))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 500,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(Step7Cip2ConfirmDetails))