import { toggleLoader, toggleError } from "actions/actions"
import utils from "resources/utils"
import _ from 'lodash'

export const FD_RECEIVED = 'FD_RECEIVED'
export const NRI_UPDATE_SIGNUP_KEY = 'NRI_UPDATE_SIGNUP_KEY'
export const FD_CREATED = 'FD_CREATED'
export const FD_UPDATED = 'FD_UPDATED'

const request_get_fd_terms = () => (
	utils.india_bank_request({
		url: `/fd-terms`
	})
)

// const requestCreateFD = (data) => (
// 	utils.india_bank_request({
// 		url: `/fd`,
// 		method: "POST",
// 		data: data
// 	})
// )

const requestCreateFD = (data) => (
	utils.india_bank_request({
		url: `/fd-manual`,
		method: "POST",
		data: data
	})
)

const requestGetAllFD = () => (
	utils.india_bank_request({
		url: `/fd-manual`
	})
)

const request_cancel_fd = (id) => (
	utils.india_bank_request({
		url: `/fd-manual/${id}`,
		method: "PATCH"
	})
)

const requestRegisterNRIUser = (data) => (
	utils.india_bank_request({
		url: `/register`,
		method: "POST",
		data: data
	})
)

const requestUploadKyc = (data) => (
	utils.india_bank_request({
		url: `/kyc/upload`,
		method: "POST",
		data: data
	})
)

export const get_fd_terms = (state, changeState) => dispatch => {
	dispatch(toggleLoader())

	return request_get_fd_terms().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			let data = JSON.parse(JSON.stringify(res.data.data))

			changeState({ terms: data })
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const createFD = (data, changeStep) => dispatch => {
	dispatch(toggleLoader())

	return requestCreateFD(data).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			changeStep(4)
			dispatch(fd_created(res.data.data))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const getAllFD_Fn = () => dispatch => {

	return requestGetAllFD().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(fd_received(res.data.data))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
		}
	)
}

export const cancel_fd = (id, changeState) => dispatch => {
	dispatch(toggleLoader())

	return request_cancel_fd(id).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			changeState({ fd: res.data[0] })
			dispatch(fd_updated(res.data[0]))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const registerNRIUser = (data, navigation) => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()
	data = JSON.parse(JSON.stringify(data))

	if (state.App.userInfo.cipTag !== -1) {
		delete data.address
	}

	return requestRegisterNRIUser(data).then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(NRI_updateSignUpKey("first_stage_complete"))
			navigation.navigate("NRI_Kyc", {
				step: _.get(state, 'App.userInfo.cipTag') == -1 ? 1 : 3
			})
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const uploadKyc = (data, changeStep) => dispatch => {
	dispatch(toggleLoader())

	return requestUploadKyc(data).then(
		async res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(NRI_updateSignUpKey("kyc_status_pending"))
			changeStep(3)
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

const fd_updated = (fd) => ({
	type: FD_UPDATED,
	fd
})

const fd_created = (fd) => ({
	type: FD_CREATED,
	fd
})

const fd_received = (fd) => ({
	type: FD_RECEIVED,
	fd
})

const NRI_updateSignUpKey = (nri_sign_up_status) => ({
	type: NRI_UPDATE_SIGNUP_KEY,
	nri_sign_up_status
})