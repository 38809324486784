import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import ContainedButton from 'components/ContainedButton';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import OutlinedButton from 'components/OutlinedButton';

export default class Step2 extends Component {

	render() {
		const { formData, changeStep, handleBack, handleChange } = this.props.parentProps

		const actionCondition = !_.isEmpty(formData.address1) && !_.isEmpty(formData.address2) && !_.isEmpty(formData.city) && !_.isEmpty(formData.state) && !_.isEmpty(formData.postalCode)

		return (
			<div>
				<Text bold="true" style={STYLES.headline}>Let's get started</Text>
				<ValidatorForm onSubmit={() => { }}>

					{
						_.map([{
							label: "Street Address", placeholder: "For example, 1 Market Street", name: "address1"
						}, {
							label: "House/Apartment/Unit Number", placeholder: "For example Apt 42", name: "address2"
						}, {
							label: "City", placeholder: "Enter your city", name: "city"
						}, {
							label: "State", placeholder: "Select your state", name: "state",
						}, {
							label: "Zip Code", placeholder: "Enter your zip code", name: "postalCode",
						}], (value, index) => {
							return (
								<TextValidator
									key={`address_${index}`}
									value={formData.username}
									onChange={e => handleChange(value.name, e.target.value)}
									value={formData[value.name]}
									label={value.label}
									placeholder={value.placeholder}
									variant="outlined"
									style={{ marginTop: 40 }} fullWidth />
							)
						})
					}

					<div style={{ width: 450, paddingTop: 50, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            			</OutlinedButton>
						<ContainedButton
							color={!actionCondition && "default"}
							style={{ width: 200 }}
							onClick={actionCondition ? () => changeStep(3) : () => { }}>
							Continue
            			</ContainedButton>
					</div>

				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	container: {
		flex: 1,
	},
	contentContainer: {
		width: "90%",
		marginLeft: '5%'
	},
	headline: {
		fontSize: 24
	},
	headerContainer: {
		marginTop: 20,
		flexDirection: 'row', justifyContent: 'space-between'
	},
	arrowBack: {
		marginLeft: -5
	},
	textInput: {
		marginTop: 20,
	},
	actionButton: {
		marginTop: 30, marginBottom: 30
	}
}