import axios from 'axios';
import Cookies from 'universal-cookie';
import _ from 'lodash';

const hostname = window && window.location && window.location.hostname;
//dont put slash in end

const env = {
	STAGING: 'Staging',
	PRODUCTION: 'Production'
};

const ENVIRONMENT = (() => {
	if (/localhost/i.test(hostname) || (hostname === '127.0.0.1') || /nolo-dev/i.test(hostname) || /qawebapp.nolobank.com/i.test(hostname) || /vercel.app/i.test(hostname))
		return env.STAGING;
	else
		return env.PRODUCTION;
})()

const STAGING_CLEVERTAP_PROJECT_ID = 'TEST-Z8Z-WZR-8Z6Z';
const PRODUCTION_CLEVERTAP_PROJECT_ID = 'W8Z-WZR-8Z6Z';

const STAGING_PROXY_URL = 'https://tntqwidwm2g.SANDBOX.verygoodproxy.com/api/v1'
const PRODUCTION_PROXY_URL = 'https://tntcg4jd1ty.LIVE.verygoodproxy.com/api/v1'

const STAGING_TRANSFERWISE_URL = 'https://sandbox.transferwise.tech'
const PRODUCTION_TRANSFERWISE_URL = 'https://transferwise.com'

const BEAMER_URL = 'https://api.getbeamer.com/v0';
const STAGING_BEAMER_API_KEY = 'b_kJ2KlZIqkRYFsQNImlZQDEp20p6gZXO+jcCApzudeNc='
const PRODUCTION_BEAMER_API_KEY = 'b_GNd4tjWAt5eqqfZPVMKP3EQJ/67RTGmL4wHJXJtXJmk='

const PLAID_PUBLIC_KEY = '5e495b53be2471236165f16f40c6c4'

const STAGING_RAZORPAY_KEY = 'rzp_test_cRGeL48rvEXDoX'
const PRODUCTION_RAZORPAY_KEY = 'rzp_live_EMLJPtamSM9d62'

const STAGING_URL = 'https://qa.nolobank.com/api/v1';
// const STAGING_URL = "http://192.168.1.71:3004/api/v1";
const PRODUCTION_URL = 'https://prod.nolobank.com/api/v1';

const STAGING_TRANSFERWISE_BACKEND_URL = 'https://qa-send-abroad.nolobank.com/api/v1'
// const STAGING_TRANSFERWISE_BACKEND_URL = "http://192.168.1.71:3002/api/v1";
const PRODUCTION_TRANSFERWISE_BACKEND_URL = 'https://prod-send-abroad.nolobank.com/api/v1'

const STAGING_INDIA_BANK_URL = 'https://qa-ind-bnk.nolobank.com/india-bank'
// const STAGING_INDIA_BANK_URL = "http://192.168.1.71:3000/api/v1";
const PRODUCTION_INDIA_BANK_URL = 'https://prod-ind-bnk.nolobank.com/india-bank'

const STAGING_INDIA_PLAID_URL = 'https://qa-india-plaid.nolobank.com/api'
// const STAGING_INDIA_PLAID_URL = "http://192.168.1.25:3000/api";
const PRODUCTION_INDIA_PLAID_URL = 'https://prod-india-plaid.nolobank.com/api'

const STAGING_MUTUAL_FUND_URL = 'https://qa-imf.nolobank.com/api/v1'
// const STAGING_MUTUAL_FUND_URL = "http://192.168.1.71:3001/api/v1";
const PRODUCTION_MUTUAL_FUND_URL = 'https://prod-imf.nolobank.com/api/v1'

const STAGING_US_INVESTING_URL = 'https://qa-us-invest.nolobank.com/api/v1'
// const STAGING_US_INVESTING_URL = "http://192.168.1.25:3003/api/v1";
const PRODUCTION_US_INVESTING_URL = 'https://prod-us-invest.nolobank.com/api/v1'


let utils = {
	//API Related,
	env,
	ENVIRONMENT,
	get_base_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_URL
			case env.PRODUCTION:
				return PRODUCTION_URL
		}
	},
	get_proxy_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_PROXY_URL
			case env.PRODUCTION:
				return PRODUCTION_PROXY_URL
		}
	},
	get_india_bank_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_INDIA_BANK_URL
			case env.PRODUCTION:
				return PRODUCTION_INDIA_BANK_URL
		}
	},
	get_india_plaid_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_INDIA_PLAID_URL
			case env.PRODUCTION:
				return PRODUCTION_INDIA_PLAID_URL
		}
	},
	get_mutual_fund_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_MUTUAL_FUND_URL
			case env.PRODUCTION:
				return PRODUCTION_MUTUAL_FUND_URL
		}
	},
	get_transferwise_backend_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_TRANSFERWISE_BACKEND_URL
			case env.PRODUCTION:
				return PRODUCTION_TRANSFERWISE_BACKEND_URL
		}
	},
	get_transferwise_site_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_TRANSFERWISE_URL
			case env.PRODUCTION:
				return PRODUCTION_TRANSFERWISE_URL
		}
	},
	get_us_investing_url: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_US_INVESTING_URL
			case env.PRODUCTION:
				return PRODUCTION_US_INVESTING_URL
		}
	},
	get_beamer_url: () => {
		return BEAMER_URL
	},
	get_beamer_api_key: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_BEAMER_API_KEY
			case env.PRODUCTION:
				return PRODUCTION_BEAMER_API_KEY
		}
	},
	get_plaid_public_key: () => {
		return PLAID_PUBLIC_KEY;
	},
	get_razorpay_key: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_RAZORPAY_KEY
			case env.PRODUCTION:
				return PRODUCTION_RAZORPAY_KEY
		}
	},
	get_clevertap_project_id: () => {
		switch (ENVIRONMENT) {
			case env.STAGING:
				return STAGING_CLEVERTAP_PROJECT_ID
			case env.PRODUCTION:
				return PRODUCTION_CLEVERTAP_PROJECT_ID
		}
	},

	//requests
	request: (config) => {
		console.log('Request config', config);
		return axios.request(config);
	},
	india_bank_request: (config) => {
		console.log('Request config', config);
		config.url = utils.get_india_bank_url() + config.url
		return axios.request(config);
	},
	india_plaid_request: (config) => {
		console.log('Request config', config);
		config.url = utils.get_india_plaid_url() + config.url
		return axios.request(config);
	},
	mutual_fund_request: (config) => {
		console.log('Request config', config);
		config.url = utils.get_mutual_fund_url() + config.url
		return axios.request(config);
	},
	us_investing_request: (config) => {
		console.log('Request config', config);
		config.url = utils.get_us_investing_url() + config.url
		return axios.request(config);
	},
	transferwise_request: (config) => {
		console.log('Request config', config);
		config.url = utils.get_transferwise_backend_url() + config.url
		return axios.request(config);
	},


	//app fns
	setRequestAuthHeader: (accessToken, oauthToken, userId, email) => {
		utils._storeData("Authorization", accessToken)
		utils._storeData("auth", oauthToken)
		utils._storeData("userId", userId)
		utils._storeData("email", email)

		setRequestAuthHeader(accessToken, oauthToken, userId);
	},
	get_user_id: () => {
		return cookies.get('userId');
	},
	clearCookies: () => {
		cookies.remove('Authorization', { path: '/' });
		cookies.remove('auth', { path: '/' });
		cookies.remove('email', { path: '/' });
		cookies.remove('userId', { path: '/' });
		setRequestAuthHeader('');
	},
	isAuthenticated: () => {
		return !!cookies.get('access_token');
	},
	logout: () => {
		utils.clearCookies()
		setRequestAuthHeader()
	},


	//cookie related
	_storeData: (name, value) => {
		cookies.set(name, value, { path: '/' });
	},
	_retrieveData: (name) => {
		return cookies.get(name);
	},
	_removeData: (name) => {
		return cookies.remove(name, { path: '/' });
	},

	//custom fns
	updateItemList: (list, item, action) => {
		list = list || [];
		let newList = list.slice();
		let itemIndex;
		if (action === 'UPDATE') {
			itemIndex = _.findIndex(newList, { id: item.id });
			if (itemIndex !== -1)
				newList.splice(itemIndex, 1, item);
			return newList;
		} else if (action === 'ADD') {
			newList.unshift(item);
			return newList;
		} else if (action === 'DELETE') {
			_.remove(newList, item)
			return newList;
		}
		return newList;
	},
	inr_comma_format: (x) => {
		x = x.toString()
		let split = x.split(".")
		if (split.length > 1) {
			x = split[0]
		}
		let lastThree = x.substring(x.length - 3)
		let otherNumbers = x.substring(0, x.length - 3)
		if (otherNumbers != "") lastThree = "," + lastThree
		let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
		if (split.length > 1) {
			res = res + "." + split[1]
		}
		return res
	},
	usd_comma_format: (x) => {
		x = x.toFixed(2)
		x = x.toString();
		let split = x.split(".")
		x = split[0]

		let res = x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + split[1];
		return res
	}
}

switch (ENVIRONMENT) {
	case env.STAGING:
		axios.defaults.baseURL = STAGING_URL
		break

	case env.PRODUCTION:
		axios.defaults.baseURL = PRODUCTION_URL
		break;
}

axios.defaults.headers.post['Content-Type'] = 'application/json';
const cookies = new Cookies();

let setRequestAuthHeader = (accessToken, oAuth, userId) => {
	axios.defaults.headers.common['Authorization'] = accessToken;
	axios.defaults.headers.common['auth'] = oAuth;
	axios.defaults.headers.common['user_id'] = userId;
}

let accessToken = cookies.get('Authorization');
let oAuthToken = cookies.get('auth');
let userId = cookies.get('userId');

if (accessToken) {
	setRequestAuthHeader(accessToken, oAuthToken, userId);
}

export default utils;