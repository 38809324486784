import React, { Component } from 'react'
import { connect } from 'react-redux'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import _ from 'lodash';
import { PDFDownloadLink, Document, Page, View, Text as Text2, StyleSheet } from '@react-pdf/renderer'

const DetailsDoc = (props) => (
	<Document>
		<Page style={PDFStyles.body}>
			<Text2 style={PDFStyles.title} fixed>
				Funds Transfer Info
      </Text2>
			{props.bank_details.map(value => (
				<View>
					<Text2 style={PDFStyles.text}>{value.name} - {value.details}</Text2>
				</View>
			))}
		</Page>
	</Document>
)

const PDFDownload = (props) => {
	return (
		<div>
			<PDFDownloadLink document={<DetailsDoc bank_details={props.bank_details} />} fileName="TransferInfo.pdf">
				{({ blob, url, loading, error }) => (loading ? <Text style={{ fontSize: 16, color: Theme.Colors.primaryPurple, marginLeft: 15 }}>Downloading</Text> : <Text style={{ fontSize: 16, color: Theme.Colors.primaryPurple, marginLeft: 15 }}>Download wire transfer info</Text>)}
			</PDFDownloadLink>
		</div>
	)
}

class USI_Add_Funds extends Component {

	state = {
		bank_details: [{
			name: "Bank Name", details: "M&T Bank"
		}, {
			name: "ABA#", details: "022000046"
		}, {
			name: "SWIFT Code", details: "MANTUS33"
		}, {
			name: "Bank Address", details: "One M & T Plaza, Buffalo, NY 14203"
		}, {
			name: "Beneficiary Name", details: "DriveWealth, LLC"
		}, {
			name: "Beneficiary Account Number", details: "9870304186"
		}, {
			name: "Beneficiary Address", details: "97 Main Street, Second Floor, Chatham, NJ 07928"
		}, {
			name: "Further Credit To", details: this.props.us_investor_details.account_no
		}],
		share_details: false,
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ ...this.state, share_details: true })
		}, 1000)
	}

	render() {
		return (
			<div>
				<div style={{ padding: 40, background: Theme.Colors.white, borderRadius: 5 }}>
					<div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
						<img src={require("images/Investing/US_Investing/add-funds.png")} style={{ height: 100, width: 100 }} />
						<Text bold="true" style={{ marginTop: 20, fontSize: 24 }}>Congratulations!</Text>
						<Text style={{ marginTop: 5, lineHeight: 2.4, color: Theme.Colors.purplishGrey, textAlign: 'center', width: 320 }}>Your US brokerage account has been created. Here’s what to do next</Text>
					</div>

					<div className="box-shadow" style={{
						marginTop: 20, padding: 20, paddingBottom: 0, backgroundColor: Theme.Colors.white, borderRadius: 10
					}}>
						<div style={{ display: "flex", border: `0px solid ${Theme.Colors.whiteTwo}`, borderBottomWidth: 1, paddingBottom: 20 }}>
							<img src={require("images/Investing/US_Investing/bank.png")} style={{ height: 45, width: 45 }} />
							<Text bold="true" style={{ fontSize: 16, marginLeft: 10, marginTop: 10 }}>Transfer funds to the following account</Text>
						</div>
						{
							_.map(this.state.bank_details, (v, i) => {
								return (
									<div key={`item_${i}`} style={{
										...i !== this.state.bank_details.length - 1 && { border: `0px solid ${Theme.Colors.whiteTwo}`, borderBottomWidth: 1 },
										padding: `20px 0`
									}}>
										<Text>{v.name}</Text>
										<Text style={{ marginTop: 5 }}>{v.details}</Text>
									</div>
								)
							})
						}

					</div>
					<Text style={{ color: Theme.Colors.purplishGrey, marginTop: 25 }}>Why should you transfer?</Text>
					<Text style={{ marginTop: 5, lineHeight: 2.4 }}>You will be able to invest 100% of the funds in your account. North Loop users transfer $1,250 on average. </Text>

					<Text style={{ color: Theme.Colors.purplishGrey, marginTop: 25 }}>Is my money safe?</Text>
					<Text style={{ marginTop: 5, lineHeight: 2.4 }}>All money held in your US brokerage account is insured up to $500,000 by the US Government. </Text>

					<div style={{ marginTop: 25, marginBottom: 30 }}>
						<div style={{ display: "flex" }}>
							<i className="material-icons" style={{ color: Theme.Colors.primaryPurple, fontSize: 20, marginTop: 2 }}>share</i>
							{this.state.share_details && <PDFDownload bank_details={this.state.bank_details} />}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Investing, ["us_investor_details"])
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(USI_Add_Funds)

const PDFStyles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 45,
		paddingHorizontal: 35,
	},
	title: {
		fontSize: 20,
		marginBottom: 15,
		textAlign: 'center',
	},
	text: {
		margin: 12,
		fontSize: 12,
		textAlign: 'justify',
	},
})