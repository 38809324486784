import React, { Component } from 'react';
import utils from 'resources/utils'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash'
class PrivateRoute extends Component {

	state = {}

	componentWillMount() {

	}

	render() {
		const { accountInfo, userInfo } = this.props

		if (utils.get_user_id()) {
			if (_.isObject(userInfo) && _.isEmpty(userInfo)) {
				return <div></div>
			}

			if (!_.isEmpty(userInfo) && userInfo.sign_up_status_2 !== 'firstStageIncomplete') {
				return (
					<Route path={this.props.path} component={this.props.component} />
				)
			}
		}

		return <Redirect to='/' />
	}
}


const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo'])
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)