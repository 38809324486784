import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { getExpenseDetailedTransactions } from './actions';
import { Button, ListItem } from '@material-ui/core';
import Header from 'components/Header';
import moment from 'moment';
import ExpenseMerchantHistory from './ExpenseMerchantHistory';

const Categories = [{
	name: "Entertainment", category: "service", categoryAlias: "service", image: require('images/Expense/entertainment.png'), color: '#fc7788'
}, {
	name: "Transportation", category: "travel/transportation", categoryAlias: "transportation", image: require('images/Expense/travel.png'), color: '#6287ed'
}, {
	name: "Food and Dining", category: "dining", categoryAlias: "dining", image: require('images/Expense/dining.png'), color: '#f48c37'
}, {
	name: "Transfers", category: "transfer", categoryAlias: "transfer", image: require('images/Transaction/Transfer.png'), color: '#8fc144'
}, {
	name: "General Spending", category: "pos", categoryAlias: "pos", image: require('images/Transaction/POS.png'), color: '#ce63c7'
}, {
	name: "Groceries", category: "grocery", categoryAlias: "grocery", image: require('images/Expense/groceries.png'), color: '#eaca0a'
}, {
	name: "Withdrawal", category: "withdrawal", categoryAlias: "withdrawal", image: require('images/Expense/withdraw.png'), color: '#39b1c6'
}, {
	name: "Others", category: "", categoryAlias: "", image: require('images/Expense/others.png'), color: '#79abf7'
}]

class ExpenseDetails extends Component {

	state = {
		merchantDetails: {}
	}

	componentWillMount() {
		let categoryObj = _.find(Categories, { categoryAlias: this.props.match.params.category })

		if (_.isEmpty(categoryObj)) {
			this.props.history.push("/404")
			return
		}

		let month = Number(this.props.match.params.month || moment().format("M"))
		let year = Number(this.props.match.params.year || moment().format("YYYY"))

		this.props.getExpenseDetailedTransactions(categoryObj.category, month, year, _.isEmpty(this.props.spendByCategories) ? true : false)
	}

	componentWillReceiveProps(nextProps) {
		let category = nextProps.match.params.category.toLowerCase()
		if (category != this.props.match.params.category.toLowerCase()) {
			let categoryObj = _.find(Categories, { categoryAlias: nextProps.match.params.category })

			if (_.isEmpty(categoryObj)) {
				this.props.history.push("/404")
				return
			}

			let month = Number(nextProps.match.params.month || moment().format("M"))
			let year = Number(nextProps.match.params.year || moment().format("YYYY"))

			this.props.getExpenseDetailedTransactions(categoryObj.category, month, year)
		}
	}

	handleSelectMerchant = (merchantDetails) => {
		this.setState({ merchantDetails })
	}

	render() {
		const { expenseTrans, spendByCategories } = this.props

		let categoryObj = _.find(Categories, { categoryAlias: this.props.match.params.category })

		let month = Number(this.props.match.params.month || moment().format("M"))
		let year = Number(this.props.match.params.year || moment().format("YYYY"))

		let totalAmount = 0

		_.map(Categories, (value, index) => {
			totalAmount += Number((
				(_.find(spendByCategories, { to_merchant_category: value.category }) || {}).sum_amount || 0
			).toFixed(2))
		})

		let name = categoryObj.name

		let merchant_name
		if (name == 'Withdrawal') {
			merchant_name = "Atm Withdrawal"
		}
		return (
			<div>
				<Header name="Categories" rightBar={!_.isEmpty(this.state.merchantId) ? 350 : 650} />
				<div style={{ marginBottom: 20, width: !_.isEmpty(this.state.merchantId) ? 350 : 650 }}>
					{
						_.map(Categories, (value, index) => {
							let amount = (
								(_.find(spendByCategories, { to_merchant_category: value.category }) || {}).sum_amount || 0
							).toFixed(2)

							if (amount == 0) {
								return (
									<React.Fragment key={`transaction_${index}`}>
									</React.Fragment>
								)
							}

							let percentage = (amount / totalAmount * 100).toFixed(0)
							return (
								<div style={STYLES.contentRow} key={`transaction_${index}`} className="box-shadow">
									<ListItem button onClick={
										() => this.props.history.push(`/user/expense-details/${value.categoryAlias}/${month}/${year}`)} style={{ padding: 20, borderRadius: 10, display: 'flex' }}>
										<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
											<img src={value.image} style={{ height: 44, width: 44 }} />
										</div>
										<div style={{ flex: 7, marginLeft: 5 }}>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<Text style={STYLES.rowTitle}>{value.name}</Text>
												<Text bold="true" style={STYLES.rowAmount}>${amount}</Text>
											</div>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<div style={{ height: 7, marginTop: 8, borderRadius: 10, backgroundColor: Theme.Colors.lightGrey, width: '87%' }}>
													<div style={{ backgroundColor: value.color, width: `${percentage}%`, height: '100%', borderRadius: 10, }}></div>
												</div>
												<Text style={{ fontSize: 12, marginTop: 3, color: Theme.Colors.greyishBrown }}>{percentage}%</Text>
											</div>
										</div>
									</ListItem>
								</div>
							)
						})
					}

				</div>
				<div style={{ ...STYLES.expenseTransactions, ...!_.isEmpty(this.state.merchantId) && { right: 350 } }}>
					{
						(!this.props.loader && !_.isEmpty(expenseTrans)) &&
						<div>
							<div>
								<div style={{ padding: 20 }}>
									<Text bold="true" style={STYLES.headline}>{name}</Text>
								</div>
							</div>

							{
								_.map(expenseTrans, (value, index) => {
									return (
										<ListItem button key={`item${index}`} onClick={() => this.handleSelectMerchant({
											merchantId: value.to_merchant_id,
											logo: value.to_merchant_logo,
											name: value.to_merchant_name
										})} style={STYLES.transContainer}>
											<div style={{ justifyContent: 'center', flex: 2 }}>
												<div style={STYLES.transImageContainer}>
													<img src={value.to_merchant_logo} style={{ height: 44, width: 44, borderRadius: 22 }} />
												</div>
											</div>
											<Text style={{ flex: 8, color: Theme.Colors.greyishBrown }}>{merchant_name || value.to_merchant_name}</Text>
											<Text style={{ flex: 2, textAlign: 'right', color: Theme.Colors.greyishBrown }}>${value.amount_amount}</Text>
										</ListItem>
									)
								})
							}
						</div>
					}

				</div>

				{
					!_.isEmpty(this.state.merchantDetails) &&
					<div style={STYLES.expenseTransactions}>
						<ExpenseMerchantHistory merchantDetails={this.state.merchantDetails} />
					</div>
				}

			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['loader']),
	..._.pick(state.Expense, ['expenseTrans', 'spendByCategories', 'totalSpend', 'expenseComparison']),
})

const mapDispatchToProps = dispatch => ({
	getExpenseDetailedTransactions: (category, month, year, getExpenseDetails) => dispatch(getExpenseDetailedTransactions(category, month, year, getExpenseDetails))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetails)

const STYLES = {
	headline: {
		fontSize: 25
	},
	contentRow: {
		marginTop: 20, borderRadius: 10, backgroundColor: Theme.Colors.white
	},
	expenseTransactions: {
		position: 'fixed', right: 0, top: 0, bottom: 0, width: 350, background: Theme.Colors.white, overflow: 'scroll', border: `0px solid ${Theme.Colors.lightGrey}`, borderLeftWidth: 1
	},
	transContainer: {
		display: 'flex', padding: `15px`, borderBottomWidth: 1, borderBottomColor: Theme.Colors.whiteTwo
	},
	transImageContainer: {
		height: 44, width: 44, backgroundColor: Theme.Colors.white, borderRadius: 22
	}
}