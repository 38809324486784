import React, { PureComponent } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import Upload from 'components/Upload'
import { addImage, removeImage } from '../../actions';
import { Card, Button, withStyles } from '@material-ui/core'
import loginBackground from 'images/login-background.png'

class SubStep1Cip2Passport extends PureComponent {

	render() {
		const { classes } = this.props
		let document = false //review docs

		let front = document || "signUpPayloadCip2.documents.physical_docs[0].document_value"

		let actionCondition = !_.isEmpty(this.props, front)

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Upload a photo of your ID</h1>
					<div style={{ marginTop: 30 }}>
						<Upload
							navigation={this.props.navigation}
							label="Front page of passport"
							image={_.get(this.props, front)}
							addImage={(image) => this.props.addImage(front, image)}
							removeImage={() => this.props.removeImage(front)}
							style={{ marginTop: 40 }} />

					</div>
					<div style={{ display: 'flex', marginTop: 40, marginBottom: 30, justifyContent: 'center' }}>
						<div>
							<div style={{ borderColor: Theme.Colors.lightGrey, borderWidth: 0.5, borderRadius: 5, backgroundColor: 'white' }}>
								<img src={require('images/Auth/passport-sample.jpg')} />
							</div>
							<Text style={{ color: Theme.Colors.warmGrey, marginTop: 10, textAlign: 'center' }}>Sample</Text>
						</div>
						<div style={{ marginLeft: 40, marginTop: 10 }}>
							<div style={{ display: 'flex', }}>
								<Text style={{ color: Theme.Colors.warmGrey }}>1.</Text>
								<Text style={{ color: Theme.Colors.warmGrey, marginLeft: 10 }}>Make sure all corners are clearly visible.</Text>
							</div>
							<div style={{ display: 'flex', marginTop: 10 }}>
								<Text style={{ color: Theme.Colors.warmGrey }}>2.</Text>
								<Text style={{ color: Theme.Colors.warmGrey, marginLeft: 10 }}>Please make sure details are visible and no flash is used.</Text>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 20 }}>
						<p style={{ marginBottom: 5 }}>NOTE:</p>
						<ol style={{ lineHeight: 2, marginLeft: 15 }}>
							<li>Make sure all corners are clearly visible.</li>
							<li>Please make sure details are visible and no flash is used.</li>
						</ol>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color='primary'
							variant="outlined"
							onClick={() => this.props.changeStep(2)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color={!actionCondition ? 'default' : 'primary'}
							variant="contained"
							onClick={actionCondition ? () => this.props.changeStep(2.2) : () => { }}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
                        	</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ["signUpPayloadCip2"]),
	..._.pick(state.App, ["accountInfo"])
})

const mapDispatchToProps = dispatch => ({
	addImage: (key, image, reupload) => dispatch(addImage(key, image, reupload)),
	removeImage: (key, reupload) => dispatch(removeImage(key, reupload)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 800,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SubStep1Cip2Passport))