import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Text from 'components/Text';
import { submitLostCard } from './actions';
import { Button, ListItem } from '@material-ui/core';

class US_LostCardStep2 extends React.Component {

	state = {
		expedite: false
	}

	handleSubmit = () => {
		this.props.submitLostCard({
			expedite: this.state.expedite,
			issueNewCard: this.props.issueNewCard,
		}, this.props.changeStep)
	}

	render() {
		const { } = this.props
		return (
			<div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginTop: 30 }}>
					<img style={{ width: 160, height: 150 }} src={require('images/LostCard/step2.png')} />
				</div>
				<Text style={{ fontSize: 14, color: Theme.Colors.purplishGrey, marginTop: 20 }}>Delivery address</Text>
				<div style={{ padding: 20, marginTop: 10, backgroundColor: Theme.Colors.whiteTwo, }}>
					<Text style={{ fontSize: 14 }}>Your card will be shipped to your registered address</Text>
				</div>
				<Text style={{ fontSize: 14, marginTop: 30, color: Theme.Colors.purplishGrey }}>Select Delivery Type</Text>
				{
					_.map([{
						title: "Standard mail", subTitle: "8 business days", money: "0", expedite: false
					}, {
						title: "Rush", subTitle: "2 business days", money: "50", expedite: true
					}], (value, index) => {
						return (
							<div key={`box${index}`} style={{ backgroundColor: Theme.Colors.white, }}>
								<ListItem
									button
									onClick={() => this.setState({ expedite: value.expedite })}
									style={{
										padding: 30, marginTop: 15, borderRadius: 5, display: 'flex', justifyContent: "space-between",
										...this.state.expedite === value.expedite &&
										{ borderWidth: 1, borderColor: Theme.Colors.primaryPurple }
									}}>
									<div style={{ display: 'flex' }}>
										{
											this.state.expedite === value.expedite ?
												<img src={require("images/Auth/group5.png")} style={{ height: 30, width: 30 }} />
												:
												<div style={{ backgroundColor: Theme.Colors.whiteTwo, height: 30, width: 30, borderRadius: 5 }}>
												</div>
										}
										<div style={{ marginLeft: 30, marginTop: -10 }}>
											<Text bold="true" style={{
												fontSize: 22,
												...this.state.expedite === value.expedite &&
												{ color: Theme.Colors.primaryPurple }
											}}>{value.title}</Text>
											<Text style={{ fontSize: 14, color: this.state.expedite === value.expedite ? Theme.Colors.primaryPurple : Theme.Colors.warmGrey }}>{value.subTitle}</Text>
										</div>
									</div>
									<Text bold="true" style={{ color: Theme.Colors.primaryPurple, fontSize: 34, marginTop: -10 }}>${value.money}</Text>
								</ListItem>
							</div>
						)
					})
				}

				<Button variant="contained" color="primary" onClick={this.handleSubmit} style={{ width: '100%', marginTop: 30, marginBottom: 20 }}>
					CONTINUE
				</Button>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
	submitLostCard: (formData, changeStep) => dispatch(submitLostCard(formData, changeStep))
})

export default connect(mapStateToProps, mapDispatchToProps)(US_LostCardStep2)


const STYLES = {

}