import utils from 'resources/utils'
import { toggleLoader, toggleError } from 'actions/actions'
import _ from 'lodash'
// import firebase from 'react-native-firebase';

export const CASHBACK_DETAILS_RECEIVED = 'CASHBACK_DETAILS_RECEIVED'
export const POLL_PLACES_LIST_RECEIVED = 'POLL_PLACES_LIST_RECEIVED'

const requestCashbackDetails = () => (
	utils.request({
		url: `/banking/cashback`
	})
)

const requestRedeemCashback = () => (
	utils.request({
		url: `/banking/cashback/redeem`,
		method: "POST"
	})
)

const requestCheckPollStatus = () => (
	utils.request({
		url: `/campus/status`
	})
)

const requestGetPlaceList = () => (
	utils.request({
		url: `/campus/poll`
	})
)

const requestSubmitVote = (data) => (
	utils.request({
		url: `/campus/vote`,
		method: "POST",
		data: data
	})
)

export const getCashbackDetails = (navigation) => (dispatch) => {
	dispatch(toggleLoader())
	requestCashbackDetails().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(cashbackDetailsReceived(_.get(res, 'data.data') || []))
			requestCheckPollStatus().then(
				res2 => {
					if (res2.status !== 200) {
						console.log("Something went wrong")
						dispatch(toggleLoader())
						return
					}

					requestGetPlaceList().then(
						res3 => {
							if (res3.status !== 200) {
								console.log("Something went wrong")
								dispatch(toggleLoader())
								return
							}

							if (_.isEmpty(res3.data.data)) {
								dispatch(toggleLoader())
							} else {
								dispatch(pollPlacesReceived(res3.data.data))
								if (res2.data.data.voted === false) {
									// navigation.push("Vote")
								}
								dispatch(toggleLoader())
							}
						},
						err => {
							console.log(JSON.stringify(err), 'error')
							dispatch(toggleError(err))
							dispatch(toggleLoader())
						}
					)
				},
				err => {
					console.log(JSON.stringify(err), 'error')
					dispatch(toggleError(err))
					dispatch(toggleLoader())
				}
			)
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const redeemCashback = () => dispatch => {
	dispatch(toggleLoader())
	// firebase.analytics().logEvent("cashback_redeem", {});
	requestRedeemCashback().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(toggleLoader())
			dispatch(getCashbackDetails())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}

export const submitVote = (formData, navigation) => dispatch => {
	dispatch(toggleLoader())
	requestSubmitVote(formData).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(toggleLoader())
			navigation.goBack()
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			dispatch(toggleError(err))
			dispatch(toggleLoader())
		}
	)
}


const cashbackDetailsReceived = (details) => ({
	type: CASHBACK_DETAILS_RECEIVED,
	details
})

const pollPlacesReceived = (pollPlacesList) => ({
	type: POLL_PLACES_LIST_RECEIVED,
	pollPlacesList
})