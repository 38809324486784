import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import Upload from 'components/Upload'
import { addImage, removeImage } from '../../actions';
import { withStyles, Button, Card } from '@material-ui/core';
import loginBackground from 'images/login-background.png'

class SubStep2Cip3IdProof extends Component {

	state = {
		help: false
	}

	toggleHelp = () => {
		this.setState({
			help: !this.state.help
		})
	}

	render() {
		const { classes } = this.props

		let front = "signUpPayloadCip3.documents.physical_docs[0].document_value"
		let back = "signUpPayloadCip3.documents.physical_docs[1].document_value"

		let actionCondition = !_.isEmpty(this.props, front) && !_.isEmpty(this.props, back)

		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>Upload a photo of your ID</h1>
					<div style={{ marginTop: 30 }}>
						<Upload
							navigation={this.props.navigation}
							label="Front of ID"
							image={_.get(this.props, front)}
							addImage={(image) => this.props.addImage(front, image)}
							removeImage={() => this.props.removeImage(front)}
							style={{ marginTop: 40 }} />
						<Upload
							navigation={this.props.navigation}
							label="Back of ID"
							image={_.get(this.props, back)}
							addImage={(image) => this.props.addImage(back, image)}
							removeImage={() => this.props.removeImage(back)}
							style={{ marginTop: 40 }} />

					</div>
					<div style={{ marginTop: 20 }}>
						<p style={{ marginBottom: 5 }}>NOTE:</p>
						<ol style={{ lineHeight: 2, marginLeft: 15 }}>
							<li>Make sure all corners are clearly visible.</li>
							<li>Please make sure details are visible and no flash is used.</li>
						</ol>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => this.props.changeStep(2)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color={actionCondition ? 'primary' : 'default'}
							variant="contained"
							onClick={actionCondition ? () => this.props.changeStep(3) : () => { }}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
                        	</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ["signUpPayloadCip3", "doc_cip_tag3"])
})

const mapDispatchToProps = dispatch => ({
	addImage: (key, image) => dispatch(addImage(key, image)),
	removeImage: (key) => dispatch(removeImage(key))
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 800,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SubStep2Cip3IdProof))