import React, { PureComponent } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme'
import Upload from 'components/Upload'
import { addImage, removeImage } from '../../actions';
import { Card, Button, withStyles } from '@material-ui/core'
import loginBackground from 'images/login-background.png'

class SubStep1Cip2i20 extends PureComponent {

	render() {

		const { classes } = this.props

		let page1 = "signUpPayloadCip2.documents.physical_docs[1].document_value"
		let page2 = "signUpPayloadCip2.documents.physical_docs[2].document_value"
		let page3 = "signUpPayloadCip2.documents.physical_docs[3].document_value"

		let actionCondition = !_.isEmpty(this.props, page1) && !_.isEmpty(this.props, page2) && !_.isEmpty(this.props, page3)
		return (
			<div className={classes.container}>
				<Card className={classes.card}>
					<h1>I-20 (All 3 pages)</h1>
					<div style={{ marginTop: 30 }}>

						<Upload
							navigation={this.props.navigation}
							label="Page 1"
							image={_.get(this.props, page1)}
							addImage={(image) => this.props.addImage(page1, image)}
							removeImage={() => this.props.removeImage(page1)}
							style={{ marginTop: 20 }} />

						<Upload
							navigation={this.props.navigation}
							label="Page 2"
							image={_.get(this.props, page2)}
							addImage={(image) => this.props.addImage(page2, image)}
							removeImage={() => this.props.removeImage(page2)}
							style={{ marginTop: 20 }} />

						<Upload
							navigation={this.props.navigation}
							label="Page 3"
							image={_.get(this.props, page3)}
							addImage={(image) => this.props.addImage(page3, image)}
							removeImage={() => this.props.removeImage(page3)}
							style={{ marginTop: 20 }} />

					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: 400, margin: '0 auto', marginTop: 50 }}>
						<Button
							color={'primary'}
							variant="outlined"
							onClick={() => this.props.changeStep(2)}
							style={{ fontWeight: 700, width: 170 }}>
							Back
                        	</Button>
						<Button
							color={!actionCondition ? 'default' : 'primary'}
							variant="contained"
							onClick={actionCondition ? () => this.props.changeStep(2.3) : () => { }}
							style={{ fontWeight: 700, width: 170 }}>
							Continue
						</Button>
					</div>
				</Card>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.Auth, ["signUpPayloadCip2"])
})

const mapDispatchToProps = dispatch => ({
	addImage: (key, image) => dispatch(addImage(key, image)),
	removeImage: (key) => dispatch(removeImage(key)),
})

const STYLES = {
	container: {
		minHeight: '100vh',
		background: `url(${loginBackground})`,
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: 800,
		padding: 60,
		borderRadius: 10
	}
}

export default withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(SubStep1Cip2i20))