import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Text from 'components/Text'
import Theme from 'resources/Theme'
import { withStyles, ListItem, Button } from '@material-ui/core'
import Plaid from './Plaid'
import { withRouter } from 'react-router-dom'

class BankAccountSection extends React.Component {

	render() {
		const { userInfo, accountInfo, plaidAccounts } = this.props
		let depositAccounts = _.filter(plaidAccounts, (value, index) => {
			if (value.type != 'credit') {
				return true
			}
		})

		return (
			<>
				{
					userInfo.cipTag != -1 && (
						_.isEmpty(plaidAccounts) && userInfo.sign_up_status_2 !== 'completed' ?
							<ListItem button onClick={() => document.getElementById("plaid-id").click()} style={{ ...STYLES.accountsContainer, marginTop: 20, padding: 20, flexDirection: 'row', alignItems: 'center' }}>
								<div style={{ width: 80 }}>
									<img src={require("images/linkAccount.png")} style={{ width: 72, height: 72 }} />
								</div>
								<div style={{ marginLeft: '3%', marginTop: -8 }}>
									<Text style={{ color: Theme.Colors.greyishBrown, lineHeight: 2 }}>Link your bank account and start understanding your expenses</Text>
									<span style={{ display: 'flex', marginTop: 5 }}>
										<Text bold="true" style={{ fontSize: 16, color: Theme.Colors.primaryPurple }}>
											Link Account
										</Text>
										<i className="material-icons" style={{ fontSize: 24, marginTop: -1, color: Theme.Colors.primaryPurple }}>chevron_right</i>
									</span>
								</div>
							</ListItem>
							:
							<div style={{ marginTop: 20, }}>
								<div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
									<Text semi="true" style={{ fontSize: 16 }}>My accounts</Text>
								</div>
								<div style={{ ...STYLES.accountsContainer, marginTop: 10, }}>
									{
										userInfo.sign_up_status_2 == 'completed' &&
										<ListItem button
											onClick={() => this.props.history.push('/user/transactions/north-loop')}
											style={{ ...STYLES.account, border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1, }}>
											<div style={STYLES.imageContainer}>
												<img src={require("images/BottomMenu/tab3-filled.png")} style={{ width: 35, height: 30 }} />
											</div>
											<div style={{ marginLeft: 20 }}>
												<Text style={{ fontSize: 12, color: Theme.Colors.warmGreyTwo }}>North Loop</Text>
												<Text bold="true" style={{ fontSize: 24, marginTop: 5 }}>
													${(_.get(accountInfo, 'info.balance.amount') !== undefined ? Number(_.get(accountInfo, 'info.balance.amount')).toFixed(2) : "")}
												</Text>
											</div>
											<i className="material-icons" style={STYLES.rightIcon}>chevron_right</i>
										</ListItem>
									}
									{
										_.map(depositAccounts || [], (value, index) => {
											return (
												<div key={`item${index}`} style={{
													border: `0px solid ${Theme.Colors.lightGrey}`, borderBottomWidth: 1,
												}}>
													<ListItem button
														onClick={() => {
															if (value.error) {
																this.props.toggleModal(value.item_id)
															} else {
																this.props.history.push(`/user/transactions/linked-${value.account_id}`)
															}
														}}
														style={STYLES.account}>
														<div style={STYLES.imageContainer}>
															<img src={`data: image / png; base64, ${value.logo}`} style={{ width: 40, height: 40 }} />
														</div>
														<div style={{ marginLeft: 20 }}>
															<Text style={{ fontSize: 12, color: Theme.Colors.warmGreyTwo }}>{value.institution_name}</Text>
															<Text bold="true" style={{ fontSize: 24, marginTop: 5 }}>
																${(Number(_.get(value, 'balances.available') || 0)).toFixed(2)}
															</Text>
														</div>
														<i className="material-icons" style={STYLES.rightIcon}>chevron_right</i>
													</ListItem>
												</div>
											)
										})
									}
									<div>
										<Button onClick={() => document.getElementById("plaid-id").click()} style={{ width: "100%", padding: `15px 0px` }}>
											<Text bold="true" style={{ fontSize: 18, textAlign: 'center', color: Theme.Colors.primaryPurple }}>+ LINK A BANK ACCOUNT</Text>
										</Button>
									</div>
								</div>
							</div>
					)
				}

				<Plaid />
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['accountInfo', 'userInfo']),
	..._.pick(state.MyProfile, ['plaidAccounts'])
})

const mapDispatchToProps = {

}

const STYLES = {
	accountsContainer: {
		backgroundColor: Theme.Colors.white, borderRadius: 5, display: 'flex', flexDirection: 'column'
	},
	account: {
		width: '100%', padding: 20, display: 'flex'
	},
	imageContainer: {
		width: 50, height: 50, borderRadius: 25, alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'
	},
	rightIcon: {
		fontSize: 40, color: Theme.Colors.warmGrey, position: 'absolute', right: 10
	}
}

export default withRouter(withStyles(STYLES)(connect(mapStateToProps, mapDispatchToProps)(BankAccountSection)))