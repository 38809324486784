import { Button } from '@material-ui/core';
import React, { Component } from 'react'
import Theme from 'resources/Theme';
import Text from './Text';

export default class ContainedButton extends Component {
	render() {
		return (
			<Button
				variant="contained"
				size="medium"
				color={this.props.color || "primary"}
				style={{ width: '100%', ...(this.props.style || {}) }}
				onClick={this.props.onClick}>
				<Text
					style={{ color: Theme.Colors.white, fontSize: 15, ...(this.props.textStyle || {}) }}
					{...this.props.textProps}>
					{this.props.children}
				</Text>
			</Button>
		)
	}
}
