import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { Card } from '@material-ui/core';
import ContainedButton from 'components/ContainedButton';

class Step8 extends Component {

	render() {
		return (
			<div style={STYLES.container}>
				<img src={require('images/Auth/thankyou.png')} style={{ height: 220, width: 280 }} />
				<h1 style={{ marginTop: 20 }}>Thank you for signing up</h1>
				<Text style={{ lineHeight: 1.8, width: 240, textAlign: 'center', marginTop: 10, color: Theme.Colors.purplishGrey }}>
					Your account is created.
					</Text>

				<ContainedButton
					onClick={this.handleBackPress}
					style={{ fontWeight: 700, marginTop: 50, width: 200 }}>
					Start Investing
					</ContainedButton>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Step8)

const STYLES = {
	container: {
		display: 'flex', flexDirection: 'column', alignItems: 'center',
	},
}