import React, { Component } from 'react'
import Text from 'components/Text'
import _ from 'lodash'
import Theme from 'resources/Theme';
import ContainedButton from 'components/ContainedButton';
import OutlinedButton from 'components/OutlinedButton';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { MenuItem } from '@material-ui/core';

export default class Step1 extends Component {

	render() {
		const { formData, changeStep, handleBack, handleChange } = this.props.parentProps

		const actionCondition = formData.fatca_detail.gross_annual_income > 0

		return (
			<div>
				<Text bold style={STYLES.headline}>Let's get started!</Text>
				<ValidatorForm onSubmit={() => { }}>
					<TextValidator
						onChange={(e) => handleChange('fatca_detail.gross_annual_income', e.target.value)}
						value={formData.fatca_detail.gross_annual_income === 0 ? "" : String(formData.fatca_detail.gross_annual_income)}
						style={{ marginTop: 40 }}
						label="Gross annual income"
						placeholder="Enter income"
						type="number"
						variant="outlined"
						fullWidth
					/>
					<SelectValidator
						value={formData.fatca_detail.source_of_wealth}
						variant="outlined"
						style={{ marginTop: 40 }}
						onChange={(e) =>
							handleChange("fatca_detail.source_of_wealth", e.target.value)
						}
						label="Source of wealth"
						fullWidth>

						<MenuItem value="SALARY">Salary</MenuItem>
						<MenuItem value="BUSINESS">Business</MenuItem>
						<MenuItem value="GIFT">Gift</MenuItem>
						<MenuItem value="ANCESTRAL_PROPERTY">Ancestral Property"</MenuItem>
						<MenuItem value="RENTAL_INCOME">Rental Income</MenuItem>
						<MenuItem value="PRIZE_MONEY">Prize Money</MenuItem>
						<MenuItem value="ROYALTY">Royalty</MenuItem>
						<MenuItem value="OTHERS">Others</MenuItem>

					</SelectValidator>

					<SelectValidator
						value={formData.kyc_identity_detail.occupation}
						variant="outlined"
						style={{ marginTop: 40 }}
						onChange={(e) =>
							handleChange("kyc_identity_detail.occupation", e.target.value)
						}
						label="Occupation"
						fullWidth>

						<MenuItem value="AGRICULTURE">Agriculture</MenuItem>
						<MenuItem value="BUSINESS">Business</MenuItem>
						<MenuItem value="DOCTOR">Doctor</MenuItem>
						<MenuItem value="FOREX_DEALER">Forex dealer</MenuItem>
						<MenuItem value="GOVERNMENT_SERVICE">Government service</MenuItem>
						<MenuItem value="HOUSE_WIFE">House wife</MenuItem>
						<MenuItem value="PRIVATE_SECTOR_SERVICE">Private sector service</MenuItem>
						<MenuItem value="PROFESSIONAL">Professional</MenuItem>
						<MenuItem value="PUBLIC_SECTOR_SERVICE">Public sector service</MenuItem>
						<MenuItem value="RETIRED">Retired</MenuItem>
						<MenuItem value="SERVICE">Service</MenuItem>
						<MenuItem value="STUDENT">Student</MenuItem>
						<MenuItem value="OTHERS">Others</MenuItem>

					</SelectValidator>

					<div style={{ width: 450, paddingTop: 100, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
						<OutlinedButton
							style={{ width: 200 }}
							onClick={handleBack}>
							Previous
            		</OutlinedButton>
						<ContainedButton
							color={!actionCondition && "default"}
							style={{ width: 200 }}
							onClick={actionCondition ? () => changeStep(2) : () => { }}>
							Continue
            		</ContainedButton>
					</div>
				</ValidatorForm>
			</div>
		)
	}
}

const STYLES = {
	headline: {
		fontSize: 24
	}
}