import utils from 'resources/utils'
import { toggleLoader, toggleError, getAccountInfo_Function } from 'actions/actions'
import _ from 'lodash'

export const EXPENSES_RECEIVED = 'EXPENSES_RECEIVED'
export const EXPENSE_TRANSACTIONS_RECEIVED = 'EXPENSE_TRANSACTIONS_RECEIVED'
export const ANALYSIS_DETAILS_RECEIVED = 'ANALYSIS_DETAILS_RECEIVED'
export const SUBSCRIPTIONS_RECEIVED = 'SUBSCRIPTIONS_RECEIVED'
export const MERCHANT_HISTORY_RECEIVED = 'MERCHANT_HISTORY_RECEIVED'

const requestExpenseDetailedTransactions = (category, month, year) => (
	utils.request({
		url: `/pfm/expense-transactions/${year}/${month}/${encodeURIComponent(category)}`
	})
)

const requestAnalysisDetails = (year) => (
	utils.request({
		url: `/pfm/spending-analysis/${year}`
	})
)

const requestSubscriptions = () => (
	utils.request({
		url: `/pfm/subscriptions`
	})
)

const requestExpenseDetails = (month, year) => (
	utils.request({
		url: `/pfm/expense-details/${year}/${month}`,
	})
)

const requestMerchantHistory = (merchantId, year, month) => (
	utils.request({
		url: `/pfm/merchant-history/${merchantId}/${year}/${month}`,
	})
)

export const getExpenseDetails = (month, year) => (dispatch, getState) => {
	dispatch(toggleLoader())

	let state = getState()

	Promise.all([
		_.isEmpty(state.Expense.expense_comparison) ? dispatch(getExpenseDetails_Fn(month, year)) : Promise.resolve(),
		(_.isEmpty(state.App.accountInfo) && _.get(state, "App.userInfo.sign_up_status_2" == 'completed')) ? dispatch(getAccountInfo_Function()) : Promise.resolve(),
	]).then(res => {
		dispatch(toggleLoader())
	})
}

export const getExpenseDetails_Fn = (month, year) => dispatch => {
	return requestExpenseDetails(month, year).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(expenseDetailsReceived(_.get(res, 'data') || {}))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
		}
	)
}

export const getExpenseDetailedTransactions = (category, month, year, getExpenseDetails = false) => dispatch => {
	dispatch(toggleLoader())

	Promise.all([
		getExpenseDetails ? dispatch(getExpenseDetails_Fn(month, year)) : Promise.resolve(),
		dispatch(getExpenseDetailedTransactions_Fn(category, month, year))
	]).then(res => {
		dispatch(toggleLoader())
	})
}


export const getExpenseDetailedTransactions_Fn = (category, month, year) => dispatch => {

	return requestExpenseDetailedTransactions(category, month, year).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				return
			}
			dispatch(expenseDetailedTransactionsReceived(_.get(res, 'data.data') || []))
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(expenseDetailedTransactionsReceived([]))
		}
	)
}


export const getAnalysisDetails = (year) => dispatch => {
	dispatch(toggleLoader())
	requestAnalysisDetails(year).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(analysisDetailsReceived(_.get(res, 'data') || []))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const getSubscriptions = () => dispatch => {
	dispatch(toggleLoader())
	requestSubscriptions().then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}
			dispatch(subscriptionsReceived(_.get(res, 'data.data') || []))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const getMerchantHistory = (merchantId) => dispatch => {
	dispatch(toggleLoader())
	requestMerchantHistory(merchantId, 2020, 2).then(
		res => {
			if (res.status !== 200) {
				console.log("Something went wrong")
				dispatch(toggleLoader())
				return
			}

			dispatch(merchantHistoryReceived(_.get(res, 'data.data') || []))
			dispatch(toggleLoader())
		},
		err => {
			console.log(JSON.stringify(err), 'error')
			toggleError(err)
			dispatch(toggleLoader())
		}
	)
}

export const merchantHistoryReceived = (merchantHistory) => ({
	type: MERCHANT_HISTORY_RECEIVED,
	merchantHistory
})

const expenseDetailedTransactionsReceived = (expenseTrans) => ({
	type: EXPENSE_TRANSACTIONS_RECEIVED,
	expenseTrans
})

const expenseDetailsReceived = (expenses) => ({
	type: EXPENSES_RECEIVED,
	expenses
})

const analysisDetailsReceived = (analysisDetails) => ({
	type: ANALYSIS_DETAILS_RECEIVED,
	analysisDetails
})

const subscriptionsReceived = (subscriptions) => ({
	type: SUBSCRIPTIONS_RECEIVED,
	subscriptions
})