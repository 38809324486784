export default {
	sign_up_status: {
		FIRST_STAGE_INCOMPLETE: "first_stage_incomplete",
		FIRST_STAGE_PHONE_VERIFY: "first_stage_phone_verify",
		FIRST_STAGE_COMPLETE: "first_stage_complete",
		SECOND_STAGE_WAITLISTED: "second_stage_waitlisted",
		SECOND_STAGE_PROCESSING: "second_stage_processing",
		SECOND_STAGE_VERIFY_PHONE: "second_stage_verify_phone",
		SECOND_STAGE_REVIEW_DOCS: "second_stage_review_docs",
		SECOND_STAGE_INVALID_SSN: "second_stage_invalid_ssn",
		COMPLETED: "completed",
	},

	mutual_fund_status: {
		CHECK_KYC: "check_kyc",
		CREATE_INVESTOR: "create_investor",
		COMPLETED: "completed"
	},

	us_investing_status: {
		NO_ACCOUNT: "no_account",
		UPLOAD_KYC: "upload_kyc",
		PROCESSING: "processing",
		CUSTOMER_CARE_ONBOARDING: "customer_care_onboarding",
		PAYMENT_REQUIRED: "payment_required",
		PAYMENT_PROCESSING: "payment_processing",
		PAYMENT_AUTHORIZED: 'payment_authorized',
		PAYMENT_FAILED: "payment_failed",
		COMPLETED: "completed",
		REJECTED: "rejected",
	}
}