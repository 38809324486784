import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Grid, Button, Divider } from '@material-ui/core'

import Text from "components/Text"
import Theme from "resources/Theme"

class Step4 extends Component {
	render() {
		const { formData, handleBack } = this.props.parentProps
		const { userInfo, fd } = this.props
		return (
			<div style={STYLES.container}>
				<div style={STYLES.headerContainer} className="pointer" onClick={handleBack}>
					<span className="material-icons" style={{ fontSize: 34, color: Theme.Colors.warmGrey }}>chevron_left</span>
					<Text style={STYLES.backText}>Payment details</Text>
				</div>
				<div style={STYLES.contentContainer}>
					<div style={STYLES.rectWhite}>
						<Grid container>
							<Grid item xs={12} sm={12}>
								<Text style={{
									fontSize: "21px",
									fontWeight: 'bold',
									fontStretch: 'normal',
									fontStyle: 'normal',
									lineHeight: 'normal',
									letterSpacing: "0.7px"
								}}>Congratulations! </Text>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Text style={{ marginTop: "1vh", fontSize: "14px", color: Theme.Colors.greyishBrown }}>Please transfer <span style={{ color: Theme.Colors.primaryPurple }}>₹ {new Intl.NumberFormat('en-US').format(formData.amount)} {"  "}</span>to the following account to be issued your Fixed Deposit.</Text>
							</Grid>
							<Divider style={STYLES.divider} />
							<Grid item xs={12} sm={12} container style={{ marginTop: "1vh" }} spacing={4}>
								{_.map(
									[
										..._.map(fd.paymentAddress, (value, key) => {
											return { label: _.capitalize(key), detail: value }
										}),
										{
											label: "Instructions",
											detail: (
												<Grid item xs={12} sm={12}>
													<Text style={{ fontSize: 16 }}>
														Please send the following note with the transfer:{" "}
													</Text>
													<Text
														bold
														style={{ fontSize: 17, marginTop: 5 }}
													>
														FD from{" "}
														{userInfo.basic_information.name}
													</Text>
												</Grid>
											),
											instruction: true,
										},
									],
									(value, index) => {
										return (
											<Grid item xs={12} sm={4} key={`bank${index}`} style={{
												...STYLES.row,
												...(index === 0 && {
													borderTopColor: Theme.Colors.whiteTwo,
													borderTopWidth: 1,
												}),
											}}>
												<Text
													style={{
														fontSize: 14,
														color: Theme.Colors.warmGreyTwo,
														// padding: "1vh 0"
													}}
												>
													{value.label === "Swift" && "SWIFT" || value.label === "Account_no" && "Account No" || value.label}
												</Text>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														marginTop: 10,
													}}
												>
													{value.instruction ? (
														value.detail
													) : (
														<Text
															style={{
																fontSize: 16,
																paddingBottom: "1vh 0",
																color:
																		value.color ||
																		Theme.Colors.black,
															}}
														>
															{value.detail}
														</Text>
													)}
												</div>
											</Grid>
										)
									}
								)}
							</Grid>
							<Grid container justify="center">
								<Button style={STYLES.primaryBtn} onClick={handleBack}>
									DONE
								</Button>
							</Grid>
						</Grid>

					</div>

				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ["userInfo"]),
	..._.pick(state.NRI_Banking, ["fd"]),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Step4)

const STYLES = {
	divider: {
		marginTop: "28px"
	},
	headerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "left",
		paddingTop: 22,
	},
	contentContainer: {
		width: "90%",
	},
	headline: {
		fontSize: 24,
		marginTop: 30,
	},
	backText: {
		paddingLeft: "5px",
		fontFamily: 'Muli',
		fontSize: 26,
		fontWeight: 600,
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 0.87,
		color: Theme.Colors.warmGrey
	},
	backBtn: {
		position: "absolute",
		left: 0,
		marginLeft: "3%",
		marginTop: 20,
	},
	rectWhite: {
		width: "100%",
		padding: "48px",
		marginTop: "2vh",
		minHeight: "147px",
		border: 'solid 1px #f5f5f5',
		backgroundColor: Theme.Colors.white,
	},
	actionButton: {
		marginTop: 40,
		marginBottom: 20,
		width: "90%",
		marginLeft: "5%",
	},
	row: {
		borderBottomColor: Theme.Colors.whiteTwo,
		borderBottomWidth: 1,
		paddingHorizontal: "6%",
		paddingVertical: "3%",
	},
	primaryBtn: {
		width: 170,
		height: 45,
		borderRadius: 5,
		backgroundColor: Theme.Colors.primaryPurple,
		color: Theme.Colors.white,
		textTransform: "uppercase",
		marginLeft: "10px",
		marginTop: "50px",
		fontWeight: 700
	}
}