export default [
	{
		"name": "Euro",
		"currency": "EUR",
		"flag": require("images/Flags/eur.png")
	},
	{
		"name": "British pound",
		"currency": "GBP",
		"flag": require("images/Flags/gbp.png")
	},
	{
		"name": "Indian rupee",
		"currency": "INR",
		"flag": require("images/Flags/inr.png")
	},
	{
		"name": "Canadian dollar",
		"currency": "CAD",
		"flag": require("images/Flags/cad.png")
	},
	{
		"name": "Argentine peso",
		"currency": "ARS",
		"flag": require("images/Flags/ars.png")
	},
	{
		"name": "Brazilian real",
		"currency": "BRL",
		"flag": require("images/Flags/brl.png")
	},
	{
		"name": "Chilean peso",
		"currency": "CLP",
		"flag": require("images/Flags/clp.png")
	},
	{
		"name": "Costa Rican colón",
		"currency": "CRC",
		"flag": require("images/Flags/crc.png")
	},
	{
		"name": "Mexican peso",
		"currency": "MXN",
		"flag": require("images/Flags/mxn.png")
	},
	{
		"name": "Peruvian nuevo sol",
		"currency": "PEN",
		"flag": require("images/Flags/pen.png")
	},
	{
		"name": "Uruguayan peso",
		"currency": "UYU",
		"flag": require("images/Flags/uyu.png")
	},
	{
		"name": "Swiss franc",
		"currency": "CHF",
		"flag": require("images/Flags/chf.png")
	},
	{
		"name": "Danish krone",
		"currency": "DKK",
		"flag": require("images/Flags/dkk.png")
	},
	{
		"name": "Norwegian krone",
		"currency": "NOK",
		"flag": require("images/Flags/nok.png")
	},
	{
		"name": "Swedish krona",
		"currency": "SEK",
		"flag": require("images/Flags/sek.png")
	},
	{
		"name": "Bulgarian lev",
		"currency": "BGN",
		"flag": require("images/Flags/bgn.png")
	},
	{
		"name": "Czech koruna",
		"currency": "CZK",
		"flag": require("images/Flags/czk.png")
	},
	{
		"name": "Croatian kuna",
		"currency": "HRK",
		"flag": require("images/Flags/hrk.png")
	},
	{
		"name": "Hungarian forint",
		"currency": "HUF",
		"flag": require("images/Flags/huf.png")
	},
	{
		"name": "Polish złoty",
		"currency": "PLN",
		"flag": require("images/Flags/pln.png")
	},
	{
		"name": "Romanian leu",
		"currency": "RON",
		"flag": require("images/Flags/ron.png")
	},
	{
		"name": "Russian rouble",
		"currency": "RUB",
		"flag": require("images/Flags/rub.png")
	},
	{
		"name": "Ukrainian hryvnia",
		"currency": "UAH",
		"flag": require("images/Flags/uah.png")
	},
	{
		"name": "Georgian lari",
		"currency": "GEL",
		"flag": require("images/Flags/gel.png")
	},
	{
		"name": "Australian dollar",
		"currency": "AUD",
		"flag": require("images/Flags/aud.png")
	},
	{
		"name": "New Zealand dollar",
		"currency": "NZD",
		"flag": require("images/Flags/nzd.png")
	},
	{
		"name": "United Arab Emirates dirham",
		"currency": "AED",
		"flag": require("images/Flags/aed.png")
	},
	{
		"name": "Israeli shekel",
		"currency": "ILS",
		"flag": require("images/Flags/ils.png")
	},
	{
		"name": "Turkish lira",
		"currency": "TRY",
		"flag": require("images/Flags/try.png")
	},
	{
		"name": "Egyptian pound",
		"currency": "EGP",
		"flag": require("images/Flags/egp.png")
	},
	{
		"name": "Moroccan dirham",
		"currency": "MAD",
		"flag": require("images/Flags/mad.png")
	},
	{
		"name": "Ghanaian cedi",
		"currency": "GHS",
		"flag": require("images/Flags/ghs.png")
	},
	{
		"name": "Kenyan shilling",
		"currency": "KES",
		"flag": require("images/Flags/kes.png")
	},
	{
		"name": "Nigerian naira",
		"currency": "NGN",
		"flag": require("images/Flags/ngn.png")
	},
	{
		"name": "Tanzanian shilling",
		"currency": "TZS",
		"flag": require("images/Flags/tzs.png")
	},
	{
		"name": "Ugandan shilling",
		"currency": "UGX",
		"flag": require("images/Flags/ugx.png")
	},
	{
		"name": "West African CFA franc",
		"currency": "XOF",
		"flag": require("images/Flags/xof.png")
	},
	{
		"name": "South African rand",
		"currency": "ZAR",
		"flag": require("images/Flags/zar.png")
	},
	{
		"name": "Bangladeshi taka",
		"currency": "BDT",
		"flag": require("images/Flags/bdt.png")
	},
	{
		"name": "Nepalese rupee",
		"currency": "NPR",
		"flag": require("images/Flags/npr.png")
	},
	{
		"name": "Sri Lankan rupee",
		"currency": "LKR",
		"flag": require("images/Flags/lkr.png")
	},
	{
		"name": "Pakistani rupee",
		"currency": "PKR",
		"flag": require("images/Flags/pkr.png")
	},
	{
		"name": "Chinese yuan",
		"currency": "CNY",
		"flag": require("images/Flags/cny.png")
	},
	{
		"name": "Hong Kong dollar",
		"currency": "HKD",
		"flag": require("images/Flags/hkd.png")
	},
	{
		"name": "Indonesian rupiah",
		"currency": "IDR",
		"flag": require("images/Flags/idr.png")
	},
	{
		"name": "Japanese yen",
		"currency": "JPY",
		"flag": require("images/Flags/jpy.png")
	},
	{
		"name": "South Korean won",
		"currency": "KRW",
		"flag": require("images/Flags/krw.png")
	},
	{
		"name": "Malaysian ringgit",
		"currency": "MYR",
		"flag": require("images/Flags/myr.png")
	},
	{
		"name": "Philippine peso",
		"currency": "PHP",
		"flag": require("images/Flags/php.png")
	},
	{
		"name": "Singapore dollar",
		"currency": "SGD",
		"flag": require("images/Flags/sgd.png")
	},
	{
		"name": "Thai baht",
		"currency": "THB",
		"flag": require("images/Flags/thb.png")
	},
	{
		"name": "Vietnamese dong",
		"currency": "VND",
		"flag": require("images/Flags/vnd.png")
	}
]