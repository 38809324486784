import React, { Component } from 'react'
import Text from 'components/Text'
import { connect } from 'react-redux'
import _ from 'lodash'
import Theme from 'resources/Theme';
import { Button } from '@material-ui/core';
import Header from 'components/Header';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { changePin } from "./actions"

const NodeRSA = require('node-rsa');

let key = new NodeRSA(`-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAqNaLCcO7Xguc9crLNjGmcaRjUwPULdWo2NWDIYvEep8Yte5o3fIB
atYWmPDKHutSOyYhe6kzyh+5+H+lBhhEd02XxL+8vhF+Gw6D3TZpZEszIH4w8Cvs
xMG43lJoWi3zP+K1RKzKd9CnRbAPV/5sRpKFdKf+/XOdSIAXIAjKEOvlPfzOU3Nn
CW2RHGDBFBu1/RPc70IgBmZNorzeC6ThrwQe6I851WerGt2IQABttCQVGlHlVD+2
XoElkLQTeeLVb5rPA+QhObj8bwhSqOAoVd+ECIrvbqWZ5ySpzYIWB3zgmIN5Hz8P
Mvyqlp+1xeZo3lYNgL/wL9KOI+y8kqCPoQIDAQAB
-----END RSA PUBLIC KEY-----`)

key.setOptions({ encryptionScheme: "pkcs1" });

class US_SetNewPinStep1 extends Component {

	state = {
		formData: {
			pin: "",
			confirmPin: ""
		},
		step: 1
	}

	handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value

		this.state.formData[name] = value
		this.setState(this.state)
	}

	changeStep = (step) => {
		this.setState({ step })
	}

	handleSubmit = async () => {
		let { formData } = this.state
		if (!(_.isEmpty(formData.pin) || formData.pin !== formData.confirmPin)) {
			let encryptedPin = await key.encrypt(formData.pin, 'base64')
			this.props.changePin(encryptedPin, this.changeStep)
		}
	}

	render() {
		let { formData, step } = this.state

		return (
			<div>
				<Header name="Set Pin" />
				<div>
					{
						step == 1 &&
						<ValidatorForm onSubmit={this.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
							<TextValidator
								type="password"
								value={formData.pin}
								onChange={this.handleChange}
								name="pin"
								label="New Pin"
								placeholder="Enter a new Pin number"
								variant="outlined"
								style={{ marginTop: 40, width: 400 }} fullWidth />

							<TextValidator
								type="password"
								value={formData.confirmPin}
								onChange={this.handleChange}
								name="confirmPin"
								label="Confirm Pin"
								placeholder="Confirm new Pin number"
								variant="outlined"
								style={{ marginTop: 40, width: 400 }} fullWidth />

							<Button
								variant="contained"
								color={(_.isEmpty(formData.pin) || formData.pin !== formData.confirmPin) ? "default" : "primary"}
								type="submit"
								style={{ marginTop: 50, width: 400 }}>
								SET
							</Button>
						</ValidatorForm>
					}

					{
						step == 2 &&
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100, paddingTop: 30 }}>
							<img src={require('images/tick_purple.png')} style={{ width: 103, height: 105 }} />
							<Text style={{ fontSize: 20, marginTop: 20 }}>Done</Text>
							<Text style={{ fontSize: 16, color: Theme.Colors.purplishGrey, textAlign: 'center', marginTop: 20 }}>Successfully changed your PIN</Text>
							<Button
								variant="contained"
								color="primary"
								style={{ marginTop: 40, width: 200 }}
								onClick={() => this.props.history.push("/user/my-cards/north-loop-us-debit/details")}>
								DONE
                			</Button>
						</div>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	..._.pick(state.App, ['userInfo']),
})

const mapDispatchToProps = dispatch => ({
	changePin: (pin, changeStep) => dispatch(changePin(pin, changeStep))
})

export default connect(mapStateToProps, mapDispatchToProps)(US_SetNewPinStep1)

const STYLES = {

}